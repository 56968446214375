<template>
  <div :class="$style.forbidden">
    <div :class="['x-error-page', `x-error-page--type-404`]">
      <div class="x-error-page--icon" />
      <div class="x-error-page--main-tip">
        {{ $t('errorPage.403', 'package') }}
      </div>
      <a-button type="primary" class="x-error-page--btn">
        <a :href="createModuleUrl('app')">
          {{ $t('errorPage.back', 'package') }}
        </a>
      </a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { ErrorPage } from '@triascloud/x-blocks';
import { createModuleUrl } from '@/utils';

@Component({
  components: { ErrorPage },
})
export default class ForbiddenPage extends Vue {
  createModuleUrl = createModuleUrl;
}
</script>
<style lang="less" module>
.forbidden {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  :global {
    .x-error-page--type-404 .x-error-page--icon {
      width: 641px;
      height: 370px;
      background-image: url('../assets/images/403.png');
    }
    .x-error-page--main-tip {
      margin-top: 65px;
    }
  }
}
</style>
