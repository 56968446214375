<template>
  <LayoutContent>
    <template v-slot:header-left>
      <span :class="$style.title">{{ $t('generalView.title') }}</span>
    </template>
    <template slot="header-right">
      <search-header
        v-if="activeKey === 'operation-safety'"
        @change="changeHandle"
      />
    </template>
    <div :class="$style.content" slot="content">
      <div :class="$style.topData">
        <div
          :class="$style.item"
          v-for="(item, idx) in topDataOptions"
          :key="idx"
          :style="{ backgroundColor: item.backgroundColor }"
        >
          <div :class="$style.textDiv">
            <div :class="$style.numberDiv">
              {{ countObj[item.number] }}
            </div>
            <div :class="$style.numberTextDiv">
              {{ item.text }}
            </div>
          </div>
          <div :class="$style.iconDiv" :style="{ boxShadow: item.boxShadow }">
            <x-icon
              :class="$style.iconStyle"
              :type="item.icon"
              :style="{ color: item.backgroundColor }"
            />
          </div>
        </div>
      </div>
      <a-tabs
        :default-active-key="activeKey"
        :tabBarStyle="{ marginLeft: '8px' }"
        @change="changeTab"
      >
        <a-tab-pane
          key="operation-safety"
          :tab="$t('generalView.tab.operations')"
        >
          <safe-operation
            v-if="activeKey === 'operation-safety'"
            :currentRange="currentRange"
            :customTime="customTime"
          />
        </a-tab-pane>
        <a-tab-pane key="device-status" :tab="$t('generalView.tab.status')">
          <deviceStatus v-if="activeKey === 'device-status'" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </LayoutContent>
</template>
<script>
import FormTab from '@/components/form-tab';
import SearchHeader from './device-status/search-header';
import { LayoutContent } from '@triascloud/x-blocks';
import { Component, Vue } from 'vue-property-decorator';
import DeviceStatus from './device-status';
import SafeOperation from './safe-operation';
import { topCounts } from '@/services/iot-electric-box/device-status';
import { timeFormat } from './safe-operation/format';
Vue.component('form-tab', FormTab);

@Component({
  components: {
    LayoutContent,
    SearchHeader,
    DeviceStatus,
    SafeOperation,
  },
})
export default class GeneralView extends Vue {
  topDataOptions = [
    {
      number: 'riseBuildingNumber',
      text: this.$t('generalView.head.liftedBuildings'),
      icon: 'tc-icon-building',
      backgroundColor: '#00b893',
      boxShadow: '-6px 0 0 4px #80dbc9',
    },
    {
      number: 'riseProjectNumber',
      text: this.$t('generalView.head.liftedProjects'),
      icon: 'tc-icon-stack-filled',
      backgroundColor: '#516CF0',
      boxShadow: '-6px 0 0 4px #a8b5f7',
    },
    {
      number: 'riseLayerNumber',
      text: this.$t('generalView.head.liftedFloors'),
      icon: 'tc-icon-stairs',
      backgroundColor: '#947DFA',
      boxShadow: '-6px 0 0 4px #c9befc',
    },
    {
      number: 'errorNumber',
      text: this.$t('generalView.head.faults'),
      icon: 'tc-icon-traffic-cone',
      backgroundColor: '#FE5959',
      boxShadow: '-6px 0 0 4px #feacac',
    },
    {
      number: 'warnNumber',
      text: this.$t('generalView.head.warnings'),
      icon: 'tc-icon-caution',
      backgroundColor: '#FFA348',
      boxShadow: '-6px 0 0 4px #ffd1a3',
    },
  ];
  countObj = {
    riseBuildingNumber: 0,
    riseProjectNumber: 0,
    riseLayerNumber: 0,
    errorNumber: 0,
    warnNumber: 0,
  };

  mounted() {
    this.getTopCounts();
  }
  async getTopCounts() {
    const params = {
      dateRangeType: this.currentRange,
    };
    if (this.currentRange === 'CUSTOMIZE') {
      params.startTime = timeFormat(this, 0);
      params.endTime = timeFormat(this, 1);
    }
    try {
      this.countObj = await topCounts(params);
    } catch {
      return false;
    }
  }
  activeKey = this.$route.query.active || 'operation-safety';
  changeTab(activeKey) {
    this.activeKey = activeKey;
    this.$router.push({ query: { active: activeKey } });
  }
  get i18n() {
    return this.$t('generalView') && this.$t('generalView');
  }
  currentRange = 'BEFORE_THIRTY_DATE';
  customTime = [];
  changeHandle(value) {
    const { currentRange, customTime } = value;
    this.currentRange = currentRange;
    this.customTime = customTime;
    this.getTopCounts();
  }
}
</script>

<style lang="less" module>
.title {
  font-size: 16px;
}
.content {
  padding: 0 20px;
  .topData {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .item {
      width: 18%;
      height: 100px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      margin-top: 20px;
      .textDiv {
        color: #fff;
        text-align: center;
        width: 200px;
        .numberDiv {
          font-weight: 500;
          font-size: 32px;
        }
        .numberTextDiv {
          font-weight: 400;
          font-size: 14px;
        }
      }
      .iconDiv {
        position: absolute;
        right: 15px;
        background-color: white;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle {
          font-size: 50px;
        }
      }
    }
  }
}
.row {
  margin-bottom: 30px;
}
</style>
