<template>
  <div>
    <div v-if="codeShow" :class="$style.codeBox">
      <x-qrcode
        @click.native="$preview($refs.qrcode.imageSrc, 'image')"
        :class="$style.code"
        :src="qrcodeSrc"
        ref="qrcode"
      ></x-qrcode>
      <a-button
        :class="$style.download"
        type="primary"
        @click="handleDownload"
        >{{ $t('matterwebset.screenmanage.downloadtext') }}</a-button
      >
    </div>
    <p>
      {{ $t('matterwebset.screenmanage.yoursharetext') }}
      {{ shareInfo.epcName || shareInfo.contractorName || areaDetail }}
      {{ $t('matterwebset.screenmanage.screentext') }}
    </p>
    <div :class="$style.shareBox">
      <span :class="$style.lable">
        {{ $t('matterwebset.screenmanage.linktext') }}
      </span>
      <a-input :value="shareInfo.shareUrl" disabled :class="$style.shareUrlBox">
        <span slot="suffix" @click="handleShowQrcode">
          <i class="TC tc-icon-erweima1" :class="$style.suffix"></i>
        </span>
      </a-input>
    </div>
    <div v-if="shareInfo.password" :class="$style.shareBox">
      <span :class="$style.lable">
        {{ $t('matterwebset.screenmanage.passwordtext') }}
      </span>
      <a-input disabled :value="shareInfo.password" :class="$style.password" />
    </div>
    <div :class="$style.buttonList">
      <a-button @click="view" v-if="showToView">
        {{
          shareInfo.isReport
            ? $t('screen.viewReport')
            : $t('matterwebset.screenmanage.toscreen')
        }}
      </a-button>
      <a-button v-if="!showToView" @click="$emit('close')">
        {{ $t('msg.cancel') }}
      </a-button>
      <a-button type="primary" @click="copy">{{
        shareInfo.password
          ? $t('matterwebset.screenmanage.copylink')
          : $t('matterwebset.screenmanage.copyShare')
      }}</a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { clipboardWrite } from '@triascloud/utils';
import XQrcode from '@/components/common/x-qrcode/index.vue';
import XDownloadAnchor from '@/components/common/x-download-anchor/index.vue';
import { createModuleUrl } from '@/utils';

@Component({ components: { XQrcode } })
export default class ShareBox extends Vue {
  @Prop({ type: Object, required: true }) shareInfo;
  @Prop({ type: Boolean, default: true }) showToView;
  codeShow = false;
  qrcodeSrc = '';
  handleShowQrcode() {
    this.codeShow = true;
    this.qrcodeSrc = `${createModuleUrl(
      'm',
    )}/#/pages/iot/shareInputPassword/index?id=${this.shareInfo.id}${
      this.shareInfo.isReport ? '&type=REPORT' : ''
    }`;
  }
  handleDownload() {
    XDownloadAnchor.download(this.$refs.qrcode.imageSrc, 'qrcode.png');
  }
  copy() {
    const {
      shareUrl,
      password,
      isReport,
      // , id: screenManageId
    } = this.shareInfo;
    let str = '';
    if (isReport) {
      str = `安监终端楼栋提升报告链接: ${shareUrl}`;
    } else {
      str = `智能安全防护平台运维大屏链接: ${shareUrl}`;
    }
    clipboardWrite(password ? `${str} 密码: ${password}` : str).then(() => {
      this.$message.success('复制成功');
    });
  }
  view() {
    window.open(this.shareInfo.shareUrl);
  }
  get areaDetail() {
    try {
      const { province, city, area } = this.shareInfo.address;
      return province
        ? Array.from(new Set([province, city, area])).join('')
        : '全国';
    } catch (error) {
      return undefined;
    }
  }
}
</script>

<style lang="less" module>
.codeBox {
  position: absolute;
  height: 100%;
  width: 246px;
  z-index: 1;
  left: calc(100% + 10px);
  top: 0;
  padding: 20px;
  background-color: var(--modal-bg);
  text-align: center;
  box-shadow: 1px 1px 10px rgba(8, 1, 3, 0.08);
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .code {
    width: 206px;
    height: 206px;
    margin-bottom: 10px;
  }
  .download {
    height: 36px;
    width: 206px;
  }
}
.shareBox {
  margin-top: 20px;
  position: relative;
  :global(.ant-input-affix-wrapper .ant-input-suffix) {
    right: -5px;
  }
  .lable {
    width: 40px;
    color: var(--font-title);
    vertical-align: middle;
    float: left;
    font-size: 14px;
    line-height: 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .shareUrlBox {
    display: inline-block;
    width: 340px;
    input {
      padding-right: 70px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .password {
    display: inline-block;
    width: 110px;
  }
  .suffix {
    font-size: 18px;
    display: inline-block;
    width: 32px;
    border-left: 1px solid var(--tag-border);
    line-height: 32px;
    cursor: pointer;
  }
  .codeBox {
    position: absolute;
    z-index: 1;
    right: 0;
    top: calc(100% + 10px);
    padding: 10px;
    background-color: #fff;
    text-align: center;
    box-shadow: 1px 1px 10px rgba(8, 1, 3, 0.08);
    border-radius: 5px;
    .code {
      width: 120px;
      height: 120px;
      display: block;
      margin-bottom: 10px;
    }
    .download {
      padding: 0px 10px;
      height: 36px;
      width: 100px;
      color: #00a4a4;
      border-color: #b3e4e4;
    }
  }
}

.buttonList {
  text-align: right;
  margin: 50px 0 25px;
  button {
    margin-left: 10px;
  }
}
</style>
