<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OssWpsService, UploadService } from '@triascloud/services';
import { PreviewType } from './type';
import PreviewImage from './image';
// import PreviewOffice from './office';
// import PreviewVideo from './video';
import { ensureArray } from '@triascloud/utils';
import { createModal, Icon as XIcon } from '@triascloud/x-components';
function normalizeOptions(options, type) {
  if (typeof options === 'string') {
    return normalizeOptions([options], type);
  } else if (Array.isArray(options)) {
    return normalizeOptions({ urls: options });
  } else {
    return {
      index: 0,
      watermark: false,
      ...options,
      urls: ensureArray(options.urls).map(url =>
        typeof url === 'string'
          ? {
              name: '',
              url,
              type,
            }
          : url,
      ),
    };
  }
}

function createPreviewModal(options, type = '', otherOpts = []) {
  if (type && Array.isArray(type)) {
    otherOpts = type;
  }
  const modal = createModal(
    h =>
      h(Preview, {
        props: { ...normalizeOptions(options, type), opts: otherOpts },
      }),
    {
      className: 'x-preview',
      width: '100vw',
    },
  );
  return modal;
}
export const getExt = url => {
  const result = /\.([^.]+)$/.exec(url.split('?')[0]);
  return result ? result[1].toLowerCase() : null;
};
export const isImage = url => {
  const ext = getExt(url);
  if (!ext) return false;
  return [
    'png',
    'jpg',
    'jpeg',
    'gif',
    'svg',
    'ico',
    'bmp',
    'webp',
    'dcm',
  ].includes(ext);
};
export const isVideo = url => {
  const ext = getExt(url);
  if (!ext) return false;
  return ['mp4', 'ogg', 'mpeg4', 'wehm'].includes(ext);
};
export const isOffice = url => {
  const ext = getExt(url);
  if (!ext) return false;
  return [
    'pptx',
    'ppt',
    'pot',
    'potx',
    'pps',
    'ppsx',
    'dps',
    'dpt',
    'pptm',
    'potm',
    'ppsm',
    'xls',
    'xlt',
    'et',
    'ett',
    'xlsx',
    'xltx',
    'csv',
    'xlsb',
    'xlsm',
    'xltm',
    'doc',
    'dot',
    'wps',
    'wpt',
    'docx',
    'dotx',
    'docm',
    'dotm',
    'wpss',
    'dpss',
    'ets',
    'pdf',
    'lrc',
    'c',
    'cpp',
    'h',
    'asm',
    's',
    'java',
    'asp',
    'bat',
    'bas',
    'prg',
    'cmd',
    'rtf',
    'txt',
    'log',
    'xml',
    'htm',
    'html',
    'Zip',
    '7z',
    'rar',
  ].includes(ext);
};

@Component()
export default class Preview extends Vue {
  @Prop({ type: Number, default: 0 }) index;
  @Prop({ type: Array, default: () => [] }) urls;
  @Prop({ type: Boolean, default: false }) watermark;

  static ossBasePath = '/oss/oss';
  @Prop({ type: String }) ossBasePath;
  @Prop() ossService;
  get computedOssService() {
    return (
      this.ossService ||
      new UploadService(this.ossBasePath || Preview.ossBasePath)
    );
  }

  static wpsBasePath = '/oss/wps';
  @Prop({ type: String }) wpsBasePath;
  @Prop() wpsService;
  get computedWpsService() {
    return (
      this.wpsService ||
      new OssWpsService(this.wpsBasePath || Preview.wpsBasePath)
    );
  }

  static createModal = createPreviewModal;
  currentIndex = 0;
  loading = true;
  loadedUrls = [];
  async created() {
    this.currentIndex = this.index;
    await this.getPositionUrl();
    this.loading = false;
  }
  async getPositionUrl() {
    const urlMap = {};
    const ossPaths = this.urls
      .map(item => ({
        ...item,
        ossPath:
          (item.type !== PreviewType.Image &&
            this.isOssPathOrExpired(item.url)) ||
          '',
      }))
      .filter(item => item.ossPath);
    if (ossPaths.length) {
      const urls = await this.computedOssService.getDownloadAuth(
        ossPaths.map(item => ({
          filename: `${item.name || 'file'}.${getExt(item.ossPath)}`,
          path: decodeURIComponent(item.ossPath),
        })),
      );
      urls.forEach((url, index) => {
        url && url.includes('http') && (urlMap[ossPaths[index].url] = url);
      });
    }
    this.loadedUrls = this.urls.map(item => ({
      ...item,
      name: item.name,
      url: urlMap[item.url] || item.url,
    }));
  }
  isOssPathOrExpired(url) {
    if (url.indexOf('base64') !== -1) {
      return false;
    } else if (url.indexOf('http') !== -1) {
      const uri = this.getUriParams(url);
      return uri
        ? uri &&
            uri.query.Expires &&
            Number(uri.query.Expires) < new Date().getTime() / 1000 &&
            uri.path.substr(1)
        : false;
    } else if (url.indexOf('blob') !== -1) {
      return false;
    } else if (url.indexOf('ftp') !== -1) {
      return false;
    } else if (isImage(url)) {
      // 图片直接给oss-image处理
      return false;
    }
    return url;
  }
  /**
   * 解析链接为地址对象
   * null | { path: string; query: Record<string, string> }
   * @param uri 链接
   */
  getUriParams(uri) {
    let url = null;
    try {
      url = new URL(uri);
    } catch (e) {
      return null;
    }
    /** @type { Record<string, string> } */
    const query = {};
    url.search
      .replace('?', '')
      .split('&')
      .forEach(params => {
        query[params.split('=')[0]] = params.split('=')[1];
      });
    return {
      path: url.pathname,
      query,
    };
  }
  prev() {
    this.currentIndex =
      this.currentIndex < 1 ? this.urls.length - 1 : this.currentIndex - 1;
  }
  next() {
    this.currentIndex =
      this.currentIndex >= this.urls.length - 1 ? 0 : this.currentIndex + 1;
  }
  @Watch('currentIndex')
  handleIndexChange(index) {
    this.$emit('currentChange', index);
  }
  getUrlType(url, type = PreviewType.Auto) {
    if (type !== PreviewType.Auto) {
      return type;
    } else if (isImage(url)) {
      return PreviewType.Image;
    } else if (isVideo(url)) {
      return PreviewType.Video;
    } else {
      return PreviewType.File;
    }
  }
  renderPreviewContent(item, opt) {
    const type = this.getUrlType(item.url, item.type || PreviewType.Auto);
    switch (type) {
      case PreviewType.Image:
        return (
          <PreviewImage
            service={this.computedOssService}
            watermark={this.watermark}
            url={item.url}
            name={item.name || 'image'}
            opt={opt}
          />
        );
      // case PreviewType.Video:
      //   return <PreviewVideo url={item.url} />;
      // default:
      //   return isOffice(item.url) ? (
      //     <PreviewOffice
      //       name={item.name || 'file'}
      //       basePath={this.wpsBasePath || Preview.wpsBasePath}
      //       url={item.url}
      //     />
      //   ) : (
      //     <span class="x-preview-content-tip">
      //       {this.$t('preview.tip', 'package')}
      //     </span>
      //   );
    }
  }

  get currentUrl() {
    return this.loadedUrls[this.currentIndex] || null;
  }

  getDownloadName({ name, url }) {
    if (name && !isImage(name) && !isVideo(name) && !isOffice(name)) {
      name += `.${getExt(url)}`;
    }
    return (
      name ||
      url
        .split('?')[0]
        .split('/')
        .pop() ||
      url
    );
  }

  renderHeader(url) {
    if (!url) return null;
    return [
      this.loadedUrls.length > 1
        ? `${this.currentIndex + 1}/${this.loadedUrls.length}  `
        : null,
      url.name || this.$t('preview.title', 'package'),
      this.getUrlType(url.url, url.type) === PreviewType.Image ? null : (
        <a
          class="x-preview-header__download"
          href={url.url}
          download={this.getDownloadName(url)}
        >
          <XIcon type="vertical-align-bottom" />
        </a>
      ),
    ];
  }

  /** @name 第三方配置参数 */
  @Prop({ type: Array }) opts;

  render() {
    if (this.loading) {
      return <ASpin spinning={true} class="x-preview-loading"></ASpin>;
    }
    let otherOpt = {};
    if (this.opts && this.opts.length > 0) {
      otherOpt = this.opts[this.currentIndex];
    }
    return (
      <div class="x-preview-body">
        <div class="x-preview-header">{this.renderHeader(this.currentUrl)}</div>
        <div class="x-preview-content">
          {this.renderPreviewContent(
            this.loadedUrls[this.currentIndex],
            otherOpt,
          )}
          {this.urls.length > 1
            ? [
                <XIcon
                  type="left"
                  class="x-preview-button x-preview-button--left"
                  onClick={e => {
                    e.stopPropagation();
                    this.prev();
                  }}
                />,
                <XIcon
                  type="right"
                  class="x-preview-button"
                  onClick={e => {
                    e.stopPropagation();
                    this.next();
                  }}
                />,
              ]
            : null}
        </div>
      </div>
    );
  }
}
</script>
<style lang="less" module></style>
