import request from '../request';

const serviceName = '/iot-electric-box/client';

/**
 * @name 提升层数
 */
export function getClimbRiseCount(data) {
  return request(`${serviceName}/operationalSafety/climbRiseCount`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 提升记录
 */
export function getReportRelationCount(data) {
  return request(`${serviceName}/operationalSafety/reportRelationCount`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 提升次数
 */
export function getBuildingRiseRccord(data) {
  return request(`/things/client/safetyPlatform/listBuildingRiseRecord`, {
    method: 'GET',
    body: data,
  });
}
