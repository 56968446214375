import baseRequest from '@triascloud/request';
import { ssoClient } from '@triascloud/sso-client';
import { promiseLock } from '@triascloud/utils';
import { globalVueI18n } from '@triascloud/i18n';
import closeableMessage from '@/components/closeable-message';
import TenantError from '@/components/tenant-error';
import { ErrorCode } from '@/enum/code';
import router from '@/router';
import { createModuleUrl } from '@/utils';
const lockedMessage = promiseLock(
  errorMessage => closeableMessage.error(errorMessage),
  errorMessage => errorMessage,
);

let customHeaders = {};
/**
 * @name 批量设置header
 * @param { Record<string, string> } headers
 */
export const setHeaders = headers => {
  customHeaders = {
    ...customHeaders,
    ...headers,
  };
};

/** @type { typeof baseRequest } */
export default async function request(relativeUrl, options = {}) {
  const absoluteUrl = relativeUrl.startsWith('/')
    ? createModuleUrl('api', 'https:') + relativeUrl
    : relativeUrl;
  const headers = { ...customHeaders };
  if (options.auth !== false) {
    headers['X-Access-Token'] =
      ssoClient.getTokenSync() || (await ssoClient.getToken());
  }
  return baseRequest(absoluteUrl, {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  }).catch(async error => {
    error.url = relativeUrl;
    if (
      error instanceof DOMException &&
      error.code === DOMException.ABORT_ERR
    ) {
      throw error;
    }
    switch (error.code) {
      case ErrorCode.NotLogin:
        if (options.auth !== false) {
          await ssoClient.logout(true);
          return request(relativeUrl, options);
        }
        break;
      case ErrorCode.Forbidden:
        if (router.currentRoute.path !== '/403') {
          router.replace('/403');
        }
        break;
      case ErrorCode.TenantError:
        if (options.displayTenantError !== false) {
          await TenantError.create(error.message);
          return request(relativeUrl, options);
        }
        break;
      default: {
        if (options.displayError !== false) {
          const errorMessage =
            error &&
            (error.message ||
              error.errmsg ||
              globalVueI18n.translate('msg.unknownError', 'package'));
          !options.closeMessage && lockedMessage(errorMessage);
        }
      }
    }
    throw error;
  });
}
