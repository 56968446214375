<template>
  <div :class="$style.divCard">
    <div :class="$style.head">
      <div :class="$style.text">{{ $t('generalView.count.eventTypes') }}</div>
      <div>
        <a-radio-group v-model="selectTime" button-style="solid">
          <a-radio-button
            v-for="item in timeOptions"
            :key="item.text"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio-button>
        </a-radio-group>
        <a-select
          v-model="form.projectId"
          :placeholder="$t('generalView.projects')"
          show-search
          allowClear
          style="width:160px;margin-left: 10px"
          option-filter-prop="children"
          :filter-option="false"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
          @change="handProjectChange"
          @search="input => filterProjectOption(input)"
        >
          <a-select-option
            v-for="item in projects"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
          <template slot="notFoundContent">
            <a-empty :image="simpleImage" />
          </template>
        </a-select>
      </div>
    </div>
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <no-data-chart
        font="250px"
        isIcon
        :style="{ height: '100%', width: '100%' }"
      />
    </div>
    <div v-else>
      <div ref="eventCharts" style="height: 400px; width: 780px" />
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { debounce } from 'lodash-decorators';
import { eventDistribution } from '@/services/iot-electric-box/device-status';
import { getRelatedProject } from '@/services/things/project';
import { Empty } from 'ant-design-vue';
import { crossStorageModule } from '@/enum/store.js';

@Component({})
export default class EventDistribution extends Vue {
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }
  selectTime = 'CURRENT_DATE';
  timeOptions = [
    {
      text: this.$t('generalView.filter.day'),
      value: 'CURRENT_DATE',
    },
    {
      text: this.$t('generalView.filter.week'),
      value: 'BEFORE_SEVEN_DATE',
    },
    {
      text: this.$t('generalView.filter.month'),
      value: 'BEFORE_THIRTY_DATE',
    },
  ];
  @Watch('selectTime')
  chanheTime(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.selectTime = newVal;
      this.getChartList();
    }
  }
  isNoData = true;
  form = {
    projectId: undefined,
  };
  projects = [];
  mounted() {
    this.getChartList();
    this.getProjectsList();
  }
  handProjectChange(id) {
    this.form.projectId = id;
    if (!id) {
      this.projectSearchInput = '';
      this.getProjectsList();
    }
    this.getChartList();
  }
  projectSearchInput = '';
  @debounce(300)
  filterProjectOption(input) {
    this.projectSearchInput = input.toLowerCase();
    this.getProjectsList();
  }
  async getProjectsList() {
    const params = {
      'query.size': 30,
      'projectId': this.form.projectId,
    };
    if (this.projectSearchInput) {
      params.keyword = this.projectSearchInput;
    }
    const result = await getRelatedProject(params);
    this.projects = result.map(item => {
      return {
        id: item.projectId,
        name: item.projectName,
      };
    });
  }
  /**
   * ● 载荷事件 改为 故障（主控箱急停、分控箱急停、分控箱掉线、分控箱超载（原“载荷故障”）、分控箱失载（原“倾斜故障”）、分控箱倾斜）
   * ● 倾斜事件 改与 预警 （超载预警、失载预警、倾斜预警、总荷载预警、总电流预警、天气预警）
   */
  async getChartList() {
    const params = {
      dateRangeType: this.selectTime,
      idxProjectId: this.form.projectId,
    };
    try {
      let result = await eventDistribution(params);
      if (result.eventCount > 0) {
        this.isNoData = false;
        this.seriesData = [
          { value: result.error, name: 'error' },
          { value: result.warn, name: 'warn' },
        ];
        this.$nextTick(() => {
          this.initChart();
        });
      } else {
        this.isNoData = true;
      }
    } catch {
      return false;
    }
  }
  colorArr = ['#ffbf07', '#00b893', '#fe5959', '#ffa348', '#516cf0', '#947dfa'];
  legendTextObj = {
    error: '故障',
    warn: '预警',
  };
  legendData = ['error', 'warn'];
  seriesData = [];
  @crossStorageModule.State('skin') skin;
  textColor = '';
  @Watch('skin', { immediate: true, deep: true })
  changeSkin(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.textColor = newVal.mode === 'dark' ? '#999999' : '#333333';
      this.initChart();
    }
  }
  initChart() {
    if (this.seriesData.length > 0) {
      const chart = this.$refs.eventCharts;
      const myChart = echarts.init(chart);
      const textColor = this.textColor;
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: params => {
            const name = this.legendTextObj[params.data.name];
            const rate = params.percent;
            const value = params.data.value;
            return `${name}: ${value}次(${rate}%)`;
          },
        },
        color: this.colorArr,
        legend: {
          data: this.legendData,
          orient: 'vertical',
          top: '35%',
          right: 80,
          textStyle: {
            color: textColor,
          },
          formatter: name => {
            if (this.seriesData.length) {
              const item = this.seriesData.filter(
                item => item.name === name,
              )[0];
              const n = this.legendTextObj[name];
              return `${n}：${item.value}次`;
            }
          },
        },
        series: [
          {
            name: '事件分布',
            type: 'pie',
            center: ['25%', '50%'],
            radius: '70%',
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            data: this.seriesData,
          },
        ],
      };
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" module>
.divCard {
  height: 360px;
  box-shadow: 0 0 8px 0 #eeeeee;
  border-radius: 5px;
  position: relative;
  .head {
    z-index: 999;
    font-size: 14px;
    padding: 10px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .text {
      font-weight: 500;
    }
    .icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>
