import request from '../request';
import { stringify, promiseLock } from '@triascloud/utils';

const serviceName = '/things/building';

const reportList = '/things/buildingReportRelation';

// GET获取楼栋基本信息-楼栋
export function getBuildingDetail(buildingId) {
  return request(`${serviceName}/getBuildingDetail`, {
    method: 'GET',
    body: {
      buildingId,
    },
    auth: false,
  });
}

/**
 * @name 获取楼栋安全防护平台的提升记录
 */
export function getBuildingHeightLog(data) {
  return request(`${serviceName}/getBuildingHeightLog`, {
    method: 'GET',
    body: data,
    auth: false,
  });
}

/**
 * @name 获取楼栋安全指数
 */
export function getBuildSafeIndex(id) {
  return request(`${serviceName}/safeIndexByBuild/${id}`, {});
}

/**
 * @name 获取报告列表
 */
export function getReportList(params) {
  return request(`${reportList}/list`, {
    body: params,
    method: 'POST',
  });
}

/**
 * @name 获取报告列表
 */
export function getReportListByShare(params) {
  return request(`/things/backend/safetyPlatform/list`, {
    body: params,
    method: 'POST',
    auth: false,
  });
}

/**
 * @name 生成报告分享链接
 */
export function reportGenerate(data) {
  return request(`/things/reportShare/generate`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: stringify(data),
  });
}

/**
 * @name 获取3D项目概览
 */
export function getProjectData(projectId) {
  return request(`/things/3d/projectInfo/${projectId}`, {});
}

/**
 * @name 获取3D楼栋操作记录
 */
export function getBuildingRecord(data) {
  return request(`/things/3d/buildingOperationRecord`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 获取3D楼栋操作记录
 */
export const getBuildingRecordByLock = promiseLock(
  async function(data) {
    return request(`/things/3d/buildingOperationRecord`, {
      method: 'GET',
      body: data,
    });
  },
  {
    keyGenerator: data => data.layer,
    forever: true,
  },
);

/**
 * @name 获取3D楼栋电箱信息
 */
export function getBuildingInfo(buildingId) {
  return request(`/things/3d/buildingCurrentStatus/${buildingId}`, {});
}

/**
 * @name 获取分控箱状态
 */
export function getSubBoxStatus(data) {
  return request(`/things/3d/getSubStatus`, {
    body: data,
  });
}
/**
 * @name 获取主控箱状态
 */
export function getMainBoxStatus(data) {
  return request(`/things/3d/mainBoxStatus`, {
    body: data,
  });
}
/**
 * @name 主、分控箱状态
 */
export function getMainBoxDistribution(data) {
  return request(`/things/3d/mainBoxDistribution`, {
    body: data,
  });
}

/** @name 获取项目楼栋列表 */
export function getBuildingByProject(data) {
  return request(`${serviceName}/buildingByProject`, {
    method: 'GET',
    body: data,
  });
}
