<template>
  <div>
    <div :class="$style.tabHeader">
      <ul :class="$style.tabHeaderTitle">
        <li
          v-for="(item, index) in tabs"
          :key="item.label"
          :class="[
            $style.title,
            carouselCurrent === index ? $style.active : '',
          ]"
          @click="handleGoToTab(index)"
        >
          {{ item.label }}
        </li>
      </ul>
      <div>
        <slot :name="'form' + carouselCurrent" :class="$style.formBox"></slot>
        <!-- 公有的 -->
        <slot name="form" :class="$style.formBox"></slot>
      </div>
    </div>
    <a-carousel ref="carouselRef" :dots="false">
      <div>
        <slot :name="nameSolt"></slot>
      </div>
    </a-carousel>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class FormTab extends Vue {
  @Prop({ type: Array, default: () => [] }) tabs;
  @Prop({ type: Object, default: () => {} }) carouselItemStyle;
  @Prop({ type: Number, default: 0 }) activeKey;

  created() {
    this.carouselCurrent = this.activeKey;
    this.carouselCurrent && this.handleGoToTab(this.carouselCurrent);
  }
  get nameSolt() {
    return `value${this.carouselCurrent}`;
  }
  carouselCurrent = 0;
  handleGoToTab(index) {
    // 过滤点击重复项
    if (this.carouselCurrent === index) return;
    this.carouselCurrent = index;
    this.$emit('change', index);
    this.$nextTick(() => {
      this.$refs.carouselRef.goTo(index);
    });
  }
}
</script>

<style lang="less" module>
.tabHeader {
  height: 64px;
  line-height: 64px;
  display: flex;
  justify-content: space-between;

  .tabHeaderTitle {
    margin: 0;
    padding: 0;
    list-style: none;
    .title {
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      color: var(--font-info);
      &.active {
        color: var(--font);
        font-weight: 500;
      }
    }
    .title + .title {
      margin-left: 20px;
    }
  }
}
</style>
