/** @name 初始化flexiable  */
export const initFlexible = () => {
  var win = window;
  var doc = win.document;
  var docEl = doc.documentElement;
  var metaEl = doc.querySelector('meta[name="viewport"]');
  var dpr = 0;
  var scale = 0;
  if (metaEl) {
    var match = metaEl
      .getAttribute('content')
      // eslint-disable-next-line no-useless-escape
      .match(/initial\-scale=([\d\.]+)/);
    if (match) {
      scale = parseFloat(match[1]);
      dpr = parseInt(1 / scale);
    }
  }

  if (devicePixelRatio >= 3 && (!dpr || dpr >= 3)) {
    dpr = 3;
  } else if (devicePixelRatio >= 2 && (!dpr || dpr >= 2)) {
    dpr = 2;
  } else {
    dpr = 1;
  }
  scale = 1 / window.devicePixelRatio;
  dpr = window.devicePixelRatio;

  if (!metaEl) {
    metaEl = doc.createElement('meta');
    metaEl.setAttribute('name', 'viewport');
    metaEl.setAttribute(
      'content',
      'width=device-width, initial-scale=' +
        scale +
        ', maximum-scale=' +
        scale +
        ', minimum-scale=' +
        scale +
        ', user-scalable=no',
    );

    if (docEl.firstElementChild) {
      docEl.firstElementChild.appendChild(metaEl);
    } else {
      var wrap = doc.createElement('div');
      wrap.appendChild(metaEl);
      doc.write(wrap.innerHTML);
    }
  } else {
    metaEl.setAttribute(
      'content',
      'width=device-width, initial-scale=' +
        scale +
        ', maximum-scale=' +
        scale +
        ', minimum-scale=' +
        scale +
        ', user-scalable=no',
    );
  }
  // docEl.setAttribute('data-dpr', dpr);
  // if (doc.readyState === 'complete') {
  //   doc.body.style.fontSize = 12 * dpr + 'px';
  // } else {
  //   doc.addEventListener(
  //     'DOMContentLoaded',
  //     function() {
  //       doc.body.style.fontSize = 12 * dpr + 'px';
  //     },
  //     false,
  //   );
  // }
};
