import request from '../request';

const serviceName = '/things/project';

/**
 * @name 设备码绑定获取项目列表
 */
export function getProjectByCompanyId() {
  return request(`${serviceName}/getProjectByCompanyId`, {
    method: 'GET',
  });
}
/**
 * @name 我负责的项目列表
 */
export function getRelatedProject(data) {
  return request(`${serviceName}/related-project`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 获取项目信息及所含楼栋
 * @param { String } projectId 项目id
 */
export function getProjectAndBuilding(projectId) {
  return request(`${serviceName}/getProjectAndBuilding/${projectId}`, {
    method: 'GET',
  });
}

/**
 * @name 获取项目信息及所含楼栋
 * @param { String } projectId 项目id
 */
export function getProjectAndBuildingInAuthority(projectId) {
  return request(
    `${serviceName}/getProjectAndBuildingInAuthority/${projectId}`,
    {
      method: 'GET',
    },
  );
}
