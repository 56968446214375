/** @type { import('vuex').Module } */
const home = {
  namespaced: true,
  state: {
    collection: null,
    bigScreen: false,
  },
  mutations: {
    updateCollection(state, payload) {
      state.collection = payload;
    },
    setBigScreen(state, boolean) {
      state.bigScreen = boolean;
    },
  },
};

export default home;
