/** @type { import('vuex').Module } */
const project = {
  namespaced: true,
  state: {
    projects: [],
    attentionProjects: [],
    recommendProjects: [],
    hotCities: {},
    third: {
      projectName: '',
      projectId: '',
      buildingName: '',
      buildId: '',
    },
  },
  mutations: {
    updateProjects(state, payload) {
      state.projects = payload;
    },
    updateAttentionProjects(state, payload) {
      state.attentionProjects = payload;
    },
    updateRecommendProjects(state, payload) {
      state.recommendProjects = payload;
    },
    updateHotCities(state, payload) {
      state.hotCities = payload;
    },
    SET_THIRD(state, payload) {
      state.third = payload;
    },
  },
};

export default project;
