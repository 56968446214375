<template>
  <div :class="$style.headerWrap">
    <div :class="$style.header">
      <a-button
        :class="[
          $style.inButton,
          currentIndex === index ? $style.inButtonActive : '',
        ]"
        v-for="(item, index) in searchArr"
        :key="index"
        @click="selectItem(index)"
      >
        {{ item }}
      </a-button>
      <a-range-picker @change="onChange">
        <a-button
          :class="[
            $style.inButton,
            currentIndex === 3 ? $style.inButtonActive : '',
          ]"
          >{{ $t('generalView.filter.self') }}</a-button
        >
      </a-range-picker>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class SearchHeader extends Vue {
  get searchArr() {
    return [
      this.$t('generalView.filter.day'),
      this.$t('generalView.filter.week'),
      this.$t('generalView.filter.month'),
    ];
  }

  currentIndex = 2;
  form = {
    currentRange: 'BEFORE_THIRTY_DATE',
    customTime: [],
  };
  data = '';
  selectItem(index) {
    const dateKey = {
      0: 'CURRENT_DATE',
      1: 'BEFORE_SEVEN_DATE',
      2: 'BEFORE_THIRTY_DATE',
      3: 'CUSTOMIZE',
    };
    this.currentIndex = index;
    this.form.currentRange = dateKey[index];
    this.form.customTime = [];
    this.$emit('change', this.form);
  }
  onChange(a, time) {
    this.currentIndex = 3;
    this.form.currentRange = 'CUSTOMIZE';
    this.form.customTime = time;
    this.$emit('change', this.form);
  }
}
</script>

<style lang="less" module>
.headerWrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-right: 32px;
}
.header {
  display: flex;
  align-items: center;
}
.inButton {
  margin-left: 10px;
  height: 32px;
  line-height: 32px;
  padding-top: 0;
  padding-bottom: 0;

  &.inButtonActive {
    color: var(--font-active);
    border-color: var(--font-active);
  }
}
.item {
  padding-right: 20px;
  cursor: pointer;
  color: var(--font-info);
  position: relative;
  .itemInner {
    background: var(--main-bg);
  }
}

.active {
  color: var(--font);
  font-weight: bold;
}
</style>
