export class Settings {
  defaultColorSet = [
    '#f99603',
    '#fa541c',
    '#4771ff',
    '#00b3b3',
    '#04a9f3',
    '#9743fe',
    '#41c400',
    '#ff333d',
    '#888888',
    '#000000',
    '#ffffff',
  ];

  /** 默认的前景色 */
  defaultColor = this.defaultColorSet[0];
  /** 默认的填充色 */
  defaultFillColor = this.defaultColorSet[0];
  /** 默认的stroke颜色 */
  defaultStrokeColor = this.defaultColorSet[10];
  /** 默认的highlighter颜色 */
  defaultHighlightColor = this.defaultColorSet[3];

  defaultStrokeWidth = 3;

  defaultStrokeDasharray = '';

  defaultHighlightOpacity = 0.5;

  defaultFontFamily = 'Helvetica, Arial, sans-serif';

  defaultStrokeWidths = [1, 2, 3, 5, 10];

  defaultStrokeDasharrays = ['', '3', '12 3', '9 6 3 6'];

  defaultOpacitySteps = [0, 0.1, 0.25, 0.5, 1];

  displayMode = 'popup';

  defaultFontFamilies = [
    'Times, "Times New Roman", serif',
    'Helvetica, Arial, sans-serif',
    'Courier, "Courier New", monospace',
    'cursive',
    'fantasy',
  ];

  popupMargin = 0;

  newFreehandMarkerOnPointerUp = false;

  defaultColorsFollowCurrentColors = false;

  freehandPixelRatio = 1;

  disableRotation = false;

  uiOffsetTop;

  uiOffsetLeft;

  defaultCaptionFontSize = '1rem';

  defaultCaptionText = 'Text';
  /**
   * Enable word wrapping in text markers (`TextMarker`, `CalloutMarker`)
   *
   */
  wrapText = false;
}

/**
 * @typedef IStyleSettings
 * @property { String } canvasBackgroundColor Background color for the editor canvas when in popup mode.
 * @property { String } toolbarBackgroundColor Background color of the toolbar block.
 * @property { String } toolbarBackgroundHoverColor Background color of toolbar buttons on hover.
 * @property { String } toolbarColor Foreground color of toolbar icons.
 * @property { Number } toolbarHeight Base height of the toolbar block in pixels.
 * @property { Boolean } hideToolbar If set to true, the toolbar is hidden.
 * @property { Boolean } hideToolbox If set to true, the toolbox is hidden.
 * @property { Boolean } undoButtonVisible Is undo button visible?
 * @property { Boolean } redoButtonVisible Is redo button visible?
 * @property { Boolean } notesButtonVisible Is notes button visible?
 * @property { Boolean } zoomButtonVisible Is zoom button visible?
 * @property { Boolean } zoomOutButtonVisible Is zoom out button visible?
 * @property { Boolean } clearButtonVisible Is clear button visible?
 * @property { Boolean } resultButtonBlockVisible Are render and close buttons visible?
 * @property { String } toolboxBackgroundColor Background color of the toolbox (property panel) block.
 * @property { String } toolboxColor Foreground color of toolbox buttons and objects.
 * @property { String } toolboxAccentColor Accent color for selected toolbox objects.
 * @property { String } selectButtonColor Custom icon color for the select (pointer) toolbar button
 * @property { String } deleteButtonColor Custom icon color for the delete toolbar button
 * @property { String } okButtonColor Custom icon color for the OK (render) toolbar button
 * @property { String } closeButtonColor Custom icon color for the close (cancel) toolbar button
 * @property { String } toolbarStyleColorsClassName CSS class name defining the visual style of the toolbar block.
 * @property { String } toolbarOverflowBlockStyleColorsClassName CSS class name defining the visual style of the toolbar overflow block. Displayed when markers don't fit in the main toolbar block.
 * @property { String } toolbarButtonStyleColorsClassName CSS class name defining the visual style of the toolbar buttons.
 * @property { String } toolbarActiveButtonStyleColorsClassName CSS class name defining the visual style of the active (selected) toolbar button.
 * @property { String } toolboxStyleColorsClassName CSS class name defining the visual style of the toolbox (property panel) block.
 * @property { String } toolboxButtonStyleColorsClassName CSS class name defining the visual style of the toolbox buttons.
 * @property { String } toolboxActiveButtonStyleColorsClassName CSS class name defining the visual style of the active (selected) toolbox button.
 * @property { String } toolboxButtonRowStyleColorsClassName CSS class name defining the visual style of the panel containing toolbox buttons. That is the top level panel with buttons switching active toolbox panels.
 * @property { String } toolboxPanelRowStyleColorsClassName CSS class name defining the visual style of the panel containing specific toolbox properties. This is the popup panel that opens when a toolbox button is pressed.
 * @property { String } notesAreaStyleClassName CSS class name defining the visual style of the note editing text area.
 * @property { String } logoPosition Position logo in the free version on the bottom left or right of the marker area. Default - left.
 * @property { String } zIndex Defaults to 5 in inline mode and 1000 in popup mode.
 *
 */
