import request from '../request';

const serviceName = '/iot-electric-box';

/**
 * @name 楼栋部署
 */
export function buildingDeploy() {
  return request(`${serviceName}/deployStatById`, {
    method: 'GET',
  });
}

/**
 * @name 分控箱占比
 */
export function controlBox() {
  return request(`${serviceName}/api/device-binds/subControlBox`, {
    method: 'GET',
  });
}

/**
 * @name 部署效率
 */
export function deployEfficiently() {
  return request(`${serviceName}/efficientStatById`, {
    method: 'GET',
  });
}

/**
 * @name 事件分布
 */
export function eventDistribution(data) {
  return request(`${serviceName}/message/eventDistribution`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 地区分布
 */
export function areaDistribution(data) {
  return request(`${serviceName}/client/deviceStatus/deviceAreaCount`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 楼栋进度
 */
export function buildingSchedule(data) {
  return request(`${serviceName}/client/deviceStatus/buildingProgress`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 安全防护平台统计
 */
export function topCounts(data) {
  return request(`${serviceName}/client/operationalSafety/safePlatformCount`, {
    method: 'POST',
    body: data,
  });
}
