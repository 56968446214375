var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.hint},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',[_c('a-icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":"exclamation-circle"}}),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.$t('hat.managementModal.setting.hint.tips'))+"：")]),(_vm.haveShift)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tipsOne)}})]:_vm._e(),(!_vm.isJoin)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tipsTwo)}})]:_vm._e(),(_vm.noClock)?_c('span',[_vm._v(" "+_vm._s(this.$t('hat.managementModal.setting.hint.tipsThree', { value: _vm.deviceDetail.userName, }))+" ")]):_vm._e()],2),(_vm.haveShift)?_c('div',[(_vm.attendanceData.currentStatus == '1')?_c('div',[_c('span',{staticStyle:{"margin-left":"24px"}},[_vm._v("本轮班次：")]),_c('span',[_vm._v(_vm._s(_vm.attendanceData.current)+"（状态："+_vm._s(_vm.syncStatus[_vm.attendanceData.currentSyncStatus])),(_vm.attendanceData.currentSyncStatus != '1')?_c('a-icon',{class:_vm.$style.icon,attrs:{"type":_vm.currentLoading ? 'loading' : 'sync'},on:{"click":function($event){return _vm.synchronization(
                _vm.attendanceData.currentShift,
                'current',
                _vm.attendanceData.currentSyncStatus
              )}}}):_vm._e(),_vm._v("）")],1)]):_vm._e(),(_vm.attendanceData.nextStatus == '1')?_c('div',[_c('span',{staticStyle:{"margin-left":"24px"}},[_vm._v("下轮班次：")]),_c('span',[_vm._v(_vm._s(_vm.attendanceData.next)+"（状态："+_vm._s(_vm.syncStatus[_vm.attendanceData.nextSyncStatus])),(_vm.attendanceData.nextSyncStatus != '1')?_c('a-icon',{class:_vm.$style.icon,attrs:{"type":_vm.nextLoading ? 'loading' : 'sync'},on:{"click":function($event){return _vm.synchronization(
                _vm.attendanceData.nextShift,
                'next',
                _vm.attendanceData.nextSyncStatus
              )}}}):_vm._e(),_vm._v("）")],1)]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }