/** 返回精度为2的数字 */
export function toFixed(value, fixed = 2) {
  return Number(value.toFixed(fixed));
}

/** 16进制的颜色转rgba */
export function colorRgba(opacity = 1) {
  // 16进制颜色值的正则
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 把颜色值变成小写
  let color = this.toLowerCase();
  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      let colorNew = '#';
      for (let i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
      }
      color = colorNew;
    }
    // 处理六位的颜色值，转为RGB
    const colorChange = [];
    for (let i = 1; i < 7; i += 2) {
      colorChange.push(parseInt(`0x${color.slice(i, i + 2)}`, 16));
    }
    return `rgba(${colorChange.join(',')},${opacity})`;
  }
  return color;
}

/**
 * 通过DOMMatrix获取旋转的角度
 * @param {import("./TransformMatrix").ITransformMatrix} matrix
 */
export function getRotationAngle(matrix) {
  // 计算sin(角度) = 对角线 / 斜对角线长度
  let sin = matrix.b;
  // 计算cos(角度) = 依靠对角线长度 / 斜对角线长度
  let cos = matrix.a;
  // 使用反标量函数 arctan 计算旋转角度
  let angle = Math.atan2(sin, cos);
  // 转换为角度制
  return angle * (180 / Math.PI);
}

/**
 *
 * @param { number[] } points
 * @returns {import("./MarkerBase").IPoint[]}
 */
export function pointToObjectList(points) {
  const convertedPoints = [];

  for (let i = 0; i < points.length; i += 2) {
    const point = {
      x: points[i],
      y: points[i + 1],
    };
    convertedPoints.push(point);
  }
  return convertedPoints;
}

/**
 *
 * @param {import("./MarkerBase").IPoint[]} points
 * @returns { number[] }
 */
export function pointToSingleList(points) {
  const flattenedPoints = [];
  for (const point of points) {
    flattenedPoints.push(point.x, point.y);
  }
  return flattenedPoints;
}

export function dashToNumber(str) {
  switch (str) {
    case '':
      return 1;
    case '3':
      return 3;
    case '12 3':
      return 2;
    case '9 6 3 6':
      return 4;
    default:
      return 1;
  }
}
