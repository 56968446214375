import { createModuleUrl } from '@/utils';
export async function handleLink(projectId, buildId) {
  try {
    let url = `${createModuleUrl(
      'iot-max',
    )}/iot/screen/projectBuilding/${projectId}`;
    if (buildId) {
      url += `?b=${buildId}`;
    }
    window.open(url);
  } catch {
    return false;
  }
}
