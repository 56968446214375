import {
  Color_Rising,
  Color_Waiting,
  Color_Building,
  Color_Outing,
  Color_Electricbox,
  LEVEL_WAITING,
  LEVEL_BUILDING,
  LEVEL_OUTING,
  LEVEL_ELECTRIC_BOX,
  LEVEL_RISING,
} from '@/components/main-screen/final.js';
const KEY_VISITOR_INFO = 'visitorInfo';

function getData() {
  const data = localStorage.getItem(KEY_VISITOR_INFO);
  return data ? JSON.parse(data) : {};
}

const state = {
  visitorInfo: getData(),
  mapUrlQueue: [], // 跳转地址队列
  buttonGroups: [
    {
      label: 'screen.btn.notStarted',
      color: Color_Waiting,
      key: LEVEL_WAITING,
      selected: true,
    },
    {
      label: 'screen.btn.started',
      color: Color_Building,
      key: LEVEL_BUILDING,
      selected: true,
    },
    {
      label: 'screen.btn.terminalsInstalled',
      color: Color_Electricbox,
      key: LEVEL_ELECTRIC_BOX,
      selected: true,
    },
    {
      label: 'screen.btn.operating',
      color: Color_Rising,
      key: LEVEL_RISING,
      selected: true,
    },
    {
      label: 'screen.btn.finished',
      color: Color_Outing,
      key: LEVEL_OUTING,
      selected: true,
    },
  ],
};

const getters = {
  lastMapUrlQueueItem: state => state.mapUrlQueue[state.mapUrlQueue.length - 1],
  waitingButton: state => state.buttonGroups[0].selected,
  buildingButton: state => state.buttonGroups[1].selected,
  electricBoxButton: state => state.buttonGroups[2].selected,
  risingButton: state => state.buttonGroups[3].selected,
  outtingButton: state => state.buttonGroups[4].selected,
};

const mutations = {
  SET_VISITOR_INFO(state, info) {
    state.visitorInfo = info;
  },
  SET_MAP_URL_QUEUE(state, payload) {
    state.mapUrlQueue = payload;
  },
  SET_BUTTON_GROUPS(state, payload) {
    state.buttonGroups = payload;
  },
};

const actions = {
  setVisitorInfo({ commit }, info) {
    localStorage.setItem(KEY_VISITOR_INFO, JSON.stringify(info));
    commit('SET_VISITOR_INFO', info);
  },
  addMapUrlQueue({ commit, state }, payload) {
    commit('SET_MAP_URL_QUEUE', [...state.mapUrlQueue, payload]);
  },
  deleteMapUrlQueueOfPop({ commit, state }) {
    const arr = state.mapUrlQueue.slice();
    arr.pop();
    commit('SET_MAP_URL_QUEUE', [...arr]);
  },
  updateButtonGroups({ commit, state }, payload) {
    const arr = state.buttonGroups.slice();
    arr[payload.index].selected = payload.selected;
    commit('SET_BUTTON_GROUPS', arr);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
