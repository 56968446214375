// eslint-disable-next-line no-unused-vars
import { Settings } from '../../core/Settings';
// eslint-disable-next-line no-unused-vars
import { ToolboxPanel } from '../../ui/ToolboxPanel';
import { SvgHelper } from '../../core/SvgHelper';
import { ColorPickerPanel } from '../../ui/toolbox-panels/ColorPickerPanel';
import { LineStylePanel } from '../../ui/toolbox-panels/LineStylePanel';
import { LineWidthPanel } from '../../ui/toolbox-panels/LineWidthPanel';
import { OpacityPanel } from '../../ui/toolbox-panels/OpacityPanel';
import { PolyLinearMarkerBase } from '../PolyLinearMarkerBase';
import Icon from '!!svg-inline-loader!./polygon-marker-icon.svg';
import FillColorIcon from '!!svg-inline-loader!../../ui/toolbox-panels/fill-color-icon.svg';
import { dashToNumber, pointToObjectList } from '../../core/Utils';
import { merge } from 'lodash';

export class PolygonMarker extends PolyLinearMarkerBase {
  /**
   * String type name of the marker type.
   *
   * Used when adding {@link MarkerArea.availableMarkerTypes} via a string and to save and restore state.
   */
  static typeName = 'PolygonMarker';

  static type = 'POLYGON';

  /**
   * Marker type title (display name) used for accessibility and other attributes.
   */
  static title = 'Polygon marker';
  /**
   * SVG icon markup displayed on toolbar buttons.
   */
  static icon = Icon;

  /**
   * Visible marker polyline.
   * @type {SVGPolylineElement}
   */
  visibleLine;

  /** 实际绘制成功后的多边形 @type {SVGPolygonElement} */
  visiblePolygon;

  fillColor = 'transparent';
  /**
   * Line color.
   */
  strokeColor = 'transparent';
  /**
   * Line width.
   */
  strokeWidth = 0;
  /**
   * Line dash array.
   */
  strokeDasharray = '';
  opacity = 1;
  fillOpacity = 0.25;

  /** @type {ColorPickerPanel} */
  strokePanel;
  /** @type {ColorPickerPanel} */
  fillPanel;
  /** @type {LineWidthPanel} */
  strokeWidthPanel;
  /** @type {LineStylePanel} */
  strokeStylePanel;
  /** @type {OpacityPanel} */
  opacityPanel;
  /** @type {OpacityPanel} */
  fillOpacityPanel;

  /**
   *
   * @param {SVGGElement} container
   * @param {HTMLDivElement} overlayContainer
   * @param {Settings} settings
   */
  constructor(container, overlayContainer, settings, opts = {}) {
    super(container, overlayContainer, settings);

    this.setStrokeColor = this.setStrokeColor.bind(this);
    this.setFillColor = this.setFillColor.bind(this);
    this.setStrokeWidth = this.setStrokeWidth.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.setFillOpacity = this.setFillOpacity.bind(this);
    this.setStrokeDasharray = this.setStrokeDasharray.bind(this);
    this.createVisual = this.createVisual.bind(this);

    this.strokeColor = settings.defaultColor;
    this.strokeWidth = settings.defaultStrokeWidth;
    this.strokeDasharray = settings.defaultStrokeDasharray;
    this.fillColor = settings.defaultFillColor;

    if (opts && opts.defaultColor) {
      this.strokeColor = opts.defaultColor;
    }

    this.strokePanel = new ColorPickerPanel(
      'Line color',
      settings.defaultColorSet,
      settings.defaultColor,
    );
    this.strokePanel.onColorChanged = this.setStrokeColor;

    this.strokeWidthPanel = new LineWidthPanel(
      'Line width',
      settings.defaultStrokeWidths,
      settings.defaultStrokeWidth,
    );
    this.strokeWidthPanel.onWidthChanged = this.setStrokeWidth;

    this.strokeStylePanel = new LineStylePanel(
      'Line style',
      settings.defaultStrokeDasharrays,
      settings.defaultStrokeDasharray,
    );
    this.strokeStylePanel.onStyleChanged = this.setStrokeDasharray;

    this.fillPanel = new ColorPickerPanel(
      'Fill color',
      [...settings.defaultColorSet, 'transparent'],
      this.fillColor,
      FillColorIcon,
      'fill-color-panel',
    );
    this.fillPanel.onColorChanged = this.setFillColor;

    this.opacityPanel = new OpacityPanel(
      'Opacity',
      settings.defaultOpacitySteps,
      this.opacity,
    );
    this.opacityPanel.onOpacityChanged = this.setOpacity;

    this.fillOpacityPanel = new OpacityPanel(
      'Fill Opacity',
      settings.defaultOpacitySteps,
      this.fillOpacity,
    );
    this.fillOpacityPanel.onOpacityChanged = this.setFillOpacity;
  }

  /**
   *
   * @param {EventTarget} el
   * @returns {boolean}
   */
  ownsTarget(el) {
    if (
      super.ownsTarget(el) ||
      el === this.visual ||
      el === this.visibleLine ||
      el === this.visiblePolygon ||
      // @ts-ignore
      (this.visibleLine &&
        this.visibleLine
          .getAttribute('points')
          .includes(el.getAttribute('points'))) ||
      // @ts-ignore
      (this.visiblePolygon &&
        this.visiblePolygon
          .getAttribute('points')
          .includes(el.getAttribute('points')))
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param {import("../../core/MarkerBase").IPoint[]} points
   * @returns {string}
   */
  getPointsToString(points) {
    let str = '';
    points.forEach(point => {
      str += `${point.x},${point.y} `;
    });
    if (str.length > 0) {
      str = str.slice(0, str.length - 1);
    }
    return str;
  }

  createVisual() {
    if (this.visual) return;
    this.visual = SvgHelper.createGroup();
    const str = this.getPointsToString(this.points);
    this.visibleLine = SvgHelper.createPolyline(str, [
      ['stroke', this.strokeColor],
      ['fill', this.fillColor],
      ['fill-opacity', this.fillOpacity.toString()],
      ['stroke-width', this.strokeWidth.toString()],
    ]);

    this.visual.appendChild(this.visibleLine);

    this.addMarkerVisualToContainer(this.visual);
  }

  /** 创建Polygon */
  createVisualPolygon() {
    if (this.visual) return;
    this.visual = SvgHelper.createGroup();
    const str = this.getPointsToString(this.points);
    this.visiblePolygon = SvgHelper.createPolygon(str, [
      ['stroke', this.strokeColor],
      ['fill', this.fillColor],
      ['fill-opacity', this.fillOpacity.toString()],
      ['stroke-width', this.strokeWidth.toString()],
    ]);

    this.visual.appendChild(this.visiblePolygon);

    this.addMarkerVisualToContainer(this.visual);
  }

  /**
   * Sets line color.
   * @param color - new color.
   */
  setStrokeColor(color) {
    this.strokeColor = color;
    this.adjustVisual();
    this.colorChanged(color);
  }
  /**
   * Sets line width.
   * @param width - new width.
   */
  setStrokeWidth(width) {
    this.strokeWidth = width;
    this.adjustVisual();
  }

  /**
   * Sets line dash array.
   * @param dashes - new dash array.
   */
  setStrokeDasharray(dashes) {
    this.strokeDasharray = dashes;
    this.adjustVisual();
    this.stateChanged();
  }

  /**
   * Sets marker's opacity.
   * @param opacity - new opacity value (0..1).
   */
  setOpacity(opacity) {
    this.opacity = opacity;
    if (this.container) {
      SvgHelper.setAttributes(this.container, [
        ['opacity', this.opacity.toString()],
      ]);
    }
    this.stateChanged();
  }

  /**
   * Sets marker's fill-opacity.
   * @param opacity - new opacity value (0..1).
   */
  setFillOpacity(opacity) {
    this.fillOpacity = opacity;
    this.adjustVisual();
    this.stateChanged();
  }

  /**
   * Sets marker's fill (background) color.
   * @param color - new fill color.
   */
  setFillColor(color) {
    this.fillColor = color;
    this.adjustVisual();
    this.stateChanged();
  }

  /**
   * Returns the list of toolbox panels for this marker type.
   * @returns {ToolboxPanel[]}
   */
  get toolboxPanels() {
    return [
      this.strokePanel,
      this.fillPanel,
      this.strokeWidthPanel,
      this.strokeStylePanel,
      this.fillOpacityPanel,
    ];
  }

  /**
   * Returns current marker state that can be restored in the future.
   */
  getState() {
    const result = merge(
      {
        attributes: {
          strokeColor: this.strokeColor,
          transparency: this.opacity,
          border: this.strokeWidth,
          fillColor: this.fillColor,
          lineformat: dashToNumber(this.strokeDasharray),
          strokeDasharray: this.strokeDasharray,
          strokeWidth: this.strokeWidth,
          fillOpacity: this.fillOpacity,
        },
      },
      super.getState(),
    );
    result.typeName = PolygonMarker.typeName;
    result.type = PolygonMarker.type;

    return result;
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) down event.
   *
   * @param {import("../../core/MarkerBase").IPoint} point - event coordinates.
   * @param {EventTarget} target - direct event target element.
   */
  pointerDown(point, target) {
    super.pointerDown(point, target);
    if (this.state === 'new') {
      this.createVisual();
      this.adjustVisual();
    }
  }

  /**
   * 结束线段的绘制，将Polyline变更为Polygon
   * @param {import("../../core/MarkerBase").IPoint} point
   * @param {EventTarget} target
   */
  dblClick(point, target) {
    super.dblClick(point, target);

    this.changePolygon();
  }

  /** 将Polyline变更为Polygon */
  changePolygon() {
    this.container.removeChild(this.visual);

    const str = this.getPointsToString(this.points);

    this.visiblePolygon = SvgHelper.createPolygon(str, [
      ['stroke', this.strokeColor],
      ['fill', this.fillColor],
      ['fill-opacity', this.fillOpacity.toString()],
      ['stroke-width', this.strokeWidth.toString()],
    ]);
    this.visual = SvgHelper.createGroup();
    this.visual.appendChild(this.visiblePolygon);
    this.addMarkerVisualToContainer(this.visual);
  }

  /**
   * Adjusts visual after manipulation.
   */
  adjustVisual() {
    let visible = this.visibleLine;
    if (this.created) {
      visible = this.visiblePolygon;
    }
    if (visible) {
      const str = this.getPointsToString(this.points);

      visible.setAttribute('points', str);

      SvgHelper.setAttributes(visible, [['stroke', this.strokeColor]]);
      SvgHelper.setAttributes(visible, [['fill', this.fillColor]]);
      SvgHelper.setAttributes(visible, [
        ['fill-opacity', this.fillOpacity.toString()],
      ]);
      SvgHelper.setAttributes(visible, [
        ['stroke-width', this.strokeWidth.toString()],
      ]);
      SvgHelper.setAttributes(visible, [
        ['stroke-dasharray', this.strokeDasharray.toString()],
      ]);
    }
  }

  /**
   * Restores previously saved marker state.
   *
   * @param state - previously saved state.
   */
  restoreState(state) {
    super.restoreState(state);

    const lmState = state;
    this.strokeColor = lmState.attributes.strokeColor;
    this.strokeWidth = lmState.attributes.border;
    this.strokeDasharray = lmState.attributes.strokeDasharray;
    this.fillColor = lmState.attributes.fillColor;
    this.fillOpacity = lmState.attributes.fillOpacity;
    this.opacity = lmState.attributes.transparency;
    this.points = pointToObjectList(lmState.points);
    this.oldPoints = [];
    lmState.points.forEach(p => {
      this.oldPoints.push(JSON.parse(JSON.stringify(p)));
    });
    this.addControlGrips();

    this.createVisualPolygon();
    this.created = true;
    this.adjustVisual();
  }
}
