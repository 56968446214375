import request from '../request';
import { stringify } from '@triascloud/utils';

const serviceName = '/things/iotAuthority';

/**
 * @name 获取用户权限
 */
export function getIotAuthority() {
  return request(`${serviceName}`, {
    method: 'GET',
  });
}

/** @description 获取当前企业物联网企业是否开启模拟数据 */
export function openSimulationData() {
  return request(`${serviceName}/openSimulationData`, {
    method: 'GET',
  });
}

// 物联网企业模拟数据开关
export function updateOpenSimulationData(data) {
  return request(`${serviceName}/openSimulationData`, {
    method: 'PUT',
    body: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
