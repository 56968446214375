import dayjs from 'dayjs';
import request from '../request';

const serviceName = '/things/screenManage';

/**
 * @name 生成大屏分享链接
 */
export function generate(data) {
  return request(`${serviceName}/generate`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: data,
  });
}

/**
 * @name 密码校验
 */
export function checkUrl(data) {
  return request(`${serviceName}/checkUrl`, {
    method: 'GET',
    body: data,
    closeMessage: true,
    auth: false,
  });
}

/**
 * @name 生成链接大屏分享
 */
export function backendGenerate(data) {
  return request(`${serviceName}/backendGenerate`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 运维大屏管理获取总包列表
 */
export function getContractorUnitList(data) {
  return request(`${serviceName}/getContractorUnitList`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 运维大屏管理获取总包用户数和安监局用户数
 */
export function getUserNumber(data) {
  return request(`${serviceName}/getUserNumber`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 获取安监局省市区
 */
export function getNotRepeatingAddress(data) {
  return request(`${serviceName}/getNotRepeatingAddress`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 运维大屏管理获取租赁商列表
 */
export function getContractorList() {
  return request(`${serviceName}/getContractorList`, {
    method: 'GET',
  });
}
/**
 * @name 展示我的分享大屏列表
 */
export function getListWithTenant(data) {
  return request(`${serviceName}/listWithTenant`, {
    method: 'GET',
    body: data,
  });
}

export const isValid = item => {
  return item.isEffective ? true : false;
};
const formatExpired = item => {
  const { finallyEffectiveDate: value } = item;
  const type = {
    FOREVER: '长期有效',
    THIRTY: '30天',
    SEVEN: '7天',
    ONE: '1天',
  };
  return type[value];
};
const formatValid = item => {
  if (isValid(item)) {
    return '有效';
  } else {
    return '过期';
  }
};
const fetchName = item => {
  const nameKey = {
    EPC: item.epcName,
    CONTRACTORINFO: item.contractorName,
    SECURITY_BUREAU: formatArea(item.address),
  };
  return nameKey[item.type];
};
const formatArea = address => {
  if (address) {
    const { province, city, area } = address;
    if (!province && !city && !area) {
      return '全国';
    } else {
      return `${province}${city}${area}`;
    }
  } else {
    return '';
  }
};
const formatData = value => {
  const typeKey = {
    EPC: '总包',
    CONTRACTORINFO: '租赁商',
    SECURITY_BUREAU: '区域',
  };
  return value.map(item => {
    return {
      id: item.id,
      name: fetchName(item),
      type: typeKey[item.type],
      author: item.sharerName,
      time: dayjs(item.createDate).format('YYYY-MM-DD HH:mm'),
      expired: formatExpired(item),
      count: `${item.count}/${item.person}`,
      status: formatValid(item),
      item: item,
    };
  });
};
export async function getListWithTenantAction(data) {
  try {
    const result = await getListWithTenant(data);
    return {
      data: formatData(result.records),
      total: result.total,
      pageSize: result.size,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @name 设置分享连接过期
 */
export function linkExpired(id) {
  return request(`${serviceName}/linkExpired`, {
    method: 'PUT',
    body: id,
  });
}
/**
 * @name 加密ID
 */
export function encryptionId(data) {
  return request(`${serviceName}/encryptionId`, {
    method: 'GET',
    body: data,
  });
}
