<template>
  <div :class="$style.mapWrap">
    <div :class="$style.screen" style="z-index: 9999999">
      <x-icon
        :type="
          $store.state.home.bigScreen
            ? 'tc-icon-fullscreen-exit'
            : 'tc-icon-fullscreen'
        "
        :class="$style.icon"
        @click="handleScreen"
      />
    </div>
    <div
      id="boxMap"
      :class="$style.boxMap"
      :style="{
        width: $store.state.home.bigScreen ? '100vw' : '100%',
        height: $store.state.home.bigScreen ? '100vh' : '430px',
      }"
    ></div>
    <div :class="$style.time">
      <span :class="$style.ff"
        >{{ $t('hat.managementModal.event.time') }}:{{ time }}</span
      >
      <span :class="$style.ll">
        {{ $t('hat.managementModal.personInfo.longitude') }}:{{
          location.split(',')[0]
        }}
      </span>
      <span :class="$style.dd"
        >{{ $t('hat.managementModal.personInfo.latitude') }}:{{
          location.split(',')[1]
        }}
      </span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  funAMap,
  createPathSimplifier,
  INIT_SPEED,
} from '../../electron-fence/components/util';
import bluehat from '@/assets/images/manage-model/365.png';
import yellowhat from '@/assets/images/manage-model/366.png';
import redhat from '@/assets/images/manage-model/367.png';
import whitehat from '@/assets/images/manage-model/368.png';
import orangehat from '@/assets/images/manage-model/369.png';
import { getZonesData } from '@/services/smart-hat/geofence';
import {
  drawCharts,
  formatToApi,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';
@Component({})
export default class TrajectoryMap extends Vue {
  @Prop({ type: String, default: '' }) color;
  bigScreen = false;
  AMap = null;
  circleMarker = [];
  hat = '';
  duration = 2000;
  time = '';
  location = '';
  avatar = '';
  circle = [
    [113.454903, 23.165857],
    [113.456347, 23.168226],
  ];
  zoneArray = [];
  mounted() {
    this.setHat();
    this.addMap();
  }
  handleScreen() {
    const bigScreen = this.$store.state.home.bigScreen;
    this.$store.commit('home/setBigScreen', !bigScreen);
  }
  beforeDestroy() {
    if (this.pathSimplifier) {
      this.pathSimplifier.setData([]);
    }
  }
  async addMap() {
    const AMap = await funAMap({
      AMapUI: {
        version: '1.1',
        plugins: ['misc/PathSimplifier'],
      },
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    this.map = new AMap.Map('boxMap', {
      zoom: 15,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    this.map.on('complete', () => {
      this.$emit('map-loaded');
    });
  }
  navigator = undefined;
  pathSimplifier = undefined;
  async createPath(data) {
    if (this.pathSimplifier) {
      this.pathSimplifier.setData([]);
    }
    const { path, time, avatar } = data;

    // 提前加载图片
    const _avatar = Array.from(new Set(avatar));
    for await (const iterator of _avatar) {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.src = iterator.indexOf('https') > -1 ? iterator : this.hat;
        img.onload = () => {
          resolve(img);
        };
        img.onerror = () => {
          reject(img);
        };
      });
    }

    if (path.length > 0) {
      this.location = `${path[0][0]},${path[0][1]}`;
      this.time = time[0];
      this.avatar = avatar[0];
      [this.navigator, this.pathSimplifier] = await createPathSimplifier(
        this.map,
        path,
        idx => {
          this.location = `${path[idx][0]},${path[idx][1]}`;
          this.time = time[idx];
          this.avatar = avatar[idx];
          if (idx === path.length - 1) {
            this.stop();
            this.isFirstPlay = true;
            this.$emit('stop-play');
          }
          // 轨迹头像实时变化
          let previousIdx;
          if (avatar[idx] !== avatar[previousIdx]) {
            previousIdx = idx;
            // 覆盖修改
            this.extend(this.navigator.getStyleOptions(), {
              width: 35,
              height: 35,
              content: (ctx, x, y, width, height) => {
                const r = width / 2;
                const renderArcImg = (img, x, y, r) => {
                  ctx.save();
                  ctx.arc(x + r, y + r, r, 0, 2 * Math.PI);
                  ctx.clip();
                  ctx.drawImage(img, x, y, width, height);
                  ctx.restore();
                };
                const img = new Image();
                img.src =
                  avatar[idx].indexOf('https') > -1 ? avatar[idx] : this.hat;
                renderArcImg(img, x, y, r);
              },
            });
            // 重新绘制
            this.pathSimplifier.renderLater();
          }
        },
        {
          content: this.avatar.indexOf('https') > -1 ? this.avatar : this.hat,
          width: 35,
          height: 35,
        },
      );
    }
  }
  extend(dst) {
    if (!dst) {
      dst = {};
    }
    let slist = Array.prototype.slice.call(arguments, 1);
    for (let i = 0, len = slist.length; i < len; i++) {
      let source = slist[i];
      if (!source) {
        continue;
      }
      for (let prop in source) {
        // eslint-disable-next-line no-prototype-builtins
        if (source.hasOwnProperty(prop)) {
          dst[prop] = source[prop];
        }
      }
    }
    return dst;
  }
  async createTracePath2(data) {
    this.isFirstPlay = true; // 初始化播放状态
    await this.createPath(data);
    await this.setSpeed(this.speed);
  }
  addCircle() {
    this.circleMarker.forEach(item => {
      item.setMap(this.map);
    });
  }
  deleteCircle() {
    this.circleMarker.forEach(item => {
      this.map.remove(item);
    });
  }
  isFirstPlay = true;
  play() {
    if (this.isFirstPlay) {
      this.navigator.start();
      this.isFirstPlay = false;
    } else {
      this.navigator.resume();
    }
  }
  stop() {
    this.navigator.pause();
  }
  speed = 1;
  setSpeed(duration) {
    this.speed = duration;
    const speed = INIT_SPEED * parseFloat(duration);
    this.navigator.setSpeed(speed);
  }
  async handleFenceChange(id, listFence) {
    const item = listFence.find(v => v.geofenceId === id);
    if (item.point && item.point.lng) {
      this.map.setCenter([item.point.lng, item.point.lat]);
    }
    const array = await getZonesData({
      geofenceId: id,
    });
    this.zoneArray = formatToApi(array);
    const list = drawCharts(this.zoneArray, this.AMap, (a, b) =>
      mouseEvent(a, b, this.map),
    );
    list.forEach(item => {
      this.circleMarker.push(item);
    });
    this.map.add(list);
  }
  setHat() {
    this.hat = {
      WHITE: whitehat,
      BLUE: bluehat,
      YELLOW: yellowhat,
      RED: redhat,
      ORANGE: orangehat,
    }[this.color];
  }
}
</script>

<style lang="less" module>
.mapWrap {
  position: relative;
  height: 100%;
  width: 100%;
  .screen {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
  }
  .icon {
    font-size: 30px;
    color: var(--primary);
  }
  .boxMap {
    height: 450px;
  }

  .time {
    height: 25px;
    width: 440px;
    display: flex;
    background-color: #ffffff;
    position: absolute;
    right: 10px;
    bottom: 5px;
    padding: 0px 10px;
    line-height: 25px;
    border-radius: 5px;
    span {
      height: 25px;
    }

    .ff {
      width: 180px;
    }

    .ll {
      width: 130px;
      text-align: left;
      padding-left: 10px;
    }

    .dd {
      width: 110px;
    }
  }
}
</style>
