var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.trajectory,style:({
    width: _vm.$store.state.home.bigScreen ? '100vw' : '100%',
    height: _vm.$store.state.home.bigScreen ? '100vh' : '482px',
    position: _vm.$store.state.home.bigScreen ? 'fixed' : '',
    top: '-20px',
    left: 0,
    zIndex: 9999,
  })},[_c('div',{class:_vm.$style.select,style:({
      position: _vm.$store.state.home.bigScreen ? 'fixed' : '',
      top: '10px',
      height: 'fixcontent',
      right: '120px',
      zIndex: 9999,
    })},[_c('a-form-model',{ref:"ruleForm",attrs:{"layout":"inline"}},[_c('a-form-model-item',[_c('a-range-picker',{staticStyle:{"width":"320px"},style:({
            backgroundColor: _vm.bigScreenSelectColor,
          }),attrs:{"show-time":{ format: 'YYYY-MM-DD HH:mm' },"ranges":_vm.presetTime,"format":"YYYY-MM-DD HH:mm","placeholder":[
            _vm.$t('hat.clockRecord.startDate'),
            _vm.$t('hat.clockRecord.endDate') ],"disabled-date":_vm.disabledDate,"disabled":_vm.play},on:{"change":_vm.handleSearchTime,"ok":_vm.handleChange},model:{value:(_vm.searchForm.timeRange),callback:function ($$v) {_vm.$set(_vm.searchForm, "timeRange", $$v)},expression:"searchForm.timeRange"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"105px"},style:({
            backgroundColor: _vm.bigScreenSelectColor,
          }),attrs:{"placeholder":_vm.$t('hat.managementModal.event.member'),"allow-clear":"","disabled":_vm.play},on:{"change":_vm.handleChange},model:{value:(_vm.searchForm.userId),callback:function ($$v) {_vm.$set(_vm.searchForm, "userId", $$v)},expression:"searchForm.userId"}},_vm._l((_vm.users),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"105px"},style:({
            backgroundColor: _vm.bigScreenSelectColor,
          }),attrs:{"placeholder":_vm.$t('hat.clockRecord.electronicFence'),"allow-clear":"","disabled":_vm.play},on:{"change":_vm.handleSelectFence},model:{value:(_vm.searchForm.fence),callback:function ($$v) {_vm.$set(_vm.searchForm, "fence", $$v)},expression:"searchForm.fence"}},_vm._l((_vm.fenceList),function(item){return _c('a-select-option',{key:item.geofenceId,attrs:{"value":item.geofenceId}},[_vm._v(" "+_vm._s(item.geofenceName)+" ")])}),1)],1),_c('a-form-model-item',[_c('div',{staticStyle:{"position":"relative"}},[_c('a-select',{staticStyle:{"width":"75px"},style:({
              backgroundColor: _vm.bigScreenSelectColor,
            }),attrs:{"placeholder":"1.0X","allow-clear":"","disabled":_vm.play},on:{"change":_vm.handleSelectSpeed},model:{value:(_vm.searchForm.speed),callback:function ($$v) {_vm.$set(_vm.searchForm, "speed", $$v)},expression:"searchForm.speed"}},_vm._l((_vm.speed),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('a-button',{staticStyle:{"margin-left":"10px","min-width":"50px","height":"30px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.handlePlay}},[_vm._v(" "+_vm._s(!_vm.play ? _vm.$t('hat.managementModal.trajectory.play') : _vm.$t('generalView.key.stop'))+" ")])],1)])],1)],1),_c('div',{class:_vm.$style.map},[_c('TrajectoryMap',{ref:"trace",attrs:{"color":_vm.record.color},on:{"stop-play":_vm.handleStopPlay,"map-loaded":_vm.handleMapLoaded}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }