/**
 * @typedef ITransformMatrix
 * @property { Number } a
 * @property { Number } b
 * @property { Number } c
 * @property { Number } d
 * @property { Number } e
 * @property { Number } f
 */

/**
 * A utility class to transform between SVGMatrix and its simplified representation.
 */
export class TransformMatrix {
  /**
   * @param { SVGMatrix } matrix
   * @returns { ITransformMatrix }
   */
  static toITransformMatrix(matrix) {
    return {
      a: matrix.a,
      b: matrix.b,
      c: matrix.c,
      d: matrix.d,
      e: matrix.e,
      f: matrix.f,
    };
  }
  /**
   * @param { SVGMatrix } currentMatrix
   * @param { ITransformMatrix } newMatrix
   * @returns { SVGMatrix }
   */
  static toSVGMatrix(currentMatrix, newMatrix) {
    currentMatrix.a = newMatrix.a;
    currentMatrix.b = newMatrix.b;
    currentMatrix.c = newMatrix.c;
    currentMatrix.d = newMatrix.d;
    currentMatrix.e = newMatrix.e;
    currentMatrix.f = newMatrix.f;
    return currentMatrix;
  }
}
