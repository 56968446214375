<template>
  <div :class="$style.status">
    <div :class="$style.topWrap">
      <section :class="$style.topInfo">
        <div>
          <span v-if="deviceDetail.factoryDays > 0">
            {{ $t('hat.managementModal.status.helmetDuration ') }}：{{
              deviceDetail.factoryDays || '-'
            }}{{ $t('screen.days') }}</span
          >
          <a-tooltip v-if="deviceDetail.factoryDays > 0">
            <template slot="title">
              {{ $t('hat.managementModal.status.tips') }}
            </template>
            <a-icon type="exclamation-circle" style="margin-left: 10px" />
          </a-tooltip>
        </div>
        <div>
          <a-icon
            type="reload"
            style="margin-right: 5px;"
            @click="updateDevice('manual')"
          /><span
            >{{ $t('hat.managementModal.status.DeviceUpdateTime') }}：</span
          >{{ isreflash ? lastStatusTime : setStatusTime }}
        </div>
      </section>
    </div>
    <div :class="$style.infoItem">
      <div
        v-for="item in list"
        :key="item.title"
        :class="[$style.item, $style.card]"
      >
        <div :class="$style.iconTitle">
          <x-icon
            v-if="item.icon.indexOf('tc-') !== -1"
            :type="item.icon"
            :class="$style.leftIcon"
          ></x-icon>
          <a-icon v-else :type="item.icon" :class="$style.leftIcon" />
          <span :class="$style.title">{{ item.title }}</span>
          <a-tooltip v-if="item.tip">
            <template slot="title">
              {{ item.tip }}
            </template>
            <a-icon type="exclamation-circle" style="margin-left: 10px" />
          </a-tooltip>
          <a-tooltip v-if="item.guide">
            <template slot="title">
              {{ item.guide }}
            </template>
            <a-icon
              type="exclamation-circle"
              @click="showGuideModal"
              style="margin-left: 10px;cursor: pointer;"
            />
          </a-tooltip>
        </div>
        <div
          :class="[
            $style.text,
            deviceDetail.status !== 'ONLINE' ? $style['text--offline'] : '',
          ]"
        >
          <a-progress
            type="circle"
            v-if="item.value.str"
            :percent="item.value.diff"
            :strokeColor="StrokeColor"
            :width="60"
          >
            <template #format>
              <span :class="$style.progressLabel">{{ item.value.str }}</span>
            </template>
          </a-progress>
          <span v-else>{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import dayjs from 'dayjs';
import {
  getDeviceDetail,
  updateDevideInfo,
  getDeviceProperty,
} from '@/services/smart-hat/device';
import { Debounce } from 'lodash-decorators';
import { createModal } from '@triascloud/x-components';
import WearingGuide from './wearing-guide.vue';

@Component
export default class Status extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @InjectReactive('eventData') eventData;

  @Watch('eventData', { immediate: true, deep: true })
  changeStatus(data) {
    switch (data.event) {
      // 脱帽/戴帽
      case 'WEARING_STATUS':
        this.list[0].value = this.takeOffHatEnum[data.data.statusVal];
        break;
      // 加速计
      case 'ACCELEROMETER_STATUS':
        this.list[1].value = this.gyroscopeEnum[data.data.statusVal];
        break;
    }
  }

  /** @name 状态类型 */
  STATUS_KEY = {
    NOT_SUPPORTED: this.$t('hat.managementModal.status.unsupported'),
    NORMAL: this.$t('hat.clockRecord.normal'),
    FAULT: this.$t('screen.faults'),
    NOT_CONNECTED: this.$t('hat.managementModal.status.disconnected'),
  };
  lastStatusTime = '';
  isreflash = false;
  dayFormat(val) {
    if (val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
    }
    return val;
  }

  detail = {};
  created() {
    this.updateDevice = this.updateDevice.bind(this);
  }
  mounted() {
    this.getDeviceProperty();
  }
  get setStatusTime() {
    if (this.deviceDetail.lastStatusTime) {
      return this.$moment(this.deviceDetail.lastStatusTime).format(
        'YYYY-MM-DD HH:mm:ss',
      );
    } else {
      return '';
    }
  }
  @Debounce(500)
  async updateDevice(operationType) {
    const deviceId = this.deviceDetail.deviceId;
    try {
      await updateDevideInfo({ deviceId, type: 2 });
      if (operationType === 'manual') {
        this.$message.success(
          this.$t('hat.managementModal.status.updateMessage'),
        );
      }
      const getLocation = async () => {
        const detail = await getDeviceDetail(deviceId);
        this.isreflash = true;
        this.lastStatusTime = this.$moment(detail.lastStatusTime).format(
          'YYYY-MM-DD HH:mm:ss',
        );
      };
      setTimeout(() => {
        getLocation();
      }, 1000);
    } catch (error) {
      error && error;
    }
  }
  async getDeviceProperty() {
    this.detail = await getDeviceProperty(this.deviceDetail.deviceId);
    this.list = [
      {
        title: this.$t('hat.managementModal.status.unhatDetection'),
        value: this.showHat(this.detail.takeOffHat),
        icon: 'tc-icon-unbonnet',
        guide: this.$t('hat.deviceManagement.wearingGuide.title'),
      },
      {
        title: this.$t('hat.managementModal.status.accelerometer'),
        value: this.showGyroscope(this.detail.gyroscope),
        icon: 'tc-icon-gyroscope',
      },
      {
        title: this.$t('hat.managementModal.status.quantityElectricity'),
        value:
          this.detail.electricity && this.detail.electricity !== 'null'
            ? `${this.detail.electricity}%`
            : '-',
        icon: 'tc-icon-battery',
      },
      {
        title: this.$t('hat.managementModal.status.storage'),
        value: this.getStore(),
        icon: 'tc-icon-terabyte',
      },
      {
        title: this.$t('hat.managementModal.status.EquipmentDistance'),
        value:
          this.detail.deviceDistance && this.detail.deviceDistance !== -1
            ? `${this.detail.deviceDistance}km`
            : '-',
        icon: 'tc-icon-distance',
        tip: this.$t('hat.managementModal.status.tipsTwo'),
      },
      {
        title: this.$t('hat.managementModal.status.movementSpeed'),
        value: this.detail.velocity ? `${this.detail.velocity}m/s` : '-',
        tip: this.$t('hat.managementModal.status.tipsOne'),
        icon: 'tc-icon-run',
      },
      {
        title: this.$t('hat.managementModal.status.currentHeight'),
        value: this.detail.height
          ? `${(+this.detail.height).toFixed(2)}m`
          : '-',
        tip: this.$t('hat.managementModal.status.tipsOne'),
        icon: 'column-height',
      },
      {
        title: this.$t('hat.managementModal.status.nearCurrentDetection'),
        value: this.statusContent(this.detail.nearElectricity),
        tip: this.$t('hat.managementModal.status.tipsThree') + '：220V',
        icon: 'tc-icon-warn-electricity',
      },
      {
        title: this.$t('hat.managementModal.status.bloodPressureTesting'),
        value: this.statusContent(this.detail.bloodPressure),
        icon: 'tc-icon-bloodPressure',
      },
      {
        title: this.$t('hat.managementModal.status.harmfulGas'),
        value: this.statusContent(this.detail.harmfulGases),
        icon: 'tc-icon-mask',
      },
      {
        title: this.$t('hat.managementModal.status.heart'),
        value: this.statusContent(this.detail.heartRate, 'bmp'),
        icon: 'tc-icon-heartRate',
      },
      {
        title: this.$t('hat.managementModal.status.bloodOxygenDetection'),
        value: this.statusContent(this.detail.bloodOxygen, '%'),
        icon: 'tc-icon-bloodOxygen',
      },
      {
        title: this.$t('hat.managementModal.status.temp'),
        value: this.statusContent(this.detail.bodyTemp, '℃'),
        icon: 'tc-icon-eyedropper',
      },
    ];
  }

  statusContent(item, unit = '') {
    if (!item) return item;
    if (item.status === 'NORMAL') {
      return item.val ? item.val + unit : '';
    } else {
      return this.STATUS_KEY[item.status];
    }
  }

  getStore() {
    if (this.detail.availableStorage && this.detail.totalStorage) {
      let a = this.detail.availableStorage;
      let t = this.detail.totalStorage;
      let diff = +(+a / +t).toFixed(3) * 100;
      let str = `${a}M/${t}M`;
      if (+a > 1024) {
        a = (a / 1024).toFixed(0) + 'G';
        str = `${a}/${t}M`;
      }
      if (+t > 1024) {
        t = (t / 1024).toFixed(0) + 'G';
        str = `${a}/${t}`;
      }
      if (!diff) {
        diff = 0;
      }
      return {
        diff,
        str,
      };
    }
    return '-';
  }
  takeOffHatEnum = {
    '1': this.$t('hat.managementModal.status.capping'),
    '2': this.$t('hat.managementModal.status.uncapping'),
  };
  showHat(takeOffHat) {
    const status = takeOffHat.status;
    if (status == 'NORMAL') {
      return this.takeOffHatEnum[takeOffHat.val] || takeOffHat.val;
    } else {
      return this.STATUS_KEY[takeOffHat.status];
    }
  }
  gyroscopeEnum = {
    '1': this.$t('hat.managementModal.status.standstill'),
    '2': this.$t('hat.managementModal.status.moving'),
    '3': this.$t('hat.managementModal.status.falling'),
    '4': this.$t('hat.managementModal.status.dropping'),
  };
  showGyroscope(gyroscope) {
    const status = gyroscope.status;
    if (status == 'NORMAL') {
      return this.gyroscopeEnum[gyroscope.val] || gyroscope.val;
    } else {
      return this.STATUS_KEY[gyroscope.status];
    }
  }
  list = [];
  /**
   * 安全帽佩戴指引
   */
  showGuideModal() {
    this.modal = createModal(
      () => <WearingGuide onClose={() => this.modal.handleClose()} />,
      {
        title: this.$t('hat.deviceManagement.wearingGuide.title'),
        closable: true,
        maskClosable: false,
        width: 1300,
      },
    );
  }

  get StrokeColor() {
    try {
      return window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--font-active');
    } catch (error) {
      return '#9743fe';
    }
  }
}
</script>

<style lang="less" module>
.status {
  :global {
    .ant-progress-circle-trail {
      stroke: var(--font-color-secondary) !important;
    }
  }
  .topWrap {
    padding: 0 8px;
  }

  .topInfo {
    display: flex;
    justify-content: space-between;
    color: var(--font-info);
    margin: 10px 0 20px;
  }

  .infoItem {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 25px 22px;
    padding-right: 6px;

    .item {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-rows: 1fr 2fr;

      .iconTitle {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .leftIcon {
          margin-right: 10px;
          font-size: 16px;
        }

        .title {
          max-width: 60px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--font-info);
          font-size: 14px;
        }
      }

      .text {
        font-size: 20px;
        font-weight: 600;
        color: var(--font);
        width: 100%;
        text-align: center;
        .progressLabel {
          font-size: 10px;
          color: var(--font);
        }
      }
      .text--offline {
        color: var(--font-info);
        .progressLabel {
          font-size: 10px;
          color: var(--font-info);
        }
      }
    }
  }

  .card {
    height: 110px;
    background: var(--modal-header-bg);
    border-radius: 5px;
  }
}
.fontActive {
  color: var(--font-active);
}
</style>
