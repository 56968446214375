var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.content,style:({
    width: _vm.$store.state.home.bigScreen ? '100vw' : '100%',
    height: _vm.$store.state.home.bigScreen ? '100vh' : '100%',
    position: _vm.$store.state.home.bigScreen ? 'fixed' : '',

    top: '-0',
    left: 0,
    zIndex: 9999,
  })},[_c('div',{class:_vm.$style.screen,staticStyle:{"z-index":"9999999"}},[_c('x-icon',{class:_vm.$style.icon,attrs:{"type":_vm.$store.state.home.bigScreen
          ? 'tc-icon-fullscreen-exit'
          : 'tc-icon-fullscreen'},on:{"click":_vm.handleScreen}})],1),_c('div',{class:_vm.$style.wrap,style:({
      width: _vm.$store.state.home.bigScreen ? '100vw' : '100%',
      height: _vm.$store.state.home.bigScreen ? '100vh' : '100%',
    }),attrs:{"id":"container"}}),_c('section',{class:_vm.$style.selectWrap},[_c('a-select',{on:{"change":_vm.handleChange},model:{value:(_vm.geofence),callback:function ($$v) {_vm.geofence=$$v},expression:"geofence"}},_vm._l((_vm.list),function(item){return _c('a-select-option',{key:item.geofenceId,attrs:{"value":item.geofenceId}},[_vm._v(_vm._s(item.geofenceName))])}),1)],1),_c('a-button',{class:_vm.$style.bottomCenter,attrs:{"type":"primary"},on:{"click":_vm.handleClose}},[_vm._v(_vm._s(_vm.$t('hat.managementModal.personInfo.closeMap')))]),_c('a-button',{class:_vm.$style.refresh,attrs:{"type":"link"},on:{"click":function($event){return _vm.refreshLocation('manual')}}},[_vm._v(_vm._s(_vm.$t('hat.managementModal.personInfo.refreshPositioning')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }