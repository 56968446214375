import { Button, notification, Icon } from 'ant-design-vue';

export const useNotification = () => {
  /**
   * 调用系统通知
   * @param {string} title
   * @param { NotificationOptions } options
   */
  const systemNotification = (title, options) => {
    const handleNotification = () => {
      const _notification = new Notification(title, options);
      _notification.onclick = () => {};
    };

    if (!('Notification' in window)) {
      // Check if the browser supports notifications
      // alert('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      handleNotification();
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          handleNotification();
        }
      });
    }
  };

  const playAudio = msg => {
    if (window.speechSynthesis) {
      speechSynthesis.cancel();
      /**
       * @type { SpeechSynthesisUtterance }
       */
      const _SpeechSynthesisUtterance = new SpeechSynthesisUtterance();
      _SpeechSynthesisUtterance.text = msg.split('').join(' ');
      speechSynthesis.speak(_SpeechSynthesisUtterance);
    } else {
      const _audio = new Audio(require('@/assets/audio/SOS.mp3'));
      _audio.play();
    }
  };

  /**
   * SOS通知
   * @param {object} config
   * @param {string} config.title
   * @param {description} config.description
   */
  const sosNotification = config => {
    notification.open({
      message: config.title,
      description: config.description,
      icon: h => {
        return h(Icon, { style: { color: 'red' }, props: { type: 'alert' } });
      },
      btn: h => h(Button, { props: { type: 'link' } }, '查看详情'),
      onClick: config.onClick,
    });
    systemNotification(config.title, {
      body: config.description,
    });
    playAudio(config.description);
  };

  return { sosNotification };
};
