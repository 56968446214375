const ThemeData = {
  tint: {
    font: '#333333',
    mainBg: '#ffffff',
    border: '#eeeeee',
    fontInfo: '#999999',
  },
  dark: {
    font: '#cccccc',
    mainBg: '#444444',
    border: '#3f3f3f',
    fontInfo: '#666666',
  },
  purple: {
    primary: '#9743fe',
    primary80: 'rgba(151, 67, 254, 0.8)',
    primary50: 'rgba(151, 67, 254, 0.5)',
    primary30: 'rgba(151, 67, 254, 0.3)',
  },
  green: {
    primary: '#41c400',
    primary80: 'rgba(65, 196, 0, 0.8)',
    primary50: 'rgba(65, 196, 0, 0.5)',
    primary30: 'rgba(65, 196, 0, 0.3)',
  },
  cyan: {
    primary: '#00b3b3',
    primary80: 'rgba(0, 179, 179, 0.8)',
    primary50: 'rgba(0, 179, 179, 0.5)',
    primary30: 'rgba(0, 179, 179, 0.3)',
  },
  red: {
    primary: '#ff333d',
    primary80: 'rgba(255, 51, 61, 0.8)',
    primary50: 'rgba(255, 51, 61, 0.5)',
    primary30: 'rgba(255, 51, 61, 0.3)',
  },
  blue: {
    primary: '#4771ff',
    primary80: 'rgba(71, 113, 255, 0.8)',
    primary50: 'rgba(71, 113, 255, 0.5)',
    primary30: 'rgba(71, 113, 255, 0.3)',
  },
  orange: {
    primary: '#f99603',
    primary80: 'rgba(249, 150, 3, 0.8)',
    primary50: 'rgba(249, 150, 3, 0.5)',
    primary30: 'rgba(249, 150, 3, 0.3)',
  },
  grey: {
    primary: '#888888',
    primary80: 'rgba(136, 136, 136, 0.8)',
    primary50: 'rgba(136, 136, 136, 0.5)',
    primary30: 'rgba(136, 136, 136, 0.3)',
  },
  volcanic: {
    primary: '#fa541c',
    primary80: 'rgba(250, 84, 28, 0.8)',
    primary50: 'rgba(250, 84, 28, 0.5)',
    primary30: 'rgba(250, 84, 28, 0.3)',
  },
  azure: {
    primary: '#04a9f3',
    primary80: 'rgba(4, 169, 243, 0.8)',
    primary50: 'rgba(4, 169, 243, 0.5)',
    primary30: 'rgba(4, 169, 243, 0.3)',
  },
};

export default ThemeData;
