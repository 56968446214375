import Vue from 'vue';
import { getCurrentTenantInfoList } from '@/services/tenant-center/enterprise';
import { crossStorage } from '@triascloud/cross-storage';
import { getCurrentUser } from '@/services/tenant-center/member';
import { ensureArray, promiseLock } from '@triascloud/utils';
import { getCurrentDepartmentList } from '@/services/tenant-center/department';
import { openSimulationData } from '@/services/things/iot-authority';

/** @type { import('vuex').Module } */
const global = {
  namespaced: true,
  state: {
    companyList: [],
    departmentList: [],
    refreshMenuFlag: false,
    refreshMessageFlag: false,
    menusShowStatus: false,
    isBuildEnterprise: false,
    /**
     * @name 是否自动模拟电箱的数据
     * @link https://alidocs.dingtalk.com/i/nodes/KOEmgBoGwD78v4MbMNDDVndLerP9b30a?nav=spaces&navQuery=spaceId%3Dnb9XJVZAMZQaXyAp
     */
    mock: false,
  },
  mutations: {
    updateCompanyList(state, payload) {
      state.companyList = ensureArray(payload);
    },
    updateDepartmentList(state, payload) {
      state.departmentList = ensureArray(payload);
    },
    updateRefreshMenuFlag(state, payload) {
      state.refreshMenuFlag = !!payload;
    },
    refreshMessage(state) {
      state.refreshMessageFlag = !state.refreshMessageFlag;
    },
    updateMenusShowStatus(state, payload) {
      state.menusShowStatus = payload;
    },
    updateIsBuildEnterprise(state, isBuild) {
      state.isBuildEnterprise = isBuild;
    },
    updateMock(state, payload) {
      state.mock = payload;
    },
  },
  actions: {
    /**
     * @name 加载公司列表
     * @description 切换企业、更新企业设置、退出企业、解散企业等操作时触发，会更新左上角企业列表与当前企业信息
     */
    async refreshCompanyList({ commit, dispatch, rootState }) {
      dispatch('refreshUser');
      const companyList = await getCurrentTenantInfoList();
      commit('updateCompanyList', companyList);
      const { tenant } = rootState.crossStorage;
      const newTenant =
        companyList.find(item => item.pkId === (tenant && tenant.pkId)) || // 最新的用户当前企业信息
        companyList.find(item => item.mainEnterprise === true) || // 用户主企业
        companyList[0] || // 列表第一个企业
        null;
      crossStorage.setItem('tenant', JSON.stringify(newTenant));
    },
    async refreshDepartmentList({ commit }) {
      commit('updateDepartmentList', await getCurrentDepartmentList());
    },
    refreshUser: promiseLock(
      async ({ dispatch }) => {
        const [user] = await Promise.all([
          getCurrentUser(),
          dispatch('refreshDepartmentList'),
        ]);
        crossStorage.setItem('user', JSON.stringify(user));
      },
      () => 'refreshUser',
    ),
    async refreshMenu({ commit }) {
      commit('updateRefreshMenuFlag', true);
      await Vue.nextTick();
      commit('updateRefreshMenuFlag', false);
    },
    async fetchMockStatus({ commit }) {
      const result = await openSimulationData();
      commit('updateMock', result);
    },
  },
};

export default global;
