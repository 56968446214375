export const warnList = [
  {
    value: '',
    label: '全部',
  },
  {
    value: 'GRAVITY',
    label: '超载预警',
  },
  {
    value: 'STANCE',
    label: '倾斜预警',
  },
  {
    value: 'LOST_LOAD',
    label: '失载预警',
  },
  {
    value: 'TOTAL_LOAD',
    label: '总荷载预警',
  },
  {
    value: 'TOTAL_CURRENT',
    label: '总电流预警',
  },
  {
    value: 'WEATHER',
    label: '天气预警',
  },
  {
    value: 'LOCATION',
    label: '位置预警',
  },
  {
    value: 'CLOSEDTOP',
    label: '封顶预警',
  },
  {
    value: 'SUB_CONNECTION', //SD110根据数据规则判断有连接问题
    label: '分控箱连接',
  },
];
export const errorList = [
  {
    value: '',
    label: '全部',
  },
  {
    value: 'GRAVITY',
    label: '分控箱超载',
  },
  {
    value: 'STANCE',
    label: '分控箱倾斜',
  },
  {
    value: 'LOST_LOAD',
    label: '分控箱失载',
  },
  {
    value: 'SUB_CONTROL_BOX_DROPPED', //设备主动上报告知掉线
    label: '分控箱掉线',
  },
  {
    value: 'SUB_CONTROL_BOX_STOP',
    label: '分控箱急停',
  },
  {
    value: 'MAIN_CONTROL_BOX_STOP',
    label: '主控箱急停',
  },
];
// export const eventKey = [].concat(errorList, warnList).reduce((prev, cur) => {
//   prev[`${cur['value']}`] = cur['label'];
//   return prev;
// }, {});
export const eventKey = {
  GRAVITY: '分控箱超载',
  LOST_LOAD: '分控箱失载',
  MAIN_CONTROL_BOX_STOP: '主控箱急停',
  STANCE: '分控箱倾斜',
  SUB_CONTROL_BOX_DROPPED: '分控箱掉线',
  SUB_CONTROL_BOX_STOP: '分控箱急停',
  TOTAL_CURRENT: '总电流预警',
  TOTAL_LOAD: '总荷载预警',
  WEATHER: '天气预警',
  LOCATION: '位置预警',
  CLOSEDTOP: '封顶预警',
  SUB_CONNECTION: '分控箱连接',
};
