<template>
  <div :class="$style.notify">
    <Hint />
    <div :class="$style.content">
      <div :class="$style.buttonGroups">
        <a-button
          :class="$style.buttonItem"
          type="primary"
          @click="isEdit = true"
          v-if="!isEdit && $p.action('UPDATE')"
          >{{ $t('hat.deviceManagement.edit') }}</a-button
        >
        <a-button
          :class="$style.buttonItem"
          type="default"
          @click="isEdit = false"
          v-if="isEdit"
          >{{ $t('msg.cancel') }}</a-button
        >
        <a-button
          :class="$style.buttonItem"
          type="primary"
          @click="handleSave"
          v-if="isEdit"
          >{{ $t('msg.save') }}</a-button
        >
      </div>
      <a-col v-for="(item, key, index) in notifyArr" :key="key">
        <a-col :class="$style.check">
          <span v-if="index === 0">{{
            $t('hat.managementModal.setting.hint.notice.shiftsReminder')
          }}</span>
          <span v-if="index === 2">{{
            $t('hat.managementModal.setting.hint.notice.abnormal')
          }}</span>
        </a-col>
        <a-col :offset="1" :class="$style.check">
          <a-col>
            <a-checkbox v-model="item.enable" :disabled="!isEdit">
              <span style="margin-left: 10px">{{ item.name }}</span>
              <a-tooltip :overlayStyle="toolTipsStyle">
                <template slot="title">
                  {{ item.tips }}
                </template>
                <a-icon type="exclamation-circle" style="margin: 0 10px" />
              </a-tooltip>
            </a-checkbox>
          </a-col>
          <a-col :span="10">
            <a-input
              v-model="item.content"
              :disabled="!isEdit"
              @blur="onBlur($event, key)"
            />
          </a-col>
        </a-col>
      </a-col>
      <a-col :class="$style.check">
        <span>{{
          $t('hat.managementModal.setting.hint.notice.ReminderRules')
        }}</span>
      </a-col>
      <a-col :offset="1" :class="$style.check">
        <a-form-model ref="ruleForm" :modle="notifyForm">
          <a-col>
            <a-form-model-item prop="trigger">
              <span slot="label" :class="$style.label">
                {{ $t('hat.managementModal.setting.hint.notice.TriggerShift') }}
              </span>
              <a-radio-group v-model="notifyForm.trigger" :disabled="!isEdit">
                <a-radio value="ONE_SHIFT">
                  <span>{{
                    $t('hat.managementModal.setting.hint.notice.TriggerOne')
                  }}</span>
                </a-radio>
                <a-radio value="ALL_SHIFT">
                  <span>{{
                    $t('hat.managementModal.setting.hint.notice.TriggerTwo')
                  }}</span>
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col>
            <a-form-model-item prop="duration">
              <span slot="label" :class="$style.label">
                {{
                  $t('hat.managementModal.setting.hint.notice.shiftsReminder')
                }}
              </span>
              <span>{{
                $t('hat.managementModal.setting.hint.notice.BeforeAfterWork')
              }}</span>
              <a-input-number
                v-model="notifyForm.duration"
                :min="0"
                :max="60"
                :disabled="!isEdit"
                style="margin: 0 10px"
              />
              <span>{{
                $t('hat.managementModal.setting.hint.notice.minuteReminder')
              }}</span>
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-col>
    </div>
    <a-spin :spinning="loading" />
  </div>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import { getRuleList, putRule } from '@/services/smart-hat/device-management';
import Hint from './hint.vue';
import { replaceContent } from '@/views/hat/utils.js';

@Component({
  components: {
    Hint,
  },
})
export default class Notify extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;

  toolTipsStyle = {
    maxWidth: '100%',
  };
  notifyArr = {
    onWork: {
      type: 'ON_WORK',
      name: this.$t(
        'hat.managementModal.setting.hint.notice.attendanceReminder',
      ),
      tips: this.$t('hat.managementModal.setting.hint.notice.attendanceTips'),
      enable: false,
      content: '',
    },
    unWork: {
      type: 'OFF_WORK',
      name: this.$t('hat.managementModal.setting.hint.notice.leaveReminder'),
      tips: this.$t('hat.managementModal.setting.hint.notice.'),
      enable: false,
      content: '',
    },
    late: {
      type: 'BE_LATE',
      name: this.$t('hat.managementModal.setting.hint.notice.late'),
      tips: this.$t('hat.managementModal.setting.hint.notice.lateTips'),
      enable: false,
      content: '',
    },
    leaveEarly: {
      type: 'LEAVE_EARLY',
      name: this.$t('hat.managementModal.setting.hint.notice.earlyLeave'),
      tips: this.$t('hat.managementModal.setting.hint.notice.earlyLeaveTips'),
      enable: false,
      content: '',
    },
    absenteeism: {
      type: 'ABSENCE_FROM_WORK',
      name: this.$t('hat.managementModal.setting.hint.notice.absence'),
      tips: this.$t('hat.managementModal.setting.hint.notice.absenceTips'),
      enable: false,
      content: '',
    },
  };
  notifyForm = {
    trigger: 'ONE_SHIFT',
    duration: 1,
  };
  isEdit = false;
  mounted() {
    this.getRules();
  }
  loading = false;
  async getRules() {
    this.loading = true;
    try {
      const enumList = {
        ON_WORK: 'onWork',
        OFF_WORK: 'unWork',
        BE_LATE: 'late',
        LEAVE_EARLY: 'leaveEarly',
        ABSENCE_FROM_WORK: 'absenteeism',
      };
      const data = await getRuleList(this.deviceDetail.deviceId);
      this.loading = false;
      data.forEach(item => {
        if (
          [
            'ON_WORK',
            'OFF_WORK',
            'BE_LATE',
            'LEAVE_EARLY',
            'ABSENCE_FROM_WORK',
          ].includes(item.type)
        ) {
          this.notifyArr[enumList[item.type]] = {
            ...this.notifyArr[enumList[item.type]],
            ...item,
          };
          this.notifyForm.trigger = item.priority;
          this.notifyForm.duration = item.duration;
        }
      });
    } catch {
      this.loading = false;
    }
  }
  async handleSave() {
    const postData = {
      deviceId: this.deviceDetail.deviceId,
      reminders: [],
    };
    for (let key in this.notifyArr) {
      // if (this.notifyArr[key].enable) {
      // eslint-disable-next-line no-unused-vars
      const { name, tips, ...data } = this.notifyArr[key];
      data.priority = this.notifyForm.trigger;
      data.duration = this.notifyForm.duration;
      // 和活动规则用的同一个接口，这两个必须传null
      data.defaultStartTime = null;
      data.defaultEndTime = null;
      postData.reminders.push(data);
      // }
    }
    await putRule(postData);
    this.$message.success('保存成功！');
    this.isEdit = false;
    this.getRules();
  }
  onBlur(e, key) {
    const { value } = e.target;
    this.notifyArr[key].content = replaceContent(value);
  }
}
</script>

<style lang="less" module>
.notify {
  .content {
    padding-top: 15px;
    position: relative;
    .buttonGroups {
      position: absolute;
      z-index: 9999;
      right: 10px;
      top: 6px;
      .buttonItem {
        min-width: 50px;
        height: 30px;
        margin-left: 10px;
      }
    }
    .check {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .label {
      color: var(--font);
    }
  }
}
</style>
