<template>
  <div :class="$style.chartContainer">
    <div
      :id="id"
      :style="{ height: height, width: width }"
      v-if="Object.values(chartData).length"
    ></div>
    <empty-content :class="$style.noData" :label="$t('common.nodata')" v-else />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    EmptyContent,
  },
})
export default class HatLineChart extends Vue {
  @Prop({ type: String, default: '200px' }) height;
  @Prop({ type: String, default: '200px' }) width;
  @Prop({ type: Object, default: () => {} }) chartData;
  @Prop({ type: String, default: 'id' }) id;
  @Prop({ type: Boolean, default: true }) showLegend;
  @Prop({ type: Boolean, default: true }) showArea;
  @Prop({ type: Number, default: 1 }) areaOpacity;
  @Prop({ type: String, default: '2' }) lineWidth;
  @Prop({ type: Object, default: () => ({ left: 'center', top: '90%' }) })
  legendPosition;
  @Prop({
    type: Object,
    default: () => ({ top: '20%', left: '3%', right: '6%', bottom: '10%' }),
  })
  grid;
  @Prop({
    type: Array,
    default: () => [
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#3BA272',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ],
  })
  colorArray;

  @Watch('chartData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal.dataList?.length) {
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get option() {
    return {
      color: this.colorArray,
      title: {
        show: false,
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        type: 'scroll',
        ...this.legendPosition,
        show: this.showLegend,
      },
      grid: {
        ...this.grid,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          show: true,
          lineStyle: {
            color: 'gray',
          },
        },
        axisTick: {
          show: false,
        },
        data: this.chartData.times,
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: this.getChartSeries(this.chartData.dataList),
    };
  }
  getChartSeries(dataSeries) {
    let result = [];
    dataSeries.forEach(item => {
      result.push({
        name: item.name,
        type: 'line',
        smooth: false,
        showSymbol: true,
        data: item.values,
        areaStyle: this.showArea
          ? {
              opacity: this.areaOpacity,
            }
          : null,
        lineStyle: {
          width: this.lineWidth,
        },
      });
    });
    return result;
  }
  chart = null;
  initChart() {
    if (this.chartData.dataList?.length) {
      this.chart = echarts.init(document.getElementById(this.id));
      const options = this.option;
      this.chart.setOption(options);
    }
  }
}
</script>

<style lang="less" module>
.chartContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  .noData {
    height: 95%;
    width: 90%;
  }
}
</style>
