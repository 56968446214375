// eslint-disable-next-line no-unused-vars
import { ResizeGrip } from './ResizeGrip';

/**
 * RectangularBoxMarkerGrips is a set of resize/rotation grips for a rectangular marker.
 */
export class RectangularBoxMarkerGrips {
  /**
   * Top-left grip.
   * @type { ResizeGrip }
   */
  topLeft;
  /**
   * Top-center grip.
   * @type { ResizeGrip }
   */
  topCenter;
  /**
   * Top-right grip.
   * @type { ResizeGrip }
   */
  topRight;
  /**
   * Center-left grip.
   * @type { ResizeGrip }
   */
  centerLeft;
  /**
   * Center-right grip.
   * @type { ResizeGrip }
   */
  centerRight;
  /**
   * Bottom-left grip.
   * @type { ResizeGrip }
   */
  bottomLeft;
  /**
   * Bottom-center grip.
   * @type { ResizeGrip }
   */
  bottomCenter;
  /**
   * Bottom-right grip.
   * @type { ResizeGrip }
   */
  bottomRight;

  /**
   * Creates a new marker grip set.
   */
  constructor() {
    this.findGripByVisual = this.findGripByVisual.bind(this);
  }

  /**
   * Returns a marker grip owning the specified visual.
   * @param { EventTarget } gripVisual - visual for owner to be determined.
   */
  findGripByVisual(gripVisual) {
    if (this.topLeft.ownsTarget(gripVisual)) {
      return this.topLeft;
    } else if (this.topCenter.ownsTarget(gripVisual)) {
      return this.topCenter;
    } else if (this.topRight.ownsTarget(gripVisual)) {
      return this.topRight;
    } else if (this.centerLeft.ownsTarget(gripVisual)) {
      return this.centerLeft;
    } else if (this.centerRight.ownsTarget(gripVisual)) {
      return this.centerRight;
    } else if (this.bottomLeft.ownsTarget(gripVisual)) {
      return this.bottomLeft;
    } else if (this.bottomCenter.ownsTarget(gripVisual)) {
      return this.bottomCenter;
    } else if (this.bottomRight.ownsTarget(gripVisual)) {
      return this.bottomRight;
    } else {
      return undefined;
    }
  }
}
