<template>
  <div :class="[showBack ? $style.backColor : '']">
    <div style="margin-bottom: 10px">{{ $t('generalView.rule.head') }}</div>
    <div v-for="(item, idx) in tips" :key="idx" style="margin-bottom: 5px">
      <span>{{ idx + 1 }}、</span>
      <span>{{ item }}</span>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ScoreTips extends Vue {
  @Prop({ type: Boolean, default: false }) showBack; // 是否显示背景色
  tips = [
    this.$t('generalView.rule.1'),
    this.$t('generalView.rule.2'),
    this.$t('generalView.rule.3'),
    this.$t('generalView.rule.4'),
    this.$t('generalView.rule.5'),
  ];
}
</script>

<style lang="less" module>
.backColor {
  color: #05d3ff;
}
</style>
