import request from '../request';

const serviceName = '/production/module-auth';

export function fetchPermission(module) {
  return request(serviceName, {
    method: 'GET',
    body: { module },
  });
}

// export function fetchPermission() {
//   return Promise.resolve({
//     '/enterpriseIot/generalView': [],
//     '/enterpriseIot/iotScreenManage': [],
//     '/enterpriseIot/electricBoxMock': [],
//   });
// }
