import Vue from 'vue';
import Vh from './index.vue';

export default function VerticalHorizontal() {
  const dom = document.createElement('div');
  document.body.appendChild(dom);
  const vm = new Vue({
    el: dom,
    render() {
      return <Vh />;
    },
  });
  return vm;
}
