import Vue from 'vue';
import { VuePermissionRouter } from '@triascloud/permission';
import { ssoClient, SSOClientWeb } from '@triascloud/sso-client';
import { crossStorage, CrossStorageWeb } from '@triascloud/cross-storage';
import { fetchPermission } from '@/services/production/module-auth';
import MainLayout from '@/layout/main.vue';
import Iot from '@/views/iot/index.vue';
import OvenControl from '@/views/oven-control/index.vue';
import Things from '@/views/iot-platform/index.vue';
import SmartHat from '@/views/hat/index.vue';
import GeneralView from '@/views/iot/general-view/index.vue';
import NotFoundPage from '@/views/404.vue';
import ForbiddenPage from '@/views/403.vue';
import { createModuleUrl } from '@/utils';
import store from '@/store';
import { getIotStatus } from '@/services/tenant-center/department';
import HatPasswordIndex from '@/views/hat/screen/components/share/password.vue';

Vue.use(VuePermissionRouter);
/**
 * @type {import('vue-router').RouteConfig[]} }
 * @description
 * 1、meta: { auth: false } auth = false，不需要加载权限也能访问。auth = true，加载权限并且要求具有页面访问权限
 * 未配置auth的情况下，页面静默加载权限（要求登录），但后台不配置权限也可以访问
 * 2、meta: { share: true } share = true 页面可分享，【接口】需要加入【白名单】
 * request 请求接口配置如下：
    request(`/oss/backend/getAuth`, {
      method: 'POST',
      body: data,
      auth: false, // 【前端】需要设置auth为false，同时这个接口后端也需要加入【白名单】中
    });
 * 3、meta: { alone: true } alone = true 页面不需要全局的【消息总线】
 */
const routes = [
  {
    path: '/',
    component: MainLayout,
    meta: {
      auth: false,
    },
    children: [
      /** @name 无开通权限页面 */
      {
        path: '/noPermission',
        name: 'noPermission',
        component: () => import('@/views/iot/no-permission.vue'),
        meta: {
          title: 'SD110',
        },
      },
      {
        path: '/',
        name: 'iot',
        component: Iot,
        redirect: '/iot/iotScreenManage',
        meta: {
          auth: false,
        },
        children: [
          {
            path: '/iot/iotScreenManage',
            name: 'iotScreenManage',
            component: () => import('@/views/iot/iot-screen-manage'),
            meta: {
              title: '大屏分享管理',
              auth: true,
            },
          },
          {
            path: '/iot/electricBoxMock',
            name: 'electricBoxMock',
            component: () => import('@/views/iot/electric-box-mock/index.vue'),
            meta: {
              title: '智能安监终端数据模拟',
              auth: true,
            },
          },
          {
            path: '/iot/electricBox',
            name: 'electricBox',
            component: () => import('@/views/iot/electric-box/index.vue'),
            meta: {
              title: '安监终端管理',
              auth: true,
            },
          },
          {
            path: '/iot/generalView',
            name: 'generalView',
            component: GeneralView,
            meta: {
              title: '安全防护平台统计',
              auth: true,
            },
          },
          {
            path: '/iot/camera',
            name: 'camera',
            component: () => import('@/views/iot/camera/index.vue'),
            meta: {
              title: '摄像头管理',
              auth: true,
            },
          },
        ],
      },
      {
        path: '/oven',
        name: 'oven',
        component: OvenControl,
        redirect: '/oven/product',
        meta: {
          auth: false,
        },
        children: [
          {
            path: '/oven/product',
            name: 'ovenProduct',
            component: () => import('@/views/oven-control/product'),
            meta: {
              title: '产品管理',
              auth: true,
            },
          },
          {
            path: '/oven/device',
            name: 'ovenDevice',
            component: () => import('@/views/oven-control/device'),
            meta: {
              title: '设备管理',
              auth: true,
            },
          },
          {
            path: '/oven/permission',
            name: 'ovenPermission',
            component: () => import('@/views/oven-control/permission'),
            meta: {
              title: '权限管理',
              auth: true,
            },
          },
          {
            path: '/oven/report',
            name: 'ovenReport',
            component: () => import('@/views/oven-control/report'),
            meta: {
              title: '报表统计',
              auth: true,
            },
          },
        ],
      },
      // TODO: 企业后台
      {
        path: '/iot-platform',
        name: 'iot-platform',
        component: Things,
        redirect: '/iot-platform/deviceManage',
        meta: {
          auth: false,
        },
        children: [
          {
            path: '/iot-platform/deviceManage',
            name: 'device-manage',
            component: () =>
              import('@/views/iot-platform/device-manage/index.vue'),
            meta: {
              title: '设备管理',
              auth: true,
            },
          },
          {
            path: '/iot-platform/twins',
            name: 'twins',
            component: () => import('@/views/iot-platform/twins/index.vue'),
            meta: {
              title: '数字孪生',
              auth: true,
            },
          },
        ],
      },
      // 智能安全帽
      {
        path: '/hat',
        name: 'hat',
        component: SmartHat,
        redirect: '/hat/device',
        children: [
          {
            path: '/hat/device',
            name: 'device',
            component: () => import('@/views/hat/device/index.vue'),
            meta: {
              title: '设备管理',
              auth: true,
            },
          },
          {
            path: '/hat/fence',
            name: 'fence',
            component: () => import('@/views/hat/electron-fence/index.vue'),
            meta: {
              title: '电子围栏',
              auth: true,
            },
          },
          {
            path: '/hat/clock',
            name: 'clock',
            component: () => import('@/views/hat/clock-record/index.vue'),
            meta: {
              title: '考勤记录',
              auth: true,
            },
          },
          {
            path: '/hat/broadcast',
            name: 'broadcast',
            component: () => import('@/views/hat/broadcast/index.vue'),
            meta: {
              title: '消息广播',
              auth: true,
            },
          },
          {
            path: '/hat/sos',
            name: 'sos',
            component: () => import('@/views/hat/sos/index.vue'),
            meta: {
              title: 'SOS紧急呼救',
              auth: true,
            },
          },
          {
            path: '/hat/file',
            name: 'hatFile',
            component: () => import('@/views/hat/file/index.vue'),
            meta: {
              title: '文件管理',
              auth: true,
            },
          },
        ],
      },
    ],
  },
  /** @name 智能安全帽-文件标记 */
  {
    path: '/hat/file/marking/:id',
    name: 'hatFileMarking',
    component: () => import('@/views/hat/file/marking/index.vue'),
    meta: {
      title: '文件标记',
      auth: true, // TODO: 待修改
    },
  },
  /** @name 概览页面（楼栋页面）*/
  {
    path: '/iot/screen',
    name: 'iotScreen',
    component: () => import('@/views/iot/screen'),
    meta: {
      title: '智能安防运维大屏',
      alone: true,
      auth: true,
    },
  },
  /**
   * @name 概览页面（楼栋页面）-token登录
   * @example url: /iot/screen/projectBuilding/:projectId?b=&c=
   *  - projectId 项目id
   *  - b 楼栋id
   *  - c 摄像头id
   */
  {
    path: '/iot/screen/projectBuilding/:projectId?',
    name: 'iotbuildingfloorToken',
    component: () => import('@/views/iot/screen/project-info/index'),
    meta: {
      title: '智能安防运维大屏-楼栋',
      alone: true,
      auth: false,
    },
  },
  /** @name 概览页面（楼栋页面）-appId登录 */
  {
    path: '/iot/screen/buildingFloor/:projectId?',
    name: 'iotbuildingfloor',
    component: () => import('@/views/iot/screen/project-info/index'),
    meta: {
      title: '智能安防运维大屏-楼栋',
      share: true,
      alone: true,
      auth: false,
    },
  },
  /** @name 纯项目分享页-appId登录 */
  {
    path: '/iot/screen/projectShare',
    name: 'projectShare',
    component: () => import('@/views/iot/screen/project-info/index'),
    meta: {
      title: '智能安防运维大屏-项目分享',
      share: true,
      alone: true,
      auth: false,
    },
  },
  /** @name 总包、租赁商和安监局入口（密码校验） */
  {
    path: '/iot/generalScreen/proof/:id',
    name: 'proof',
    component: () => import('@/views/iot/general-screen/proof.vue'),
    meta: {
      title: '智能安防运维大屏',
      share: true,
      alone: true,
      auth: false,
    },
  },
  /** @name 项目分享入口（密码校验） */
  {
    path: '/iot/generalScreen/projectProof/:id',
    name: 'projectProof',
    component: () => import('@/views/iot/general-screen/proof.vue'),
    meta: {
      title: '项目分享验证页',
      share: true,
      alone: true,
      auth: false,
    },
  },
  /** @name 总包和安监局页面 */
  {
    path: '/iot/generalScreen',
    name: 'generalScreen',
    component: () => import('@/views/iot/general-screen/index.vue'),
    meta: {
      title: '智能安防运维大屏',
      share: true,
      alone: true,
      auth: false,
    },
  },
  /** @name 监控视图*/
  {
    path: '/iot/cameraView',
    name: 'iotCamera',
    component: () => import('@/views/iot/camera-view'),
    meta: {
      title: '监控视图',
      alone: true,
      auth: false,
    },
  },
  /** @name 编辑监控视图*/
  {
    path: '/iot/editCameraView',
    name: 'iotEditCamera',
    component: () =>
      import('@/views/iot/camera-view/components/edit/index.vue'),
    meta: {
      title: '编辑监控视图',
      alone: true,
      auth: false,
    },
  },
  /** @name 查看监控视图*/
  {
    path: '/iot/showCameraView/:id',
    name: 'iotShowCameraView',
    component: () =>
      import('@/views/iot/camera-view/components/person/index.vue'),
    meta: {
      title: '查看监控视图',
      alone: true,
      auth: false,
    },
  },
  /** @name 分享查看监控视图*/
  {
    path: '/iot/shareCamera/:id',
    name: 'iotShareCamera',
    component: () =>
      import('@/views/iot/camera-view/components/person/index.vue'),
    meta: {
      title: '监控视图',
      share: true,
      alone: true,
      auth: false,
    },
  },
  /** @name 监控视图入口（密码校验） */
  {
    path: '/iot/generalScreen/monitorProof/:id',
    name: 'monitorProof',
    component: () => import('@/views/iot/general-screen/proof.vue'),
    meta: {
      title: '监控视图分享验证页',
      share: true,
      alone: true,
      auth: false,
    },
  },
  {
    path: '/iot/bm/:projectId',
    name: 'bm',
    component: () =>
      import('@/views/iot/screen/project-info/components/building-model'),
    meta: {
      title: '楼栋3D视图',
      auth: false,
      alone: true,
    },
  },
  /** @name 智能安全帽（运维大屏页面）  */
  {
    path: '/hat/screen',
    name: 'hatScreen',
    component: () => import('@/views/hat/screen'),
    meta: {
      title: '智能安全帽运维大屏',
      alone: true,
      auth: false,
    },
  },
  /** @name 智能安全帽（运维大屏页面）分享  */
  {
    path: '/hat/share/:id',
    name: 'hatShare',
    component: HatPasswordIndex,
    meta: {
      auth: false,
      title: '智能安全帽分享',
      share: true,
    },
  },
  {
    path: '/hat/share/screen/:id/:password',
    name: 'hatShareScreen',
    component: () => import('@/views/hat/screen'),
    meta: {
      auth: false,
      title: '智能安全帽运维大屏分享',
      share: true,
    },
  },
  /** @name 403页面 */
  {
    path: '/403',
    name: '403',
    component: ForbiddenPage,
  },
  /** @name 404页面 */
  {
    path: '*',
    name: '404',
    component: NotFoundPage,
  },
];

const router = new VuePermissionRouter({
  fetch: () => {
    return ssoClient.status ? fetchPermission('iot') : Promise.resolve({});
  },
  forbiddenRoute: '/403',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/**
 * @name 临时处理之前路由
 * (/main => /enterpriseIot)
 * (/enterpriseIot） => /iot)
 */
function hasMainForRedirect(to, next) {
  if (to.path.indexOf('/main') > -1) {
    next({
      path: to.path.replace(/^\/main/g, '/iot'),
      replace: true,
    });
    return;
  }
  if (to.path.indexOf('/enterpriseIot') > -1) {
    next({
      path: to.path.replace(/^\/enterpriseIot/g, '/iot'),
      replace: true,
    });
    return;
  }
}

router.beforeEach(async (to, from, next) => {
  hasMainForRedirect(to, next);
  const user = store.state.crossStorage && store.state.crossStorage.user;
  if (!to.meta.share) {
    if (user.accountStatus && user.accountStatus === 'OK') {
      const permission = await getIotStatus();
      if (!permission && to.path === '/403') {
        next({ path: '/noPermission' });
      } else if (permission && to.path === '/403') {
        next();
      } else if (!permission && to.path === '/noPermission') {
        next();
      } else if (permission && to.path === '/noPermission') {
        next('/');
      } else if (permission) {
        next();
      } else if (!permission && from.path === '/noPermission') {
        next(false);
      } else {
        next({ path: '/noPermission' });
      }
    } else {
      const ssoOrigin = createModuleUrl('account');
      await crossStorage.init(new CrossStorageWeb(`${ssoOrigin}/storage.html`));
      ssoClient.init(new SSOClientWeb(ssoOrigin));
      // 要求登录后才能访问
      await ssoClient.login();
    }
  } else {
    next();
  }
});

export default router;
