import Icon from '!!svg-inline-loader!./ellipse-marker-icon.svg';
import { SvgHelper } from '../../core/SvgHelper';
import { RectangularBoxMarkerBase } from '../RectangularBoxMarkerBase';
// eslint-disable-next-line no-unused-vars
import { Settings } from '../../core/Settings';
import { ColorPickerPanel } from '../../ui/toolbox-panels/ColorPickerPanel';
import { LineWidthPanel } from '../../ui/toolbox-panels/LineWidthPanel';
import { LineStylePanel } from '../../ui/toolbox-panels/LineStylePanel';
// eslint-disable-next-line no-unused-vars
import { ToolboxPanel } from '../../ui/ToolboxPanel';
import FillColorIcon from '!!svg-inline-loader!../../ui/toolbox-panels/fill-color-icon.svg';
import { OpacityPanel } from '../../ui/toolbox-panels/OpacityPanel';
import { dashToNumber } from '../../core/Utils';
import { merge } from 'lodash';

export class EllipseMarker extends RectangularBoxMarkerBase {
  /**
   * String type name of the marker type.
   *
   * Used when adding {@link MarkerArea.availableMarkerTypes} via a string and to save and restore state.
   */
  static typeName = 'EllipseMarker';

  static type = 'ELLIPSE';
  /**
   * Marker type title (display name) used for accessibility and other attributes.
   */
  static title = 'Ellipse marker';
  /**
   * SVG icon markup displayed on toolbar buttons.
   */
  static icon = Icon;

  /**
   * Ellipse fill color.
   */
  fillColor = 'transparent';
  /**
   * Ellipse border color.
   */
  strokeColor = 'transparent';
  /**
   * Ellipse border line width.
   */
  strokeWidth = 0;
  /**
   * Ellipse border dash array.
   */
  strokeDasharray = '';
  /**
   * Ellipse fill-opacity (0..1).
   */
  fillOpacity = 0.25;
  opacity = 1;

  /** @type {ColorPickerPanel} */
  strokePanel;
  /** @type {ColorPickerPanel} */
  fillPanel;
  /** @type {LineWidthPanel} */
  strokeWidthPanel;
  /** @type {LineStylePanel} */
  strokeStylePanel;
  /** @type {OpacityPanel} */
  fillOpacityPanel;
  /** @type {OpacityPanel} */
  opacityPanel;

  /**
   * Creates a new marker.
   *
   * @param {SVGGElement} container - SVG container to hold marker's visual.
   * @param {HTMLDivElement} overlayContainer - overlay HTML container to hold additional overlay elements while editing.
   * @param {Settings} settings - settings object containing default markers settings.
   */
  constructor(container, overlayContainer, settings, opts = {}) {
    super(container, overlayContainer, settings);

    this.strokeColor = settings.defaultColor;
    this.strokeWidth = settings.defaultStrokeWidth;
    this.strokeDasharray = settings.defaultStrokeDasharray;
    this.fillColor = settings.defaultFillColor;

    if (opts && opts.defaultColor) {
      this.strokeColor = opts.defaultColor;
    }

    this.setStrokeColor = this.setStrokeColor.bind(this);
    this.setFillColor = this.setFillColor.bind(this);
    this.setStrokeWidth = this.setStrokeWidth.bind(this);
    this.setStrokeDasharray = this.setStrokeDasharray.bind(this);
    this.setFillOpacity = this.setFillOpacity.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.createVisual = this.createVisual.bind(this);

    this.strokePanel = new ColorPickerPanel(
      'Line color',
      [...settings.defaultColorSet, 'transparent'],
      settings.defaultColor,
      undefined,
      'stroke-color-panel',
    );
    this.strokePanel.onColorChanged = this.setStrokeColor;

    this.fillPanel = new ColorPickerPanel(
      'Fill color',
      [...settings.defaultColorSet, 'transparent'],
      this.fillColor,
      FillColorIcon,
      'fill-color-panel',
    );
    this.fillPanel.onColorChanged = this.setFillColor;

    this.strokeWidthPanel = new LineWidthPanel(
      'Line width',
      settings.defaultStrokeWidths,
      settings.defaultStrokeWidth,
    );
    this.strokeWidthPanel.onWidthChanged = this.setStrokeWidth;

    this.strokeStylePanel = new LineStylePanel(
      'Line style',
      settings.defaultStrokeDasharrays,
      settings.defaultStrokeDasharray,
    );
    this.strokeStylePanel.onStyleChanged = this.setStrokeDasharray;

    this.fillOpacityPanel = new OpacityPanel(
      'Opacity',
      settings.defaultOpacitySteps,
      this.fillOpacity,
    );
    this.fillOpacityPanel.onOpacityChanged = this.setFillOpacity;

    this.opacityPanel = new OpacityPanel(
      'Opacity',
      settings.defaultOpacitySteps,
      this.opacity,
    );
    this.opacityPanel.onOpacityChanged = this.setOpacity;
  }

  /**
   * Returns true if passed SVG element belongs to the marker. False otherwise.
   *
   * @param {EventTarget} el - target element.
   */
  ownsTarget(el) {
    if (super.ownsTarget(el) || el === this.visual) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Creates marker visual.
   */
  createVisual() {
    this.visual = SvgHelper.createEllipse(this.width / 2, this.height / 2, [
      ['fill', this.fillColor],
      ['stroke', this.strokeColor],
      ['stroke-width', this.strokeWidth.toString()],
      ['stroke-dasharray', this.strokeDasharray],
      ['fill-opacity', this.fillOpacity.toString()],
    ]);
    this.addMarkerVisualToContainer(this.visual);
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) down event.
   *
   * @param {import('../../core/MarkerBase').IPoint} point - event coordinates.
   * @param {EventTarget} target - direct event target element.
   */
  pointerDown(point, target) {
    super.pointerDown(point, target);
    if (this.state === 'new') {
      this.createVisual();

      this.moveVisual(point);

      this._state = 'creating';
    }
  }

  /**
   * Handles marker manipulation (move, resize, rotate, etc.).
   *
   * @param {import('../../core/MarkerBase').IPoint} point - event coordinates.
   */
  manipulate(point) {
    super.manipulate(point);
  }

  /**
   * Resize marker based on current pointer coordinates and context.
   * @param {import('../../core/MarkerBase').IPoint} point
   */
  resize(point) {
    super.resize(point);
    this.setSize();
  }

  /**
   * Sets marker's visual size after manipulation.
   */
  setSize() {
    super.setSize();
    SvgHelper.setAttributes(this.visual, [
      ['cx', (this.width / 2).toString()],
      ['cy', (this.height / 2).toString()],
      ['rx', (this.width / 2).toString()],
      ['ry', (this.height / 2).toString()],
    ]);
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) up event.
   *
   * @param {import('../../core/MarkerBase').IPoint} point - event coordinates.
   */
  pointerUp(point) {
    super.pointerUp(point);
    this.setSize();
  }

  /**
   * Sets marker's line color.
   * @param {string} color - new line color.
   */
  setStrokeColor(color) {
    this.strokeColor = color;
    if (this.visual) {
      SvgHelper.setAttributes(this.visual, [['stroke', this.strokeColor]]);
    }
    this.colorChanged(color);
    this.stateChanged();
  }
  /**
   * Sets marker's fill (background) color.
   * @param {string} color - new fill color.
   */
  setFillColor(color) {
    this.fillColor = color;
    if (this.visual) {
      SvgHelper.setAttributes(this.visual, [['fill', this.fillColor]]);
    }
    this.fillColorChanged(color);
    this.stateChanged();
  }
  /**
   * Sets marker's line width.
   * @param {number} width - new line width
   */
  setStrokeWidth(width) {
    this.strokeWidth = width;
    if (this.visual) {
      SvgHelper.setAttributes(this.visual, [
        ['stroke-width', this.strokeWidth.toString()],
      ]);
    }
    this.stateChanged();
  }
  /**
   * Sets marker's border dash array.
   * @param {string} dashes - new dash array.
   */
  setStrokeDasharray(dashes) {
    this.strokeDasharray = dashes;
    if (this.visual) {
      SvgHelper.setAttributes(this.visual, [
        ['stroke-dasharray', this.strokeDasharray],
      ]);
    }
    this.stateChanged();
  }
  /**
   * Sets marker's fill-opacity.
   * @param {number} opacity - new opacity value (0..1).
   */
  setFillOpacity(opacity) {
    this.fillOpacity = opacity;
    if (this.visual) {
      SvgHelper.setAttributes(this.visual, [
        ['fill-opacity', this.fillOpacity.toString()],
      ]);
    }
    this.stateChanged();
  }
  /**
   * Sets marker's opacity.
   * @param {number} opacity - new opacity value (0..1).
   */
  setOpacity(opacity) {
    this.opacity = opacity;
    if (this.container) {
      SvgHelper.setAttributes(this.container, [
        ['opacity', this.opacity.toString()],
      ]);
    }
    this.stateChanged();
  }

  /**
   * Returns the list of toolbox panels for this marker type.\
   * @returns {ToolboxPanel[]}
   */
  get toolboxPanels() {
    return [
      this.strokePanel,
      this.fillPanel,
      this.strokeWidthPanel,
      this.strokeStylePanel,
      this.fillOpacityPanel,
    ];
  }

  /**
   * Returns current marker state that can be restored in the future.
   */
  getState() {
    const result = merge(
      {
        points: [
          this.width / 2 + this.left,
          this.height / 2 + this.top,
          this.width / 2,
          this.height / 2,
        ],
        attributes: {
          transparency: this.opacity,
          strokeColor: this.strokeColor,
          border: this.strokeWidth,
          fillColor: this.fillColor,
          fillOpacity: this.fillOpacity,
          lineformat: dashToNumber(this.strokeDasharray),
          strokeDasharray: this.strokeDasharray,
        },
      },
      super.getState(),
    );
    result.typeName = EllipseMarker.typeName;
    result.type = EllipseMarker.type;

    return result;
  }

  /**
   * Restores previously saved marker state.
   *
   * @param state - previously saved state.
   */
  restoreState(state) {
    const rectState = state;
    this.fillColor = rectState.attributes.fillColor;
    this.strokeColor = rectState.attributes.strokeColor;
    this.strokeWidth = rectState.attributes.border;
    this.strokeDasharray = rectState.attributes.strokeDasharray;
    this.fillOpacity = rectState.attributes.fillOpacity;
    this.opacity = rectState.attributes.transparency;

    this.createVisual();
    super.restoreState(state);
    this.setSize();
  }

  /**
   * Scales marker. Used after the image resize.
   *
   * @param {number} scaleX - horizontal scale
   * @param {number} scaleY - vertical scale
   */
  scale(scaleX, scaleY) {
    super.scale(scaleX, scaleY);

    this.setSize();
  }
}
