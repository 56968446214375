import request from '../request';

const serviceName = '/iot-smart-helmet/device';

/**
 * @name 获取我负责的设备
 */
export function getMyDevice(data) {
  return request(`${serviceName}/myDevice`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 获取设备绑定的项目信息
 */
export function getMyDeviceBindProject(data) {
  return request(`${serviceName}/listBindProject`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 获取设备信息
 */
export function getDeviceDetail(id) {
  return request(`${serviceName}/${id}`, {
    method: 'GET',
  });
}

/**
 * @name 设备状态属性
 */
export function getDeviceProperty(id) {
  return request(`${serviceName}/property/${id}`, {
    method: 'GET',
  });
}

/**
 * @name 获取设备参数列表
 */
export function getSearchParamList(deviceId) {
  return request(`${serviceName}/message/topParam/${deviceId}`, {
    method: 'GET',
  });
}
/**
 * @name 获取轨迹参数列表
 */
export function getTraceParamList(deviceId) {
  return request(`${serviceName}/position/topParam/${deviceId}`, {
    method: 'GET',
  });
}
export function updateDevideInfo({ deviceId, type }) {
  return request(
    `${serviceName}/updateDeviceStatus/?deviceId=${deviceId}&type=${type}`,
    {
      method: 'GET',
    },
  );
}
/**
 * @name 大屏-设备事件明细列表
 */
export function msgPageDetail(data) {
  return request(`${serviceName}/message/pageDetail`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 设备数据更新请求（含内容）
 */
export function updateDeviceData(data) {
  return request(`${serviceName}/updateDeviceData`, {
    method: 'POST',
    body: data,
  });
}
