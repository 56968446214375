<template>
  <div :class="$style.chartContainer">
    <div
      :id="id"
      :style="{ height: height, width: width }"
      v-if="chartData.length"
    ></div>
    <empty-content :class="$style.noData" :label="$t('common.nodata')" v-else />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    EmptyContent,
  },
})
export default class HatRingChart extends Vue {
  @Prop({ type: String, default: '200px' }) height;
  @Prop({ type: String, default: '200px' }) width;
  @Prop({ type: Array, default: () => [] }) chartData;
  @Prop({ type: String, default: 'id' }) id;
  @Prop({ type: Array, default: () => ['46%', '64%'] }) radius;
  @Prop({ type: Boolean, default: true }) showLegend;
  @Prop({ type: Boolean, default: false }) showLabel;
  @Prop({ type: String, default: '总' }) title;
  @Prop({ type: Array, default: () => ['48%', '39%'] }) titlePosition;
  @Prop({ type: Object, default: () => ({ left: 'center', top: '87%' }) })
  legendPosition;
  @Prop({
    type: Array,
    default: () => [
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#3BA272',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ],
  })
  colorArray;

  @Watch('chartData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal.length) {
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get option() {
    let totalNum = 0;
    this.chartData.forEach(element => {
      totalNum += element.value;
    });
    return {
      color: this.colorArray,
      tooltip: {
        trigger: 'item',
      },
      legend: {
        type: 'scroll',
        ...this.legendPosition,
        show: this.showLegend,
      },
      title: {
        text: `{a|${totalNum}\n}{b|${this.title}}`,
        x: this.titlePosition[0],
        y: this.titlePosition[1],
        textAlign: 'center',
        textStyle: {
          textAlign: 'center',
          rich: {
            a: {
              fontSize: 16,
              fontWeight: '400',
              color: '#000',
              padding: [0, 0, 10],
            },
            b: {
              fontSize: 16,
              color: '#000',
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          radius: this.radius,
          avoidLabelOverlap: false,
          minAngle: 10,
          itemStyle: {
            borderRadius: 0,
            borderColor: '#fff',
            borderWidth: 4,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: this.showLabel,
              fontSize: 14,
              fontWeight: 'normal',
            },
          },
          labelLine: {
            show: false,
          },
          data: this.chartData,
        },
      ],
    };
  }
  chart = null;
  initChart() {
    if (this.chartData.length) {
      this.chart = echarts.init(document.getElementById(this.id));
      const options = this.option;
      this.chart.setOption(options);
    }
  }
}
</script>

<style lang="less" module>
.chartContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  .noData {
    height: 95%;
    width: 90%;
  }
}
</style>
