import { SvgHelper } from '../../core/SvgHelper';
// eslint-disable-next-line no-unused-vars
import { Settings } from '../../core/Settings';
import Icon from '!!svg-inline-loader!./callout-marker-icon.svg';
import TextColorIcon from '!!svg-inline-loader!../../ui/toolbox-panels/text-color-icon.svg';
import FillColorIcon from '!!svg-inline-loader!../../ui/toolbox-panels/fill-color-icon.svg';
import { ColorPickerPanel } from '../../ui/toolbox-panels/ColorPickerPanel';
// eslint-disable-next-line no-unused-vars
import { ToolboxPanel } from '../../ui/ToolboxPanel';
import { FontFamilyPanel } from '../../ui/toolbox-panels/FontFamilyPanel';
import { TextMarker } from '../text-marker/TextMarker';
import { ResizeGrip } from '../ResizeGrip';
import { OpacityPanel } from '../../ui/toolbox-panels/OpacityPanel';
import { merge } from 'lodash';

export class CalloutMarker extends TextMarker {
  /**
   * String type name of the marker type.
   *
   * Used when adding {@link MarkerArea.availableMarkerTypes} via a string and to save and restore state.
   */
  static typeName = 'CalloutMarker';

  static type = 'TAG';

  /**
   * Marker type title (display name) used for accessibility and other attributes.
   */
  static title = 'Callout marker';
  /**
   * SVG icon markup displayed on toolbar buttons.
   */
  static icon = Icon;

  strokeColor = 'transparent';
  /**
   * Color picker toolbox panel for the background (fill) color.
   * @type {ColorPickerPanel}
   */
  strokeColorPanel;

  opacity = 1;

  /**
   * @type {OpacityPanel}
   */
  opacityPanel;

  /** @type {import('../../core/MarkerBase').IPoint} */
  tipPosition = { x: 0, y: 0 };
  /** @type {import('../../core/MarkerBase').IPoint} */
  tipBase1Position = { x: 0, y: 0 };
  /** @type {import('../../core/MarkerBase').IPoint} */
  tipBase2Position = { x: 0, y: 0 };
  /** @type {SVGPolygonElement} */
  tip;
  /** @type {ResizeGrip} */
  tipGrip;
  tipMoving = false;

  /**
   * Creates a new marker.
   *
   * @param {SVGGElement} container - SVG container to hold marker's visual.
   * @param {HTMLDivElement} overlayContainer - overlay HTML container to hold additional overlay elements while editing.
   * @param {Settings} settings - settings object containing default markers settings.
   */
  constructor(container, overlayContainer, settings, opts) {
    super(container, overlayContainer, settings);

    if (opts && opts.text) {
      this.text = opts.text;
    }

    this.color = settings.defaultStrokeColor;
    this.strokeColor = settings.defaultFillColor;
    this.fontFamily = settings.defaultFontFamily;

    if (opts && opts.defaultColor) {
      this.strokeColor = opts.defaultColor;
    }

    this.defaultSize = { x: 100, y: 30 };

    this.setStrokeColor = this.setStrokeColor.bind(this);
    this.getTipPoints = this.getTipPoints.bind(this);
    this.positionTip = this.positionTip.bind(this);
    this.setTipPoints = this.setTipPoints.bind(this);
    this.setOpacity = this.setOpacity.bind(this);

    this.colorPanel = new ColorPickerPanel(
      'Text color',
      settings.defaultColorSet,
      this.color,
      TextColorIcon,
      'text-color-panel',
    );
    this.colorPanel.onColorChanged = this.setColor;

    this.strokeColorPanel = new ColorPickerPanel(
      'Fill color',
      settings.defaultColorSet,
      this.strokeColor,
      FillColorIcon,
      'fill-color-panel',
    );
    this.strokeColorPanel.onColorChanged = this.setStrokeColor;

    this.fontFamilyPanel = new FontFamilyPanel(
      'Font',
      settings.defaultFontFamilies,
      settings.defaultFontFamily,
    );
    this.fontFamilyPanel.onFontChanged = this.setFont;

    this.opacityPanel = new OpacityPanel(
      'Opacity',
      settings.defaultOpacitySteps,
      this.opacity,
    );
    this.opacityPanel.onOpacityChanged = this.setOpacity;

    this.tipGrip = new ResizeGrip();
    this.tipGrip.visual.transform.baseVal.appendItem(
      SvgHelper.createTransform(),
    );
    this.controlBox.appendChild(this.tipGrip.visual);
  }

  /**
   * Returns true if passed SVG element belongs to the marker. False otherwise.
   *
   * @param {EventTarget} el - target element.
   */
  ownsTarget(el) {
    return (
      super.ownsTarget(el) || this.tipGrip.ownsTarget(el) || this.tip === el
    );
  }

  createTip() {
    SvgHelper.setAttributes(this.bgRectangle, [
      ['fill', this.strokeColor],
      ['rx', '10px'],
    ]);

    this.tip = SvgHelper.createPolygon(this.getTipPoints(), [
      ['fill', this.strokeColor],
    ]);
    this.visual.appendChild(this.tip);
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) down event.
   *
   * @param {import('../../core/MarkerBase').IPoint} point - event coordinates.
   * @param {EventTarget} target - direct event target element.
   */
  pointerDown(point, target) {
    if (this.state === 'new') {
      super.pointerDown(point, target);
    }

    if (this.state === 'creating') {
      this.createTip();
    } else if (this.tipGrip.ownsTarget(target)) {
      this.manipulationStartLeft = this.left;
      this.manipulationStartTop = this.top;
      this.tipMoving = true;
    } else {
      super.pointerDown(point, target);
    }
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) up event.
   *
   * @param {import('../../core/MarkerBase').IPoint} point - event coordinates.
   */
  pointerUp(point) {
    if (this.tipMoving) {
      this.tipMoving = false;
      this.isMoved = true;
      super.pointerUp(point);
    } else {
      const isCreating = this.state === 'creating';
      super.pointerUp(point);
      this.setTipPoints(isCreating);
      this.positionTip();
    }
  }

  /**
   * Handles marker manipulation (move, resize, rotate, etc.).
   *
   * @param {import('../../core/MarkerBase').IPoint} point - event coordinates.
   */
  manipulate(point) {
    if (this.tipMoving) {
      const rotatedPoint = this.unrotatePoint(point);
      this.tipPosition = {
        x: rotatedPoint.x - this.manipulationStartLeft,
        y: rotatedPoint.y - this.manipulationStartTop,
      };
      this.positionTip();
    } else {
      super.manipulate(point);
    }
  }

  /**
   * Sets marker's background/fill color.
   * @param {string} color - new background color.
   */
  setStrokeColor(color) {
    if (this.bgRectangle && this.tip) {
      SvgHelper.setAttributes(this.bgRectangle, [['fill', color]]);
      SvgHelper.setAttributes(this.tip, [['fill', color]]);
    }
    this.strokeColor = color;
    this.fillColorChanged(color);
  }

  /**
   * Sets marker's opacity.
   * @param {number} opacity - new opacity value (0..1).
   */
  setOpacity(opacity) {
    this.opacity = opacity;
    if (this.container) {
      SvgHelper.setAttributes(this.container, [
        ['opacity', this.opacity.toString()],
      ]);
    }
    this.stateChanged();
  }

  getTipPoints() {
    this.setTipPoints(this.state === 'creating');
    return `${this.tipBase1Position.x},${this.tipBase1Position.y} ${this.tipBase2Position.x},${this.tipBase2Position.y} ${this.tipPosition.x},${this.tipPosition.y}`;
  }

  setTipPoints(isCreating = false) {
    let offset = Math.min(this.height / 2, 15);
    let baseWidth = this.height / 5;
    if (isCreating) {
      this.tipPosition = { x: offset + baseWidth / 2, y: this.height + 20 };
    }

    const cornerAngle = Math.atan(this.height / 2 / (this.width / 2));
    if (
      this.tipPosition.x < this.width / 2 &&
      this.tipPosition.y < this.height / 2
    ) {
      // top left
      const tipAngle = Math.atan(
        (this.height / 2 - this.tipPosition.y) /
          (this.width / 2 - this.tipPosition.x),
      );
      if (cornerAngle < tipAngle) {
        baseWidth = this.width / 5;
        offset = Math.min(this.width / 2, 15);
        this.tipBase1Position = { x: offset, y: 0 };
        this.tipBase2Position = { x: offset + baseWidth, y: 0 };
      } else {
        this.tipBase1Position = { x: 0, y: offset };
        this.tipBase2Position = { x: 0, y: offset + baseWidth };
      }
    } else if (
      this.tipPosition.x >= this.width / 2 &&
      this.tipPosition.y < this.height / 2
    ) {
      // top right
      const tipAngle = Math.atan(
        (this.height / 2 - this.tipPosition.y) /
          (this.tipPosition.x - this.width / 2),
      );
      if (cornerAngle < tipAngle) {
        baseWidth = this.width / 5;
        offset = Math.min(this.width / 2, 15);
        this.tipBase1Position = { x: this.width - offset - baseWidth, y: 0 };
        this.tipBase2Position = { x: this.width - offset, y: 0 };
      } else {
        this.tipBase1Position = { x: this.width, y: offset };
        this.tipBase2Position = { x: this.width, y: offset + baseWidth };
      }
    } else if (
      this.tipPosition.x >= this.width / 2 &&
      this.tipPosition.y >= this.height / 2
    ) {
      // bottom right
      const tipAngle = Math.atan(
        (this.tipPosition.y - this.height / 2) /
          (this.tipPosition.x - this.width / 2),
      );
      if (cornerAngle < tipAngle) {
        baseWidth = this.width / 5;
        offset = Math.min(this.width / 2, 15);
        this.tipBase1Position = {
          x: this.width - offset - baseWidth,
          y: this.height,
        };
        this.tipBase2Position = { x: this.width - offset, y: this.height };
      } else {
        this.tipBase1Position = {
          x: this.width,
          y: this.height - offset - baseWidth,
        };
        this.tipBase2Position = { x: this.width, y: this.height - offset };
      }
    } else {
      // bottom left
      const tipAngle = Math.atan(
        (this.tipPosition.y - this.height / 2) /
          (this.width / 2 - this.tipPosition.x),
      );
      if (cornerAngle < tipAngle) {
        baseWidth = this.width / 5;
        offset = Math.min(this.width / 2, 15);
        this.tipBase1Position = { x: offset, y: this.height };
        this.tipBase2Position = { x: offset + baseWidth, y: this.height };
      } else {
        this.tipBase1Position = { x: 0, y: this.height - offset };
        this.tipBase2Position = { x: 0, y: this.height - offset - baseWidth };
      }
    }
  }

  /**
   * Resize marker based on current pointer coordinates and context.
   * @param {import('../../core/MarkerBase').IPoint} point
   */
  resize(point) {
    super.resize(point);
    this.positionTip();
  }

  positionTip() {
    SvgHelper.setAttributes(this.tip, [['points', this.getTipPoints()]]);
    const translate = this.tipGrip.visual.transform.baseVal.getItem(0);
    translate.setTranslate(this.tipPosition.x, this.tipPosition.y);
    this.tipGrip.visual.transform.baseVal.replaceItem(translate, 0);
  }

  /**
   * Returns the list of toolbox panels for this marker type.
   * @returns {ToolboxPanel[]}
   */
  get toolboxPanels() {
    return [this.colorPanel, this.strokeColorPanel, this.fontFamilyPanel];
  }

  /**
   * Selects this marker and displays appropriate selected marker UI.
   */
  select() {
    this.positionTip();
    super.select();
  }

  /**
   * Returns current marker state that can be restored in the future.
   */
  getState() {
    const result = merge(
      {
        points: [this.tipPosition.x, this.tipPosition.y],
        attributes: {
          transparency: this.opacity,
          strokeColor: this.strokeColor,
          tipPosition: this.tipPosition,
        },
      },
      super.getState(),
    );
    result.typeName = CalloutMarker.typeName;
    result.type = CalloutMarker.type;

    return result;
  }

  /**
   * Restores previously saved marker state.
   *
   * @param state - previously saved state.
   */
  restoreState(state) {
    const calloutState = state.attributes;
    this.strokeColor = calloutState.strokeColor;
    this.tipPosition = calloutState.tipPosition;
    this.opacity = calloutState.transparency;

    super.restoreState(state);
    this.createTip();
    this.setTipPoints();
  }

  /**
   * Scales marker. Used after the image resize.
   *
   * @param {number} scaleX - horizontal scale
   * @param {number} scaleY - vertical scale
   */
  scale(scaleX, scaleY) {
    super.scale(scaleX, scaleY);

    this.tipPosition = {
      x: this.tipPosition.x * scaleX,
      y: this.tipPosition.y * scaleY,
    };

    this.positionTip();
  }
}
