<template>
  <div
    :class="[$style.audioVideo, isFullScreen ? $style.fullscreen : '']"
    @click="changeMenuStatus"
  >
    <div
      :class="[$style.videoContainer, screenMode ? $style.vertical : '']"
      v-if="isCall"
    >
      <video
        id="video"
        ref="video"
        muted="muted"
        :controls="false"
        :class="$style.video"
      />
    </div>
    <div :class="$style.imgWrap" v-if="videoType === 'audio' || !isCall">
      <x-oss-image
        :class="$style.img"
        :ossPath="detail.userAvatar"
        v-if="detail.userAvatar"
      />
    </div>
    <p :class="$style.loadText" v-if="videoType === 'audio' || !isCall">
      {{
        isCall
          ? '通话中'
          : `网络${
              videoType === 'audio' ? '电话' : '视频'
            }正在接通中${ellipsis}`
      }}
    </p>
    <ul :class="$style.topIcon">
      <li
        :class="$style.icon"
        @click.stop="handleCapturePicture"
        v-if="videoType === 'video' && !isFullScreen"
      >
        <a-icon :class="$style.aIcon" type="scissor" />
      </li>
      <!-- <li :class="$style.funIcon">
          <a-icon :class="$style.aIcon" type="video-camera" />
        </li> -->
      <li :class="$style.icon">
        <a-icon
          :class="$style.aIcon"
          :type="isFullScreen ? 'fullscreen-exit' : 'fullscreen'"
          @click.stop="handleFullscreen"
        />
      </li>
    </ul>
    <div
      :class="$style.menuButtons"
      :style="{
        transform: showMenu
          ? 'translateX(-50%)'
          : 'translateX(-50%) translateY(160px)',
      }"
    >
      <div :class="$style.time">{{ timeConversion(callDuration) }}</div>
      <ul :class="$style.iconWrap">
        <li :class="$style.icon">
          <span
            :class="[$style.iconBg, $style.iconBgRed]"
            @click.stop="handleHandUp"
          >
            <a-icon :class="$style.aIcon" type="close" />
          </span>
          <span :class="$style.iconTxt">挂断</span>
        </li>
        <li :class="$style.icon">
          <span
            :class="[$style.iconBg, mic ? $style.iconBgActive : '']"
            @click.stop="handleMic"
          >
            <div :class="$style.line" v-if="!mic"></div>
            <a-icon :class="$style.aIcon" type="audio" />
          </span>
          <span :class="$style.iconTxt">麦克风</span>
        </li>
        <li :class="$style.icon">
          <span
            :class="[$style.iconBg, muted ? '' : $style.iconBgActive]"
            @click.stop="handleSound"
          >
            <div :class="$style.line" v-if="muted"></div>
            <a-icon :class="$style.aIcon" type="sound" />
          </span>
          <span :class="$style.iconTxt">扬声器</span>
        </li>
        <li :class="$style.icon" v-if="videoType === 'video' && isFullScreen">
          <span :class="$style.iconBg" @click.stop="handleCapturePicture">
            <a-icon :class="$style.aIcon" type="scissor" />
          </span>
          <span :class="$style.iconTxt">截图</span>
        </li>
        <li :class="$style.icon" v-if="isFullScreen">
          <span :class="$style.iconBg" @click.stop="handleChangeType">
            <a-icon :class="$style.aIcon" type="retweet" />
          </span>
          <span :class="$style.iconTxt">{{
            videoType === 'video' ? '转为语音' : '转为视频'
          }}</span>
        </li>
        <li :class="$style.icon" v-if="videoType === 'video' && isFullScreen">
          <span
            :class="[$style.iconBg, $style.setting]"
            @click.stop="handleChangeFPS"
          >
            <x-icon
              :type="
                screenMode
                  ? 'tc-icon-landscape-screen'
                  : 'tc-icon-portrait-screen'
              "
              :class="$style.screenIcon"
            />
            <span :class="$style.text">{{ resolution }}p</span>
          </span>
          <span :class="$style.iconTxt">画面</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { Component, Vue, InjectReactive, Prop } from 'vue-property-decorator';
import { capturePicture } from '@/views/hat/utils';
import 'aliyun-webrtc-sdk';
// import { sha256 } from './sha256'; // 测试用
import { timeConversion } from '@/views/hat/utils';
import { globalSocket } from '@triascloud/message-hub';
import { HatSocket } from '@/enum/socket';
import { crossStorageModule } from '@/enum/store.js';
import {
  initiateCall,
  // finishCall,
  updateDeviceData,
} from '@/services/smart-hat/device-management';
import { createFormModal } from '@triascloud/x-components';
import ScreenSetting from './screen-setting.vue';

@Component()
export default class AudioVideo extends Vue {
  @InjectReactive('deviceDetail') detail;
  @Prop({ type: String }) type;

  videoType = '';
  timer = null;
  ellipsis = '.';
  mounted() {
    this.videoType = this.type;
    this.timer = setInterval(() => {
      if (this.ellipsis === '...') {
        this.ellipsis = '.';
      } else {
        this.ellipsis += '.';
      }
    }, 800);
    this.timeout = setTimeout(() => {
      this.showMenu = false;
    }, 10000);
    this.loadVoice();
    this.initSocket();
    this.call();
    // this.testLoadVideo(); // 测试用
  }
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.callTimer);
    this.callTimer = null;
    clearTimeout(this.timeout);
    this.timeout = null;
    if (this.audio) {
      this.audio.pause(); // 关闭铃声
      this.audio = null;
    }
    if (this.aliWebrtc) {
      this.aliWebrtc.leaveChannel(); // 离开频道
      this.aliWebrtc.dispose(); // 释放实例
    }
    // 定时是为了防止立刻挂断时，拨打接口请求还未返回channelId导致通话无法结束
    setTimeout(async () => {
      this.channelId && (await this.finishCall()); // 结束通话
    }, 2000);
    globalSocket.off(HatSocket.DeviceCall, this.getSocketData);
  }
  /**
   * 结束通话
   */
  async finishCall() {
    const postData = {
      deviceId: this.detail.deviceId,
      exData: {
        channelId: this.channelId,
      },
      type: 'END_CALL',
    };
    await updateDeviceData(postData);
  }
  /**
   * 通话时间计时
   */
  callDuration = 0;
  callTimer = null;
  timeConversion = timeConversion;
  recordCallDuration() {
    this.callTimer = setInterval(() => {
      this.callDuration++;
    }, 1000);
  }
  /**
   * 控制通话菜单栏的显示隐藏
   */
  showMenu = true;
  timeout = null;
  changeMenuStatus() {
    clearTimeout(this.timeout);
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.timeout = setTimeout(() => {
        this.showMenu = false;
      }, 10000);
    }
  }
  /**
   * 发起通话
   */
  async call() {
    try {
      const isVideo = this.videoType === 'video';
      // const isVideo = true;
      const { channelId } = await initiateCall({
        audioStream: true,
        deviceId: this.detail.deviceId,
        videoStream: isVideo,
      });
      this.channelId = channelId;
    } catch {
      this.handleHandUp();
    }
  }
  /**
   * 铃声加载
   */
  audio = null;
  isCall = false;
  loadVoice() {
    this.audio = new Audio(require('@/assets/audio/ring.mp3'));
    this.audio.loop = true;
    this.audio.play();
  }
  /**
   * 关闭通话
   */
  async handleHandUp() {
    this.$emit('hand-up');
  }
  /**
   * 麦克风开关控制
   */
  mic = true;
  handleMic() {
    // 参数一：是否停止本地音频采集
    // 参数二：是否记住状态（停止推流后再重新推流使用之前的音频采集配置）
    this.aliWebrtc.muteLocalMic(this.mic, false);
    this.mic = !this.mic;
  }
  /**
   * 声音开关控制
   */
  muted = false;
  handleSound() {
    this.muted = !this.muted;
    const video = document.getElementById('video');
    video.muted = this.muted;
  }
  /**
   * 截图
   */
  handleCapturePicture() {
    const video = document.getElementById('video');
    capturePicture(video);
  }
  /**
   * 全屏
   */
  isFullScreen = false;
  handleFullscreen() {
    this.isFullScreen = !this.isFullScreen;
  }
  /**
   * 音视频画面转换
   */
  async handleChangeType() {
    if (this.videoType === 'video') {
      this.videoType = 'audio';
      // await this.receivePublishManual(userId);
    } else {
      this.videoType = 'video';
      // await this.receivePublishManual(userId);
    }
    // 通过接口去控制音视频切换
    const postData = {
      deviceId: this.detail.deviceId,
      exData: {
        audioStream: true,
        videoStream: this.videoType === 'video',
      },
      type: 'MEDIA_SWITCHING',
    };
    await updateDeviceData(postData);
  }
  /**
   * 改变视频清晰度
   */
  resolution = 480;
  screenMode = 0;
  async handleChangeFPS() {
    try {
      const result = await createFormModal(
        () => (
          <ScreenSetting
            deviceId={this.detail.deviceId}
            resolution={this.resolution}
            screenMode={this.screenMode}
          />
        ),
        {
          width: '400px',
          maskClosable: false,
          title: '画面设置',
        },
      );
      if (result) {
        this.resolution = result.exData.resolution;
        this.screenMode = result.exData.screenMode;
      }
    } catch {
      return false;
    }
  }
  /**
   * 音视频通话socket
   */
  msgEnum = {
    NOT_CONVENIENT: '用户不方便接听，请稍后再拨！',
    USER_BUSY: '用户忙，请稍后再拨！',
    WRONG_INFORMATION: '频道订阅验证信息错误！',
    ON_THE_LINE: '用户通话中，请稍后再试！',
    NETWORK_ERROR: '设备网络环境差，无法正常通话！',
  };
  initSocket() {
    globalSocket.on(HatSocket.DeviceCall, this.getSocketData);
  }
  channelId = ''; // 频道id
  getSocketData(data) {
    if (data.data.code === 'SUCCESS') {
      this.audio.pause();
      this.loadVideo(data.data);
      this.channelId = data.data.channelId;
      this.isCall = true;
    } else {
      // 因为initiateCall接口在发起通话时就能获取到channelId
      // 因此这里赋空是为了防止在用户通话中时，调用了结束通话的接口
      this.channelId = '';
      this.$message.error(this.msgEnum[data.data.code]);
      this.handleHandUp();
    }
  }
  /**
   * 音视频通话-加载音视频通话
   * WEB_API文档地址: https://help.aliyun.com/document_detail/128789.html?spm=a2c4g.88703.0.0.e33444871uLH9d
   */
  aliWebrtc = null;
  async loadWebrtc() {
    // eslint-disable-next-line no-undef
    this.aliWebrtc = new AliRtcEngine();
  }
  async loadVideo(info) {
    await this.loadWebrtc();
    // 设置是否需要开启摄像头(PC端不需求开启摄像头)
    this.aliWebrtc.enableCamera = false;
    this.aliWebrtc
      .isSupport()
      .then(() => {
        // this.onJoin();
        this.joinRoom(info);
        this.onLeave();
        this.onBye();
        this.onPublisher();
      })
      .catch(error => {
        this.$message.error(error);
      });
  }
  @crossStorageModule.State user;
  /**
   * 音视频通话-加入房间
   */
  userList = [];
  joinRoom(info) {
    const authInfo = {
      appid: info?.appId,
      userid: info?.userId,
      timestamp: info?.timestamp,
      nonce: info?.nonce,
      token: info?.token,
      gslb: info?.gslb,
      channel: info?.channelId,
    };
    const userName = this.user.memberName;
    // 加入频道 默认推音频视频流
    this.aliWebrtc
      .joinChannel(authInfo, userName)
      .then(async () => {
        try {
          // 设置是否允许发布相机流
          this.aliWebrtc.configLocalCameraPublish = false;
          // 设置是否允许发布音频流
          this.aliWebrtc.configLocalAudioPublish = true;
          // 发布本地流
          await this.aliWebrtc.publish();
          this.showMenu = false;
          this.recordCallDuration();
        } catch (err) {
          window.console.log(err);
        } finally {
          setTimeout(async () => {
            // 获取房间成员列表
            this.userList = this.aliWebrtc.getUserList();
            window.console.log('房间成员列表', this.userList);
            if (this.userList.length) {
              const userId = this.userList[0].userId;
              const video = this.$refs.video;
              await this.receivePublishManual(userId);
              this.aliWebrtc.setDisplayRemoteVideo(
                userId, // 用户ID
                video, // html中用于显示stream对象的video元素
                1, // 1表示摄像头流（大流和小流），2表示屏幕分享流
              );
            }
          }, 1000);
        }
      })
      .catch(error => {
        window.console.log('加入房间失败', error);
      });
  }
  /**
   * 音视频通话-订阅安全帽的视频流
   */
  receivePublishManual(userId) {
    return new Promise((resolve, reject) => {
      // 重置订阅状态
      // 默认订阅远端音频和视频大流，但需要调用subscribe才能生效
      // 这里取消默认订阅，根据需求进行订阅
      const showVideo = this.videoType === 'video';
      // const showVideo = true;
      this.aliWebrtc.configRemoteCameraTrack(userId, true, showVideo);
      this.aliWebrtc.configRemoteAudio(userId, true);
      this.aliWebrtc
        .subscribe(userId)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  /**
   * 监听用户加入房间 onJoin
   */
  onJoin() {
    // 目前是1V1通话,因此房间有人的话就不执行以下方法
    if (!this.userList.length) {
      this.aliWebrtc.on('onJoin', async publisher => {
        window.console.log('加入房间成员', publisher);
        if (publisher.userId) {
          const userId = publisher.userId;
          const video = this.$refs.video;
          await this.receivePublishManual(userId);
          this.aliWebrtc.setDisplayRemoteVideo(
            userId, // 用户ID
            video, // html中用于显示stream对象的video元素
            1, // 1表示摄像头流（大流和小流），2表示屏幕分享流
          );
        }
      });
    }
  }
  /**
   * 监听用户离开房间 onLeave
   */
  onLeave() {
    this.aliWebrtc.on('onLeave', () => {
      const userList = this.aliWebrtc.getUserList();
      if (!userList.length) {
        this.handleHandUp();
      }
    });
  }
  /**
   * 被服务器踢出或者频道关闭时回调 onBye
   */
  onBye() {
    this.aliWebrtc.on('onBye', () => {
      this.handleHandUp();
    });
  }
  /**
   * 远程用户推流回调 onPublisher
   */
  onPublisher() {
    this.aliWebrtc.on('onPublisher', publisher => {
      window.console.log('onPublisher', publisher);
      // 调用音视频切换接口时，硬件会重新推流，前端需要重新拉流
      this.userList.length &&
        this.receivePublishManual(this.userList[0].userId);
    });
  }
  /**
   * 测试用
   */
  // testLoadVideo() {
  //   const key = 'c9ec59f30d9a39128d854938f19adcfc';
  //   const channelId = '123';
  //   const appId = '83lymcqg';
  //   const userId = 'user123';
  //   const timestamp = parseInt(new Date().getTime() / 1000 + 48 * 60 * 60);
  //   const nonce = 'AK-' + timestamp;
  //   const gslb = ['https://rgslb.rtc.aliyuncs.com'];
  //   const token = sha256(appId + key + channelId + userId + nonce + timestamp);
  //   const authInfo = {
  //     appId,
  //     userId,
  //     timestamp,
  //     nonce,
  //     token,
  //     gslb,
  //     channelId,
  //   };
  //   this.audio.pause();
  //   this.loadVideo(authInfo);
  //   this.isCall = true;
  // }
}
</script>
<style lang="less" module>
.audioVideo {
  width: 285px;
  height: 405px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .videoContainer {
    width: 100%;
    height: 100%;
    .video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .vertical {
    width: 40%;
  }
  .imgWrap {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    .img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  .loadText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
  .menuButtons {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    transition: all 0.2s;
    .time {
      color: #fff;
      margin-bottom: 5px;
    }
    .iconWrap {
      padding: 12px 20px;
      display: flex;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.4);
      .icon {
        padding: 4px 0;
        list-style: none;
        margin-right: 12px;
        text-align: center;
        cursor: pointer;
        .iconBg {
          display: inline-block;
          position: relative;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-bottom: 5px;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          &.iconBgRed {
            background-color: #f45454;
            .aIcon {
              font-weight: bolder;
              font-size: 24px;
            }
          }
          &.iconBgActive {
            // background-color: rgba(255, 255, 255) !important;
            .aIcon {
              color: #48ff00;
            }
          }
          .aIcon {
            font-size: 20px;
            color: #fff;
          }
          .text {
            color: #fff;
            font-size: 14px;
          }
          .line {
            height: 60%;
            width: 1px;
            position: absolute;
            border: 1px solid #fff;
            transform-origin: 50%;
            transform: rotateZ(45deg);
          }
        }
        .iconTxt {
          font-size: 12px;
          color: #fff;
          cursor: default;
        }
        .setting {
          display: flex;
          flex-direction: column;
          .text {
            color: #fff;
            font-size: 10px;
            margin-top: 2px;
          }
          .screenIcon {
            color: #fff;
            font-size: 16px;
          }
        }
      }
      .icon:last-of-type {
        margin-right: 0;
      }
      .icon:first-of-type {
        border-right: 1px solid rgba(255, 255, 255, 0.6);
        padding-right: 15px;
      }
    }
  }
  .topIcon {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    top: 8px;
    .icon {
      font-size: 20px;
      margin-left: 8px;
      color: #ffffff;
    }
  }
}
.fullscreen {
  position: fixed;
  width: 1000px;
  height: 500px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  .menuButtons {
    .iconWrap {
      .icon {
        margin-right: 40px;
      }
      .icon:first-of-type {
        margin-right: 20px;
        padding-right: 20px;
      }
    }
  }
}
</style>
