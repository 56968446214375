import request from '../request';

const serviceName = '/things/reportShare';

/**
 * @name 生成链接大屏分享（后台管理系统）
 */
export function backendGenerate(data) {
  return request(`${serviceName}/backendGenerate`, {
    method: 'POST',
    body: data,
  });
}
