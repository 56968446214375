/** @type { import('vuex').Module } */
const project = {
  namespaced: true,
  state: {
    cameraId: '',
    screenshotList: [],
    currentShot: -1,
    showScreenshot: false,
    video: {
      url: '',
      token: '',
    },
  },
  mutations: {
    updateCurrentShot(state, payload) {
      state.currentShot = payload;
    },
    updateShotList(state, payload) {
      state.screenshotList = payload;
    },
    updateShowShot(state, payload) {
      state.showScreenshot = payload;
    },
    updateVideo(state, payload) {
      state.video = payload;
    },
    updateCameraId(state, payload) {
      state.cameraId = payload;
    },
  },
};

export default project;
