// 颜色变量
/** @name 待进场项目 */
export const Color_Waiting = '#ff9900';
/** @name 已进场项目 */
export const Color_Building = '#00eb75';
/** @name 已退场项目 */
export const Color_Outing = '#cccccc';
/** @name 已进场有安监终端项目 */
export const Color_Electricbox = '#ff5a20';
/** @name 已进场有安监终端项目 */
export const Color_Rising = '#f3e925';

// 地图的级别
/** @name 地图的级别：全国 */
export const LEVEL_TYPE_COUNTRY = 'CHINA';
/** @name 地图的级别：省 */
export const LEVEL_TYPE_PROVINCE = 'PROVINCE';
/** @name 地图的级别：市 */
export const LEVEL_TYPE_CITY = 'CITY';
/** @name 地图的级别：区 */
export const LEVEL_TYPE_DISTRICT = 'AREA';

// 项目的状态

/** @name 提升中项目 */
export const LEVEL_RISING = 'RISE';
/** @name 待进场项目 */
export const LEVEL_WAITING = 'WAIT_ENTER';
/** @name 已进场项目  */
export const LEVEL_BUILDING = 'BUILD_LOADING';
/** @name 退场项目  */
export const LEVEL_OUTING = 'QUIT';
/** @name 已进场有安监终端项目  */
export const LEVEL_ELECTRIC_BOX = 'DEVICE_BIND';

/** @name 获取入参addressType的值 */
export const getAddressType = type => {
  let addressType = '';
  if (type === LEVEL_TYPE_COUNTRY) {
    addressType = 'PROVINCE';
  }
  if (type === LEVEL_TYPE_PROVINCE) {
    addressType = 'CITY';
  }
  if (type === LEVEL_TYPE_CITY) {
    addressType = 'AREA';
  }
  return addressType;
};
