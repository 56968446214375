import { Vue } from 'vue-property-decorator';
import { crossStorage } from '@triascloud/cross-storage';
import dayjs from 'dayjs';
import { createModuleUrl as _createModuleUrl } from './createModuleUrl';

/** @typedef { (module: string, protocol: string) => string } CreateModuleUrl */

/** @typedef { ((...args: [...Parameters<CreateModuleUrl>, CreateModuleUrl]) => string) | null } CustomModuleUrl */

/** @type { CustomModuleUrl } */
let customModuleUrl = null;
/**
 * @name 设置获取域名方法
 * @param { CustomModuleUrl } custom
 */
export const setCustomModuleUrl = custom => {
  customModuleUrl = custom;
};

/**
 * @name 根据功能模块创建地址
 * @type { CreateModuleUrl }
 */
export function createModuleUrl(module, protocol = location.protocol) {
  return typeof customModuleUrl === 'function'
    ? customModuleUrl(module, protocol, _createModuleUrl)
    : _createModuleUrl(module, protocol);
}

// export function createModuleUrl(module, protocol = location.protocol) {
//   const prefix =
//     process.env.VUE_APP_ENV === 'production'
//       ? ''
//       : process.env.VUE_APP_ENV + '.';
//   return `${protocol}//${prefix}${module}.${process.env.VUE_APP_HOST}`;
// }

export function safeParseJSON(json, fallback) {
  if (!json || typeof json !== 'string') return fallback;
  try {
    return JSON.parse(json);
  } catch (e) {
    return fallback;
  }
}

export function createVNode(h, Component, name) {
  if (!Component) return null;
  /** @type { import('vue/types/umd').VNode } */
  let instance = null;
  if (typeof Component === 'function') {
    instance = Component(h);
  } else {
    instance = h(Component);
  }
  instance.data = instance.data || {};
  // instance.data.ref = name;
  instance.key = name;
  return instance;
}
export function getCompanyId() {
  const tenant = safeParseJSON(crossStorage.getItem('tenant'), null);
  if (tenant) return tenant.pkId;
}
export function getTree(tree) {
  return tree.map(item => {
    if (item.children) {
      return {
        path: item.path,
        title: item.meta && item.meta.title ? item.meta.title : item.name,
        children: getTree(item.children),
      };
    } else {
      return {
        path: item.path,
        title: item.meta && item.meta.title ? item.meta.title : item.name,
      };
    }
  });
}

/**
 *  @name 补足默认数据
 *  @param { Array } data
 *  @param { Number } size
 *  @param { Object } defaultValue
 *  @returns { Array }
 */
export function makeUpData(data, size, defaultValue) {
  const list = [];
  if (Array.isArray(data)) {
    const currentDate = new Date();
    const currentDateFormat = dayjs(currentDate)
      .add(1, 'd')
      .format('MM-DD');
    const currentTime = currentDate.getTime();
    const startTime = currentTime - size * 86400000;
    const map = new Map();
    data.forEach(v => {
      const time = dayjs(v.time).format('MM-DD');
      map.set(time, { ...v, time });
    });
    let diffTime = startTime;
    let diffDate = dayjs(startTime).format('MM-DD');
    while (diffDate !== currentDateFormat) {
      if (map.has(diffDate)) {
        list.push(map.get(diffDate));
      } else {
        list.push({ ...defaultValue, time: dayjs(diffTime).format('MM-DD') });
      }
      diffTime = diffTime + 86400000;
      diffDate = dayjs(diffTime).format('MM-DD');
    }
  }
  return list.length ? list : data;
}

/**
 * @name 判断对象是否没有属性和方法
 * @param { Object } obj
 */
export function isEmptyObject(obj) {
  let name;
  for (name in obj) {
    return false;
  }
  return true;
}

/**
 * @name 火星坐标系（GCJ-02）与百度坐标系（BD-09）的转换
 * @param { number } lng 经度
 * @param { number } lat 纬度
 */
export function gcj02tobd09(lng, lat) {
  const x_PI = (3.14159265358979324 * 3000.0) / 180.0;
  lat = +lat;
  lng = +lng;
  const z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI);
  const theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI);
  const bd_lng = z * Math.cos(theta) + 0.0065;
  const bd_lat = z * Math.sin(theta) + 0.006;
  return {
    lng: bd_lng,
    lat: bd_lat,
  };
}

/**
 * @typedef monthData
 * @property {Number} longDate 匹配时间戳
 * @property {Number} currentLongDate 当前时间戳
 * @property {Boolean} extra 处理大后天这个时间
 */
/**
 * @name 日期格式
 * @param { monthData } monthData 时间戳
 *  ○ 当预测时间为今天、明天、后天、大后天时，显示“预计提升今天/明天/后天/大后天“；
 *  ○ 当预测时间超过后天24点后，显示格式为MM/DD，例如”预计提升5/30“；
 *  ○ 当预测时间已过时，显示格式为MM/DD
 */
export const dateFormatForMonthAndDay = monthData => {
  const { longDate, currentLongDate } = monthData;
  const tonightStart = stringForTime(currentLongDate, ' 00:00:00');
  const tonightEnd = stringForTime(currentLongDate, ' 23:59:59');
  const diffCount = count =>
    ifTimeDiff(longDate, tonightStart, tonightEnd, count);
  if (monthData.extra) {
    if (diffCount(3)) {
      return '大后天';
    }
  }
  if (diffCount(0)) {
    return '今天';
  } else if (diffCount(1)) {
    return '明天';
  } else if (diffCount(2)) {
    return '后天';
  } else {
    return dayjs(longDate).format('MM/DD');
  }
};
const ONE_DAY_UNIT = 86400000;
const ifTimeDiff = (target, start, end, count) =>
  start + ONE_DAY_UNIT * count <= target &&
  target <= end + ONE_DAY_UNIT * count;

const stringForTime = (longDate, format) =>
  dayjs(dayjs(longDate).format('YYYY-MM-DD') + format).valueOf();

/**
 * @returns { String }
 * @param { String } txt
 * @name 匹配中文
 */
export function matchChinese(txt) {
  if (!txt) return txt;
  const result = txt.match(/[\u4e00-\u9fa5]/g);
  if (result && result.length && result.length > 0) {
    return result.join('');
  }
}

/** @name 获取yAxis的max值 */
export const getYMax = max => {
  try {
    const data = `${max}`;
    const diff5And10 = a => {
      if (+a >= 5) {
        return 10;
      } else {
        return 5;
      }
    };
    const stringZero = target => {
      let result = '';
      for (let i = 0; i < target.length; i++) {
        result += '0';
      }
      return result;
    };
    return `${diff5And10(data.slice(0, 1))}${stringZero(data.slice(1))}` - 0;
  } catch (error) {
    return max;
  }
};

export const Emitter = new Vue();
