import request from '../request';

const serviceName = '/iot-smart-helmet/device';
const groupService = '/iot-smart-helmet/group';
const deviceScreen = '/iot-smart-helmet/screen';
const projectService = '/iot-smart-helmet/pm';

export function getDeviceList(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    body: data,
  });
}

export function addDevice(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

export function putDevice(data) {
  return request(`${serviceName}`, {
    method: 'PUT',
    body: data,
  });
}

export function deleteDevice(deviceId) {
  return request(`${serviceName}/${deviceId}`, {
    method: 'DELETE',
  });
}

export function exportDeviceExcel() {
  return request(`${serviceName}/exportDeviceExcel`, {
    method: 'GET',
  });
}

export function putBinding(data) {
  return request(`${serviceName}/bind`, {
    method: 'PUT',
    body: data,
  });
}

export function getDeviceStatistics() {
  return request(`${serviceName}/count`, {
    method: 'GET',
  });
}

export function addProject(data) {
  return request(`${projectService}`, {
    method: 'POST',
    body: data,
  });
}

export function getProjectList(data) {
  return request(`${projectService}/pagePm`, {
    method: 'GET',
    body: data,
  });
}

export function deleteProject(id) {
  return request(`${projectService}/${id}`, {
    method: 'DELETE',
  });
}

/** @name 按企业分页模糊查询 */
export function getProjectListPage(data) {
  return request('/smart-site/project/tenant/page', {
    method: 'GET',
    body: data,
  });
}

export function getUserList(idxProjectId) {
  return request(`/smart-site/project-member/list`, {
    method: 'GET',
    body: { idxProjectId },
  });
}

// 编组接口
export function getGroupList(data) {
  return request(`${groupService}/pageByName`, {
    method: 'GET',
    body: data,
  });
}

export function getGroupById(id) {
  return request(`${groupService}/${id}`, {
    method: 'GET',
  });
}

export function addGroup(data) {
  return request(`${groupService}`, {
    method: 'POST',
    body: data,
  });
}

export function putGroup(data) {
  return request(`${groupService}`, {
    method: 'PUT',
    body: data,
  });
}

export function deleteGroup(id) {
  return request(`${groupService}/${id}`, {
    method: 'DELETE',
  });
}

// 事件明细
export function getEventList(data) {
  return request(`${serviceName}/message`, {
    method: 'GET',
    body: data,
  });
}

// 事件统计
export function getEventStatistics(data) {
  return request(`${serviceName}/message/countReport`, {
    method: 'GET',
    body: data,
  });
}

// 管理-设置接口
export function getRuleList(deviceId) {
  return request(`${serviceName}/reminder/${deviceId}`, {
    method: 'GET',
  });
}

export function putRule(data) {
  return request(`${serviceName}/reminder`, {
    method: 'PUT',
    body: data,
  });
}

export function batchUpdateRule(data) {
  return request(`${serviceName}/reminder/batchUpdate`, {
    method: 'PUT',
    body: data,
  });
}

export function resetDevice(deviceId) {
  return request(`${serviceName}/resetDevice/${deviceId}`, {
    method: 'GET',
  });
}

export function putFrequency(data) {
  return request(`${serviceName}/frequency`, {
    method: 'PUT',
    body: data,
  });
}

export function putOtherSetting(data) {
  return request(`${serviceName}/otherSetting`, {
    method: 'PUT',
    body: data,
  });
}

// OTA升级接口
export function executeOtaUpgrade(data) {
  return request(`${serviceName}/ota/executeOtaUpgrade`, {
    method: 'PUT',
    body: data,
  });
}

export function deviceOtaUpgrade(deviceId) {
  return request(`${serviceName}/ota/deviceOtaUpgrade/${deviceId}`, {
    method: 'GET',
  });
}

// 获取项区域统计
export function areaMap(data) {
  return request(`${deviceScreen}/count`, {
    method: 'GET',
    body: data,
  });
}

// 获取地图设备列表
export function getMapDeviceList(data) {
  return request(`${deviceScreen}/list`, {
    method: 'GET',
    body: data,
  });
}

// 音视频-发起通话
export function initiateCall(data) {
  return request(`${serviceName}/call/initiate`, {
    method: 'POST',
    body: data,
  });
}

// 音视频-结束通话
export function finishCall(channelId) {
  return request(`${serviceName}/call/finish/${channelId}`, {
    method: 'POST',
  });
}

// 设备数据更新
export function updateDeviceData(data) {
  return request(`${serviceName}/updateDeviceData`, {
    method: 'POST',
    body: data,
  });
}
