import request from '../request';

const serviceName = '/iot-smart-helmet/geofence';

/**
 * @name 电子围栏分页查询
 */
export function getFenceList(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 电子围栏列表查询
 */
export function getFenceListById(data) {
  return request(`${serviceName}/listById`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

export function insertFence(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

export function updateFence(data) {
  return request(`${serviceName}`, {
    method: 'PUT',
    body: data,
  });
}

/** @name 电子围栏查询 */
export function getFenceDetail(id) {
  return request(`${serviceName}/${id}`, {
    method: 'GET',
  });
}

/** @description 电子围栏删除 */
export function removeFence(ids) {
  return request(`${serviceName}?geofenceIds=${ids}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

/** @description 电子围栏复制 */
export function copyFence(id) {
  return request(`${serviceName}/copy`, {
    method: 'POST',
    body: id,
  });
}

/** @description 获取电子围栏区域列表 */
export function getZonesData(data) {
  return request(`${serviceName}/zone/list`, {
    method: 'GET',
    body: data,
  });
}

/** @description 电子围栏区域批量添加 */
export function batchZone(data) {
  return request(`${serviceName}/batch/zone`, {
    method: 'POST',
    body: data,
  });
}

/** @description 电子围栏区域删除 */
export function deleteZone(id) {
  return request(`${serviceName}/zone/?geofenceZoneIds=${id}`, {
    method: 'DELETE',
  });
}
/** @description 电子围栏删除 */
export function deleteBatch(id) {
  return request(`${serviceName}/?geofenceIds=${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
