<template>
  <div :class="$style.container">
    <div :class="$style.proofBox" v-if="!timeout">
      <!-- <p>{{ $t('matterwebset.screenmanage.screentext') }}</p> -->
      <p>{{ $t('hat.screen.title') }}</p>
      <div v-if="isNeedProof" :class="$style.inputBox">
        <a-input
          @keydown.enter="checkUrl"
          :class="$style.input"
          v-model="password"
          @change="input"
          :placeholder="$t('hat.screen.enterPassword')"
        >
          <a-icon slot="prefix" type="lock" />
        </a-input>
        <a-button
          type="primary"
          :class="[$style.button, password.length === 4 ? $style.active : '']"
          @click="checkUrl"
          >{{ $t('common.action.confirm') }}</a-button
        >
      </div>
      <div :class="[errorMes ? $style.showMes : '', $style.errorMes]">
        {{ errorMes }}
      </div>
    </div>
    <div :class="[$style.proofBox, $style.outTimeBox]" v-else>
      <p>{{ $t('matterwebset.screenmanage.timeout') }}</p>
    </div>
  </div>
</template>

<script>
import '@/assets/css/reset.css';
import { Component, Vue } from 'vue-property-decorator';
import { checkShare } from '@/services/smart-hat/screen-share';
import { flexDpr } from '@/assets/js/flex-dpr.js';
// import { Emitter } from '@/views/screen/components/screen-utils';
import { uuid } from '@triascloud/utils';

flexDpr();
@Component({
  created() {
    this.checkUrl(false);
  },
})
export default class Password extends Vue {
  password = '';
  isNeedProof = true;
  timeout = false;
  errorMes = '';
  flag = null;
  /**
   * @name 密码锁定只能用字母+数字4位
   */
  input({ target: { value } }) {
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.substring(0, 4);
    this.password = value;
  }
  showMes(val) {
    this.errorMes = val;
    this.flag && clearTimeout(this.flag);
    this.flag = setTimeout(() => (this.errorMes = ''), 3000);
  }
  async checkUrl(needVerify = true) {
    if (needVerify) {
      if (this.isNeedProof && this.password.length < 4) {
        this.showMes(
          this.password.length
            ? this.$t('iotScreenManage.pleaseSetFourPwd')
            : this.$t('hat.screen.enterPassword'),
        );
        return;
      }
    }
    try {
      const id = this.$route.params.id;
      const data = { shareId: id, password: this.password };
      const { expired, shareTitle } = await checkShare(data);
      if (!expired) {
        sessionStorage.setItem('SHOW_HEADER', shareTitle ? 'SHOW' : 'HIDDEN');
        this.$router.push({
          path: `/hat/share/screen/${id}/${this.password || uuid()}`,
        });
      }
    } catch (e) {
      if (e.code === 10064) {
        this.isNeedProof && needVerify
          ? this.showMes(e.message)
          : (this.isNeedProof = true);
      } else if (e.code === 10063) {
        this.timeout = true;
      }
    }
  }
}
</script>

<style lang="less" module>
.container {
  width: 100vw;
  height: 100vh;
  background-image: url('~@/assets/images/iot-screen-manage/share-screen-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .proofBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
      color: #05d3ff;
      text-align: center;
      font-size: 0.48rem;
      margin-bottom: 0.5rem;
    }
    .inputBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: content-box;
      box-shadow: 0rem 0rem 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
      background: linear-gradient(#07d3fd, #07d3fd) left top,
        linear-gradient(#07d3fd, #07d3fd) left top,
        linear-gradient(#07d3fd, #07d3fd) right top,
        linear-gradient(#07d3fd, #07d3fd) right top,
        linear-gradient(#07d3fd, #07d3fd) left bottom,
        linear-gradient(#07d3fd, #07d3fd) left bottom,
        linear-gradient(#07d3fd, #07d3fd) right bottom,
        linear-gradient(#07d3fd, #07d3fd) right bottom;
      background-repeat: no-repeat;
      background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
      padding: 0.2rem;
    }
    .input {
      width: 3.4rem;
      height: 0.54rem;
      border-radius: 5px;
      border-color: #0884b9;
      background-color: #fff;
      :global(.ant-input) {
        border: none;
        height: 100%;
      }
    }
    .button {
      margin-left: 0.2rem;
      height: 0.54rem;
      width: 1.48rem;
      background-color: #0884b9;
      border-color: #0884b9;
      font-size: 0.18rem;
      font-weight: bold;
      &.active {
        background-color: #0bb3fb;
      }
    }
  }
  .outTimeBox {
    width: 6rem;
  }
  .errorMes {
    max-height: 0;
    position: absolute;
    transition: all 200ms linear;
    bottom: -0.3rem;
    font-size: 0.18rem;
    font-weight: 600;
    color: rgb(198, 43, 43);
    overflow: hidden;
  }
  .showMes {
    max-height: 0.2rem;
  }
}
</style>
