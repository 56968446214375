<template>
  <div :class="$style.card">
    <div :class="$style.header">
      <div :class="[$style.avatarIcon, hatColor]">
        <x-oss-image
          :class="$style.iconImg"
          :ossPath="detail.userAvatar"
          v-if="detail.userAvatar"
        />
        <span :class="[$style.iconImg, $style.noAvatar]" v-else>{{
          detail.userName.charAt(0)
        }}</span>
      </div>
      <div :class="$style.title">
        <div :class="$style.name">
          <div :class="[$style.text, $style.textOverflow]">
            {{ detail.userName || '-' }}
          </div>
          <div :class="$style.web">
            <div :class="$style.status">
              {{ computedSTATUS_KEY[detail.status] }}
            </div>
            <div :class="$style.net">
              <a-icon
                v-if="LOCATEMODE_KEY[detail.locateMode] === 'WIFI'"
                type="wifi"
              />
              <section :class="$style.signalWrap">
                <svg
                  v-if="detail.networkSignal === NETWORK_KEY.DIFFERENCE"
                  :class="$style.sWicon"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    d="M170.666667 614.4H34.133333c-10.24 0-17.066667-6.826667-17.066666-17.066667v-170.666666c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v170.666666c0 10.24-6.826667 17.066667-17.066666 17.066667z m-119.466667-34.133333h102.4v-136.533334h-102.4v136.533334zM375.466667 614.4H238.933333c-10.24 0-17.066667-6.826667-17.066666-17.066667s6.826667-17.066667 17.066666-17.066666h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066666s-6.826667 17.066667-17.066666 17.066667zM580.266667 614.4h-136.533334c-10.24 0-17.066667-6.826667-17.066666-17.066667s6.826667-17.066667 17.066666-17.066666h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066666s-6.826667 17.066667-17.066666 17.066667zM785.066667 614.4h-136.533334c-10.24 0-17.066667-6.826667-17.066666-17.066667s6.826667-17.066667 17.066666-17.066666h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066666s-6.826667 17.066667-17.066666 17.066667z"
                  />
                </svg>
                <svg
                  v-else-if="detail.networkSignal === NETWORK_KEY.MIDDLE"
                  :class="$style.sWicon"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    d="M170.666667 699.733333H34.133333c-10.24 0-17.066667-6.826667-17.066666-17.066666v-170.666667c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v170.666667c0 10.24-6.826667 17.066667-17.066666 17.066666z m-119.466667-34.133333h102.4v-136.533333h-102.4v136.533333zM580.266667 699.733333h-136.533334c-10.24 0-17.066667-6.826667-17.066666-17.066666s6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667s-6.826667 17.066667-17.066666 17.066666zM785.066667 699.733333h-136.533334c-10.24 0-17.066667-6.826667-17.066666-17.066666s6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667s-6.826667 17.066667-17.066666 17.066666zM375.466667 699.733333H238.933333c-10.24 0-17.066667-6.826667-17.066666-17.066666V341.333333c0-10.24 6.826667-17.066667 17.066666-17.066666h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066666v341.333334c0 10.24-6.826667 17.066667-17.066666 17.066666z m-119.466667-34.133333h102.4v-307.2h-102.4v307.2z"
                  />
                </svg>
                <svg
                  v-else-if="detail.networkSignal === NETWORK_KEY.GOOD"
                  :class="$style.sWicon"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    d="M170.666667 819.2H34.133333c-10.24 0-17.066667-6.826667-17.066666-17.066667v-170.666666c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v170.666666c0 10.24-6.826667 17.066667-17.066666 17.066667z m-119.466667-34.133333h102.4v-136.533334h-102.4v136.533334zM785.066667 819.2h-136.533334c-10.24 0-17.066667-6.826667-17.066666-17.066667s6.826667-17.066667 17.066666-17.066666h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066666s-6.826667 17.066667-17.066666 17.066667zM375.466667 819.2H238.933333c-10.24 0-17.066667-6.826667-17.066666-17.066667v-341.333333c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v341.333333c0 10.24-6.826667 17.066667-17.066666 17.066667z m-119.466667-34.133333h102.4v-307.2h-102.4v307.2zM580.266667 819.2h-136.533334c-10.24 0-17.066667-6.826667-17.066666-17.066667v-580.266666c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v580.266666c0 10.24-6.826667 17.066667-17.066666 17.066667z m-119.466667-34.133333h102.4V238.933333h-102.4v546.133334z"
                  />
                </svg>
                <svg v-else :class="$style.sWicon" viewBox="0 0 1024 1024">
                  <path
                    d="M170.666667 921.6H34.133333c-10.24 0-17.066667-6.826667-17.066666-17.066667v-170.666666c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v170.666666c0 10.24-6.826667 17.066667-17.066666 17.066667z m-119.466667-34.133333h102.4v-136.533334h-102.4v136.533334zM375.466667 921.6H238.933333c-10.24 0-17.066667-6.826667-17.066666-17.066667v-341.333333c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v341.333333c0 10.24-6.826667 17.066667-17.066666 17.066667z m-119.466667-34.133333h102.4v-307.2h-102.4v307.2zM580.266667 921.6h-136.533334c-10.24 0-17.066667-6.826667-17.066666-17.066667v-580.266666c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v580.266666c0 10.24-6.826667 17.066667-17.066666 17.066667z m-119.466667-34.133333h102.4V341.333333h-102.4v546.133334zM785.066667 921.6h-136.533334c-10.24 0-17.066667-6.826667-17.066666-17.066667v-785.066666c0-10.24 6.826667-17.066667 17.066666-17.066667h136.533334c10.24 0 17.066667 6.826667 17.066666 17.066667v785.066666c0 10.24-6.826667 17.066667-17.066666 17.066667z m-119.466667-34.133333h102.4V136.533333h-102.4v750.933334z"
                  />
                </svg>
              </section>
              <span :class="$style.netTxt">{{
                LOCATEMODE_KEY[detail.locateMode]
              }}</span>
            </div>
          </div>
        </div>
        <div :class="$style.time">
          <span :class="[$style.hatIcon, hatColor]">
            <span :class="$style.plusTxt" v-if="detail.type === 'PLUS'"
              >plus</span
            >
            <x-icon v-else :class="$style.iconW" type="tc-icon-hat-smile" />
          </span>
          <div>{{ time }}</div>
        </div>
      </div>
    </div>
    <section :class="$style.common" v-if="commonActive === 'common'">
      <div :class="$style.voiceVdeio" v-if="detail.type === 'PLUS'">
        <div :class="$style.btn" @click="handleVideo('audio')">
          <div :class="$style.icon">
            <a-icon type="audio" theme="filled" />
          </div>
          <span>
            {{ $t('hat.managementModal.personInfo.voice') }}
          </span>
        </div>
        <div :class="$style.btn" @click="handleVideo('video')">
          <div :class="$style.icon">
            <a-icon type="video-camera" theme="filled" />
          </div>
          <span>
            {{ $t('hat.managementModal.personInfo.video') }}
          </span>
        </div>
      </div>
      <div :class="$style.img" v-if="link">
        <img @click="handleShowMap" :src="link" alt="" :class="$style.map" />
        <div :class="[$style.address, $style.textOverflow]">
          <span style="color: rgba(255,255,255, 0.7);">
            {{ $t('hat.managementModal.personInfo.locationNow') }}：
          </span>
          <span>
            <a-tooltip placement="top">
              <span slot="title"> {{ address }} </span>
              {{ address }}
            </a-tooltip>
          </span>
        </div>
      </div>
      <div :class="$style.img" v-else @click="handleShowMap">
        <empty-content
          :class="$style.map"
          :label="$t('hat.deviceManagement.positionUnknown')"
        />
      </div>
      <div :class="$style.info">
        <div v-for="item in infoList" :key="item.title">
          <div style="color: var(--font-info)">{{ item.title }}</div>
          <div>{{ item.value }}</div>
        </div>
      </div>
      <div :class="$style.info">
        <div>
          <div style="color: var(--font-info)">
            {{ $t('electricBoxMock.columns.equipemnt') }}
          </div>
          <div>{{ detail.deviceName }}</div>
        </div>
      </div>
      <div :class="$style.project">
        <div style="color: var(--font-info)">
          {{ $t('hat.managementModal.personInfo.project') }}
        </div>
        <div :class="$style.textOverflow">
          <a-tooltip placement="top">
            <span slot="title">{{ detail.projectName }}</span>
            {{ detail.projectName || '-' }}
          </a-tooltip>
        </div>
      </div>
    </section>
    <div :class="$style.commonPadding" v-if="commonActive === 'video'">
      <audio-video @hand-up="handleHandUp" :type="videoType" />
    </div>
  </div>
</template>
<script>
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import EmptyContent from '@/components/empty-content';
import { funAMap } from '@/views/hat/electron-fence/components/util';
import AudioVideo from './audio-video.vue';

@Component({
  components: {
    EmptyContent,
    AudioVideo,
  },
})
export default class PersonInfo extends Vue {
  @Inject('CHANGE_MINI_MAP') changeMapStatus;
  @InjectReactive('deviceDetail') detail;

  @Watch('detail', { immediate: true, deep: true })
  detailChange() {
    this.getDeviceDetail();
  }

  /** @name 设备联网方式 */
  LOCATEMODE_KEY = {
    FOUR_G: '4G',
    THREE_G: '3G',
    TWO_G: '2G',
    WIFI: 'WIFI',
  };
  /** @name 设备网络信号 */
  NETWORK_KEY = {
    DIFFERENCE: 'DIFFERENCE',
    MIDDLE: 'MIDDLE',
    GOOD: 'GOOD',
    EXCELLENT: 'EXCELLENT',
  };
  /** @name 定位卫星信号 */
  SATELLITE_KEY = {
    WEAK: this.$t('hat.managementModal.personInfo.weak'),
    STRONG: this.$t('hat.managementModal.personInfo.strong'),
  };
  /** @name 信号类型 */
  SIGNAL_KEY = {
    NOT: this.$t('hat.managementModal.personInfo.not'),
    GPS: 'GPS',
    BEIDOU: this.$t('hat.managementModal.personInfo.BDS'),
    GALILEO: this.$t('hat.managementModal.personInfo.galileo'),
    QUASI_ZENITH: this.$t('hat.managementModal.personInfo.QZSS'),
    DEAL: this.$t('hat.managementModal.personInfo.GLONASS'),
    HYBRID_POSITION: this.$t('hat.managementModal.personInfo.hybrid'),
  };
  POSITION_KEY = {
    BASE_STATION: this.$t('hat.managementModal.personInfo.baseStation'),
    WIFI: 'wifi',
  };
  get computedSTATUS_KEY() {
    const { $t } = this;
    return {
      UNACTIVE: $t('hat.managementModal.personInfo.unactived'),
      ONLINE: $t('hat.managementModal.personInfo.online'),
      OFFLINE: $t('hat.managementModal.personInfo.offline'),
      STANDBY: $t('hat.managementModal.personInfo.standby'),
      DISABLE: $t('hat.managementModal.personInfo.disable'),
      EXPIRED: $t('hat.managementModal.personInfo.expired'),
    };
  }
  get hatColor() {
    if (this.detail && this.detail.color) {
      switch (this.detail.color) {
        case 'WHITE':
          return this.$style.white;
        case 'RED':
          return this.$style.red;
        case 'YELLOW':
          return this.$style.yellow;
        case 'BLUE':
          return this.$style.blue;
        case 'ORANGE':
          return this.$style.orange;
      }
    }
    return '';
  }
  setSatellite() {
    const detail = this.detail;
    return this.POSITION_KEY[detail.positionType]
      ? this.POSITION_KEY[detail.positionType]
      : this.SIGNAL_KEY[detail.satelliteType] || '-';
  }
  handleShowMap() {
    let array = [];
    if (+this.detail?.longitude && +this.detail?.latitude) {
      array = [+this.detail.longitude, +this.detail.latitude];
    }
    if (array.length) {
      this.changeMapStatus(true, array);
    } else {
      this.changeMapStatus(true);
    }
  }

  videoType = 'video';
  handleVideo(type) {
    if (this.detail.status !== 'ONLINE') {
      this.$message.warning('当前设备不在线！');
      return;
    }
    if (this.detail.type !== 'PLUS') {
      this.$message.warning('该版本不支持视频语音通话！');
      return;
    }
    this.videoType = type;
    if (this.commonActive === 'common') {
      this.commonActive = 'video';
    }
  }

  handleHandUp() {
    if (this.commonActive !== 'common') {
      this.commonActive = 'common';
    }
  }

  commonActive = 'common';

  get infoList() {
    return [
      {
        title: this.$t('hat.deviceManagement.details.panel.type'),
        value: this.setSatellite(),
      },
      {
        title: this.$t('hat.managementModal.personInfo.longitude'),
        value: +this.detail?.longitude || '-',
      },
      {
        title: this.$t('hat.managementModal.personInfo.latitude'),
        value: +this.detail?.latitude || '-',
      },
      {
        title: this.$t('hat.managementModal.personInfo.signal'),
        value: this.SATELLITE_KEY[this.detail.satelliteSignal] || '-',
      },
    ];
  }
  get computedInfoList() {
    const $t = this.$t;
    return [
      {
        title: $t('hat.managementModal.personInfo.satellite'),
        value: this.setSatellite(),
      },
      {
        title: $t('hat.managementModal.personInfo.longitude'),
        value: +this.detail?.longitude || '-',
      },
      {
        title: $t('hat.managementModal.personInfo.latitude'),
        value: +this.detail?.latitude || '-',
      },
      {
        title: $t('hat.managementModal.personInfo.signal'),
        value: this.SATELLITE_KEY[this.detail.satelliteSignal] || '-',
      },
    ];
  }

  time = '';
  async getDeviceDetail() {
    this.getStaticMap();
    if (this.detail.lastOnlineTime) {
      this.time = this.timeFormat(Date.now(), this.detail.lastOnlineTime);
    }
    await this.getAddressByLngLat();
  }

  // 秒、分钟、小时、天
  timeFormat(current = 0, target = 0) {
    /** @name 1天的毫秒数 */
    const diffDay = 86400000;
    /** @name 1小时的毫秒数 */
    const dH = 3600000;
    /** @name 1分钟的毫秒数 */
    const dM = 60000;

    const diff = current - target;

    let result = '';
    if (diff >= diffDay) {
      const day = Math.floor(diff / diffDay);
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: day,
        unit: this.$t('screen.days'),
      });
    } else if (diff > dH) {
      const hour = dayjs(current)
        .diff(target, 'hour', true)
        .toFixed(1);
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: hour,
        unit: this.$t('hat.screen.hours'),
      });
    } else if (diff > dM) {
      const minute = dayjs(current).diff(target, 'minute');
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: minute,
        unit: this.$t('hat.managementModal.setting.hint.rule.minutes'),
      });
    } else {
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: '1',
        unit: this.$t('hat.managementModal.setting.hint.rule.minutes'),
      });
    }
    return result;
  }

  link = '';
  getStaticMap() {
    if (+this.detail?.longitude && +this.detail?.latitude) {
      const LngLat = `${+this.detail.longitude},${+this.detail.latitude}`;
      const link = `https://restapi.amap.com/v3/staticmap?location=${LngLat}&zoom=16&size=305*160&markers=small,0x3691f6,:${LngLat}&key=${process.env.VUE_APP_AMAP_WEB_API_KEY}`;
      this.link = link;
    }
  }

  address = '';
  async getAddressByLngLat() {
    if (this.detail.longitude) {
      const AMap = await funAMap({
        plugins: ['AMap.Geocoder'],
      });
      const geocoder = new AMap.Geocoder();

      geocoder.getAddress(
        [this.detail.longitude, this.detail.latitude],
        (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            this.address = result.regeocode.formattedAddress;
          }
        },
      );
    }
  }
}
</script>
<style lang="less" module>
.card {
  box-shadow: 0 0 6px 0 #e1e1e1;
  border-radius: 6px;
  padding: 0 20px;
}

.header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .avatarIcon {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconImg {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      object-fit: cover;
    }
    .noAvatar {
      color: #fff;
      background-color: var(--x-modal-select-avatar);
      text-align: center;
      line-height: 42px;
      font-weight: 800;
      font-size: 18px;
    }
  }

  .title {
    flex: 1;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 240px;

      .text {
        max-width: 140px;
        font-size: 20px;
        font-weight: 600;
      }

      .web {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .status {
          box-sizing: content-box;
          font-size: 12px;
          color: white;
          background-color: var(--primary);
          border-radius: 10px;
          text-align: center;
          margin-right: 10px;
          padding: 1px 10px;
        }

        .net {
          color: var(--primary);
          display: flex;
          align-items: center;
          .netTxt {
            margin-left: 4px;
          }
        }
      }
    }

    .time {
      width: 200px;
      display: flex;
      align-items: center;
      color: var(--font-info);
      margin-top: 2px;
      .hatIcon {
        width: 32px;
        height: 16px;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        .iconW {
          color: #ffffff;
          font-size: 14px;
        }
      }
    }
  }
}

.common {
  .voiceVdeio {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;

    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85px;
      font-size: 16px;
      cursor: pointer;

      .icon {
        height: 42px;
        width: 42px;
        border-radius: 50%;
        background-color: var(--tag);
        color: var(--font);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.commonPadding {
  padding: 10px;
}

.img {
  position: relative;
  margin-top: 20px;

  .map {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 #e1e1e1;
  }

  .address {
    position: absolute;
    bottom: 0;
    color: var(--block-bg);
    width: 100%;
    padding: 2px 0 2px 4px;
    background: rgba(51, 51, 51, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.info {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.project {
  margin-top: 20px;
}

.textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blue {
  background-color: #4771ff;
}
.yellow {
  background-color: #ffbf07;
}
.white {
  background-color: #f3f6fc;
  .iconW {
    color: #fff !important;
  }
}
.red {
  background-color: #f60000;
}
.orange {
  background-color: #ff8707;
}

.signalWrap {
  display: inline-block;
  height: 20px;
  .sWicon {
    fill: var(--font-active);
    width: 20px;
    height: 20px;
  }
  .sIcon {
    border-radius: 4px;
    display: inline-flex;
    width: 3px;
    height: 1px;
    background-color: var(--font-active);
  }
}
.plusTxt {
  font-size: 20px;
  font-weight: bolder;
  transform: scale(0.5);
  margin: 0 !important;
  color: #ffffff;
}
</style>
