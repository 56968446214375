import request from '../request';

const serviceName = '/things/alarm';

/**
 * @name 获取未读告警信息
 */
export function getUnReadMessageList(options) {
  return request(`${serviceName}/countAlarmMessage`, {
    method: 'GET',
    body: options,
    auth: false,
    closeMessage: true,
  });
}
