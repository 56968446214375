<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popover, Menu, Tooltip } from 'ant-design-vue';
import { Icon } from '@triascloud/x-components';

/**
 * @param { Vue.CreateElement } h
 * @param { Vue.VNode } vNode
 */
function cloneVNode(h, vNode) {
  return h(
    vNode.componentOptions.Ctor,
    {
      ...vNode.data,
      props: {
        ...vNode.componentOptions.propsData,
      },
      on: {
        ...vNode.componentOptions.listeners,
        ...(vNode.data && vNode.data.on),
      },
    },
    vNode.componentOptions.children,
  );
}

const MenuItem = Menu.Item;

@Component()
export default class FormTableActionBar extends Vue {
  @Prop({ type: Boolean, default: false }) showText;
  @Prop({ type: Number, default: 10 }) sliceIndex;
  @Prop({ type: Object, default: {} }) featureMap;

  getMoreList() {
    const slots = this.$scopedSlots.default({ close: this.handleClose }) || [];
    return this.sliceIndex > -1 ? slots.slice(this.sliceIndex) : [];
  }

  handleClose() {
    this.visible = false;
  }

  visible = false;
  render(h) {
    const moreList = this.getMoreList();
    const dropdown =
      moreList.length > 0 ? (
        <Popover
          trigger={['click']}
          overlayClassName={this.$style.ActionPopover}
        >
          <Menu class={this.$style.dropdownMenu} slot="content">
            {moreList.map(vNode =>
              vNode.key === 'Setting' ? (
                <MenuItem
                  class={this.$style.dropdownMenuSetting}
                  key={vNode.key}
                >
                  {cloneVNode(h, vNode)}
                </MenuItem>
              ) : (
                <MenuItem key={vNode.key}>{cloneVNode(h, vNode)}</MenuItem>
              ),
            )}
          </Menu>
          <Icon type="ellipsis" class={this.$style.ellipsis} hover />
        </Popover>
      ) : null;
    const slots = this.$scopedSlots.default({ close: this.handleClose }) || [];
    return (
      <div class={this.$style.container}>
        <div ref="scroll" class={this.$style.scroll}>
          {slots.map((vNode, index) => {
            const title =
              vNode.data.attrs.title || vNode.componentOptions.propsData.title;
            return (
              <Tooltip>
                {this.showText ? '' : <span slot="title">{title}</span>}
                <Wrap
                  key={vNode.key}
                  class={[
                    (this.sliceIndex > -1 && this.sliceIndex <= index) ||
                    (!this.featureMap[vNode.key] && vNode.key !== 'home')
                      ? this.$style.hidden
                      : null,
                    this.showText ? '' : this.$style.wrap,
                  ]}
                >
                  {vNode}
                </Wrap>
              </Tooltip>
            );
          })}
        </div>
        {dropdown}
      </div>
    );
  }
}

@Component()
class Wrap extends Vue {
  render() {
    return this.$slots.default[0];
  }
}
</script>
<style lang="less" module>
.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :global(.wrapBox) {
    padding: 0 10px;
  }
}
.scroll {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ellipsis {
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  margin: 0 12px;
}
.hidden {
  display: none !important;
}
.wrap {
  margin-right: 10px;

  :global(.text) {
    display: none;
  }

  :global(.themeSelect .x-icon.x-icon-hover) {
    padding: 0;
  }
}
.ActionPopover {
  :global(.ant-menu) {
    padding-top: 5px;
  }
  :global(.wrapBox) {
    margin: 0px;
    :global(.x-download) {
      width: 105px;
    }
    :global(.ant-badge) {
      display: flex;
    }
  }
  :global(.ant-popover-inner-content) {
    padding: 0;
  }
  .dropdownMenuSetting {
    border-top: 1px solid var(--border);
    min-width: 75px;
    display: flex;
    justify-content: center;

    i {
      color: var(--font);
    }
  }
  :global(.ant-menu-vertical .ant-menu-item) {
    padding: 0;
    margin: 0;
  }
  :global(.ant-menu-item-selected .ant-btn) {
    color: var(--primary);
    a {
      color: var(--primary);
    }
    i {
      color: var(--primary);
    }
  }
  :global(.ant-menu-item-selected .ant-badge) {
    i {
      color: var(--primary);
    }
  }
  :global(.ant-menu-item-active .ant-btn) {
    .action {
      color: var(--primary);
    }
    a {
      color: var(--primary);
    }
  }
}
</style>
