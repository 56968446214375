import { Settings } from './core/Settings';
import { UndoRedoManager } from './core/UndoRedoManager';
import { StyleManager, Style } from './core/Style';
import { toFixed } from './core/Utils';
import {
  EventListenerRepository,
  MarkerAreaRenderEvent,
  MarkerAreaEvent,
  MarkerEvent,
} from './core/Events';
import { SvgHelper } from './core/SvgHelper';
import { Renderer } from './core/Renderer';
import { CalloutMarker } from './markers/callout-marker/CalloutMarker';
import { EllipseMarker } from './markers/ellipse-marker/EllipseMarker';
import { PolylineMarker } from './markers/polyline-marker/PolylineMarker';
import { PolygonMarker } from './markers/polygon-marker/PolygonMarker';
import { TextMarker } from './markers/text-marker/TextMarker';
// import { Toolbox } from './ui/Toolbox';
// import { Toolbar } from './ui/Toolbar';
export class MarkerArea {
  target;
  targetObserver;

  width;
  height;
  imageWidth;
  imageHeight;
  left;
  top;
  windowHeight;

  markerImage;
  markerImageHolder;
  defs;

  coverDiv;
  uiDiv;
  contentDiv;
  editorCanvas;
  editingTarget;
  overlayContainer;

  touchPoints = 0;

  /** 根元素 */
  targetRoot;

  /** 所有marker */
  get ALL_MARKER_TYPES() {
    return [
      TextMarker,
      CalloutMarker,
      EllipseMarker,
      PolylineMarker,
      PolygonMarker,
    ];
  }

  /** 默认的marker */
  get DEFAULT_MARKER_TYPES() {
    return [CalloutMarker, EllipseMarker, PolylineMarker, PolygonMarker];
  }

  _availableMarkerTypes = this.DEFAULT_MARKER_TYPES;
  get availableMarkerTypes() {
    return this._availableMarkerTypes;
  }
  set availableMarkerTypes(value = []) {
    this._availableMarkerTypes.splice(0);
    value.forEach(mt => {
      if (typeof mt === 'string') {
        const typeType = this.ALL_MARKER_TYPES.find(v => v.typeName === mt);
        if (typeType !== undefined) {
          this._availableMarkerTypes.push(typeType);
        }
      } else {
        this._availableMarkerTypes.push(mt);
      }
    });
  }

  /** 可操作的按钮面板 */
  toolbar = null;
  /** marker的属性编辑面板 */
  toolbox = null;

  /** 整体的操作模式 select | create | delete */
  mode = 'select';

  _currentMarker = undefined;
  get currentMarker() {
    return this._currentMarker;
  }
  /** marker存储数组 */
  markers = [];

  isDragging = false;
  _isOpen = false;
  get isOpen() {
    return this._isOpen;
  }

  bodyOverflowState;
  scrollYState;
  scrollXState;

  settings = new Settings();
  /** @type { import('./core/Settings').IStyleSettings } */
  uiStyleSettings;

  undoRedoManager = new UndoRedoManager();
  get isUndoPossible() {
    if (this.undoRedoManager && this.undoRedoManager.isUndoPossible) {
      return true;
    } else {
      return false;
    }
  }
  get isRedoPossible() {
    if (this.undoRedoManager && this.undoRedoManager.isRedoPossible) {
      return true;
    } else {
      return false;
    }
  }

  renderAtNaturalSize = false;

  renderImageType = 'image/png';

  renderImageQuality;

  renderMarkersOnly = false;

  renderWidth;

  renderHeight;

  renderTarget;

  zoomSteps = [1, 1.5, 2, 4];
  _zoomLevel = 1;
  get zoomLevel() {
    return this._zoomLevel;
  }
  set zoomLevel(value) {
    this._zoomLevel = value;
    if (this.editorCanvas && this.contentDiv) {
      this.editorCanvas.style.transform = `scale(${this._zoomLevel})`;
      this.contentDiv.scrollTo({
        left:
          (this.editorCanvas.clientWidth * this._zoomLevel -
            this.contentDiv.clientWidth) /
          2,
        top:
          (this.editorCanvas.clientHeight * this._zoomLevel -
            this.contentDiv.clientHeight) /
          2,
      });
    }
  }

  static instanceCounter = 0;
  _instanceNo;
  get instanceNo() {
    return this._instanceNo;
  }

  styles;

  eventListeners = new EventListenerRepository();
  addEventListener(eventType, handler) {
    this.eventListeners.addEventListener(eventType, handler);
  }
  removeEventListener(eventType, handler) {
    this.eventListeners.removeEventListener(eventType, handler);
  }

  /**
   *
   * @param { HTMLImageElement } target
   * @param { HTMLDivElement } targetRoot
   */
  constructor(target, targetRoot) {
    this._instanceNo = MarkerArea.instanceCounter++;

    this.styles = new StyleManager(this.instanceNo);

    this.uiStyleSettings = this.styles.settings;

    this.target = target;
    // this.targetRoot = document.body;
    this.targetRoot = targetRoot;

    this.width = target.clientWidth;
    this.height = target.clientHeight;
    this.styles.removeStyleSheet();

    this.open = this.open.bind(this);
    this.setTopLeft = this.setTopLeft.bind(this);

    this.toolbarButtonClicked = this.toolbarButtonClicked.bind(this);
    this.createNewMarker = this.createNewMarker.bind(this);
    this.addNewMarker = this.addNewMarker.bind(this);
    this.markerCreated = this.markerCreated.bind(this);
    this.setCurrentMarker = this.setCurrentMarker.bind(this);
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onDblClick = this.onDblClick.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onPointerOut = this.onPointerOut.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.overrideOverflow = this.overrideOverflow.bind(this);
    this.restoreOverflow = this.restoreOverflow.bind(this);
    this.close = this.close.bind(this);
    this.closeUI = this.closeUI.bind(this);
    this.clientToLocalCoordinates = this.clientToLocalCoordinates.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.deleteSelectedMarker = this.deleteSelectedMarker.bind(this);
    this.setWindowHeight = this.setWindowHeight.bind(this);
    this.removeMarker = this.removeMarker.bind(this);
    this.colorChanged = this.colorChanged.bind(this);
    this.fillColorChanged = this.fillColorChanged.bind(this);
    this.onPopupTargetResize = this.onPopupTargetResize.bind(this);
    this.showNotesEditor = this.showNotesEditor.bind(this);
    this.hideNotesEditor = this.hideNotesEditor.bind(this);
    this.stepZoom = this.stepZoom.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.markerStateChanged = this.markerStateChanged.bind(this);
    this.switchToSelectMode = this.switchToSelectMode.bind(this);
    this.addDefs = this.addDefs.bind(this);
    this.addDefsToImage = this.addDefsToImage.bind(this);
  }

  show() {
    // backwards compatibility with deprecated static Style class
    if (
      this.styles.styleSheetRoot === undefined &&
      Style.styleSheetRoot !== undefined
    ) {
      this.styles.styleSheetRoot = Style.styleSheetRoot;
    }

    this.markers.splice(0);

    this.setWindowHeight();
    this.showUI();
    this.open();
    this.eventListeners['show'].forEach(listener =>
      listener(new MarkerAreaEvent(this)),
    );
  }

  /**
   * Renders the annotation result.
   * 返回图片地址，通过canvas.toDataURL，转换出来的
   * Normally, you should use {@linkcode addEventListener} method to set a listener for the `render` event
   * rather than calling this method directly.
   */
  async render() {
    this.setCurrentMarker();

    const renderer = new Renderer();
    renderer.naturalSize = this.renderAtNaturalSize;
    renderer.imageType = this.renderImageType;
    renderer.imageQuality = this.renderImageQuality;
    renderer.markersOnly = this.renderMarkersOnly;
    renderer.width = this.renderWidth;
    renderer.height = this.renderHeight;

    // workaround for an issue in Safari where FreeHand marker
    // is not rendered on the first try for some reason
    await renderer.rasterize(
      this.target instanceof HTMLImageElement ? this.target : null,
      this.markerImage,
      this.renderTarget,
    );

    return await renderer.rasterize(
      this.target instanceof HTMLImageElement ? this.target : null,
      this.markerImage,
      this.renderTarget,
    );
  }

  /**
   * Closes the MarkerArea UI.
   */
  close(suppressBeforeClose = false) {
    if (this.isOpen) {
      let cancel = false;

      if (!suppressBeforeClose) {
        this.eventListeners['beforeclose'].forEach(listener => {
          const ev = new MarkerAreaEvent(this, true);
          listener(ev);
          if (ev.defaultPrevented) {
            cancel = true;
          }
        });
      }

      if (!cancel) {
        if (this.coverDiv) {
          this.closeUI();
        }
        if (this.targetObserver) {
          this.targetObserver.unobserve(this.target);
          this.targetObserver.unobserve(this.editorCanvas);
        }
        if (this.settings.displayMode === 'popup') {
          window.removeEventListener('resize', this.setWindowHeight);
        }
        this.eventListeners['close'].forEach(listener =>
          listener(new MarkerAreaEvent(this)),
        );
        this.detachEvents();
        this._isOpen = false;
      }
    }
  }

  /**
   * @param { PointerEvent } ev
   */
  onPointerDown(ev) {
    if (!this._isFocused) {
      this.focus();
    }

    this.touchPoints++;
    if (this.touchPoints === 1 || ev.pointerType !== 'touch') {
      if (
        this._currentMarker !== undefined &&
        (this._currentMarker.state === 'new' ||
          this._currentMarker.state === 'creating')
      ) {
        this.isDragging = true;
        this._currentMarker.pointerDown(
          this.clientToLocalCoordinates(ev.clientX, ev.clientY),
        );
      } else if (this.mode === 'select') {
        const hitMarker = this.markers.find(m => m.ownsTarget(ev.target));
        if (hitMarker !== undefined) {
          this.setCurrentMarker(hitMarker);
          this.isDragging = true;
          this._currentMarker.pointerDown(
            this.clientToLocalCoordinates(ev.clientX, ev.clientY),
            ev.target,
          );
        } else {
          this.setCurrentMarker();
          this.isDragging = true;
          this.prevPanPoint = { x: ev.clientX, y: ev.clientY };
        }
      }
    }
  }

  /**
   * @param { PointerEvent } ev
   */
  onDblClick(ev) {
    if (!this._isFocused) {
      this.focus();
    }

    if (this.mode === 'select') {
      const hitMarker = this.markers.find(m => m.ownsTarget(ev.target));
      if (hitMarker !== undefined && hitMarker !== this._currentMarker) {
        this.setCurrentMarker(hitMarker);
      }
      if (this._currentMarker !== undefined) {
        this._currentMarker.dblClick(
          this.clientToLocalCoordinates(ev.clientX, ev.clientY),
          ev.target,
        );
      } else {
        this.setCurrentMarker();
      }
    }
  }

  /**
   * @param { PointerEvent } ev
   */
  onPointerMove(ev) {
    if (this.touchPoints === 1 || ev.pointerType !== 'touch') {
      if (this._currentMarker !== undefined || this.isDragging) {
        // don't swallow the event when editing text markers
        if (
          this._currentMarker === undefined ||
          this._currentMarker.state !== 'edit'
        ) {
          ev.preventDefault();
        }

        if (this._currentMarker !== undefined) {
          this._currentMarker.manipulate(
            this.clientToLocalCoordinates(ev.clientX, ev.clientY),
          );
        } else if (this.zoomLevel > 1) {
          this.panTo({ x: ev.clientX, y: ev.clientY });
        }
      }
    }
  }

  /**
   * @param { PointerEvent } ev
   */
  onPointerUp(ev) {
    if (this.touchPoints > 0) {
      this.touchPoints--;
    }
    if (this.touchPoints === 0) {
      if (this.isDragging && this._currentMarker !== undefined) {
        this._currentMarker.pointerUp(
          this.clientToLocalCoordinates(ev.clientX, ev.clientY),
        );
      }
    }
    this.isDragging = false;
    this.addUndoStep();
  }

  onPointerOut() {
    if (this.touchPoints > 0) {
      this.touchPoints--;
    }
  }

  /**
   * @param { KeyboardEvent } ev
   */
  onKeyUp(ev) {
    if (
      this._currentMarker !== undefined &&
      this.notesArea === undefined &&
      ev.key === 'Delete'
    ) {
      this.deleteSelectedMarker();
    }
  }

  open() {
    this.setupResizeObserver();
    this.setEditingTarget();
    this.setTopLeft();
    // 初始化容器对象
    this.initMarkerCanvas();
    /** 创建一个overlayContainer容器，可用于notes的编辑textarea */
    this.initOverlay();
    // 添加pointer监听事件
    this.attachEvents();
    if (this.settings.displayMode === 'popup') {
      this.onPopupTargetResize();
    }

    this._isOpen = true;
    this._isFocused = true;
  }

  /** 监听元素的大小变化，缩放（scaleX,scaleY）问题，设置图片的大小问题 */
  setupResizeObserver() {
    if (this.settings.displayMode === 'inline') {
      if (window.ResizeObserver) {
        this.targetObserver = new ResizeObserver(() => {
          this.resize(this.target.clientWidth, this.target.clientHeight);
        });
        this.targetObserver.observe(this.target);
      }
    } else if (this.settings.displayMode === 'popup') {
      if (window.ResizeObserver) {
        this.targetObserver = new ResizeObserver(() =>
          this.onPopupTargetResize(),
        );
        this.targetObserver.observe(this.editorCanvas);
      }
      window.addEventListener('resize', this.setWindowHeight);
    }
  }

  onPopupTargetResize() {
    const ratio = (1.0 * this.target.clientWidth) / this.target.clientHeight;
    const newWidth =
      this.editorCanvas.clientWidth / ratio > this.editorCanvas.clientHeight
        ? this.editorCanvas.clientHeight * ratio
        : this.editorCanvas.clientWidth;
    const newHeight =
      newWidth < this.editorCanvas.clientWidth
        ? this.editorCanvas.clientHeight
        : this.editorCanvas.clientWidth / ratio;
    this.resize(newWidth, newHeight);
  }

  /** 设置(windowHeight)窗口的高度为window.innerHeight */
  setWindowHeight() {
    this.windowHeight = window.innerHeight;
  }

  _isResizing = false;
  resize(newWidth, newHeight) {
    this._isResizing = true;
    newWidth = toFixed(newWidth);
    newHeight = toFixed(newHeight);
    const scaleX = toFixed(newWidth / this.imageWidth);
    const scaleY = toFixed(newHeight / this.imageHeight);

    this.imageWidth = Math.round(newWidth);
    this.imageHeight = Math.round(newHeight);
    if (
      this.target instanceof HTMLImageElement &&
      this.editingTarget instanceof HTMLImageElement
    ) {
      this.editingTarget.src = this.target.src;
    }
    this.editingTarget.width = this.imageWidth;
    this.editingTarget.height = this.imageHeight;
    this.editingTarget.style.width = `${this.imageWidth}px`;
    this.editingTarget.style.height = `${this.imageHeight}px`;

    this.markerImage.setAttribute('width', this.imageWidth.toString());
    this.markerImage.setAttribute('height', this.imageHeight.toString());
    this.markerImage.setAttribute(
      'viewBox',
      '0 0 ' + this.imageWidth.toString() + ' ' + this.imageHeight.toString(),
    );

    this.markerImageHolder.style.width = `${this.imageWidth}px`;
    this.markerImageHolder.style.height = `${this.imageHeight}px`;

    this.overlayContainer.style.width = `${this.imageWidth}px`;
    this.overlayContainer.style.height = `${this.imageHeight}px`;

    if (this.settings.displayMode !== 'popup') {
      this.coverDiv.style.width = `${this.imageWidth.toString()}px`;
    } else {
      this.setTopLeft();
      this.positionMarkerImage();
    }

    if (this.toolbar !== undefined) {
      this.toolbar.adjustLayout();
    }
    this.scaleMarkers(scaleX, scaleY);

    this._isResizing = false;
  }

  /** 缩放所有图形 */
  scaleMarkers(scaleX, scaleY) {
    let preScaleSelectedMarker;
    if (!(this._currentMarker && this._currentMarker instanceof TextMarker)) {
      // can't unselect text marker as it would hide keyboard on mobile
      preScaleSelectedMarker = this._currentMarker;
      this.setCurrentMarker();
    } else {
      this._currentMarker.scale(scaleX, scaleY);
    }

    this.markers.forEach(marker => {
      if (marker !== this._currentMarker) {
        marker.scale(scaleX, scaleY);
      }
    });
    if (preScaleSelectedMarker !== undefined) {
      this.setCurrentMarker(preScaleSelectedMarker);
    }
  }

  /** 设置【可编辑图片对象】的属性,如：src,width,height,style */
  setEditingTarget() {
    this.imageWidth = Math.round(this.target.clientWidth);
    this.imageHeight = Math.round(this.target.clientHeight);
    if (
      this.target instanceof HTMLImageElement &&
      this.editingTarget instanceof HTMLImageElement
    ) {
      this.editingTarget.src = this.target.src;
    }
    this.editingTarget.width = this.imageWidth;
    this.editingTarget.height = this.imageHeight;
    this.editingTarget.style.width = `${this.imageWidth}px`;
    this.editingTarget.style.height = `${this.imageHeight}px`;
  }

  /** 根据【可编辑图片对象】和【可编辑容器】计算出left和top的值 */
  setTopLeft() {
    const targetRect = this.editingTarget.getBoundingClientRect();
    const bodyRect = this.editorCanvas.getBoundingClientRect();
    this.left = targetRect.left - bodyRect.left;
    this.top = targetRect.top - bodyRect.top;
  }

  /** 初始化【可操作区域】，即在【可编辑图片对象】上面，创建了一个div和svg */
  initMarkerCanvas() {
    this.markerImageHolder = document.createElement('div');
    this.markerImageHolder.style.setProperty('touch-action', 'pinch-zoom');

    this.markerImage = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg',
    );
    this.markerImage.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    this.markerImage.setAttribute('width', this.imageWidth.toString());
    this.markerImage.setAttribute('height', this.imageHeight.toString());
    this.markerImage.setAttribute(
      'viewBox',
      '0 0 ' + this.imageWidth.toString() + ' ' + this.imageHeight.toString(),
    );
    this.markerImage.style.pointerEvents = 'auto';

    this.markerImageHolder.style.position = 'absolute';
    this.markerImageHolder.style.width = `${this.imageWidth}px`;
    this.markerImageHolder.style.height = `${this.imageHeight}px`;
    this.markerImageHolder.style.transformOrigin = 'top left';
    this.positionMarkerImage();

    this.markerImageHolder.appendChild(this.markerImage);

    this.editorCanvas.appendChild(this.markerImageHolder);
  }

  /** 创建一个overlayContainer容器，可用于notes的编辑textarea */
  initOverlay() {
    this.overlayContainer = document.createElement('div');
    this.overlayContainer.style.position = 'absolute';
    this.overlayContainer.style.left = '0px';
    this.overlayContainer.style.top = '0px';
    this.overlayContainer.style.width = `${this.imageWidth}px`;
    this.overlayContainer.style.height = `${this.imageHeight}px`;
    this.overlayContainer.style.display = 'flex';
    this.markerImageHolder.appendChild(this.overlayContainer);
  }

  /** 添加绘制事件 pointer events  */
  attachEvents() {
    this.markerImage.addEventListener('pointerdown', this.onPointerDown);
    // workaround to prevent a bug with Apple Pencil
    // https://bugs.webkit.org/show_bug.cgi?id=217430
    this.markerImage.addEventListener('touchmove', ev => ev.preventDefault());

    this.markerImage.addEventListener('dblclick', this.onDblClick);
    this.attachWindowEvents();
  }

  /** 添加window监听事件 */
  attachWindowEvents() {
    window.addEventListener('pointermove', this.onPointerMove);
    window.addEventListener('pointerup', this.onPointerUp);
    window.addEventListener('pointercancel', this.onPointerOut);
    window.addEventListener('pointerout', this.onPointerOut);
    window.addEventListener('pointerleave', this.onPointerUp);
    window.addEventListener('resize', this.onWindowResize);
    window.addEventListener('keyup', this.onKeyUp);
  }

  detachEvents() {
    this.markerImage.removeEventListener('pointerdown', this.onPointerDown);
    this.markerImage.removeEventListener('dblclick', this.onDblClick);
    this.detachWindowEvents();
  }

  detachWindowEvents() {
    window.removeEventListener('pointermove', this.onPointerMove);
    window.removeEventListener('pointerup', this.onPointerUp);
    window.removeEventListener('pointercancel', this.onPointerOut);
    window.removeEventListener('pointerout', this.onPointerOut);
    window.removeEventListener('pointerleave', this.onPointerUp);
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('keyup', this.onKeyUp);
  }

  /** Toolbar buttonClickListeners 监听的逻辑判断，buttonType为标记（图形）或者动作 buttonType = marker | action */
  toolbarButtonClicked(buttonType, value, opts = {}) {
    if (buttonType === 'marker' && value !== undefined) {
      // 创建图形
      this.createNewMarker(value, opts);
    } else if (buttonType === 'action') {
      // 动作 select | delete | clear | undo | redo | zoom | zoom-out | notes
      switch (value) {
        case 'select': {
          this.switchToSelectMode();
          // workaround for text markers in continuos mode
          // otherwise it continues creation until clicked a second time
          this.switchToSelectMode();
          break;
        }
        case 'delete': {
          this.deleteSelectedMarker();
          break;
        }
        case 'clear': {
          this.clear();
          break;
        }
        case 'undo': {
          this.undo();
          break;
        }
        case 'redo': {
          this.redo();
          break;
        }
        case 'zoom': {
          this.stepZoom();
          break;
        }
        case 'zoom-out': {
          this.zoomLevel = 1;
          break;
        }
        case 'notes': {
          if (this.notesArea === undefined) {
            this.switchToSelectMode();
            this.zoomLevel = 1;
            this.showNotesEditor();
          } else {
            this.switchToSelectMode();
          }
          break;
        }
        case 'close': {
          this.close();
          break;
        }
        case 'render': {
          this.switchToSelectMode();
          this.startRenderAndClose();
          break;
        }
      }
    }
  }

  /**
   * Removes currently selected marker.
   */
  deleteSelectedMarker() {
    if (this._currentMarker !== undefined) {
      let cancel = false;

      this.eventListeners['markerbeforedelete'].forEach(listener => {
        const ev = new MarkerEvent(this, this._currentMarker, true);
        listener(ev);
        if (ev.defaultPrevented) {
          cancel = true;
        }
      });

      if (!cancel) {
        const marker = this._currentMarker;
        this._currentMarker.dispose();
        this.markerImage.removeChild(this._currentMarker.container);
        this.markers.splice(this.markers.indexOf(this._currentMarker), 1);
        this.setCurrentMarker();
        this.addUndoStep();
        this.eventListeners['markerdelete'].forEach(listener =>
          listener(new MarkerEvent(this, marker)),
        );
      }
    }
  }

  deleteMarker(delMarker) {
    if (delMarker === this._currentMarker) {
      this.deleteSelectedMarker();
    } else {
      delMarker.dispose();
      this.markerImage.removeChild(delMarker.container);
      this.markers.splice(this.markers.indexOf(delMarker), 1);
      this.setCurrentMarker();
      this.addUndoStep();
      this.eventListeners['markerdelete'].forEach(listener =>
        listener(new MarkerEvent(this, delMarker)),
      );
    }
  }

  /**
   * Removes all markers.
   *
   * @since 2.15.0
   */
  clear() {
    let cancel = false;
    if (this.markers.length > 0) {
      this.eventListeners['markerbeforedelete'].forEach(listener => {
        const ev = new MarkerEvent(this, undefined, true);
        listener(ev);
        if (ev.defaultPrevented) {
          cancel = true;
        }
      });
      if (!cancel) {
        this.setCurrentMarker();
        for (let i = this.markers.length - 1; i >= 0; i--) {
          const marker = this.markers[i];
          this.setCurrentMarker(this.markers[i]);
          this._currentMarker.dispose();
          this.markerImage.removeChild(this._currentMarker.container);
          this.markers.splice(this.markers.indexOf(this._currentMarker), 1);
          this.eventListeners['markerdelete'].forEach(listener =>
            listener(new MarkerEvent(this, marker)),
          );
        }
        this.addUndoStep();
      }
    }
  }

  notesAre = undefined;
  get isNotesAreaOpen() {
    return this.notesArea !== undefined;
  }

  showNotesEditor() {
    if (this._currentMarker !== undefined) {
      this.overlayContainer.innerHTML = '';
      this.notesArea = document.createElement('textarea');
      this.notesArea.className = this.uiStyleSettings.notesAreaStyleClassName;
      this.notesArea.style.pointerEvents = 'auto';
      this.notesArea.style.alignSelf = 'stretch';
      this.notesArea.style.width = '100%';
      this.notesArea.style.margin = `${this.uiStyleSettings.toolbarHeight /
        4}px`;
      this.notesArea.value = this._currentMarker.notes ?? '';
      this.overlayContainer.appendChild(this.notesArea);
    }
  }
  hideNotesEditor() {
    if (this.isNotesAreaOpen) {
      if (this._currentMarker !== undefined) {
        this._currentMarker.notes =
          this.notesArea.value.trim() !== '' ? this.notesArea.value : undefined;
      }
      this.overlayContainer.removeChild(this.notesArea);
      this.notesArea = undefined;
    }
  }

  selectLastMarker() {
    if (this.markers.length > 0) {
      this.setCurrentMarker(this.markers[this.markers.length - 1]);
    } else {
      this.setCurrentMarker();
    }
  }

  addUndoStep() {
    if (
      this._currentMarker === undefined ||
      this._currentMarker.state !== 'edit'
    ) {
      const currentState = this.getState();
      const lastUndoState = this.undoRedoManager.getLastUndoStep();
      if (
        lastUndoState &&
        (lastUndoState.width !== currentState.width ||
          lastUndoState.height !== currentState.height)
      ) {
        // if the size changed just replace the last step with a resized one
        this.undoRedoManager.replaceLastUndoStep(currentState);
        // @todo was sometimes fired on zoom events in popup mode
        // need to find the root cause before restoring statechange event here (if needed?)
        // this.eventListeners['statechange'].forEach((listener) =>
        //   listener(new MarkerAreaEvent(this))
        // );
      } else {
        const beforeSteps = this.undoRedoManager.undoStepCount;
        this.undoRedoManager.addUndoStep(currentState);
        if (beforeSteps < this.undoRedoManager.undoStepCount) {
          this.eventListeners['statechange'].forEach(listener =>
            listener(new MarkerAreaEvent(this)),
          );
        }
      }
    }
  }

  /**
   * Undo last action.
   *
   */
  undo() {
    this.switchToSelectMode();
    this.addUndoStep();
    this.undoStep();
  }

  undoStep() {
    const stepData = this.undoRedoManager.undo();
    if (stepData !== undefined) {
      this.restoreState(stepData);
      this.addDefsToImage();
      this.selectLastMarker();
      this.eventListeners['statechange'].forEach(listener =>
        listener(new MarkerAreaEvent(this)),
      );
    }
  }

  /**
   * Redo previously undone action.
   *
   */
  redo() {
    this.switchToSelectMode();
    this.redoStep();
  }

  redoStep() {
    const stepData = this.undoRedoManager.redo();
    if (stepData !== undefined) {
      this.restoreState(stepData);
      this.addDefsToImage();
      this.selectLastMarker();
      this.eventListeners['statechange'].forEach(listener =>
        listener(new MarkerAreaEvent(this)),
      );
    }
  }

  stepZoom() {
    const zoomStepIndex = this.zoomSteps.indexOf(this.zoomLevel);
    this.zoomLevel =
      zoomStepIndex < this.zoomSteps.length - 1
        ? this.zoomSteps[zoomStepIndex + 1]
        : this.zoomSteps[0];
  }

  prevPanPoint = { x: 0, y: 0 };
  panTo(point) {
    this.contentDiv.scrollBy({
      left: this.prevPanPoint.x - point.x,
      top: this.prevPanPoint.y - point.y,
    });
    this.prevPanPoint = point;
  }

  /**
   * Initiates markup rendering.
   *
   */
  async startRenderAndClose() {
    const result = await this.render();
    const state = this.getState();
    this.eventListeners['render'].forEach(listener =>
      listener(new MarkerAreaRenderEvent(this, result, state)),
    );
    this.close(true);
  }

  /**
   *
   * 返回MarkerAreaState结构，width,height,markers
   *
   * @param deselectCurrentMarker - when `true` is passed, currently selected marker will be deselected before getting the state.
   */
  getState(deselectCurrentMarker) {
    if (deselectCurrentMarker === true) {
      this.setCurrentMarker();
    }
    const result = {
      width: this.imageWidth,
      height: this.imageHeight,
      markers: [],
    };
    this.markers.forEach(marker => result.markers.push(marker.getState()));
    return result;
  }

  /**
   * Restores MarkerArea state to continue previous annotation session.
   *
   * **IMPORTANT**: call `restoreState()` __after__ you've opened the MarkerArea with {@linkcode show}.
   *
   * ```typescript
   * this.markerArea1.show();
   * if (this.currentState) {
   *   this.markerArea1.restoreState(this.currentState);
   * }
   * ```
   *
   * @param state - previously saved state object.
   */
  restoreState(state) {
    this.markers.splice(0);
    while (this.markerImage.lastChild) {
      this.markerImage.removeChild(this.markerImage.lastChild);
    }

    state.markers.forEach(markerState => {
      const markerType = this._availableMarkerTypes.find(
        mType => mType.typeName === markerState.typeName,
      );
      if (markerType !== undefined) {
        const marker = this.addNewMarker(markerType);
        marker.restoreState(markerState);
        this.markers.push(marker);
      }
    });
    if (
      state.width &&
      state.height &&
      (state.width !== this.imageWidth || state.height !== this.imageHeight)
    ) {
      this.scaleMarkers(
        this.imageWidth / state.width,
        this.imageHeight / state.height,
      );
    }
    this.eventListeners['restorestate'].forEach(listener =>
      listener(new MarkerAreaEvent(this)),
    );
  }

  removeMarker(marker) {
    this.markerImage.removeChild(marker.container);
    if (this.markers.indexOf(marker) > -1) {
      this.markers.splice(this.markers.indexOf(marker), 1);
    }
    marker.dispose();
  }

  /** 将mode赋值为select */
  switchToSelectMode() {
    this.mode = 'select';
    this.hideNotesEditor();
    if (this._currentMarker !== undefined) {
      if (this._currentMarker.state !== 'new') {
        this._currentMarker.select();
      } else {
        this.removeMarker(this._currentMarker);
        this.setCurrentMarker();
        this.markerImage.style.cursor = 'default';
      }
      this.addUndoStep();
    }
  }

  /**
   * Adds "defs" element to the marker SVG element.
   * Useful for using custom fonts and potentially other scenarios.
   *
   * @param {(...(string | Node)[])} nodes
   * @see Documentation article on adding custom fonts for an example
   */
  addDefs(...nodes) {
    this.defs = SvgHelper.createDefs();
    this.addDefsToImage();

    this.defs.append(...nodes);
  }
  addDefsToImage() {
    if (this.defs) {
      this.markerImage.insertBefore(this.defs, this.markerImage.firstChild);
    }
  }

  /** @description 获取相对坐标（缩放|图片宽度） */
  clientToLocalCoordinates(x, y) {
    const clientRect = this.markerImage.getBoundingClientRect();
    const scaleX = clientRect.width / this.imageWidth / this.zoomLevel;
    const scaleY = clientRect.height / this.imageHeight / this.zoomLevel;
    return {
      x: (x - clientRect.left) / this.zoomLevel / scaleX,
      y: (y - clientRect.top) / this.zoomLevel / scaleY,
    };
  }

  /** 通过监听resize事件，来变更UI的位置 */
  onWindowResize() {
    this.positionUI();
  }

  positionUI() {
    this.setTopLeft();
    switch (this.settings.displayMode) {
      case 'inline': {
        const rects = this.target.getClientRects();
        const coverTop =
          rects.length > 0 &&
          rects.item(0) &&
          rects.item(0).y > this.styles.settings.toolbarHeight
            ? this.target.offsetTop - this.styles.settings.toolbarHeight
            : 0;
        this.coverDiv.style.top = `${coverTop}px`;
        this.coverDiv.style.left = `${this.target.offsetLeft.toString()}px`;
        break;
      }
      case 'popup': {
        this.coverDiv.style.top = '0px';
        this.coverDiv.style.left = '0px';
        this.coverDiv.style.width = '100vw';
        this.coverDiv.style.height = `${this.windowHeight}px`;
        // this.contentDiv.style.maxHeight = `${this.windowHeight -
        //   this.settings.popupMargin * 2 -
        //   this.styles.settings.toolbarHeight * 3.5}px`;
        this.contentDiv.style.maxHeight = `100%`;
      }
    }
    this.positionMarkerImage();
  }

  /** 计算【可操作区域】的top和left相对于缩放比例的位置 */
  positionMarkerImage() {
    this.markerImageHolder.style.top = this.top / this.zoomLevel + 'px';
    this.markerImageHolder.style.left = this.left / this.zoomLevel + 'px';
  }

  /** 显示UI */
  showUI() {
    // displayMode = popup 为满屏模式
    if (this.settings.displayMode === 'popup') {
      this.overrideOverflow();
    }

    this.coverDiv = document.createElement('div');
    // prevent UI from blinking when just rendering state
    this.coverDiv.style.visibility = this._silentRenderMode
      ? 'hidden'
      : 'visible';
    this.coverDiv.className = `${this.styles.classNamePrefixBase} ${this.styles.classNamePrefix} coverDiv`;
    // hardcode font size so nothing inside is affected by higher up settings
    this.coverDiv.style.fontSize = '16px';
    this.coverDiv.style.userSelect = 'none';

    switch (this.settings.displayMode) {
      case 'inline': {
        this.coverDiv.style.position = 'absolute';
        const coverTop =
          this.settings.uiOffsetTop !== undefined
            ? this.target.offsetTop + this.settings.uiOffsetTop
            : this.target.offsetTop > this.styles.settings.toolbarHeight
            ? this.target.offsetTop - this.styles.settings.toolbarHeight
            : 0;
        const coverLeft =
          this.target.offsetLeft + (this.settings.uiOffsetLeft ?? 0);
        this.coverDiv.style.top = `${coverTop}px`;
        this.coverDiv.style.left = `${coverLeft}px`;
        this.coverDiv.style.width = `${this.target.offsetWidth.toString()}px`;
        //this.coverDiv.style.height = `${this.target.offsetHeight.toString()}px`;
        this.coverDiv.style.zIndex =
          this.uiStyleSettings.zIndex !== undefined
            ? this.uiStyleSettings.zIndex
            : '5';
        // flex causes the ui to stretch when toolbox has wider nowrap panels
        //this.coverDiv.style.display = 'flex';
        break;
      }
      case 'popup': {
        this.coverDiv.style.position = 'absolute';
        // this.coverDiv.style.position = 'absolute';
        this.coverDiv.style.top = '0px';
        this.coverDiv.style.left = '0px';
        this.coverDiv.style.width = '100vw';
        this.coverDiv.style.height = `${this.targetRoot.clientHeight}px`;
        this.coverDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.75)';
        this.coverDiv.style.zIndex =
          this.uiStyleSettings.zIndex !== undefined
            ? this.uiStyleSettings.zIndex
            : '1000';
        this.coverDiv.style.display = 'flex';
        // this.coverDiv.style.overflow = 'auto';
      }
    }
    // 将主容器添加到body元素里面
    this.targetRoot.appendChild(this.coverDiv);

    this.uiDiv = document.createElement('div');
    this.uiDiv.className = 'uiDiv';
    this.uiDiv.style.display = 'flex';
    this.uiDiv.style.flexDirection = 'column';
    this.uiDiv.style.flexGrow = '2';
    this.uiDiv.style.margin =
      this.settings.displayMode === 'popup'
        ? `${this.settings.popupMargin}px`
        : '0px';
    if (this.settings.displayMode === 'popup') {
      this.uiDiv.style.maxWidth = `calc(100vw - ${this.settings.popupMargin *
        2}px`;
      this.uiDiv.style.height = '100%';
    }
    this.uiDiv.style.border = '0px';
    // this.uiDiv.style.overflow = 'hidden';
    //this.uiDiv.style.backgroundColor = '#ffffff';
    // 将UI容器添加到主容器元素里面
    this.coverDiv.appendChild(this.uiDiv);

    // this.contentDiv 内容容器，装载了操作区域（this.editorCanvas）
    this.contentDiv = document.createElement('div');
    this.contentDiv.className = 'contentDiv';
    this.contentDiv.style.display = 'flex';
    this.contentDiv.style.flexDirection = 'row';
    this.contentDiv.style.flexGrow = '2';
    this.contentDiv.style.flexShrink = '1';
    if (this.settings.displayMode === 'popup') {
      this.contentDiv.style.backgroundColor = this.uiStyleSettings.canvasBackgroundColor;
      // 减去了toolbarHeight * 3.5的高度
      this.contentDiv.style.maxHeight = `100%`;
      // this.contentDiv.style.maxHeight = `calc(100vh - ${
      //   this.settings.popupMargin * 2 + this.uiStyleSettings.toolbarHeight * 3.5}px)`;
      this.contentDiv.style.maxWidth = `calc(100vw - ${this.settings
        .popupMargin * 2}px)`;
    }
    this.contentDiv.style.overflow = 'auto';
    this.uiDiv.appendChild(this.contentDiv);

    this.editorCanvas = document.createElement('div');
    this.editorCanvas.className = 'editorCanvas';
    this.editorCanvas.style.flexGrow = '2';
    this.editorCanvas.style.flexShrink = '1';
    this.editorCanvas.style.position = 'relative';
    this.editorCanvas.style.overflow = 'hidden';
    this.editorCanvas.style.display = 'flex';
    if (this.settings.displayMode === 'popup') {
      this.editorCanvas.style.alignItems = 'center';
      this.editorCanvas.style.justifyContent = 'center';
    }
    this.editorCanvas.style.pointerEvents = 'none';
    this.editorCanvas.style.transformOrigin = 'left top';
    this.editorCanvas.style.transform = `scale(${this.zoomLevel})`;
    this.contentDiv.appendChild(this.editorCanvas);

    // 判断target 是图片还是canvas，并创建【可编辑的target对象】editingTarget
    this.editingTarget =
      this.target instanceof HTMLImageElement
        ? document.createElement('img')
        : document.createElement('canvas');
    if (
      this.settings.displayMode === 'inline' &&
      this.settings.uiOffsetTop === undefined &&
      this.target.offsetTop < this.styles.settings.toolbarHeight
    ) {
      this.editingTarget.style.marginTop = `${this.target.offsetTop -
        this.styles.settings.toolbarHeight}px`;
    }
    // 将图片元素添加到可操作区域元素中
    this.editorCanvas.appendChild(this.editingTarget);

    this.eventListeners['showui'].forEach(listener => listener(this));
  }

  closeUI() {
    if (this.settings.displayMode === 'popup') {
      this.restoreOverflow();
    }
    // @todo better cleanup
    this.targetRoot.removeChild(this.coverDiv);
    this.coverDiv.remove();
    this.coverDiv = null;
  }

  /** 备份滚动和溢出的当前状态 */
  overrideOverflow() {
    this.scrollXState = window.scrollX;
    this.scrollYState = window.scrollY;
    this.bodyOverflowState = document.body.style.overflow;

    window.scroll({ top: 0, left: 0 });
    document.body.style.overflow = 'hidden';
  }

  restoreOverflow() {
    document.body.style.overflow = this.bodyOverflowState;
    window.scroll({ top: this.scrollYState, left: this.scrollXState });
  }

  addNewMarker(markerType, opts = {}) {
    const g = SvgHelper.createGroup();
    this.markerImage.appendChild(g);

    return new markerType(g, this.overlayContainer, this.settings, opts);
  }

  /** 初始化创建 */
  createNewMarker(markerType, opts = {}) {
    let mType;

    if (typeof markerType === 'string') {
      mType = this._availableMarkerTypes.find(mt => mt.typeName === markerType);
    } else {
      mType = markerType;
    }
    if (mType) {
      this.setCurrentMarker();
      this.addUndoStep();
      this._currentMarker = this.addNewMarker(mType, opts);
      this._currentMarker.onMarkerCreated = this.markerCreated;
      this._currentMarker.onColorChanged = this.colorChanged;
      this._currentMarker.onFillColorChanged = this.fillColorChanged;
      this._currentMarker.onStateChanged = this.markerStateChanged;
      this.markerImage.style.cursor = 'crosshair';
      this.toolbar.setActiveMarkerButton(mType.typeName);
      this.toolbox.setPanelButtons(this._currentMarker.toolboxPanels);
      this.eventListeners['markercreating'].forEach(listener =>
        listener(new MarkerEvent(this, this._currentMarker)),
      );
    }
  }

  /**
   * 复制一个新的marker
   * @param value 类
   * @param markerState 克隆过后的markerState
   */
  copyNewMarker(value, markerState) {
    this.createNewMarker(value);
    this._currentMarker.restoreState(markerState);
    this.markerCreated(this._currentMarker);
  }

  markerCreated(marker) {
    this.mode = 'select';
    this.markerImage.style.cursor = 'default';
    this.markers.push(marker);
    this.setCurrentMarker(marker);
    // if (
    //   marker instanceof FreehandMarker &&
    //   this.settings.newFreehandMarkerOnPointerUp
    // ) {
    //   this.createNewMarker(FreehandMarker);
    // } else {
    //   this.toolbar.setSelectMode();
    // }
    // this.toolbar.setSelectMode();
    this.addUndoStep();
    this.eventListeners['markercreate'].forEach(listener =>
      listener(new MarkerEvent(this, marker)),
    );
  }

  colorChanged(color) {
    if (this.settings.defaultColorsFollowCurrentColors) {
      this.settings.defaultColor = color;
      this.settings.defaultStrokeColor = color;
    }
  }
  fillColorChanged(color) {
    if (this.settings.defaultColorsFollowCurrentColors) {
      this.settings.defaultFillColor = color;
    }
  }

  markerStateChanged(marker) {
    this.eventListeners['markerchange'].forEach(listener =>
      listener(new MarkerEvent(this, marker)),
    );
  }

  /**
   * Sets the currently selected marker or deselects it if no parameter passed.
   *
   * @param marker marker to select. Deselects current marker if undefined.
   */
  setCurrentMarker(marker = undefined) {
    if (this._currentMarker !== marker) {
      // no need to deselect if not changed
      if (this._currentMarker !== undefined) {
        this._currentMarker.deselect();
        // TODO 需要处理外部toolbar的setCurrentMarker
        this.toolbar.setCurrentMarker();
        this.toolbox.setPanelButtons([]);

        if (!this._isResizing) {
          this.eventListeners['markerdeselect'].forEach(listener =>
            listener(new MarkerEvent(this, this._currentMarker)),
          );
        }
      }
    }
    this._currentMarker = marker;
    if (this._currentMarker !== undefined && !this._currentMarker.isSelected) {
      if (this._currentMarker.state !== 'new') {
        this._currentMarker.select();
      }
      this.toolbar.setCurrentMarker(this._currentMarker);
      this.toolbox.setPanelButtons(this._currentMarker.toolboxPanels);

      if (!this._isResizing) {
        this.eventListeners['markerselect'].forEach(listener =>
          listener(new MarkerEvent(this, this._currentMarker)),
        );
      }
    }
  }

  _silentRenderMode = false;
  /**
   * Renders previously saved state without user intervention.
   *
   * The rendered image is returned to the `render` event handlers (as in the regular interactive process).
   * Rendering options set on `MarkerArea` are respected.
   *
   * @param state state to render
   *
   */
  renderState(state) {
    this._silentRenderMode = true;
    this.settings.displayMode = 'inline';
    if (!this.isOpen) {
      this.show();
    }
    this.restoreState(state);
    this.startRenderAndClose();
    this._silentRenderMode = false;
  }

  _isFocused = false;
  /**
   * Returns true when this MarkerArea is focused.
   *
   */
  get isFocused() {
    return this._isFocused;
  }

  _previousCurrentMarker = undefined;

  /**
   * Focuses the MarkerArea to receive all input from the window.
   *
   * Is called automatically when user clicks inside of the marker area. Call manually to set focus explicitly.
   *
   */
  focus() {
    if (!this._isFocused) {
      this.attachWindowEvents();
      this._isFocused = true;
      if (this._previousCurrentMarker !== undefined) {
        this.setCurrentMarker(this._previousCurrentMarker);
      }
      this.eventListeners['focus'].forEach(listener =>
        listener(new MarkerAreaEvent(this)),
      );
    }
  }

  /**
   * Tells MarkerArea to stop reacting to input outside of the immediate marker image.
   *
   * Call `focus()` to re-enable.
   *
   */
  blur() {
    if (this._isFocused) {
      this.detachWindowEvents();
      this._isFocused = false;
      this._previousCurrentMarker = this._currentMarker;
      this.setCurrentMarker();
      this.eventListeners['blur'].forEach(listener =>
        listener(new MarkerAreaEvent(this)),
      );
    }
  }
}
