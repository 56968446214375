/** @description 这里放socket事件类型 */

/** @name 租户中心 */
const tenantCenterTopic = 'triascloud-tenant-center';
export const TenantCenterSocket = {
  /** @name 成员信息更新 */
  MemberUpdate: `${tenantCenterTopic}:member_update`,
  /** @name 账号停用 */
  MemberDisable: `${tenantCenterTopic}:member_disable`,
  /** @name 离职 */
  MemberResign: `${tenantCenterTopic}:member_resign`,
  /** @name 租户解散 */
  TenantDisband: `${tenantCenterTopic}:tenant_disband`,
  /** @name 租户信息更新 */
  TenantUpdate: `${tenantCenterTopic}:tenant_update`,
  /** @name 密码变更 */
  PasswordUpdate: `${tenantCenterTopic}:password_update`,
};

/** @name 大屏  */
const screenTopic = 'triascloud-iot-screen';
export const ScreenSocket = {
  /** @name 推送地图项目数据  */
  Screen: `${screenTopic}:SCREEN`,
  /** @name 鼠标移动到地图点时候事件  */
  MoveMapProject: `${screenTopic}:MOVE_MAP_PROJECT`,
  /** @name 推送地图项目数据  */
  MoveMapAddress: `${screenTopic}:MOVE_MAP_ADDRESS`,
};

/** @name 绑定摄像头 */
const CameraTopic = 'triascloud-iot-monitor';
export const CameraSocket = {
  /** @name 批量绑定 */
  BatchBind: `${CameraTopic}:batchCameraBindBuild`,
  /** @name 批量绑定成功 */
  BatchBindSuccess: `${CameraTopic}:BATCH_BIND_CAMERA_END`,
  /** @name 批量解绑 */
  BatchUnbind: `${CameraTopic}:batchCameraUnBindBuild`,
  /** @name 批量解绑成功 */
  BatchUnbindSuccess: `${CameraTopic}:BATCH_UN_BIND_CAMERA_EXECUTING`,
  /** @name 监控截图更新 */
  UpdateScreensHot: `${CameraTopic}:UPDATE_MONITOR_SCREENSHOT`,
};

/** @name 应用消息条数 */
const flowMessageTopic = 'triascloud-workflow';
export const FlowSocket = {
  /** @name 应用消息条数 */
  TodoTotal: `${flowMessageTopic}:HOME_TODO_COUNT`,
  CompleteTotal: `${flowMessageTopic}:HOME_COMPLETE_COUNT`,
  CcTotal: `${flowMessageTopic}:HOME_CC_COUNT`,
};

/** @name 物联平台 */
const iotTopic = 'triascloud-iot-platform';
export const IotSocket = {
  /** @name WEBSOKCT调试 */
  AmqpDebug: `${iotTopic}:AMQP_DEBUG`,
  /** @name WEBSOKCT调试异常 */
  AmqpEventError: `${iotTopic}:AMQP_EVENT_ERROR`,
  /** @name WEBSOKCT事件回调 */
  AmqpEvent: `${iotTopic}:AMQP_EVENT`,
  /** @name WEBSOKCT事件关闭 */
  AmqpDisconnect: `${iotTopic}:AMQP_DISCONNECT`,
};

/** @name 安全帽 */
const hatMessageTopic = 'triascloud-iot-smart-helmet';
export const HatSocket = {
  /** @name SOS警报 */
  SOS: `${hatMessageTopic}:DEVICE_SOS`,
  /** @name 脱帽/戴帽 */
  WearingStatus: `${hatMessageTopic}:WEARING_STATUS`,
  /** @name 加速计状态 */
  AccelerometerStatus: `${hatMessageTopic}:ACCELEROMETER_STATUS`,
  /** @name 在线/离线 */
  OnlineStatus: `${hatMessageTopic}:ONLINE_STATUS`,
  /** @name 地图定位 */
  Position: `${hatMessageTopic}:POSITION`,
  /** @name 音视频通话 */
  DeviceCall: `${hatMessageTopic}:DEVICE_CALL`,
};
