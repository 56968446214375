export class MarkerAreaEvent {
  markerArea;
  cancelable = false;

  _defaultPrevented = false;
  get defaultPrevented() {
    return this._defaultPrevented;
  }

  preventDefault() {
    this._defaultPrevented = true;
  }

  constructor(markerArea, cancelable = false) {
    this.markerArea = markerArea;
    this.cancelable = cancelable;
  }
}

export class MarkerAreaRenderEvent extends MarkerAreaEvent {
  dataUrl;
  state;

  constructor(markerArea, dataUrl, state) {
    super(markerArea, false);
    this.dataUrl = dataUrl;
    this.state = state;
  }
}

export class MarkerEvent extends MarkerAreaEvent {
  marker;

  constructor(markerArea, marker = undefined, cancelable = false) {
    super(markerArea, cancelable);
    this.marker = marker;
  }
}

/**
 * Event handler repository.
 */
export class EventListenerRepository {
  /**
   * Event handlers for the `render` event.
   */
  render = [];
  /**
   * Event handlers for the `beforeclose` event.
   */
  beforeclose = [];
  /**
   * Event handlers for the `close` event.
   */
  close = [];
  /**
   * Event handlers for the `show` event.
   */
  show = [];
  /**
   * Event handlers for the `showui` event.
   */
  showui = [];
  /**
   * Event handlers for the `restorestate` event.
   */
  restorestate = [];
  /**
   * Event handlers for the `statechange` event.
   *
   */
  statechange = [];
  /**
   * Event handlers for the `markerselect` event.
   */
  markerselect = [];
  /**
   * Event handlers for the `markerdeselect` event.
   */
  markerdeselect = [];
  /**
   * Event handlers for the `markercreating` event.
   */
  markercreating = [];
  /**
   * Event handlers for the `markercreate` event.
   */
  markercreate = [];
  /**
   * Event handlers for the `markerbeforedelete` event.
   */
  markerbeforedelete = [];
  /**
   * Event handlers for the `markerdelete` event.
   */
  markerdelete = [];
  markerchange = [];
  focus = [];
  blur = [];

  /**
   * Add an event handler for a specific event type.
   * @param eventType - event type.
   * @param handler - function to handle the event.
   */
  addEventListener(eventType, handler) {
    this[eventType].push(handler);
  }

  /**
   * Remove an event handler for a specific event type.
   * @param eventType - event type.
   * @param handler - function currently handling the event.
   */
  removeEventListener(eventType, handler) {
    const index = this[eventType].indexOf(handler);
    if (index > -1) {
      this[eventType].splice(index, 1);
    }
  }
}
