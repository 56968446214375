import { getListWithTenantAction } from '@/services/things/screen-manage';

/** @type {import('vuex').Module} */
const ShareScreen = {
  namespaced: true,
  state: {
    shareList: [],
    pagination: {
      pageSize: 10,
      current: 1,
      total: 0,
    },
    params: {
      isEffective: '',
      screenManageType: '',
    },
  },
  mutations: {
    SET_SHARE_LIST(state, payload) {
      state.shareList = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_PARAMS(state, payload) {
      state.params = payload;
    },
  },
  actions: {
    async getShareList({ commit, state }) {
      const result = await getListWithTenantAction({
        pageSize: state.pagination.pageSize,
        pageNum: state.pagination.current,
        isEffective: state.params.isEffective,
        screenManageType: state.params.screenManageType,
      });
      commit('SET_PAGINATION', {
        ...state.pagination,
        total: result.total,
        pageSize: result.pageSize,
      });
      commit('SET_SHARE_LIST', result.data);
    },
  },
};

export default ShareScreen;
