<template>
  <div :class="$style.divCard">
    <div :class="$style.title">
      <span>{{ $t('generalView.count.sbp') }}</span>
    </div>
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <no-data-chart
        font="250px"
        isIcon
        :style="{ height: '100%', width: '100%' }"
      />
    </div>
    <div v-else ref="boxCharts" style="height: 400px; width: 780px" />
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { controlBox } from '@/services/iot-electric-box/device-status';
import { crossStorageModule } from '@/enum/store.js';

@Component({})
export default class BoxProportion extends Vue {
  mounted() {
    this.getList();
  }
  isNoData = true;
  totalNumber = 0;
  seriesData = [];
  async getList() {
    try {
      const { count, countSubControlBox } = await controlBox();
      this.totalNumber = count;
      if (count > 0) {
        this.isNoData = false;
        this.seriesData = [
          { value: countSubControlBox.TEN, name: '0-10台' },
          { value: countSubControlBox.TWENTY, name: '11-20台' },
          { value: countSubControlBox.THIRTY, name: '21-30台' },
          { value: countSubControlBox.FORTY, name: '31-40台' },
          { value: countSubControlBox.FIFTY, name: '41-50台' },
          { value: countSubControlBox.SIXTY, name: '51-60台' },
          { value: countSubControlBox.OTHER, name: '60台以上' },
        ];
        this.$nextTick(() => {
          this.initChart();
        });
      }
    } catch {
      return false;
    }
  }
  colorArr = ['#947dfa', '#00b893', '#fe5959', '#ffa348', '#516cf0', '#ffbf07'];
  legendData = [
    '0-10台',
    '11-20台',
    '21-30台',
    '31-40台',
    '41-50台',
    '51-60台',
    '60台以上',
  ];
  @crossStorageModule.State('skin') skin;
  textColor = '';
  @Watch('skin', { immediate: true, deep: true })
  changeSkin(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.textColor = newVal.mode === 'dark' ? '#999999' : '#333333';
      this.initChart();
    }
  }
  initChart() {
    if (this.seriesData.length > 0) {
      const chart = this.$refs.boxCharts;
      const myChart = echarts.init(chart);
      const num = this.totalNumber;
      const textColor = this.textColor;
      const option = {
        title: {
          text: '{a|' + num + '}{b|台}',
          x: '25%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            textAlign: 'center',
            rich: {
              a: {
                fontSize: 28,
                fontWeight: '700',
              },
              b: {
                fontSize: 18,
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}套({d}%)',
        },
        color: this.colorArr,
        legend: {
          data: this.legendData,
          orient: 'vertical',
          top: '25%',
          right: 80,
          textStyle: {
            color: textColor,
          },
          formatter: name => {
            if (this.seriesData.length) {
              const item = this.seriesData.filter(
                item => item.name === name,
              )[0];
              return `${name}：${item.value}套`;
            }
          },
        },
        series: [
          {
            name: '分控箱占比',
            type: 'pie',
            center: ['25%', '45%'],
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            data: this.seriesData,
          },
        ],
      };
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" module>
.divCard {
  height: 360px;
  box-shadow: 0 0 8px 0 #eeeeee;
  border-radius: 5px;
  position: relative;
  .title {
    font-size: 14px;
    margin: 10px;
    position: absolute;
    font-weight: 500;
  }
}
</style>
