<template>
  <div :class="$style.statement">
    <a-spin :spinning="loading">
      <div :class="$style.container">
        <div
          :class="$style.chartWrap"
          v-for="item in chartList"
          :key="item.name"
        >
          <span :class="$style.title">{{ item.title }}</span>
          <RingChart
            v-if="item.type === 'ring'"
            :id="item.name"
            :chartData="item.chartData"
            height="100%"
            width="90%"
          />
          <LineChart
            v-else
            :id="item.name"
            :chartData="item.chartData"
            :areaOpacity="0.3"
            height="98%"
            width="100%"
            :grid="{ top: '20%', left: '6%', right: '12%', bottom: '10%' }"
          />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import RingChart from '@/views/hat/device/charts/ring-chart.vue';
import LineChart from '@/views/hat/device/charts/line-chart.vue';
import { findTree } from '@triascloud/utils';
import { getEventStatistics } from '@/services/smart-hat/device-management';
import { crossStorageModule } from '@/enum/store.js';

@Component({
  components: {
    RingChart,
    LineChart,
  },
})
export default class Statement extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @crossStorageModule.State locale;

  mounted() {
    this.fetchData();
  }
  chartList = [
    {
      type: 'ring',
      subType: 'ALARM',
      name: 'alarmDistribution',
      title: this.$t('hat.managementModal.event.alarmDistribution'),
      chartData: [],
    },
    {
      type: 'ring',
      subType: 'REMIND',
      name: 'remindDistribution',
      title: this.$t('hat.managementModal.event.reminderDistribution'),
      chartData: [],
    },
    {
      type: 'ring',
      subType: 'NOTICE',
      name: 'noticeDistribution',
      title: this.$t('hat.managementModal.event.notificationDistribution'),
      chartData: [],
    },
    {
      type: 'line',
      subType: 'ALARM',
      name: 'alarmTrend',
      title: this.$t('hat.managementModal.event.alarmTrend'),
      chartData: {},
    },
    {
      type: 'line',
      subType: 'REMIND',
      name: 'remindTrend',
      title: this.$t('hat.managementModal.event.remindTrends'),
      chartData: {},
    },
    {
      type: 'line',
      subType: 'NOTICE',
      name: 'noticeTrend',
      title: this.$t('hat.managementModal.event.notificationTrend'),
      chartData: {},
    },
  ];
  loading = true;
  async fetchData(params = {}) {
    this.loading = true;
    let data = {
      deviceId: this.deviceDetail.deviceId,
    };
    if (params.user) {
      data.userId = params.user;
    }
    if (params.event) {
      data.subType = params.event;
    }
    if (params.timeRange && params.timeRange.length > 0) {
      data.startTime = this.$moment(params.timeRange[0]).valueOf();
      data.endTime = this.$moment(params.timeRange[1]).valueOf();
    }
    try {
      const chartData = await getEventStatistics(data);
      this.chartList.forEach(item => {
        item.chartData =
          item.type === 'line'
            ? this.getLineChartData(chartData, item.subType)
            : this.getRingChartData(chartData, item.subType);
      });
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  getLineChartData(data, type) {
    const lineObj = findTree(data, item => item.type === type);
    if (!lineObj || !lineObj.line?.length) {
      return {};
    }
    const times = lineObj.line.map(item => {
      return lineObj.dimension === 'DAY'
        ? this.$moment(item.time).format('MM-DD')
        : this.$moment(item.time).format('YYYY-MM');
    });
    const dataList = [];
    lineObj.line[0].subMessages.forEach((item, index) => {
      dataList[index] = { name: item.subType, values: [] };
    });
    lineObj.line.forEach(element => {
      element.subMessages.forEach((item, index) => {
        dataList[index].values.push(item.subTotal);
      });
    });
    return {
      times,
      dataList,
    };
  }
  getRingChartData(data, type) {
    const ringObj = findTree(data, item => item.type === type);
    if (!ringObj || !ringObj.annular?.length) {
      return [];
    }
    const chartData = ringObj.annular.map(item => ({
      name: item.subType,
      value: item.subTotal,
    }));
    return chartData;
  }
}
</script>

<style lang="less" module>
.statement {
  .container {
    height: 405px;
    overflow: auto;
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 20px;
    padding: 5px 10px 5px 4px;
    .chartWrap {
      height: 225px;
      display: flex;
      justify-content: center;
      position: relative;
      box-shadow: 0px 0px 8px 0px #eeeeee;
      border-radius: 1px;
      .title {
        font-size: 14px;
        color: var(--font);
        position: absolute;
        left: 10px;
        top: 4px;
      }
    }
  }
  .btn {
    margin-right: 10px;
    min-width: 50px;
    height: 31px;
  }
}
</style>
