<template>
  <a-table
    :columns="columns"
    :rowKey="row => row.name"
    :data-source="list"
    :pagination="pagination"
    @change="handlePagination"
  >
  </a-table>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getProjectEventDetails,
  getBuildingEventDetails,
} from '@/services/iot-electric-box/message';
import moment from 'moment';

@Component({})
export default class OperationDetail extends Vue {
  @Prop({ type: String, default: 'project' }) type;
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Object, default: () => {} }) params;

  mounted() {
    this.fetchData();
  }
  get fnEvent() {
    return this.type === 'project'
      ? getProjectEventDetails
      : getBuildingEventDetails;
  }
  async fetchData() {
    const { records, current, size, total } = await this.fnEvent({
      idxBuildingId: this.data.buildId,
      idxProjectId: this.data.projectId,
      pageNum: this.pagination.current,
      pageSize: this.pagination.pageSize,
      ...this.params,
    });
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
    this.list = records;
  }
  columns = [
    {
      title: this.$t('generalView.operationType'),
      dataIndex: 'type',
      width: 120,
    },
    {
      title: this.$t('generalView.table.time'),
      dataIndex: 'time',
      width: 160,
      customRender: txt => moment(txt)?.format('YYYY-MM-DD HH:mm'),
    },
    {
      title: this.$t('generalView.table.ctx'),
      ellipsis: true,
      dataIndex: 'content',
    },
  ];
  list = [];

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };
  handlePagination(data) {
    this.pagination = data;
    this.fetchData();
  }
}
</script>
<style lang="less" module></style>
