const state = {
  develop: false, // 开发右侧弹窗
};

const getters = {};

const mutations = {
  SET_DEVELOP(state, payload) {
    state.develop = payload;
  },
};

const actions = {
  saveProduct({ commit }, payload) {
    commit('SET_DEVELOP', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
