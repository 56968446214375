<template>
  <div :class="$style.boxWrap">
    <no-data v-if="noData" isIcon font="300px" />
    <div
      v-else
      :class="$style.box"
      :id="id"
      :style="{ width: width, height: height }"
    ></div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import NoData from '@/components/no-data-chart';

function numberFormat(number) {
  if (number === 0) {
    return 0;
  } else {
    return `${number}%`;
  }
}

const options = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: params => {
      let result = `<div class="event-chart-box">`;

      params.forEach(item => {
        result += `
          <div class="warn-label">
            <span class="color-label" style="background-color: ${
              item.color
            }"></span>${item.seriesName}：${numberFormat(item.data.value)}
          </div>
        `;
      });
      result += `
        <div>
            累计事件： ${params[0].data.count}
          </div>
          <div class="other-label">
            公司：${params[0].data.tenantName}
          </div>
          <div class="other-label">
            项目：${params[0].data.projectName}
          </div>
          ${
            params[0].data?.buildingName
              ? `<div class="other-label">
            楼栋：${params[0].data.buildingName}
          </div>`
              : ''
          } 
        </div>
      `;
      return result;
    },
  },
  legend: {
    data: [],
    bottom: 0,
    textStyle: {
      color: '#9A9A9A',
      fontSize: '14',
    },
  },
  grid: {
    left: '0',
    right: '0',
    top: 77,
    bottom: '10%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: [],
    splitLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      color: '#9A9A9A',
    },
    axisLine: {
      lineStyle: {
        color: 'rgba(238, 238, 238, 1)',
      },
    },
  },
  yAxis: {
    type: 'value',
    max: 100,
    min: 0,
    axisLine: {
      show: false,
    },
    axisLabel: {
      color: '#9A9A9A',
      formatter: '{value} %',
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(238, 238, 238, 1)',
      },
    },
  },
  series: [],
  color: [
    'rgba(6, 173, 135, 1)',
    'rgba(58, 85, 234, 1)',
    'rgba(249, 187, 18, 1)',
    'rgba(254, 89, 89, 1)',
    'rgba(32, 100, 80, 1)',
    'rgba(255, 49, 255, 1)',
    'rgba(54, 155, 189, 1)',
    'rgba(254, 55, 189, 1)',
    'rgba(100, 105, 189, 1)',
    'rgba(154, 155, 189, 1)',
    'rgba(54, 155, 19, 1)',
    'rgba(25, 45, 89, 1)',
    'rgba(125, 25, 189, 1)',
  ],
};

@Component({
  components: {
    NoData,
  },
})
export default class EventChart extends Vue {
  @Prop({ type: String, default: '500px' }) height;
  @Prop({ type: String, default: 'id' }) id;
  @Prop({ type: String, default: '100%' }) width;
  @Prop({ type: Object, default: () => {} }) data;

  mounted() {
    this.initChart();
  }

  get noData() {
    return this.data.xAxis.length === 0;
  }

  @Watch('data')
  onDataChange(value) {
    const object = { ...options };
    object.legend.data = value.legend;
    object.xAxis.data = value.xAxis;
    object.series = value.series;
    this.chart.setOption(object);
  }

  chart = null;
  time = null;
  initChart() {
    if (this.noData) return false;
    if (!this.chart) {
      this.chart = echarts.init(document.getElementById(this.id));
    }
    if (this.data?.xAxis.length) {
      this.chart.setOption(this.fetchData(this.data));
    } else {
      this.chart.setOption(options);
    }
  }

  fetchData(value) {
    const object = { ...options };
    object.legend.data = value.legend;
    object.xAxis.data = value.xAxis;
    object.series = value.series;
    return object;
  }
}
</script>
<style lang="less" module>
.box {
  width: 500px;
  height: 500px;
}
.boxWrap {
  height: 500px;
  :global {
    .event-chart-box {
      width: 300px;
      .color-label {
        display: inline-block;
        margin-right: 6px;
        border-radius: 10px;
        width: 10px;
        height: 10px;
      }
      .other-label {
        white-space: normal;
      }
    }
  }
}
</style>
