<template>
  <a-table
    :columns="columns"
    :rowKey="row => row.climbId + row.sortNumber"
    :data-source="list"
    :locale="emptyLocale"
    :pagination="pagination"
    @change="handlePagination"
  >
    <template slot="action" slot-scope="record">
      <span
        @click.stop="promoteReport(record)"
        style="cursor: pointer;color: #faad14"
      >
        查看
      </span>
    </template>
  </a-table>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import Empty from '@/components/empty-content';
import moment from 'moment';
import { getBuildingRiseRccord } from '@/services/iot-electric-box/client';
import { createModal } from '@triascloud/x-components';
import ReportList from './report-list';

@Component({
  components: {
    Empty,
  },
})
export default class PromoteDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) layerDetailData;

  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  columns = [
    {
      title: this.$t('generalView.table.count'),
      dataIndex: 'sortNumber',
    },
    {
      title: this.$t('generalView.table.liftingTime'),
      dataIndex: 'promoteDate',
      width: 160,
      customRender: txt => moment(txt)?.format('YYYY-MM-DD HH:mm'),
    },
    {
      title: this.$t('generalView.table.layer'),
      dataIndex: 'currentLayer',
    },
    {
      title: this.$t('generalView.table.upgradeReport'),
      key: 'operation',
      scopedSlots: { customRender: 'action' },
    },
  ];
  list = [];

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };
  mounted() {
    this.getList();
  }
  async getList() {
    const params = {
      buildingId: this.layerDetailData.buildId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    try {
      const { records, current, total } = await getBuildingRiseRccord(params);
      this.list = records;
      this.pagination.current = current;
      this.pagination.total = total;
    } catch {
      return false;
    }
  }
  formatTimeToMoment(t) {
    const time = Number(t);
    if (t === '' || t === undefined || t === null) return;
    const tStr = this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
    return this.$moment(tStr, 'YYYY-MM-DD HH:mm:ss');
  }
  promoteReport(row) {
    const startTime = this.formatTimeToMoment(row.promoteDate)
      .startOf('day')
      .valueOf();
    const endTime = this.formatTimeToMoment(row.promoteDate)
      .endOf('day')
      .valueOf();
    const reportInfo = {
      buildingId: this.layerDetailData.buildId,
      startTime,
      endTime,
      scoreSort: 1,
    };
    createModal(() => <ReportList reportInfo={reportInfo} />, {
      title: this.$t('generalView.reports'),
      width: 700,
    });
  }
  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }
}
</script>

<style scoped></style>
