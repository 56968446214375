<template>
  <div :class="$style.wrap">
    <div :class="$style.cardBox">
      <div :class="$style.cardItem">
        <promote :currentRange="currentRange" :customTime="customTime" />
      </div>
      <div :class="$style.cardItem">
        <operation :currentRange="currentRange" :customTime="customTime" />
      </div>
    </div>
    <div :class="[$style.cardBox, $style.other]">
      <div :class="$style.cardItem">
        <project-event :currentRange="currentRange" :customTime="customTime" />
      </div>
      <div :class="$style.cardItem">
        <building-event :currentRange="currentRange" :customTime="customTime" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Promote from './promote.vue';
import Operation from './operation.vue';
import ProjectEvent from './project-event.vue';
import BuildingEvent from './building-event.vue';

@Component({
  components: {
    Promote,
    Operation,
    ProjectEvent,
    BuildingEvent,
  },
})
export default class SafeOperation extends Vue {
  @Prop({ type: String, default: '' }) currentRange;
  @Prop({ type: Array, default: () => [] }) customTime;
}
</script>

<style lang="less" module>
.wrap {
  padding: 5px;
}
.cardBox {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  .cardItem {
    flex: 1;
    width: 49%;
    // height: 620px;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px 0px #eeeeee;
    border-radius: 8px;
    padding: 0 20px;
  }
  .cardItem + .cardItem {
    margin-left: 20px;
  }
}
.other {
  margin-top: 20px;
}
</style>
