import request from '../request';

const serviceName = '/iot-electric-box/api';

/**
 * @name 电箱管理设备报表操作类型
 */
export function getOperationModeList(data) {
  return request(`${serviceName}/operationMode/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 获取楼栋操作详情
 */
export function getOperationModeForDetail(data) {
  return request(`${serviceName}/operationMode/standDetail`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 获取可选的操作类型
 */
export function getOperationModeForType(data) {
  return request(`${serviceName}/operationMode/type`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
