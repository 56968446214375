<template>
  <section
    :class="$style.content"
    :style="{
      width: $store.state.home.bigScreen ? '100vw' : '100%',
      height: $store.state.home.bigScreen ? '100vh' : '100%',
      position: $store.state.home.bigScreen ? 'fixed' : '',

      top: '-0',
      left: 0,
      zIndex: 9999,
    }"
  >
    <div :class="$style.screen" style="z-index: 9999999;">
      <x-icon
        :type="
          $store.state.home.bigScreen
            ? 'tc-icon-fullscreen-exit'
            : 'tc-icon-fullscreen'
        "
        :class="$style.icon"
        @click="handleScreen"
      />
    </div>
    <div
      :class="$style.wrap"
      id="container"
      :style="{
        width: $store.state.home.bigScreen ? '100vw' : '100%',
        height: $store.state.home.bigScreen ? '100vh' : '100%',
      }"
    ></div>
    <section :class="$style.selectWrap">
      <a-select @change="handleChange" v-model="geofence">
        <a-select-option
          v-for="item in list"
          :key="item.geofenceId"
          :value="item.geofenceId"
          >{{ item.geofenceName }}</a-select-option
        >
      </a-select>
    </section>
    <a-button
      :class="$style.bottomCenter"
      type="primary"
      @click="handleClose"
      >{{ $t('hat.managementModal.personInfo.closeMap') }}</a-button
    >
    <a-button
      type="link"
      :class="$style.refresh"
      @click="refreshLocation('manual')"
      >{{ $t('hat.managementModal.personInfo.refreshPositioning') }}</a-button
    >
  </section>
</template>
<script>
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import {
  drawCharts,
  formatToApi,
  funAMap,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';
import { getFenceListById, getZonesData } from '@/services/smart-hat/geofence';
import { updateDevideInfo } from '@/services/smart-hat/device';
import { getDeviceDetail } from '@/services/smart-hat/device';
import { Debounce } from 'lodash-decorators';
@Component()
export default class MiniMap extends Vue {
  @Prop({ type: Array }) center;
  @Prop({ type: Object, default: () => {} }) record;

  mounted() {
    this.initMap();
    this.getList();
  }
  map = null;
  AMap = null;
  created() {
    this.$store.commit('home/setBigScreen', false);
    this.refreshLocation = this.refreshLocation.bind(this);
  }
  handleScreen() {
    const bigScreen = this.$store.state.home.bigScreen;
    this.$store.commit('home/setBigScreen', !bigScreen);
  }
  async initMap() {
    const AMap = await funAMap();
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    this.map = new AMap.Map('container', {
      zoom: 16,
      center: this.center || undefined,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    const centerMarker = new this.AMap.Marker({
      position: this.center,
    });
    this.centerMarker = centerMarker;
    this.addPositionRange();
    centerMarker.setMap(this.map);
  }
  /** 给定位加上区域范围 */
  addPositionRange() {
    const POSITION_KEY = {
      BASE_STATION: 50,
      WIFI: 50,
      SATELLITE: 10,
    };
    const newChart = new this.AMap.Circle({
      center: this.center,
      radius: POSITION_KEY[this.record.positionType], // 半径
      borderWeight: 3,
      strokeColor: '#1791fc',
      strokeWeight: 1,
      strokeOpacity: 0.5,
      fillOpacity: 0.4,
      strokeStyle: 'solid',
      fillColor: '#1791fc',
      zIndex: 50,
    });
    this.map.add(newChart);
  }
  @InjectReactive('deviceDetail') detail;
  @Watch('detail', { immediate: true, deep: true })
  detailChange() {
    if (this.map) {
      this.map.setCenter([this.detail.longitude, this.detail.latitude]);
      this.centerMarker.setPosition([
        this.detail.longitude,
        this.detail.latitude,
      ]);
    }
  }

  list = [];
  percent = 0;
  geofence = '';

  async getList() {
    const list = [];
    let array = await getFenceListById({
      deviceGroupIds: this.detail.deviceId,
    });
    let index = -1;
    array.forEach((item, idx) => {
      if (item.defaultStatus === 1) {
        index = idx;
      }
    });
    if (index !== -1) {
      let results = array.splice(index, 1);
      results.forEach(v => {
        v.geofenceName = `${v.geofenceName}（默认）`;
      });
      list.push(...results, ...array);
    } else {
      list.push(...array);
    }
    list.unshift({
      geofenceId: '-1',
      geofenceName: '无',
    });
    this.list = list;
    this.geofence = this.detail.geofenceId;
    this.handleChange(this.detail.geofenceId);
  }

  zoneArray = [];
  chartList = [];
  async handleChange(val) {
    if (val === '-1') {
      if (this.center.length) {
        this.map.setCenter(this.center);
      }
      this.map.remove(this.chartList);
      return;
    }
    if (this.chartList.length) {
      this.map.remove(this.chartList);
    }
    const item = this.list.find(v => v.geofenceId === val);
    if (item.point && item.point.lng) {
      this.map.setCenter([item.point.lng, item.point.lat]);
    }
    const array = await getZonesData({
      geofenceId: val,
    });
    this.zoneArray = formatToApi(array);
    this.chartList = drawCharts(this.zoneArray, this.AMap, (...args) =>
      mouseEvent.call(null, ...args, this.map),
    );
    this.map.add(this.chartList);
  }

  @Inject('CHANGE_MINI_MAP') showFn;
  handleClose() {
    this.showFn(false);
  }
  @Debounce(500)
  async refreshLocation(operationType) {
    const deviceId = this.record.deviceId;

    const getLocation = async () => {
      const detail = await getDeviceDetail(deviceId);
      const center = [
        parseFloat(detail.longitude),
        parseFloat(detail.latitude),
      ];

      this.map.remove(this.centerMarker);
      const centerMarker = new this.AMap.Marker({
        position: center,
      });

      centerMarker.setMap(this.map);
      this.centerMarker = centerMarker;
    };
    try {
      await updateDevideInfo({ deviceId, type: 1 });
      if (operationType === 'manual') {
        this.$message.success(
          this.$t('hat.managementModal.personInfo.toastOne'),
        );
      }
      var timer = setInterval(() => {
        this.percent = this.percent + 1;
        if (this.percent > 100) {
          clearInterval(timer);
          getLocation();
          this.percent = 0;
        }
      }, 100);
    } catch (error) {
      error && error;
    }
  }
}
</script>
<style lang="less" module>
.content {
  position: relative;
  .screen {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 99;
  }

  .icon {
    font-size: 30px;
    color: var(--primary);
  }

  .selectWrap {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: #fff;
    border-radius: 6px;
  }

  .bottomCenter {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
  }

  .refresh {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.wrap {
  height: 600px;
  width: 100%;
}
</style>
