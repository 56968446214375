<script>
import { Prop, Component, Vue } from 'vue-property-decorator';
import { Icon as XIcon, OssImage as XOssImage } from '@triascloud/x-components';
import { isImage, getExt } from './index.vue';
import MarkerDisplay from '../marker-display.vue';

@Component
export default class PreviewImage extends Vue {
  @Prop({ type: String, default: '' }) name;
  @Prop({ type: String, required: true }) url;
  @Prop({ type: Boolean }) watermark;
  @Prop({ required: true }) service;
  downloadUrl = '';
  rotateDeg = 0;
  scalePer = 1;
  get styleObject() {
    return {
      transform: `translate(${this.offset.x}px, ${this.offset.y}px) rotate(${this.rotateDeg}deg) scale(${this.scalePer})`,
      cursor: this.dragging ? 'move' : 'default',
      zIndex: this.dragging ? 2 : 1,
      opacity: this.imageLoaded ? 0 : 1,
    };
  }
  get percentage() {
    return parseInt((this.scalePer * 100).toString());
  }
  get downloadName() {
    let name = this.name;
    if (name && !isImage(name)) {
      name += `.${getExt(this.url)}`;
    }
    return (
      name ||
      this.url
        .split('?')[0]
        .split('/')
        .pop() ||
      this.url
    );
  }
  handleZoomChange(delta = 0) {
    this.scalePer += delta;
    this.scalePer = this.scalePer < 0.1 ? 0.1 : this.scalePer;
  }
  rotate() {
    // 避免无限增长
    this.rotateDeg = (this.rotateDeg + 90) % 360;
  }
  reset() {
    this.scalePer = 1;
    this.rotateDeg = 0;
  }

  /**
   * @param {WheelEvent} event
   */
  handleWheel(event) {
    if (event.deltaY > 0) {
      this.handleZoomChange(-0.1);
    } else {
      this.handleZoomChange(0.1);
    }
  }
  dragging = false;
  offset = {
    x: 0,
    y: 0,
  };
  startMoveOffset = {
    x: 0,
    y: 0,
  };
  /**
   * @param {MouseEvent} event
   */
  handleMousemove(event) {
    event.preventDefault();
    if (!this.dragging) return;
    // 获取x坐标和y坐标
    const x = event.clientX;
    const y = event.clientY;
    this.offset.x = x - this.startMoveOffset.x;
    this.offset.y = y - this.startMoveOffset.y;
  }
  /**
   * @param {MouseEvent} event
   */
  handleMousedown(event) {
    // 忽略右键
    if (event.buttons === 2) return;
    // 获取x坐标和y坐标
    this.startMoveOffset.x = event.clientX - this.offset.x;
    this.startMoveOffset.y = event.clientY - this.offset.y;
    // 开关打开
    this.dragging = true;
  }
  handleMouseup() {
    this.dragging = false;
  }

  imageLoaded = false;
  handleLoad() {
    this.imageLoaded = true;
  }
  @Prop({ type: Object }) opt;

  render() {
    return (
      <div
        class="x-preview-image"
        style={{ position: 'relative' }}
        onWheel={this.handleWheel}
        onMousemove={this.handleMousemove}
        onMouseleave={this.handleMouseup}
      >
        <XOssImage
          id="ossImage"
          ref="ossImage"
          ossPath={this.url}
          download={this.downloadName}
          service={this.service}
          watermark={this.watermark}
          class="x-preview-image-image"
          style={this.styleObject}
          onClick={e => {
            e.stopPropagation();
            return false;
          }}
          onMousedown={this.handleMousedown}
          onMouseup={this.handleMouseup}
          fit="contain"
          onAbsolutePathChange={absolutePath => {
            this.downloadUrl = absolutePath;
          }}
          onLoad={this.handleLoad}
        />
        {this.imageLoaded ? (
          <MarkerDisplay
            mousedown={this.handleMousedown}
            mouseup={this.handleMouseup}
            target={this.$refs.ossImage}
            scalePer={this.scalePer}
            rotateDeg={this.rotateDeg}
            offset={this.offset}
            opt={this.opt}
          />
        ) : null}

        <div
          class="x-preview-image-toolbar"
          style={{ position: 'fixed' }}
          onClick={e => {
            e.stopPropagation();
            return false;
          }}
        >
          <XIcon
            class="x-preview-image-toolbar-control"
            type="zoom-in"
            onClick={() => this.handleZoomChange(0.1)}
          />
          <span class="x-preview-image-toolbar-percentage">
            {this.percentage}%
          </span>
          <XIcon
            class="x-preview-image-toolbar-control"
            type="zoom-out"
            onClick={() => this.handleZoomChange(-0.1)}
          />
          <XIcon
            class="x-preview-image-toolbar-control x-preview-image-toolbar-control--redo"
            type="redo"
            onClick={this.rotate}
          />
          <a
            class="x-preview-image-toolbar-control"
            href={this.downloadUrl || ''}
            download={this.downloadName}
            target="_blank"
          >
            <XIcon type="vertical-align-bottom" />
          </a>
        </div>
      </div>
    );
  }
}
</script>
