<template>
  <div>
    <a-tooltip :mouseLeaveDelay="0" :title="$t('common.action.search')">
      <GlobalSearchSelect
        :typeList="groupTypeList"
        ref="SearchSelect"
        @jumpTo="handleJumpTo"
        :class="$style.searchSelect"
      />
    </a-tooltip>

    <HeaderActionList
      :featureMap="featureMap"
      @checkTenant="checkTenant"
      ref="headerAction"
    />
    <user-menu
      v-if="featureMap.avatar"
      :avatar="avatar"
      :data="userMenu"
      @select="handleUserMenuClick"
    >
      <template v-slot:item="data">
        <div v-for="items in data" :key="items.text">
          <language-select
            v-if="items.value === 'theme'"
            :value="locale"
            @input="handleLocaleChange"
          />
          <span v-else>{{ items.text }}</span>
        </div>
      </template>
    </user-menu>
  </div>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UserMenu } from '@triascloud/x-blocks';
import { crossStorageModule, globalModule } from '@/enum/store';
import { ssoClient } from '@triascloud/sso-client';
import { globalVueI18n } from '@triascloud/i18n';
import { XOperationSelect } from '@/components/x-operation-select';
import GlobalSearchSelect from './global-search-select';
import { getUserAvatar } from '@/services/tenant-center/member';
import { generateMap } from '@triascloud/utils';
import { globalSocket } from '@triascloud/message-hub';
import { FlowSocket } from '@/enum/socket';
import { todoTotal } from '@/services/flow/flow';
import { createModuleUrl } from '@/utils';
import HeaderActionList from './header-action-list.vue';
import LanguageSelect from './language-select.vue';
import { HabitService } from '@triascloud/services';
import { level, TenantPermission } from '@/enum/permission';
import { createModal } from '@triascloud/x-components';

const Feature = {
  /** @name 主页 */
  Home: 'home',
  /** @name 应用管理 */
  Application: 'application',
  /**
   * @name 数据
   * @todo Release Adjustment
   */
  Data: 'data',
  /** @name 任务 */
  Task: 'task',
  /** @name 待办 */
  Todo: 'todo',
  /** @name 消息 */
  Message: 'message',
  /** @name 皮肤 */
  Theme: 'theme',
  /** @name 下载器 */
  Download: 'download',
  /** @name 语言选择 */
  Language: 'language',
  /** @name 用户 */
  Avatar: 'avatar',
  /** @name 设置 */
  Setting: 'Setting',
  /** @name 拓展 */
  Expand: 'expand',
};
@Component({
  components: {
    UserMenu,
    GlobalSearchSelect,
    HeaderActionList,
    LanguageSelect,
  },
})
export default class HeaderRight extends Vue {
  @crossStorageModule.State('user') currentUser;
  @crossStorageModule.State user;
  @crossStorageModule.State skin;
  @crossStorageModule.State locale;
  @crossStorageModule.State tenant;
  @crossStorageModule.Mutation setItem;
  @globalModule.State refreshMessageFlag;
  @globalModule.Action refreshCompanyList;

  @Prop({
    type: Array,
    default: () => [
      Feature.Home,
      Feature.Application,
      Feature.Task,
      Feature.Todo,
      Feature.Message,
      Feature.Theme,
      Feature.Download,
      Feature.Language,
      Feature.Avatar,
      Feature.Setting,
    ],
  })
  feature;
  todoCount = 0;
  static Feature = Feature;

  get featureMap() {
    return generateMap(
      this.feature.filter(item => item),
      key => key,
      () => true,
    );
  }

  get groupTypeList() {
    return [
      {
        text: this.$t('market.template.application'),
        value: 'applicationList',
        type: 1,
      },
      {
        text: this.$t('market.group'),
        value: 'groupList',
        type: 2,
      },
      {
        text: this.$t('market.template.form'),
        value: 'formList',
        type: 3,
      },
      {
        text: this.$t('path.knowledge.tltle'),
        value: 'repositories',
        type: 4,
      },
      {
        text: this.$t('common.article'),
        value: 'repositoryDetails',
        type: 5,
      },
      {
        text: this.$t('common.task'),
        value: 'taskList',
        type: 6,
      },
    ];
  }

  @Watch('locale')
  setLocale(value) {
    document.documentElement.lang = this.locale.replace('_', '-');
    globalVueI18n.locale(value);
  }

  created() {
    globalSocket.on(FlowSocket.TodoTotal, this.handleResult);
    this.getTodoTotal();
  }

  handleResult(data) {
    if (!data.event || !data || !data.data) return;
    if (data.data.event !== 'TODO') return;
    this.todoCount = data.data.count;
  }

  handleJumpTo(item, type) {
    let url = null;
    switch (type) {
      case 1:
        url = `/application/${item.pkId}`;
        break;
      case 2:
        url = `/application/${item.appId}/group/${item.pkId}`;
        break;
      case 4:
        url = `/knowledge/${item.pkId}`;
        break;
      case 5:
        url = `/knowledge/${item.repositoryId}/${item.pkId}`;
        break;
      case 6:
        url = `/task/all?ident=${item.ident}`;
        break;
      default:
        url = `/application/${item.appId}/form/${item.pkId}`;
    }
    const appUrl = createModuleUrl('app');
    location.href = `${appUrl}${url}`;
  }

  async getTodoTotal() {
    this.todoCount = (await todoTotal()).find(
      item => item.event === 'TODO',
    ).count;
  }

  /** @name 区分无企业用户和有企业用户菜单 */
  get permissionMenu() {
    return this.tenant
      ? [
          '/user/setting',
          '/feedback',
          '/image',
          '/user/organization',
          '/knowledge',
        ]
      : ['/user/setting', '/user/organization'];
  }

  get userMenu() {
    return [
      ...this.permissionMenu.map(path => ({
        text: this.$path(path, 'app'),
        value: path,
      })),
      ...this.manageMenu,
      ...this.systemPermissionMenu,
      { text: this.$t('common.myCollection'), value: 'collect' },
      { text: this.$t('common.switchLanguage'), value: 'theme' },
      { text: this.$t('common.action.logout'), value: 'logout' },
    ];
  }

  get manageMenu() {
    return [
      ...[
        {
          text: this.$path('/company/statistics', 'app'),
          value: '/company/statistics',
        },
      ],
    ];
  }
  get systemPermissionMenu() {
    return this.systemPermission
      ? [
          {
            text: this.$path('/combo', 'app'),
            value: '/combo',
          },
        ]
      : [];
  }

  get systemPermission() {
    return level(this.user.tenantRole) >= level(TenantPermission.SYSTEM_ADMIN);
  }
  get avatar() {
    return getUserAvatar(this.currentUser);
  }
  handleSkinChange(value) {
    this.setItem({
      key: 'skin',
      value: JSON.stringify(value),
    });
  }
  async handleLocaleChange(value, { text }) {
    this.setItem({
      key: 'locale',
      value,
    });

    await this.refreshConfirm(text);
    location.reload();
  }

  refreshConfirm(text) {
    return new Promise((resolve, reject) => {
      const modal = createModal(
        () => (
          <div>
            <div class="ant-modal-confirm-body">
              <XIcon type="question-circle" />
              <span class="ant-modal-confirm-title">
                {this.$t('common.tips.prompt')}
              </span>
              <div class="ant-modal-confirm-content">
                {this.$t('common.tips.switchLanguageTip', { value: text })}
              </div>
            </div>
            <div class="ant-modal-confirm-btns">
              <AButton
                onClick={() => {
                  modal.handleClose();
                  reject();
                }}
              >
                {this.$t('common.action.later')}
              </AButton>
              <AButton
                type="primary"
                onClick={() => {
                  modal.handleClose();
                  resolve();
                }}
              >
                {this.$t('common.action.refresh')}
              </AButton>
            </div>
          </div>
        ),
        {
          width: 416,
          closable: false,
          maskClosable: false,
          className: 'ant-modal-confirm ant-modal-confirm-confirm',
        },
      );
    });
  }

  handleUserMenuClick(key) {
    switch (key) {
      case 'logout':
        ssoClient.logout();
        break;
      case 'collect':
        this.openCollectList();
        break;
      case 'theme':
        break;
      default:
        this.navigateTo(key);
        // this.$router.push(key);
        break;
    }
  }

  openCollectList() {
    this.navigateTo('?openCollection=1');
  }

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }

  @Watch('refreshMessageFlag')
  handleRefreshMessage() {
    this.$refs.notice.getMessageCount();
  }

  async checkTenant(ev) {
    if (!this.tenant) {
      ev.preventDefault();
      await XOperationSelect.create();
      this.refreshCompanyList();
    }
  }

  appOrigin = createModuleUrl('app');
  navigateTo(path) {
    location.href = `${this.appOrigin}${path}`;
  }
}
</script>
<style lang="less" module>
.count {
  :global .ant-badge-count {
    height: 18px;
    line-height: 18px;
    min-width: 18px;
    padding: 0 2px;
  }
}
.searchSelect {
  position: relative;
  margin-right: 20px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 1px;
    background-color: var(--font-color-secondary);
  }
}
</style>
