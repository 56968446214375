<template>
  <div :class="$style.wearingGuide">
    <div :class="$style.guideOne">
      <div :class="$style.tip">
        <a-icon type="check-circle" theme="filled" :class="$style.right" />
        <span>{{ $t('hat.deviceManagement.wearingGuide.right') }}</span>
      </div>
      <div :class="$style.list">
        <div :class="$style.item">
          <div :class="$style.guideImg" style="position: relative;">
            <img src="@/assets/images/smart-hat/guide/guide_01.png" alt="" />
            <div :class="[$style.line, $style.line_1]"></div>
            <div :class="[$style.line, $style.line_2]"></div>
            <div :class="[$style.line, $style.line_3]"></div>
            <div :class="[$style.line, $style.line_4]"></div>
            <span :class="[$style.text, $style.text_1]">{{
              $t('hat.deviceManagement.wearingGuide.hatBody')
            }}</span>
            <span :class="[$style.text, $style.text_2]">{{
              $t('hat.deviceManagement.wearingGuide.hatHarness')
            }}</span>
            <span :class="[$style.text, $style.text_3]">{{
              $t('hat.deviceManagement.wearingGuide.hatChinstrap')
            }}</span>
            <span :class="[$style.text, $style.text_4]">{{
              $t('hat.deviceManagement.wearingGuide.hatLining')
            }}</span>
          </div>
          <div :class="$style.tip">
            <span :class="$style.tip__step">1</span>
            <span>{{ $t('hat.deviceManagement.wearingGuide.rightTip1') }}</span>
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_02.png" alt="" />
          </div>
          <div :class="$style.tip">
            <span :class="$style.tip__step">2</span>
            <span>{{ $t('hat.deviceManagement.wearingGuide.rightTip2') }}</span>
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_03.png" alt="" />
          </div>
          <div :class="$style.tip">
            <span :class="$style.tip__step">3</span>
            <span>{{ $t('hat.deviceManagement.wearingGuide.rightTip3') }}</span>
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_04.png" alt="" />
          </div>
          <div :class="$style.tip">
            <span :class="$style.tip__step">4</span>
            <span>{{ $t('hat.deviceManagement.wearingGuide.rightTip4') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.guideTwo">
      <div :class="$style.tip">
        <a-icon type="close-circle" theme="filled" :class="$style.wrong" />
        <span>{{ $t('hat.deviceManagement.wearingGuide.wrong') }}</span>
      </div>
      <div :class="$style.block">
        <div :class="$style.guideImg">
          <img src="@/assets/images/smart-hat/guide/guide_05.png" alt="" />
        </div>
        <div :class="$style.tips">
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip1') }}</span>
          </div>
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip2') }}</span>
          </div>
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip3') }}</span>
          </div>
        </div>
      </div>
      <div :class="$style.list">
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_06.png" alt="" />
          </div>
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip4') }}</span>
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_07.png" alt="" />
          </div>
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip5') }}</span>
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_08.png" alt="" />
          </div>
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip6') }}</span>
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_09.png" alt="" />
          </div>
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip7') }}</span>
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_10.png" alt="" />
          </div>
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip8') }}</span>
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.guideImg">
            <img src="@/assets/images/smart-hat/guide/guide_11.png" alt="" />
          </div>
          <div :class="$style.tip">
            <a-icon type="close" :class="$style.wrong" />
            <span>{{ $t('hat.deviceManagement.wearingGuide.wrongTip9') }}</span>
          </div>
        </div>
      </div>
    </div>
    <a-button type="primary" @click="handleClose">{{
      $t('hat.deviceManagement.wearingGuide.accept')
    }}</a-button>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class WearingGuide extends Vue {
  handleClose() {
    this.$emit('close');
  }
}
</script>

<style lang="less" module>
.wearingGuide {
  display: flex;
  flex-direction: column;
  align-items: center;
  .guideOne {
    width: 100%;
    & > .tip {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    & > .list {
      display: flex;
      justify-content: space-between;
      .item {
        text-align: center;
        .guideImg {
          width: 300px;
          height: 260px;
          background: #f9f9f9;
          border-radius: 3px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .line {
            position: absolute;
            height: 0;
            border: 1px solid #000000;
            border-bottom: none;
          }
          .text {
            position: absolute;
          }
          .line_1 {
            width: 25px;
            top: 123px;
            left: 49px;
          }
          .line_2 {
            width: 55px;
            top: 82px;
            right: 65px;
            transform: rotate(-20deg);
            transform-origin: left;
          }
          .line_3 {
            width: 35px;
            top: 157px;
            right: 53px;
            transform: rotate(20deg);
            transform-origin: left;
          }
          .line_4 {
            width: 34px;
            top: 170px;
            right: 90px;
            transform: rotate(60deg);
            transform-origin: left;
          }
          .text_1 {
            top: 112px;
            left: 15px;
          }
          .text_2 {
            top: 42px;
            right: 38px;
          }
          .text_3 {
            top: 168px;
            right: 26px;
          }
          .text_4 {
            top: 196px;
            right: 78px;
          }
        }
        .tip {
          margin-top: 12px;
          width: 300px;
          .tip__step {
            height: 20px;
            width: 20px;
            display: inline-block;
            margin-right: 8px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: #4771ff;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .guideTwo {
    width: 100%;
    & > .tip {
      display: flex;
      align-items: center;
      margin: 40px 0 15px;
    }
    & > .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      .guideImg {
        width: 880px;
        height: 240px;
        background: #f9f9f9;
        border-radius: 6px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .tips {
        width: 676px;
        display: flex;
        justify-content: space-between;
        margin: 12px 0 15px;
        .tip {
          display: flex;
          align-items: center;
        }
        .tip:last-of-type {
          transform: translateX(7px);
        }
      }
    }
    & > .list {
      display: flex;
      justify-content: space-between;
      .item {
        text-align: center;
        .guideImg {
          width: 180px;
          height: 180px;
          background: #f9f9f9;
          border-radius: 3px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .tip {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 12px 0 15px;
        }
      }
    }
  }
  .wrong {
    color: #ff0000;
    margin-right: 6px;
    font-size: 16px;
  }
  .right {
    color: #11aa66;
    margin-right: 6px;
    font-size: 16px;
  }
}
</style>
