<template>
  <a-table
    :columns="columns"
    :rowKey="row => row.name"
    :data-source="list"
    :pagination="pagination"
    @change="handlePagination"
  >
  </a-table>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getOperationModeForDetail } from '@/services/iot-electric-box/api';
import moment from 'moment';

const TypeKey = (self, txt) => {
  const opt = {
    RISE: self.$t('generalView.key.rise'),
    DECLINE: self.$t('generalView.key.down'),
    LOOSE_HOOK: self.$t('generalView.key.looseHook'),
    TIGHT_HOOK: self.$t('generalView.key.tightHook'),
    REMOTE_CONTROL: self.$t('generalView.key.remoteControl'),
  };
  return opt[txt];
};

@Component({})
export default class OperationDetail extends Vue {
  @Prop({ type: String, default: 'project' }) type;
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Object, default: () => {} }) params;

  mounted() {
    this.fetchData();
  }
  async fetchData() {
    const { records, current, size, total } = await getOperationModeForDetail({
      idxBuildingId: this.data.buildingId,
      idxProjectId: this.data.projectId,
      pageNum: this.pagination.current,
      pageSize: this.pagination.pageSize,
      ...this.params,
    });
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
    this.list = records;
  }
  columns = [
    {
      title: this.$t('generalView.operationType'),
      dataIndex: 'operationModel',
      width: 120,
      customRender: txt => TypeKey[txt],
    },
    {
      title: this.$t('generalView.table.time'),
      width: 160,
      dataIndex: 'startTime',
      customRender: (txt, record) =>
        `${moment(+record.startTime).format('YYYY-MM-DD HH:mm')}-${moment(
          +record.endTime,
        ).format('HH:mm')}`,
    },
    {
      title: this.$t('generalView.table.stand'),
      dataIndex: 'standId',
      customRender: txt => txt.replace('[', '').replace(']', ''),
    },
  ];
  list = [];

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };
  handlePagination(data) {
    this.pagination = data;
    this.fetchData();
  }
}
</script>
<style lang="less" module></style>
