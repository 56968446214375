<template>
  <div :class="$style.divCard">
    <div :class="$style.title">
      <span>{{ $t('generalView.count.locations') }}</span>
    </div>
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <no-data-chart
        font="250px"
        isIcon
        :style="{ height: '100%', width: '100%' }"
      />
    </div>
    <div v-if="!isNoData">
      <div ref="pieCharts" style="height: 370px; width: 660px" />
    </div>
    <div v-if="!isNoData" :class="$style.rank">
      <a-row :class="$style.head">
        <a-col :span="18"> Top10 {{ $t('generalView.count.city') }} </a-col>
        <a-col :span="6">
          {{ $t('generalView.count.num') }}
        </a-col>
      </a-row>
      <a-col v-for="(item, idx) in topData" :key="idx" style="padding:0">
        <a-col :span="18">
          <a-col :span="10" :class="$style.hidden">
            <span :class="$style[`number-${idx}`]">No.{{ idx + 1 }}</span>
            <span style="margin-left: 5px" :title="item.city">
              {{ item.city }}
            </span>
          </a-col>
          <a-col :span="14">
            <a-progress
              :percent="item.percent"
              :show-info="false"
              strokeColor="#00b893"
            />
          </a-col>
        </a-col>
        <a-col :span="6" style="padding:0"> {{ item.num }}套 </a-col>
      </a-col>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { getGeoData } from '@/services/oss/oss.js';
import { areaDistribution } from '@/services/iot-electric-box/device-status';
import { crossStorageModule } from '@/enum/store.js';
import theme from '@/utils/theme/theme';

@Component({})
export default class LocationDistribution extends Vue {
  @crossStorageModule.State('skin') skin;

  @Watch('skin')
  changeSkin() {
    this.initChart();
  }

  mounted() {
    this.registerMap();
  }
  isNoData = true;
  topData = [];
  async registerMap() {
    const result = await getGeoData('100000');
    echarts.registerMap('china', result);
    await this.getList();
  }
  seriesData = [];
  async getList() {
    this.seriesData = [];
    this.topData = [];
    try {
      const { deviceCity, deviceProvince } = await areaDistribution({});
      if (deviceCity.length > 0 && deviceProvince.length > 0) {
        this.isNoData = false;
        const top10CityNum = this.getTotal(deviceCity);
        deviceCity.forEach(item => {
          const o = {};
          o.city = item.city;
          o.percent = parseFloat((item.number / top10CityNum).toFixed(2)) * 100;
          o.num = item.number;
          this.topData.push(o);
        });
        this.seriesData = deviceProvince.map(item => {
          return {
            name: item.province,
            value: item.number,
          };
        });
        this.$nextTick(() => {
          this.initChart();
        });
      } else {
        this.isNoData = true;
      }
    } catch {
      return false;
    }
  }
  getTotal(arr) {
    if (arr.length === 0) return 0;
    let sum = 0;
    arr.forEach(item => {
      sum += item.number;
    });
    return sum;
  }
  initChart() {
    if (this.seriesData.length === 0) {
      return;
    }
    const chart = this.$refs.pieCharts;
    const myChart = echarts.init(chart);
    const seriesData = this.seriesData;
    let max = 0;
    seriesData.forEach(v => {
      max = Math.max(v.value, max);
    });
    if (seriesData.length === 0) {
      seriesData.push({ name: '广东省', value: 0 });
    }
    const option = {
      tooltip: {
        backgroundColor: theme[this.skin.mode].mainBg,
        trigger: 'item',
        textStyle: {
          color: theme[this.skin.mode].font,
          fontSize: 14,
        },
        extraCssText:
          'box-shadow: 0px 0px 6px 0px var(--shadow); border-radius:5px',
        padding: [5, 10],
        formatter: function(params) {
          if (!params.value) params.value = 0;
          return `<div>
            <div>设备数量</div>
            <div>${params.name}：${params.value} </div>
          </div>`;
        },
      },
      visualMap: {
        type: 'continuous',
        orient: 'horizontal',
        itemWidth: 10,
        itemHeight: 60,
        text: ['高', '低'],
        showLabel: true,
        calculable: true,
        seriesIndex: [0],
        min: 0,
        max: max,
        inRange: {
          color: ['#74add1', '#4575b4', '#313695'],
        },
        textStyle: {
          color: theme[this.skin.mode].font,
        },
        bottom: 30,
        left: '20',
      },
      geo: {
        map: 'china',
        roam: false,
        top: '50',
        left: '20',
        itemStyle: {
          borderColor: theme[this.skin.theme].primary,
          areaColor: theme[this.skin.theme].primary30,
        },
        emphasis: {
          itemStyle: {
            areaColor: '#f7f7f7',
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 20,
            borderWidth: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
      series: [
        {
          name: '楼栋分布', // 浮动框的标题
          type: 'map',
          map: 'china',
          geoIndex: 0,
          data: seriesData,
        },
      ],
    };
    myChart.setOption(option);
  }
}
</script>

<style lang="less" module>
.divCard {
  height: 360px;
  box-shadow: 0 0 8px 0 #eeeeee;
  border-radius: 5px;
  position: relative;
  .title {
    z-index: 999;
    font-size: 14px;
    margin: 10px;
    position: absolute;
    font-weight: 500;
    .icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .rank {
    z-index: 999;
    position: absolute;
    right: 30px;
    top: 50px;
    width: 350px;
    .head {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
  .hidden {
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  .number-0 {
    color: #ff4848;
  }
  .number-1 {
    color: #ff7f31;
  }
  .number-2 {
    color: #ffc916;
  }
}
</style>
