var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.imageSrc)?_c('div',{style:({
    position: 'absolute',
    width: (_vm.imageWidth + "px"),
    height: (_vm.imageHeight + "px"),
    zIndex: 999,
    transform: ("translate(" + (this.offset.x) + "px, " + (this.offset.y) + "px) rotate(" + (this.rotateDeg) + "deg) scale(" + (this.scalePer) + ")"),
  }),attrs:{"id":"editorCanvas","width":_vm.imageWidth,"height":_vm.imageHeight},on:{"mousedown":_vm.mousedown,"mouseup":_vm.mouseup}},[_c('img',{style:({
      width: (_vm.imageWidth + "px"),
      height: (_vm.imageHeight + "px"),
    }),attrs:{"src":_vm.imageSrc,"id":"editingTarget","width":_vm.imageWidth,"height":_vm.imageHeight},on:{"load":_vm.handleLoaded}}),_c('div',{style:({
      touchAction: 'pinch-zoom',
      position: 'absolute',
      top: 0,
      width: (_vm.imageWidth + "px"),
      height: (_vm.imageHeight + "px"),
      transformOrigin: 'top left',
    })},[_vm._l((_vm.tagList),function(tag,idx){return _c('div',{key:'tag' + idx},[_c('a-tooltip',{attrs:{"placement":"bottom","trigger":tag.marker.opacity === 1 ? 'hover' : 'click'},model:{value:(tag.marker.remarkFlag),callback:function ($$v) {_vm.$set(tag.marker, "remarkFlag", $$v)},expression:"tag.marker.remarkFlag"}},[_c('template',{slot:"title"},[_c('div',[_c('div',[_vm._v("可信度："+_vm._s(_vm.toFixedPercentage(tag.marker.probability)))]),_c('div',[_vm._v("备注："+_vm._s(tag.marker.remark))])])]),_c('span',{class:_vm.$style.tagWrapper,style:({
            position: 'absolute',
            left: ((tag.x) + "px"),
            top: ((tag.y) + "px"),
            opacity: tag.opacity,
          })},[(tag.marker.opacity === 1)?_c('a-icon',{class:_vm.$style.icon,attrs:{"type":"eye"},on:{"click":function($event){return _vm.handleEye(tag, 0)}}}):_c('a-icon',{class:_vm.$style.icon,attrs:{"type":"eye-invisible"},on:{"click":function($event){return _vm.handleEye(tag, 1)}}}),_vm._v(_vm._s(tag.marker.tagName))],1)],2)],1)}),_c('svg',{ref:"svgEle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":_vm.imageWidth,"height":_vm.imageHeight,"viewBox":_vm.viewBox,"id":"markerImage"}})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }