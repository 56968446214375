export class ToolboxPanel {
  /** @type { string | undefined } */
  _id;
  get id() {
    return this._id;
  }
  /** @type { string } */
  title;
  /** @type { string } */
  icon;
  /** @type { import("../core/Settings").IStyleSettings } */
  uiStyleSettings;

  constructor(title, icon = undefined, id = undefined) {
    this.title = title;
    this.icon = icon;
    this._id = id;
  }

  /** @returns { HTMLDivElement } */
  getUi() {}
}
