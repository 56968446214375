<template>
  <layout>
    <layout-menu :menus="menus" :class="$style.menus" :value="$route.path">
      <template v-slot:header-left>
        <x-icon type="tc-icon-overview" :class="$style.icon" />
        <span :class="$style.title">{{ $t('menus.iot') }}</span>
      </template>
    </layout-menu>
    <router-view />
  </layout>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Layout, LayoutMenu } from '@triascloud/x-blocks';
import { findTree } from '@triascloud/utils';
import { ssoClient } from '@triascloud/sso-client';
import { crossStorageModule, globalModule } from '@/enum/store.js';
import { XOperationSelect } from '@/components/x-operation-select';
import { createModuleUrl } from '@/utils';

@Component({
  components: {
    Layout,
    LayoutMenu,
  },
})
export default class IotIndex extends Vue {
  @crossStorageModule.State('tenant') tenant;
  @globalModule.Action refreshCompanyList;
  @globalModule.Action fetchMockStatus;
  get menus() {
    const result = findTree(
      this.$router.options.routes,
      item => item.name === 'iot',
    ).children.map(item => ({
      path: item.path,
      title: this.$path(item.path),
    }));
    result.unshift({
      path: '/iot/screen',
      title: this.$path('/iot/screen'),
      target: '_blank',
    });
    return result.filter(item => this.$p(item.path));
  }

  firstLoading = true;
  @Watch('menus')
  menusChange(menus) {
    if (this.firstLoading) {
      this.firstLoading = false;
    } else {
      if (Array.isArray(menus) && menus.length === 0) {
        window.location.replace(createModuleUrl('app'));
      }
    }
  }

  created() {
    this.checkTenant();
    if (ssoClient.status && ssoClient.getTokenSync()) {
      this.fetchMockStatus();
    }
  }

  checkTenant() {
    if (this.tenant || !this.user) return;
    if (!this.menus.length) {
      this.$router.push('/403');
    } else {
      XOperationSelect.create()
        .then(this.refreshCompanyList)
        .catch(() => this.$router.push('/'));
    }
  }
}
</script>
<style lang="less" module>
.menus {
  width: 200px;
  .icon {
    font-size: 18px;
    margin-right: 10px;
  }
  .title {
    font-size: 16px;
  }
}
</style>
