import { MarkerBase } from '../core/MarkerBase';
import { SvgHelper } from '../core/SvgHelper';
import { ResizeGrip } from './ResizeGrip';
// eslint-disable-next-line no-unused-vars
import { Settings } from '../core/Settings';
import { ColorPickerPanel } from '../ui/toolbox-panels/ColorPickerPanel';
import { LineWidthPanel } from '../ui/toolbox-panels/LineWidthPanel';
import { pointToSingleList } from '../core/Utils';

/**
 *
 * move 事件，更新线段
 * dblclick 事件，结束线段的绘制
 * down 事件，添加点
 */

/**
 * LinearMarkerBase is a base class for all line-type markers (Line, Arrow, Measurement Tool, etc.).
 */
export class PolyLinearMarkerBase extends MarkerBase {
  /**
   * x and y coordinate list.
   * @type { import('../core/MarkerBase').IPoint[] }
   */
  points = [];
  /**
   * x and y coordinate list.
   * @type { import('../core/MarkerBase').IPoint[] }
   */
  oldPoints = [];

  /**
   * Pointer coordinates at the satart of move or resize.
   */
  manipulationStartX = 0;
  manipulationStartY = 0;

  /**
   * Marker's main visual.
   * @type { SVGGraphicsElement }
   */
  visual;

  /**
   * Container for control elements.
   * @type { SVGGElement }
   */
  controlBox;

  /** manipulation grips @type { ResizeGrip[] }  */
  grips = [];

  /** 创建是否完成 */
  created = false;
  /**
   * Active manipulation grip.
   * 主动操作手柄
   * @type { ResizeGrip }
   */
  activeGrip;

  /**
   * Line color.
   */
  strokeColor = 'transparent';
  /**
   * Line width.
   */
  strokeWidth = 0;
  /**
   * Line dash array.
   */
  strokeDasharray = '';

  /**
   * Color pickar panel for line color.
   * @type { ColorPickerPanel }
   */
  strokePanel;
  /**
   * Line width toolbox panel.
   * @type { LineWidthPanel }
   */
  strokeWidthPanel;

  /**
   * Creates a LineMarkerBase object.
   *
   * @param { SVGGElement } container - SVG container to hold marker's visual.
   * @param { HTMLDivElement } overlayContainer - overlay HTML container to hold additional overlay elements while editing.
   * @param { Settings } settings - settings object containing default markers settings.
   */
  constructor(container, overlayContainer, settings) {
    super(container, overlayContainer, settings);

    this.setStrokeColor = this.setStrokeColor.bind(this);
    this.setStrokeWidth = this.setStrokeWidth.bind(this);

    this.strokeColor = settings.defaultColor;
    this.strokeWidth = settings.defaultStrokeWidth;

    this.strokePanel = new ColorPickerPanel(
      'Line color',
      settings.defaultColorSet,
      settings.defaultColor,
    );
    this.strokePanel.onColorChanged = this.setStrokeColor;

    this.strokeWidthPanel = new LineWidthPanel(
      'Line width',
      settings.defaultStrokeWidths,
      settings.defaultStrokeWidth,
    );
    this.strokeWidthPanel.onWidthChanged = this.setStrokeWidth;

    this.setupControlBox();
  }

  /**
   * Returns true if passed SVG element belongs to the marker. False otherwise.
   *
   * @param { EventTarget } el - target element.
   */
  ownsTarget(el) {
    if (super.ownsTarget(el)) {
      return true;
    } else if (this.grips.some(i => i.ownsTarget(el))) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) down event.
   *
   * @param { import('../core/MarkerBase').IPoint } point - event coordinates.
   * @param { EventTarget } target - direct event target element.
   */
  pointerDown(point, target = undefined) {
    super.pointerDown(point, target);

    this.manipulationStartX = point.x;
    this.manipulationStartY = point.y;

    if (this.state === 'new') {
      this.removeTemporaryLine();
      this.points.push({
        x: point.x,
        y: point.y,
      });
      this.addControlGrips();
    }

    this.points.forEach((point, idx) => {
      this.oldPoints[idx] = JSON.parse(JSON.stringify(point));
    });

    if (this.state !== 'new') {
      if (!target) return;
      this.select();
      if (this.grips.some(i => i.ownsTarget(target))) {
        this.activeGrip = this.grips.find(i => i.ownsTarget(target));
      } else {
        this.activeGrip = undefined;
      }

      if (this.activeGrip) {
        this._state = 'resize';
      } else {
        this._state = 'move';
      }
    }
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) up event.
   *
   * @param { import('../core/MarkerBase').IPoint } point - event coordinates.
   */
  pointerUp(point) {
    super.pointerUp(point);

    if (this.created) {
      this._state = 'select';
    }
  }

  /**
   * 结束线段的绘制
   * @param { import('../core/MarkerBase').IPoint } point
   * @param { EventTarget } target
   */
  dblClick(point, target = undefined) {
    super.dblClick(point, target);
    const inState = this.state;
    if (inState === 'new') {
      // 结束绘制
      this.removeTemporaryLine();
      this.created = true;
      this.removeLastPoint();
      if (inState === 'new' && this.onMarkerCreated) {
        this.onMarkerCreated(this);
      }

      this.adjustVisual();
      this.select();
      if (this.created) {
        this._state = 'select';
      }
    }
  }

  /**
   * When implemented adjusts marker visual after manipulation when needed.
   */
  adjustVisual() {}

  /** 临时线段 @type { SVGLineElement } */
  temporaryLine;
  /** 临时线段的组 @type { SVGGElement } */
  temGel;
  /**
   * 创建临时线段
   * @param {Number} x1
   * @param {Number} y1
   * @param {Number} x2
   * @param {Number} y2
   */
  createTemporaryLine(x1, y1, x2, y2) {
    if (this.temGel) {
      this.removeTemporaryLine();
    }
    this.temGel = SvgHelper.createGroup();
    this.temporaryLine = SvgHelper.createLine(x1, y1, x2, y2, [
      ['stroke', this.strokeColor],
      ['fill', 'none'],
      ['stroke-width', this.strokeWidth.toString()],
    ]);

    this.temGel.appendChild(this.temporaryLine);

    this.container.appendChild(this.temGel);
  }
  /** 删除临时线段 */
  removeTemporaryLine() {
    this.temGel &&
      this.container.removeChild(this.temGel) &&
      (this.temGel = null);
  }

  /** 因为dblclick，导致多绘制了一个点，所以需要清除这个点 */
  removeLastPoint() {
    this.points.pop();
    const grip = this.grips.pop();
    this.controlBox.removeChild(grip.visual);
  }

  /**
   * Handles marker manipulation (move, resize, rotate, etc.).
   *
   * @param { import('../core/MarkerBase').IPoint } point - event coordinates.
   */
  manipulate(point) {
    if (this.state === 'new') {
      if (this.points.length === 0) return;
      const [{ x, y }] = this.points.slice(-1);
      this.createTemporaryLine(x, y, point.x, point.y);
    } else if (this.state === 'creating') {
      this.resize(point);
    } else if (this.state === 'move') {
      const diffX = point.x - this.manipulationStartX;
      const diffY = point.y - this.manipulationStartY;
      this.points.forEach((item, index) => {
        item.x = this.oldPoints[index].x + diffX;
        item.y = this.oldPoints[index].y + diffY;
      });
      this.adjustVisual();
      this.adjustControlBox();
    } else if (this.state === 'resize') {
      this.resize(point);
    }
  }

  /**
   * Resizes the line marker.
   * @param { import('../core/MarkerBase').IPoint } point - current manipulation coordinates.
   */
  resize(point) {
    if (this.activeGrip) {
      const fIndex = this.grips.findIndex(i => i === this.activeGrip);
      const item = this.points[fIndex];
      item.x = point.x;
      item.y = point.y;
    }
    this.adjustVisual();
    this.adjustControlBox();
  }

  /**
   * Displays marker's controls.
   */
  select() {
    super.select();
    this.adjustControlBox();
    this.controlBox.style.display = '';
  }

  /**
   * Hides marker's controls.
   */
  deselect() {
    super.deselect();
    this.controlBox.style.display = 'none';
  }

  /**
   * Creates control box for manipulation controls.
   */
  setupControlBox() {
    this.controlBox = SvgHelper.createGroup();
    this.container.appendChild(this.controlBox);

    this.addControlGrips();

    this.controlBox.style.display = 'none';
  }

  adjustControlBox() {
    this.positionGrips();
  }

  /**
   * Adds control grips to control box.
   * todo: 每新增一个新的线段，都需要增加一个grip
   */
  addControlGrips() {
    if (this.points.length > 0) {
      this.points.forEach((_, index) => {
        if (!this.grips[index]) {
          const grip = this.createGrip();
          this.grips.push(grip);
        }
      });
    }

    this.positionGrips();
  }

  /**
   * Creates manipulation grip.
   * @returns { ResizeGrip } - manipulation grip.
   */
  createGrip() {
    const grip = new ResizeGrip();
    grip.visual.transform.baseVal.appendItem(SvgHelper.createTransform());
    this.controlBox.appendChild(grip.visual);

    return grip;
  }

  /**
   * Updates manipulation grip layout.
   */
  positionGrips() {
    if (!this.grips || this.grips.length === 0) return;
    const gripSize = this.grips[0].GRIP_SIZE;

    this.grips.forEach((grip, idx) => {
      const point = this.points[idx];
      this.positionGrip(
        grip.visual,
        point.x - gripSize / 2,
        point.y - gripSize / 2,
      );
    });
  }

  /**
   * Positions manipulation grip.
   * @param { SVGGraphicsElement } grip - grip to position
   * @param { number } x - new X coordinate
   * @param { number } y - new Y coordinate
   */
  positionGrip(grip, x, y) {
    const translate = grip.transform.baseVal.getItem(0);
    translate.setTranslate(x, y);
    grip.transform.baseVal.replaceItem(translate, 0);
  }

  /**
   * Returns marker's state.
   * @returns { PolyLinearMarkerBaseState }
   */
  getState() {
    const result = Object.assign(
      {
        points: pointToSingleList(this.points),
      },
      super.getState(),
    );

    return result;
  }

  /**
   * Restores marker's state to the previously saved one.
   * @param state - previously saved state.
   */
  restoreState(state) {
    super.restoreState(state);
    const lmbState = state;
    this.points = lmbState.points;
  }

  /**
   * Scales marker. Used after the image resize.
   *
   * @param { number } scaleX - horizontal scale
   * @param { number } scaleY - vertical scale
   */
  scale(scaleX, scaleY) {
    super.scale(scaleX, scaleY);

    this.points.forEach(point => {
      point.x = point.x * scaleX;
      point.y = point.y * scaleY;
    });

    this.adjustVisual();
    this.adjustControlBox();
  }

  /**
   * Sets line color.
   * @param { string } color - new color.
   */
  setStrokeColor(color) {
    this.strokeColor = color;
    this.adjustVisual();
    this.colorChanged(color);
  }
  /**
   * Sets line width.
   * @param { number } width - new width.
   */
  setStrokeWidth(width) {
    this.strokeWidth = width;
    this.adjustVisual();
  }
}
