<template>
  <a-form-model layout="vertical" :model="form" ref="form">
    <a-form-model-item label="画面分辨率">
      <a-radio-group v-model="form.resolution" :class="$style.radioGroup">
        <a-radio :value="320" style="margin-bottom: 12px;">
          320P(320*180)<span :class="$style.tipText"
            >每分钟消耗约10-20M流量</span
          >
        </a-radio>
        <a-radio :value="480" style="margin-bottom: 12px;">
          480P(480*360)<span :class="$style.tipText"
            >每分钟消耗约5-10M流量</span
          >
        </a-radio>
        <a-radio :value="720">
          720P(1280*720)<span :class="$style.tipText"
            >每分钟消耗约30M+流量</span
          >
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="画面比例">
      <a-radio-group v-model="form.screenMode" :class="$style.radioGroup">
        <a-radio :value="0">
          横屏
        </a-radio>
        <a-radio :value="1">
          竖屏
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { updateDeviceData } from '@/services/smart-hat/device-management';

@Component()
export default class ScreenSetting extends Vue {
  @Prop({ type: String }) deviceId;
  @Prop({ type: Number }) resolution;
  @Prop({ type: Number }) screenMode;

  form = {
    resolution: 480,
    screenMode: 0,
  };
  mounted() {
    this.form.resolution = this.resolution;
    this.form.screenMode = this.screenMode;
  }
  async getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const postData = {
              deviceId: this.deviceId,
              exData: this.form,
              type: 'VIDEO_CALL_SETTINGS',
            };
            await updateDeviceData(postData);
            this.$message.success('设置成功！');
            resolve(postData);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module>
.radioGroup {
  margin-left: 40px;
  .tipText {
    color: #999999;
  }
}
</style>
