<template>
  <div :class="$style.qrcodeBox" @click="openLink">
    <x-qrcode
      :class="$style.image"
      :style="qrStyle"
      :src="src"
      :option="option"
    />
    <div :class="$style.desc">
      <slot name="desc">
        {{ desc }}
      </slot>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import xQrcode from '@/components/x-qrcode';
@Component({
  components: {
    xQrcode,
  },
})
export default class Qrcode extends Vue {
  @Prop({ type: String, default: '' }) src;
  @Prop({ type: Boolean, default: false }) clickable;
  @Prop({
    type: Object,
    default: () => {
      return {
        width: '160px',
        height: '160px',
      };
    },
  })
  qrStyle;
  @Prop({
    type: String,
    default: '',
  })
  desc;

  option = {
    margin: 0,
  };
  openLink() {
    if (this.clickable) window.open(this.src);
  }
}
</script>

<style lang="less" module>
.qrcodeBox {
  text-align: center;
  padding: 20px 0 20px 0;
}
.desc {
  padding-top: 20px;
  margin: 0 auto;
}
</style>
