<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="文件备注名称" prop="mark">
      <a-input
        v-model="form.mark"
        placeholder="请输入备注名称，限16字符长度"
        :maxLength="16"
      ></a-input>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { putRemark } from '@/services/smart-hat/file';

@Component()
export default class FileRemark extends Vue {
  @Prop({ type: Object, default: () => {} }) data;

  mounted() {
    this.initData();
  }
  form = {
    fileId: '',
    mark: '',
  };
  rules = {
    mark: [
      {
        required: true,
        message: '文件备注名称不能为空',
      },
    ],
  };
  async initData() {
    this.form.fileId = this.data.fileId;
    this.form.mark = this.data.mark;
  }
  async getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await putRemark(this.form);
            this.$message.success('修改成功！');
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module></style>
