import { namespace } from 'vuex-class';

export const globalModule = namespace('global');
export const crossStorageModule = namespace('crossStorage');
export const shareScreenModule = namespace('shareScreen');
export const screenModule = namespace('screen');
export const generalScreenModule = namespace('generalScreen');
export const projectModule = namespace('project');
export const ovenModule = namespace('oven');
export const monitorModule = namespace('monitor');
