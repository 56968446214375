<template>
  <div :class="$style.divCard">
    <div :class="$style.head">
      <div>
        <span :class="$style.text">{{
          $t('generalView.count.schedules')
        }}</span>
        <a-tooltip placement="right">
          <template slot="title">
            <span>{{ $t('generalView.count.scheduleDesc') }}</span>
          </template>
          <a-icon :class="$style.icon" type="info-circle" />
        </a-tooltip>
      </div>
      <div>
        <a-select
          v-model="form.projectId"
          :placeholder="$t('generalView.projects')"
          show-search
          allowClear
          style="width: 160px; margin-left: 10px"
          option-filter-prop="children"
          :filter-option="false"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
          @change="handProjectChange"
          @search="input => filterProjectOption(input)"
        >
          <a-select-option
            v-for="item in projects"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
          <template slot="notFoundContent">
            <a-empty :image="simpleImage" />
          </template>
        </a-select>
      </div>
    </div>
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <no-data-chart
        font="250px"
        isIcon
        :style="{ height: '100%', width: '100%' }"
      />
    </div>
    <div v-else>
      <div ref="buildingCharts" style="height: 400px; width: 780px" />
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { debounce } from 'lodash-decorators';
import { buildingSchedule } from '@/services/iot-electric-box/device-status';
import { getRelatedProject } from '@/services/things/project';
import { Empty } from 'ant-design-vue';

@Component({})
export default class BuildingSchedule extends Vue {
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }
  mounted() {
    this.getList();
    this.getProjectsList();
  }
  isNoData = true;
  form = {
    projectId: undefined,
  };
  projects = [];
  colorArr = ['#947dfa', '#00b893', '#fe5959', '#ffa348', '#516cf0', '#ffbf07'];
  legendData = [];
  seriesData = [];
  handProjectChange(id) {
    this.form.projectId = id;
    if (!id) {
      this.projectSearchInput = '';
      this.getProjectsList();
    }
    this.getList();
  }
  projectSearchInput = '';
  @debounce(300)
  filterProjectOption(input) {
    this.projectSearchInput = input.toLowerCase();
    this.getProjectsList();
  }
  async getProjectsList() {
    const params = {
      'query.size': 30,
      'projectId': this.form.projectId,
    };
    if (this.projectSearchInput) {
      params.keyword = this.projectSearchInput;
    }
    const result = await getRelatedProject(params);
    this.projects = result.map(item => {
      return {
        id: item.projectId,
        name: item.projectName,
      };
    });
  }
  async getList() {
    this.legendData = [];
    this.seriesData = [];
    const params = {
      projectId: this.form.projectId,
    };
    try {
      const result = await buildingSchedule(params);
      if (result.length > 0) {
        this.isNoData = false;
        result.forEach(item => {
          this.legendData.push(item.buildingName);
          const o = {};
          o.name = item.buildingName;
          o.value = item.progress;
          o.totalLayer = item.totalLayer;
          o.currentLayer = item.currentLayer;
          o.projectName = item.projectName;
          o.tenantName = item.tenantName;
          this.seriesData.push(o);
        });
        this.$nextTick(() => {
          this.initChart();
        });
      } else {
        this.isNoData = true;
      }
    } catch {
      return false;
    }
  }
  get dataZoom() {
    return [
      {
        type: 'slider',
        show: true,
        start: 100,
        end: 100,
        zoomLock: true, // 不能缩放
        brushSelect: false, // 不能鼠标点击刷选
        yAxisIndex: [0],
        width: 8,
        height: 280, // 组件高度
        left: 30, // 左边的距离
        bottom: 50, // 右边的距离
        handleColor: '#E9E9E9', // '#8C8A8B',//h滑动图标的颜色
        fillerColor: '#8C8A8B',
        borderColor: '#f1f1f1',
        backgroundColor: '#f5f5f5',
        handleStyle: {
          color: 'rgba(0, 0, 0, 0)',
          opacity: 0,
        },
        showDataShadow: false, // 是否显示数据阴影 默认auto
        showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
        handleIcon:
          'M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
        filterMode: 'filter',
      },
    ];
  }
  initChart() {
    const zoom = this.dataZoom;
    const length = this.seriesData.length;
    if (length > 10) {
      zoom[0].show = true;
      const num = Math.round((10 / length) * 10000) / 100.0;
      zoom[0].end = 100 - Math.floor(num);
    } else {
      zoom[0].show = false;
      zoom[0].start = 0;
    }
    const chart = this.$refs.buildingCharts;
    const myChart = echarts.init(chart);
    const option = {
      color: this.colorArr,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: params => {
          const d = params[0].data;
          const project = `${d.name}-${d.projectName}`;
          const com = d.tenantName;
          const curr = d.currentLayer;
          const total = d.totalLayer;
          return `<div style="width:300px;">
                    <div style="width: 300px;white-space: normal;word-break: break-all;">
                      ${project}<br />
                    </div>
                    <div style="width: 300px;white-space: normal;word-break: break-all;">
                      公司：${com}<br />
                    </div>
                    当前层数：${curr}<br />
                    总层数：${total}
                  </div>`;
        },
      },
      legend: {},
      grid: {
        left: 100,
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: 100,
        interval: 10,
        axisLabel: {
          formatter: '{value}%',
        },
      },
      yAxis: {
        type: 'category',
        data: this.legendData,
        axisLabel: {
          width: 100,
          overflow: 'truncate',
        },
      },
      dataZoom: zoom,
      // dataZoom: {
      //   type: 'inside',
      //   yAxisIndex: 0,
      //   start: 100,
      //   end: a,
      // },
      series: [
        {
          type: 'bar',
          barWidth: 20,
          data: this.seriesData,
        },
      ],
    };
    myChart.setOption(option);
  }
}
</script>

<style lang="less" module>
.divCard {
  height: 360px;
  box-shadow: 0 0 8px 0 #eeeeee;
  border-radius: 5px;
  position: relative;
  .head {
    z-index: 999;
    font-size: 14px;
    padding: 10px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .text {
      font-weight: 500;
    }
    .icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>
