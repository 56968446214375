import { ToolboxPanel } from '../ToolboxPanel';
import Icon from '!!svg-inline-loader!./font-family-panel-icon.svg';

/**
 * @typedef {function} FontChangeHandler
 * @param {string} newFont
 * @returns {void}
 */

/**
 * Font family selection toolbox panel.
 */
export class FontFamilyPanel extends ToolboxPanel {
  /** @type {string[]} */
  fonts = [];
  /** @type {string} */
  currentFont;

  /** @type {HTMLDivElement[]} */
  fontBoxes = [];

  /**
   * Handler for the font family change event.
   * @type {FontChangeHandler}
   */
  onFontChanged;

  /**
   * Creates a font family toolbox panel.
   * @param {string} title - panel title.
   * @param {string[],} fonts - available font families.
   * @param {string} currentFont - currently selected font family.
   * @param {string} icon - panel button icon (SVG image markup).
   * @param {string} id - panel ID.
   */
  constructor(title, fonts, currentFont, icon, id) {
    super(title, icon ? icon : Icon, id ? id : 'font-family-panel');
    this.fonts = fonts;
    this.currentFont = currentFont;

    this.setCurrentFont = this.setCurrentFont.bind(this);
  }

  /**
   * Returns panel UI.
   * @type {HTMLDivElement}
   */
  getUi() {
    const panelDiv = document.createElement('div');
    // panelDiv.style.display = 'flex';
    panelDiv.style.overflow = 'hidden';
    panelDiv.style.flexGrow = '2';
    this.fonts.forEach(font => {
      const fontBoxContainer = document.createElement('div');
      fontBoxContainer.style.display = 'inline-block';
      // fontBoxContainer.style.flexGrow = '2';
      fontBoxContainer.style.alignItems = 'center';
      fontBoxContainer.style.justifyContent = 'space-between';
      fontBoxContainer.style.padding = '5px';
      fontBoxContainer.style.borderWidth = '2px';
      fontBoxContainer.style.borderStyle = 'solid';
      fontBoxContainer.style.overflow = 'hidden';
      fontBoxContainer.style.maxWidth = `${100 / this.fonts.length - 5}%`;
      fontBoxContainer.style.borderColor =
        font === this.currentFont
          ? this.uiStyleSettings.toolboxAccentColor
          : 'transparent';

      fontBoxContainer.addEventListener('click', () => {
        this.setCurrentFont(font, fontBoxContainer);
      });
      panelDiv.appendChild(fontBoxContainer);

      const fontBox = document.createElement('div');
      fontBox.style.display = 'flex';
      fontBox.style.minHeight = '20px';
      fontBox.style.flexGrow = '2';
      fontBox.style.fontFamily = font;
      fontBox.style.overflow = 'hidden';

      const fontLabel = document.createElement('div');
      fontLabel.style.whiteSpace = 'nowrap';
      fontLabel.style.overflow = 'hidden';
      fontLabel.style.textOverflow = 'ellipsis';
      fontLabel.innerHTML = 'The quick brown fox jumps over the lazy dog';

      fontBox.appendChild(fontLabel);

      fontBoxContainer.appendChild(fontBox);

      this.fontBoxes.push(fontBoxContainer);
    });
    return panelDiv;
  }

  /**
   *
   * @param {string} newFont
   * @param {HTMLDivElement} target
   */
  setCurrentFont(newFont, target) {
    this.currentFont = newFont;

    this.fontBoxes.forEach(box => {
      box.style.borderColor =
        box === target
          ? this.uiStyleSettings.toolboxAccentColor
          : 'transparent';
    });

    if (this.onFontChanged) {
      this.onFontChanged(this.currentFont);
    }
  }
}
