<template>
  <div :class="$style.divCard">
    <div :class="$style.title">
      <span>{{ $t('generalView.count.areaDistribution') }}</span>
      <a-tooltip placement="right">
        <template slot="title">
          <span>{{ $t('generalView.count.areaDesc') }}</span>
        </template>
        <a-icon :class="$style.icon" type="info-circle" />
      </a-tooltip>
    </div>
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <no-data-chart
        font="250px"
        isIcon
        :style="{ height: '100%', width: '100%' }"
      />
    </div>
    <div v-else>
      <div ref="pieCharts" style="height: 400px; width: 660px" />
      <div v-if="showTop" :class="$style.rank">
        <a-row :class="$style.head">
          <a-col :span="18"> Top10 {{ $t('generalView.count.city') }} </a-col>
          <a-col :span="6">
            {{ $t('generalView.count.num') }}
          </a-col>
        </a-row>
        <a-col v-for="(item, idx) in topData" :key="idx" style="padding:0">
          <a-col :span="18">
            <a-col :span="10" :class="$style.hidden">
              <span :class="$style[`number-${idx}`]">No.{{ idx + 1 }}</span>
              <span style="margin-left: 5px" :title="item.city">
                {{ item.city }}
              </span>
            </a-col>
            <a-col :span="14">
              <a-progress :percent="item.percent" :show-info="false" />
            </a-col>
          </a-col>
          <a-col :span="6" style="padding:0"> {{ item.num }}套 </a-col>
        </a-col>
      </div>
      <div v-else :class="$style.rank">
        <a-row :class="$style.head">
          <a-col :span="18">
            {{ $t('generalView.count.project') }}
          </a-col>
          <a-col :span="6">
            {{ $t('generalView.count.num') }}
          </a-col>
        </a-row>
        <div style="height: 260px;overflow-y: auto">
          <a-col
            v-for="(item, idx) in showProjectData"
            :key="idx"
            style="padding:0"
          >
            <a-col
              :span="18"
              :class="$style.hiddenTetx"
              style="margin-bottom: 5px"
            >
              <span>{{ idx + 1 }}.</span>
              <span :title="item.name" style="margin-left: 5px">
                {{ item.name }}
              </span>
            </a-col>
            <a-col :span="6" style="padding:0"> {{ item.num }}套 </a-col>
          </a-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { buildingDeploy } from '@/services/iot-electric-box/device-status';

@Component({})
export default class BuildingDeploy extends Vue {
  @Prop({ type: String, default: '' }) tenantId;

  showTop = true;
  isNoData = true;
  mounted() {
    this.getList();
  }
  topData = [];
  seriesData = [];
  projectData = [];
  showProjectData = [];
  async getList() {
    try {
      const { top10City, monthStat } = await buildingDeploy();
      if (top10City.length > 0 && monthStat.length > 0) {
        this.isNoData = false;
        const top10CityNum = this.getTotal(top10City, 1);
        top10City.forEach(item => {
          const o = {};
          o.city = item.cityName;
          o.percent = parseFloat((item.total / top10CityNum).toFixed(2)) * 100;
          o.num = item.total;
          this.topData.push(o);
        });
        const result = this.getTotal(monthStat, 2);
        this.seriesData = result.seriesData;
        this.projectData = result.projectData;
        this.$nextTick(() => {
          this.initChart();
        });
      } else {
        this.isNoData = true;
      }
    } catch {
      return false;
    }
  }
  getTotal(arr, flag) {
    if (arr.length === 0) return 0;
    if (flag === 1) {
      let sum = 0;
      arr.forEach(item => {
        sum += item.total;
      });
      return sum;
    } else {
      const result = {
        seriesData: [],
        projectData: {},
      };
      arr.forEach(item => {
        let sum = 0;
        const arrs = [];
        const o = {};
        o.name = item.month;
        item.projectStatList.forEach(item1 => {
          const o = {};
          o.name = item1.projectName;
          o.num = item1.total;
          arrs.push(o);
          sum += item1.total;
        });
        o.value = sum;
        result.seriesData.push(o);
        result.projectData[item.month] = arrs;
      });
      return result;
    }
  }
  colorArr = ['#947dfa', '#00b893', '#fe5959', '#ffa348', '#516cf0', '#ffbf07'];
  currentPie = null;
  initChart() {
    const chart = this.$refs.pieCharts;
    const myChart = echarts.init(chart);
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}月: {c}套({d}%)',
      },
      color: this.colorArr,
      series: [
        {
          name: '楼栋部署',
          type: 'pie',
          center: ['30%', '50%'],
          radius: '70%',
          avoidLabelOverlap: false,
          selectedMode: 'single',
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 2,
          },
          emphasis: {
            scaleSize: 12,
          },
          label: {
            show: false,
            position: 'center',
          },
          labelLine: {
            show: false,
          },
          data: this.seriesData,
        },
      ],
    };
    myChart.setOption(option);
    myChart.on('click', params => {
      const index = params.data.name;
      if (this.showTop) {
        this.showTop = false;
      } else {
        this.showTop = this.currentPie === index;
      }
      this.currentPie = index;
      this.showProjectData = this.projectData[index];
    });
  }
}
</script>

<style lang="less" module>
.divCard {
  height: 360px;
  box-shadow: 0 0 8px 0 #eeeeee;
  border-radius: 5px;
  position: relative;
  .title {
    z-index: 999;
    font-size: 14px;
    margin: 10px;
    position: absolute;
    font-weight: 500;
    .icon {
      cursor: pointer;
    }
  }
  .rank {
    z-index: 999;
    position: absolute;
    right: 30px;
    top: 50px;
    width: 350px;
    .head {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
  .hidden {
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  .number-0 {
    color: #ff4848;
  }
  .number-1 {
    color: #ff7f31;
  }
  .number-2 {
    color: #ffc916;
  }
  .hiddenTetx {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
