<template>
  <div :class="$style.model">
    <template v-if="detail.deviceId">
      <person-info :class="$style.left" />
      <div :class="$style.right">
        <mini-map v-if="showMiniMap" :center="center" :record="detail" />
        <a-tabs v-else v-model="activeKey">
          <a-tab-pane key="status" :tab="$t('hat.broadcast.status')">
            <Status />
          </a-tab-pane>
          <a-tab-pane
            key="event"
            :tab="$t('hat.managementModal.event.tagName')"
          >
            <Events />
          </a-tab-pane>
          <a-tab-pane
            key="tract"
            :tab="$t('hat.managementModal.trajectory.tagName')"
          >
            <Trajectory v-if="activeKey === 'tract'" :record="detail" />
          </a-tab-pane>
          <a-tab-pane
            key="file"
            v-if="detail.type === 'PLUS'"
            :tab="$t('hat.managementModal.file.tagName')"
          >
            <File />
          </a-tab-pane>
          <!-- <a-tab-pane key="traffic" tab="流量">
          <Traffic />
        </a-tab-pane> -->
          <a-tab-pane
            key="setting"
            :tab="$t('hat.managementModal.setting.tagName')"
          >
            <Setting />
          </a-tab-pane>
        </a-tabs>
      </div>
    </template>
  </div>
</template>

<script>
import {
  Component,
  Vue,
  Prop,
  Provide,
  ProvideReactive,
} from 'vue-property-decorator';
import { getDeviceDetail } from '@/services/smart-hat/device';
import Status from '@/views/hat/device/manage-model/status';
import File from '@/views/hat/device/manage-model/file/index.vue';
import Setting from './setting';
import Events from './events';
// import Traffic from './traffic';
import Trajectory from './trajectory.vue';
import PersonInfo from './person-info/person-info.vue';
import MiniMap from './mini-map.vue';
import { globalSocket } from '@triascloud/message-hub';
import { HatSocket } from '@/enum/socket';

@Component({
  components: {
    Status,
    File,
    Setting,
    Events,
    // Traffic,
    Trajectory,
    PersonInfo,
    MiniMap,
  },
})
export default class ManageModel extends Vue {
  @ProvideReactive('DEVICE_RECORD')
  @Prop({ type: Object, default: () => {} })
  record;
  @Prop({ type: String, default: 'status' })
  defaultActiveKey;

  async mounted() {
    this.activeKey = this.defaultActiveKey;
    await this.getDeviceDetail();
    this.initSocket();
  }

  beforeDestroy() {
    globalSocket.off(HatSocket.WearingStatus, this.getSocketEvent);
    globalSocket.off(HatSocket.AccelerometerStatus, this.getSocketEvent);
    globalSocket.off(HatSocket.OnlineStatus, this.getSocketEvent);
    globalSocket.off(HatSocket.Position, this.getSocketEvent);
  }

  @ProvideReactive('deviceDetail')
  detail = {};

  @Provide('UPDATE_DEVICE_DETAIL')
  async getDeviceDetail() {
    this.detail = await getDeviceDetail(this.record.deviceId);
  }
  activeKey = 'status';

  showMiniMap = false;

  center = [];

  @Provide('CHANGE_MINI_MAP')
  changeMiniMapStatus(value, center = undefined) {
    this.center = center;
    this.showMiniMap = value;
  }

  @ProvideReactive('eventData')
  eventData = {};

  getSocketEvent(data) {
    if (data.data.deviceId === this.detail.deviceId) {
      this.eventData = data;
      if (data.event === 'ONLINE_STATUS') {
        this.detail.status = data.data.status;
        this.detail.lastOnlineTime = data.data.updateTime;
      } else if (data.event === 'POSITION') {
        this.detail.latitude = data.data.lngLat.y;
        this.detail.longitude = data.data.lngLat.x;
      }
    }
  }

  initSocket() {
    globalSocket.on(HatSocket.WearingStatus, this.getSocketEvent);
    globalSocket.on(HatSocket.AccelerometerStatus, this.getSocketEvent);
    globalSocket.on(HatSocket.OnlineStatus, this.getSocketEvent);
    globalSocket.on(HatSocket.Position, this.getSocketEvent);
  }
}
</script>

<style lang="less" module>
.model {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 5px 0px 0 5px;
  height: 560px;
  .left {
    width: 340px;
    height: 545px;
  }
  .right {
    width: 895px;
    height: 550px;
    padding-left: 20px;
  }
  .card {
    box-shadow: 0 0 6px 0 #e1e1e1;
    border-radius: 6px;
  }
  .textOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
