<template>
  <div :class="$style.settingForm">
    <a-form-model ref="ruleForm" :modle="form">
      <a-form-model-item label="规则组名称" prop="name">
        <a-input
          v-model="form.name"
          placeholder="请输入名称"
          style="width: 300px"
        />
        <span :class="$style.manage">管理</span>
      </a-form-model-item>
      <a-form-model-item label="当前规则配置" prop="setting">
        <a-textarea v-model="form.setting" :rows="5" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="备注说明" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入名称" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SettingForm extends Vue {
  form = {
    name: '',
    setting: '',
    remark: '',
  };
}
</script>

<style lang="less" module>
.settingForm {
  .manage {
    margin-left: 10px;
    cursor: pointer;
    color: var(--primary);
  }
}
</style>
