// eslint-disable-next-line no-unused-vars
import { Settings } from '../../core/Settings';
import { SvgHelper } from '../../core/SvgHelper';
// eslint-disable-next-line no-unused-vars
import { ToolboxPanel } from '../../ui/ToolboxPanel';
import { ColorPickerPanel } from '../../ui/toolbox-panels/ColorPickerPanel';
import { LineStylePanel } from '../../ui/toolbox-panels/LineStylePanel';
import { LineWidthPanel } from '../../ui/toolbox-panels/LineWidthPanel';
import { PolyLinearMarkerBase } from '../PolyLinearMarkerBase';
import Icon from '!!svg-inline-loader!./polyline-marker-icon.svg';
import { OpacityPanel } from '../../ui/toolbox-panels/OpacityPanel';
import { dashToNumber, pointToObjectList } from '../../core/Utils';
import { merge } from 'lodash';

export class PolylineMarker extends PolyLinearMarkerBase {
  /**
   * String type name of the marker type.
   *
   * Used when adding {@link MarkerArea.availableMarkerTypes} via a string and to save and restore state.
   */
  static typeName = 'PolylineMarker';
  static type = 'POLYLINE';

  /**
   * Marker type title (display name) used for accessibility and other attributes.
   */
  static title = 'Polyline marker';
  /**
   * SVG icon markup displayed on toolbar buttons.
   */
  static icon = Icon;

  /**
   * Invisible wider Polyline to make selection easier/possible.
   * @type {SVGPolylineElement}
   */
  selectorLine;
  /**
   * Visible marker Polyline.
   * @type {SVGPolylineElement}
   */
  visibleLine;

  /**
   * Line color.
   */
  strokeColor = 'transparent';
  /**
   * Line width.
   */
  strokeWidth = 0;
  /**
   * Line dash array.
   */
  strokeDasharray = '';
  opacity = 1;

  /**
   * Color pickar panel for line color.
   * @type {ColorPickerPanel}
   */
  strokePanel;
  /**
   * Line width toolbox panel.
   * @type {LineWidthPanel}
   */
  strokeWidthPanel;
  /**
   * Line dash array toolbox panel.
   * @type {LineStylePanel}
   */
  strokeStylePanel;
  /**
   * Line dash array toolbox panel.
   * @type {OpacityPanel}
   */
  opacityPanel;

  /**
   *
   * @param {SVGGElement} container
   * @param {HTMLDivElement} overlayContainer
   * @param {Settings} settings
   */
  constructor(container, overlayContainer, settings, opts = {}) {
    super(container, overlayContainer, settings);

    this.setStrokeColor = this.setStrokeColor.bind(this);
    this.setStrokeWidth = this.setStrokeWidth.bind(this);
    this.setStrokeDasharray = this.setStrokeDasharray.bind(this);
    this.setOpacity = this.setOpacity.bind(this);

    this.strokeColor = settings.defaultColor;
    this.strokeWidth = settings.defaultStrokeWidth;
    this.strokeDasharray = settings.defaultStrokeDasharray;

    if (opts && opts.defaultColor) {
      this.strokeColor = opts.defaultColor;
    }

    this.strokePanel = new ColorPickerPanel(
      'Line color',
      settings.defaultColorSet,
      settings.defaultColor,
    );
    this.strokePanel.onColorChanged = this.setStrokeColor;

    this.strokeWidthPanel = new LineWidthPanel(
      'Line width',
      settings.defaultStrokeWidths,
      settings.defaultStrokeWidth,
    );
    this.strokeWidthPanel.onWidthChanged = this.setStrokeWidth;

    this.strokeStylePanel = new LineStylePanel(
      'Line style',
      settings.defaultStrokeDasharrays,
      settings.defaultStrokeDasharray,
    );
    this.strokeStylePanel.onStyleChanged = this.setStrokeDasharray;

    this.opacityPanel = new OpacityPanel(
      'Opacity',
      settings.defaultOpacitySteps,
      this.opacity,
    );
    this.opacityPanel.onOpacityChanged = this.setOpacity;
  }

  /**
   *
   * @param {EventTarget} el
   * @returns {boolean}
   */
  ownsTarget(el) {
    if (
      super.ownsTarget(el) ||
      el === this.visual ||
      el === this.selectorLine ||
      el === this.visibleLine ||
      // @ts-ignore
      this.visibleLine
        .getAttribute('points')
        .includes(el.getAttribute('points'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param {import("../../core/MarkerBase").IPoint[]} points
   * @returns {string}
   */
  getPointsToString(points) {
    let str = '';
    points.forEach(point => {
      str += `${point.x},${point.y} `;
    });
    if (str.length > 0) {
      str = str.slice(0, str.length - 1);
    }
    return str;
  }

  createVisual() {
    if (this.visual) return;
    this.visual = SvgHelper.createGroup();
    const str = this.getPointsToString(this.points);
    this.selectorLine = SvgHelper.createPolyline(str, [
      ['fill', 'none'],
      ['stroke', 'transparent'],
      ['stroke-width', (this.strokeWidth + 10).toString()],
    ]);
    this.visibleLine = SvgHelper.createPolyline(str, [
      ['stroke', this.strokeColor],
      ['fill', 'none'],
      ['stroke-width', this.strokeWidth.toString()],
    ]);

    this.visual.appendChild(this.selectorLine);
    this.visual.appendChild(this.visibleLine);

    this.addMarkerVisualToContainer(this.visual);
  }

  /**
   * Sets line color.
   * @param color - new color.
   */
  setStrokeColor(color) {
    this.strokeColor = color;
    this.adjustVisual();
    this.colorChanged(color);
  }
  /**
   * Sets line width.
   * @param width - new width.
   */
  setStrokeWidth(width) {
    this.strokeWidth = width;
    this.adjustVisual();
  }

  /**
   * Sets line dash array.
   * @param dashes - new dash array.
   */
  setStrokeDasharray(dashes) {
    this.strokeDasharray = dashes;
    this.adjustVisual();
    this.stateChanged();
  }

  /**
   * Sets marker's opacity.
   * @param opacity - new opacity value (0..1).
   */
  setOpacity(opacity) {
    this.opacity = opacity;
    if (this.container) {
      SvgHelper.setAttributes(this.container, [
        ['opacity', this.opacity.toString()],
      ]);
    }
    this.stateChanged();
  }

  /**
   * Returns the list of toolbox panels for this marker type.
   * @returns {ToolboxPanel[]}
   */
  get toolboxPanels() {
    return [this.strokePanel, this.strokeWidthPanel, this.strokeStylePanel];
  }

  /**
   * Returns current marker state that can be restored in the future.
   */
  getState() {
    const result = merge(
      {
        attributes: {
          strokeColor: this.strokeColor,
          strokeDasharray: this.strokeDasharray,
          border: this.strokeWidth,
          transparency: this.opacity,
          lineformat: dashToNumber(this.strokeDasharray),
        },
      },
      super.getState(),
    );
    result.typeName = PolylineMarker.typeName;
    result.type = PolylineMarker.type;

    return result;
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) down event.
   *
   * @param {import("../../core/MarkerBase").IPoint} point - event coordinates.
   * @param {EventTarget} target - direct event target element.
   */
  pointerDown(point, target) {
    super.pointerDown(point, target);
    if (this.state === 'new') {
      this.createVisual();
      this.adjustVisual();
    }
  }

  // /**
  //  * 结束线段的绘制
  //  * @param {import("../../core/MarkerBase").IPoint} point
  //  * @param {EventTarget} target
  //  */
  // dblClick(point, target) {
  //   super.dblClick(point, target);
  // }

  /**
   * Adjusts visual after manipulation.
   */
  adjustVisual() {
    if (this.selectorLine && this.visibleLine) {
      const str = this.getPointsToString(this.points);

      this.selectorLine.setAttribute('points', str);

      this.visibleLine.setAttribute('points', str);

      SvgHelper.setAttributes(this.visibleLine, [['stroke', this.strokeColor]]);
      SvgHelper.setAttributes(this.visibleLine, [
        ['stroke-width', this.strokeWidth.toString()],
      ]);
      SvgHelper.setAttributes(this.visibleLine, [
        ['stroke-dasharray', this.strokeDasharray.toString()],
      ]);
    }
  }

  /**
   * Restores previously saved marker state.
   *
   * @param state - previously saved state.
   */
  restoreState(state) {
    super.restoreState(state);

    const lmState = state;
    this.strokeColor = lmState.attributes.strokeColor;
    this.strokeWidth = lmState.attributes.border;
    this.strokeDasharray = lmState.attributes.strokeDasharray;
    this.opacity = lmState.attributes.transparency;
    this.points = pointToObjectList(lmState.points);
    this.oldPoints = [];
    lmState.points.forEach(p => {
      this.oldPoints.push(JSON.parse(JSON.stringify(p)));
    });

    this.addControlGrips();
    this.createVisual();
    this.created = true;
    this.adjustVisual();
  }
}
