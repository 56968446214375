import dayjs from 'dayjs';

export function timeFormat(current, step = 0) {
  return current.currentRange === 'CUSTOMIZE' && current.customTime.length
    ? dayjs(current.customTime[step] + ((step && ' 23:59:59') || '')).valueOf()
    : '';
}

export function rangeFormat(current) {
  return current.customTime.length > 0 && !current.currentRange
    ? 'CUSTOMIZE'
    : current.currentRange;
}
