import Vue from 'vue';
import Vuex from 'vuex';
import { createCrossStorageVuexPlugin } from '@triascloud/cross-storage';
import { safeParseJSON, Emitter } from '@/utils';
import screen from '@/views/iot/screen/store.js';
import generalScreen from '@/views/iot/general-screen/store';
import TenantChange from '@/components/tenant-change';
import home from './modules/home.js';
import global from './modules/global.js';
import project from './modules/project.js';
import monitor from './modules/monitor.js';
import shareScreen from './modules/share-screen.js';
import oven from '@/views/oven-control/store';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    screen,
    home,
    generalScreen,
    global,
    project,
    shareScreen,
    oven,
    monitor,
  },
  plugins: [
    createCrossStorageVuexPlugin({
      keys: {
        user: json => safeParseJSON(json, {}),
        skin: json => safeParseJSON(json, { theme: 'cyan', mode: 'tint' }),
        locale: val => val || 'zh_CN',
        tenant: json => safeParseJSON(json, null),
      },
      filter: ({ key, value }, initiative) => {
        // 如果是在当前窗口主动触发更新，则永远成功
        if (initiative || key !== 'tenant') return true;
        const oldTenant = store.state.crossStorage.tenant;
        const newTenant = safeParseJSON(value, null);
        if (!oldTenant || !newTenant || oldTenant.pkId === newTenant.pkId)
          return true;
        TenantChange.create(oldTenant);
        Emitter.$emit('Tenant_Change');
        return false;
      },
    }),
  ],
});

export default store;
