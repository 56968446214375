<template>
  <form-tab :tabs="upTabs">
    <section slot="form0">
      <a-select
        :value="form.projectId"
        option-filter-prop="children"
        allowClear
        show-search
        :getPopupContainer="triggerNode => triggerNode.parentNode"
        :placeholder="$t('generalView.projects')"
        @change="handleProjectChange"
        @search="input => filterProjectOption(input)"
      >
        <a-select-option
          v-for="item in projectList"
          :key="item.projectId"
          :value="item.projectId"
          >{{ item.projectName }}</a-select-option
        >
        <template slot="notFoundContent">
          <a-empty :image="simpleImage" />
        </template>
      </a-select>
      <a-select
        :value="form.operationType"
        @change="handOperationTypeChange"
        :getPopupContainer="triggerNode => triggerNode.parentNode"
        :placeholder="$t('generalView.operationType')"
      >
        <a-select-option
          v-for="item in operationTypeList"
          :key="item.value"
          :value="item.value"
          >{{ item.label }}</a-select-option
        >
      </a-select>
    </section>
    <div slot="value0">
      <a-table
        :columns="operationColumns"
        :rowKey="row => `${row.projectId}${row.buildingId}index`"
        :data-source="operationList"
        :pagination="pagination"
        @change="handlePagination"
        :scroll="{ y: 410 }"
      >
        <span
          slot="countColumn"
          slot-scope="item, record"
          @click.stop="handleCount(record)"
          style="cursor:pointer"
        >
          {{ item }}
          <a-icon type="double-right" />
        </span>
        <template slot="projectName" slot-scope="text, record">
          <span
            :title="text"
            style="cursor: pointer"
            @click.stop="handleLinkProject(record.projectId)"
          >
            {{ text }}
          </span>
        </template>
      </a-table>
    </div>
  </form-tab>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import { createModal } from '@triascloud/x-components';
import OperationDetail from './operation-detail.vue';
import {
  getOperationModeList,
  getOperationModeForType,
} from '@/services/iot-electric-box/api';
import { getRelatedProject } from '@/services/things/project';
import { timeFormat, rangeFormat } from './format';
import { handleLink } from './link';
import { Empty } from 'ant-design-vue';

@Component({})
export default class Operation extends Vue {
  @Prop({ type: String, default: '' }) currentRange;
  @Prop({ type: Array, default: () => [] }) customTime;

  @Watch('customTime')
  async onCustomTimeChange() {
    await this.initChange();
    await this.initMethod();
  }
  @Watch('currentRange')
  async onCurrentRangeChange() {
    await this.initChange();
    await this.initMethod();
  }
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }

  mounted() {
    this.initMethod();
  }

  initChange() {
    this.form = {
      projectId: undefined,
      operationType: '',
    };
    this.pagination.current = 1;
  }

  initMethod() {
    this.fetchTypeData();
    this.fetchListData();
    this.fetchProjectData();
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: total => this.$t('todo.flow.operationTotal', { total }),
  };
  handlePagination(data) {
    this.pagination = data;
    this.fetchListData();
  }
  form = {
    projectId: undefined,
    operationType: '',
  };
  projectList = [];
  projectSearchInput = '';
  async fetchProjectData() {
    const params = {};
    if (this.projectSearchInput) {
      params.keyword = this.projectSearchInput;
    } else {
      params['query.size'] = 30;
    }
    this.projectList = await getRelatedProject(params);
  }
  async handleProjectChange(value) {
    this.form.projectId = value;
    if (!value) {
      this.projectSearchInput = '';
      await this.fetchProjectData();
    }
    await this.fetchListData();
  }
  @Debounce(300)
  filterProjectOption(input) {
    this.projectSearchInput = input;
    this.fetchProjectData();
  }

  upTabs = [
    {
      label: this.$t('generalView.operationType'),
    },
  ];
  operationColumns = [
    {
      title: this.$t('generalView.table.buildings'),
      dataIndex: 'projectName',
      ellipsis: true,
      scopedSlots: { customRender: 'projectName' },
    },
    {
      title: this.$t('generalView.table.companies'),
      dataIndex: 'tenantName',
      ellipsis: true,
    },
    {
      title: this.$t('generalView.table.count'),
      dataIndex: 'count',
      scopedSlots: { customRender: 'countColumn' },
    },
  ];

  handleCount(item) {
    const params = {
      dateRangeType: rangeFormat(this),
      startTime: timeFormat(this, 0),
      endTime: timeFormat(this, 1),
      operationModel: this.form.operationType,
      idxProjectId: this.form.projectId,
    };
    createModal(() => <OperationDetail data={item} params={params} />, {
      title: this.$t('generalView.details'),
      width: 900,
    });
  }

  operationTypeList = [];
  async fetchTypeData() {
    const TypeKey = {
      RISE: this.$t('generalView.key.rise'),
      DECLINE: this.$t('generalView.key.down'),
      STOP: this.$t('generalView.key.stop'),
    };

    try {
      const types = await getOperationModeForType();
      this.operationTypeList = types.reduce(
        (arr, next) => {
          if (TypeKey[next]) {
            arr.push({
              value: next,
              label: TypeKey[next],
            });
          }
          return arr;
        },
        [
          {
            value: '',
            label: this.$t('iotScreenManage.all'),
          },
        ],
      );
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  handOperationTypeChange(value) {
    this.form.operationType = value;
    this.fetchListData();
  }

  operationList = [];
  page = {
    size: 10,
    number: 1,
    total: 0,
  };
  async fetchListData() {
    const { records, current, size, total } = await getOperationModeList({
      operationModel: this.form.operationType,
      idxProjectId: this.form.projectId,
      dateRangeType: rangeFormat(this),
      startTime: timeFormat(this, 0),
      endTime: timeFormat(this, 1),
      pageNum: this.pagination.current,
      pageSize: this.pagination.pageSize,
    });
    this.operationList = records.map(record => {
      return {
        ...record,
        projectName: record.buildingName
          ? `${record.projectName}(${record.buildingName})`
          : `${record.projectName}`,
      };
    });
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
  }

  @Debounce(500)
  async handleLinkProject(record) {
    await handleLink(record);
  }
}
</script>
<style lang="less" module>
:global {
  .ant-select {
    width: 120px;
  }
  .ant-select + .ant-select {
    margin-left: 10px;
  }
}
</style>
