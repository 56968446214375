/**
 * @typedef IPoint
 * @property {Number} x
 * @property {Number} y
 */

import { uuid } from '@triascloud/utils';
import { SvgHelper } from './SvgHelper';

/**
 * Base class for all available and custom marker types.
 *
 * All markers used with marker.js 2 should be descendants of this class.
 */
export class MarkerBase {
  /**
   * String type name of the marker type.
   *
   * Used when adding {@link MarkerArea.availableMarkerTypes} via a string and to save and restore state.
   */
  static typeName = 'MarkerBase';

  static type = 'MARKER';

  /**
   * Instance property returning marker's type name.
   *
   * @since 2.16.0
   */
  get typeName() {
    return Object.getPrototypeOf(this).constructor.typeName;
  }

  get type() {
    return Object.getPrototypeOf(this).constructor.type;
  }

  _container;
  /**
   * SVG container object holding the marker's visual.
   */
  get container() {
    return this._container;
  }
  _overlayContainer;
  /**
   * HTML container that can be used to render overlay objects while the marker is active.
   *
   * For example, this is used for the text editing layer while editing text in the {@see TextMarker}.
   */
  get overlayContainer() {
    return this._overlayContainer;
  }
  // MarkerState = 'new' | 'creating' | 'select' | 'move' | 'resize' | 'rotate' | 'edit';
  _state = 'new';
  /**
   * Current marker state.
   *
   * Both MarkerArea and the marker itself can react differently to different events based on what state the marker is in.
   */
  get state() {
    return this._state;
  }
  globalSettings;

  /**
   * Additional information about the marker
   */
  notes;

  /**
   * Returns the list of toolbox panels for this marker type.
   */
  get toolboxPanels() {
    return [];
  }

  /**
   * Marker type title (display name) used for accessibility and other attributes.
   */
  static title;

  /**
   * SVG icon markup displayed on toolbar buttons.
   */
  static icon;

  tagGroupID = '';

  idxTagId = '';
  tagName = '';

  probability = 100;

  sort = 1;

  remark = '';

  rotation = 0;

  pkId = '';

  uuid = '';

  /**
   * Method called when marker creation is finished.
   */
  // eslint-disable-next-line no-unused-vars
  onMarkerCreated = marker => {};

  /**
   * Method to call when foreground color changes.
   */
  // eslint-disable-next-line no-unused-vars
  onColorChanged = color => {};
  /**
   * Method to call when background/fill color changes.
   */
  // eslint-disable-next-line no-unused-vars
  onFillColorChanged = color => {};
  /**
   * Method to call when marker state changes.
   *
   * @since 2.23.0
   */
  // eslint-disable-next-line no-unused-vars
  onStateChanged = marker => {};

  /**
   * Marker's state when it is selected
   *
   * @since 2.23.0
   */
  manipulationStartState;

  /**
   * Creates a new marker.
   *
   * @param container - SVG container to hold marker's visual.
   * @param overlayContainer - overlay HTML container to hold additional overlay elements while editing.
   * @param settings - settings object containing default markers settings.
   */
  constructor(container, overlayContainer, settings) {
    this._container = container;
    this._overlayContainer = overlayContainer;
    this.globalSettings = settings;
    this.uuid = uuid();

    this.stateChanged = this.stateChanged.bind(this);
    this.colorChanged = this.colorChanged.bind(this);
    this.fillColorChanged = this.fillColorChanged.bind(this);
  }

  /**
   * Returns true if passed SVG element belongs to the marker. False otherwise.
   *
   * @param { EventTarget } el - target element.
   */
  // eslint-disable-next-line no-unused-vars
  ownsTarget(el) {
    return false;
  }

  /**
   * Is this marker selected?
   *
   * @since 2.16.0
   */
  _isSelected = false;

  /**
   * Returns true if the marker is currently selected
   *
   * @since 2.16.0
   */
  get isSelected() {
    return this._isSelected;
  }

  /**
   * Selects this marker and displays appropriate selected marker UI.
   */
  select() {
    this.container.style.cursor = 'move';
    this._isSelected = true;
    this.manipulationStartState = this.getState();
  }

  /**
   * Deselects this marker and hides selected marker UI.
   */
  deselect() {
    this.container.style.cursor = 'default';
    this._isSelected = false;
    this.stateChanged();
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) down event.
   *
   * @param { IPoint } point - event coordinates.
   * @param { EventTarget } _target - direct event target element.
   */
  // eslint-disable-next-line no-unused-vars
  pointerDown(point, _target = undefined) {}

  /**
   * Handles pointer (mouse, touch, stylus, etc.) double click event.
   *
   * @param { IPoint } point - event coordinates.
   * @param { EventTarget } target - direct event target element.
   */
  // eslint-disable-next-line no-unused-vars
  dblClick(point, target = undefined) {}

  /**
   * Handles marker manipulation (move, resize, rotate, etc.).
   *
   * @param { IPoint } point - event coordinates.
   */
  // eslint-disable-next-line no-unused-vars
  manipulate(point) {}

  /**
   * Handles pointer (mouse, touch, stylus, etc.) up event.
   *
   * @param { IPoint } point - event coordinates.
   */
  // eslint-disable-next-line no-unused-vars
  pointerUp(point) {
    this.stateChanged();
  }

  /**
   * Disposes the marker and clean's up.
   */
  // eslint-disable-next-line no-unused-vars
  dispose() {}

  addMarkerVisualToContainer(element) {
    if (this.container.childNodes.length > 0) {
      this.container.insertBefore(element, this.container.childNodes[0]);
    } else {
      this.container.appendChild(element);
    }
  }

  hoverLeft = 0;
  hoverTop = 0;
  hoverWidth = 0;
  hoverHeight = 0;
  hoverRectElement = null;
  createHoverRect() {
    if (this.hoverRectElement) return;
    this.hoverRectElement = SvgHelper.createRect(
      this.hoverWidth,
      this.hoverHeight,
      [
        ['x', this.hoverLeft.toString()],
        ['y', this.hoverTop.toString()],
        ['fill', 'transparent'],
        ['stroke', 'rgba(255, 255, 255, 0.5)'],
        ['stroke-width', '1'],
        ['stroke-dasharray', '9 6 3 6'],
        ['stroke-linecap', 'round'],
      ],
    );

    this.container.appendChild(this.hoverRectElement);
  }
  removeHoverRect() {
    if (this.hoverRectElement) {
      this.container.removeChild(this.hoverRectElement);
      this.hoverRectElement = null;
    }
  }

  remarkFlag = false;
  createRemark() {
    if (!this.remarkFlag) {
      this.remarkFlag = true;
    }
  }
  removeRemark() {
    if (this.remarkFlag) {
      this.remarkFlag = false;
    }
  }

  /**
   * Returns current marker state that can be restored in the future.
   */
  getState() {
    const object = {
      typeName: MarkerBase.typeName,
      type: MarkerBase.type,
      idxTagId: this.idxTagId,
      tagGroupID: this.tagGroupID,
      probability: this.probability,
      sort: this.sort,
      rotation: this.rotation,
      remark: this.remark,
      attributes: {
        state: this.state,
      },
    };
    if (this.pkId) {
      object['pkId'] = this.pkId;
    }
    return object;
  }

  /**
   * Restores previously saved marker state.
   *
   * @param state - previously saved state.
   */
  restoreState(state) {
    this._state = state.attributes.state;
    this.probability = state.probability;
    this.idxTagId = state.idxTagId;
    this.tagGroupID = state.tagGroupID;
    this.sort = state.sort;
    this.remark = state.remark;
    if (state.pkId) {
      this.pkId = state.pkId;
    }
    if (!this.uuid) {
      this.uuid = uuid();
    }
    if (state.tagName) {
      this.tagName = state.tagName;
    }
  }

  /**
   * Scales marker. Used after the image resize.
   *
   * @param {number} scaleX - horizontal scale
   * @param {number} scaleY - vertical scale
   */
  // eslint-disable-next-line no-unused-vars
  scale(scaleX, scaleY) {}

  /**
   * Called by a marker when its foreground color changes.
   * @param color
   */
  colorChanged(color) {
    if (this.onColorChanged) {
      this.onColorChanged(color);
    }
    this.stateChanged();
  }
  /**
   * Called by a marker when its background/fill color changes.
   * @param color
   */
  fillColorChanged(color) {
    if (this.onFillColorChanged) {
      this.onFillColorChanged(color);
    }
    this.stateChanged();
  }

  /**
   * Called by a marker when its state could have changed.
   * Does a check if the state has indeed changed before firing the handler.
   *
   * @since 2.23.0
   */
  stateChanged() {
    if (
      this.onStateChanged &&
      this.state !== 'creating' &&
      this.state !== 'new'
    ) {
      const currentState = this.getState();
      // avoid reacting to state (mode) differences
      if (this.manipulationStartState !== undefined) {
        this.manipulationStartState.state = 'select';
      }
      currentState.state = 'select';
      if (
        JSON.stringify(this.manipulationStartState) !=
        JSON.stringify(currentState)
      ) {
        this.onStateChanged(this);
      }
    }
  }
}
