import { ToolboxPanel } from '../ToolboxPanel';
import Icon from '!!svg-inline-loader!./opacity-panel-icon.svg';

/**
 * @typedef {function} OpacityChangeHandler
 * @param {number} newOpacity
 * @returns {void}
 */
/**
 * Opacity panel.
 */
export class OpacityPanel extends ToolboxPanel {
  /** @type {number[]} */
  opacities = [];
  /** @type {number} */
  currentOpacity;

  /** @type {HTMLDivElement[]} */
  opacityBoxes = [];

  /**
   * Opacity change event handler.
   * @type {OpacityChangeHandler}
   */
  onOpacityChanged;

  /**
   * Creates an opacity panel.
   * @param {string} title - panel title.
   * @param {number[]} opacities - available opacities.
   * @param {number} currentOpacity - current opacity.
   * @param {string} icon - toolbox panel button (SVG image markup).
   * @param {string} id - panel ID.
   */
  constructor(title, opacities, currentOpacity, icon, id) {
    super(title, icon ? icon : Icon, id ? id : 'opacity-panel');
    this.opacities = opacities;
    this.currentOpacity = currentOpacity;

    this.setCurrentOpacity = this.setCurrentOpacity.bind(this);
  }

  /**
   * Returns panel UI.
   * @returns {HTMLDivElement}
   */
  getUi() {
    const panelDiv = document.createElement('div');
    panelDiv.style.display = 'flex';
    panelDiv.style.overflow = 'hidden';
    panelDiv.style.flexGrow = '2';
    panelDiv.style.justifyContent = 'space-between';
    this.opacities.forEach(opacity => {
      const opacityBoxContainer = document.createElement('div');
      opacityBoxContainer.style.display = 'flex';
      //opacityBoxContainer.style.flexGrow = '2';
      opacityBoxContainer.style.alignItems = 'center';
      opacityBoxContainer.style.justifyContent = 'center';
      opacityBoxContainer.style.padding = '5px';
      opacityBoxContainer.style.borderWidth = '2px';
      opacityBoxContainer.style.borderStyle = 'solid';
      opacityBoxContainer.style.borderColor =
        opacity === this.currentOpacity
          ? this.uiStyleSettings.toolboxAccentColor
          : 'transparent';

      opacityBoxContainer.addEventListener('click', () => {
        this.setCurrentOpacity(opacity, opacityBoxContainer);
      });
      panelDiv.appendChild(opacityBoxContainer);

      const label = document.createElement('div');
      label.innerText = `${opacity * 100}%`;
      opacityBoxContainer.appendChild(label);

      this.opacityBoxes.push(opacityBoxContainer);
    });
    return panelDiv;
  }
  /**
   *
   * @param {number} newWidth
   * @param {HTMLDivElement} target
   */
  setCurrentOpacity(newWidth, target) {
    this.currentOpacity = newWidth;

    this.opacityBoxes.forEach(box => {
      box.style.borderColor =
        box === target
          ? this.uiStyleSettings.toolboxAccentColor
          : 'transparent';
    });

    if (this.onOpacityChanged) {
      this.onOpacityChanged(this.currentOpacity);
    }
  }
}
