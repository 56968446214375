import request from '../request';

const serviceName = '/iot-smart-helmet/attendance';

// 根据用户id获取考勤班次
export function getAttendanceById(userId) {
  return request(`${serviceName}/${userId}`, {
    method: 'GET',
  });
}

// 考勤同步
export function synchronizeAttendance(data) {
  return request(`${serviceName}/sync`, {
    method: 'GET',
    body: data,
  });
}

// 日历详情
export function calendarDetail(data) {
  return request(`${serviceName}/calendarList`, {
    method: 'GET',
    body: data,
  });
}
