<template>
  <form-tab :tabs="upTabs" :class="$style.wrap">
    <section slot="form0">
      <a-select
        :placeholder="$t('generalView.key.event')"
        :getPopupContainer="triggerNode => triggerNode.parentNode"
        v-model="eventType"
        @change="eventTypeChange"
      >
        <a-select-option value="">{{
          $t('iotScreenManage.all')
        }}</a-select-option>
        <a-select-option value="ERROR">{{
          $t('generalView.key.fault')
        }}</a-select-option>
        <a-select-option value="WARN">{{
          $t('generalView.key.warning')
        }}</a-select-option>
      </a-select>
      <a-select
        :getPopupContainer="triggerNode => triggerNode.parentNode"
        :placeholder="$t('generalView.key.sensors')"
        v-model="sensorType"
        @change="sensorTypeChange"
      >
        <a-select-option
          :value="item.value"
          v-for="item in typeList"
          :key="item.label"
          >{{ item.label }}</a-select-option
        >
      </a-select>
    </section>
    <div slot="value0">
      <a-table
        :columns="eventColumns"
        :data-source="eventList"
        :pagination="pagination"
        @change="handlePagination"
        :scroll="{ y: 410 }"
      >
        <span
          slot="countColumn"
          slot-scope="item, record"
          @click.stop="handleEventDetail(record)"
          style="cursor:pointer"
        >
          {{ record.count }}
          <a-icon type="double-right" />
        </span>
        <template slot="projectName" slot-scope="text, record">
          <span
            :title="text"
            style="cursor: pointer"
            @click.stop="handleLinkProject(record.projectId)"
          >
            {{ text }}
          </span>
        </template>
      </a-table>
    </div>
    <div slot="value1">
      <event-chart id="buildChart" :data="analyzeData" />
    </div>
  </form-tab>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import { Debounce } from 'lodash-decorators';
import EventDetail from './event-detail.vue';
import EventChart from './event-chart.vue';
import {
  getBuildingEvent,
  getBuildingAnalyze,
} from '@/services/iot-electric-box/message';
import { timeFormat, rangeFormat } from './format';
import { handleLink } from './link';
import { errorList, warnList } from './event';

/** @name 返回百分比整数 */
function toFixed(number) {
  return (number * 100).toFixed(0) - 0;
}

@Component({
  components: {
    EventChart,
  },
})
export default class BuildingEvent extends Vue {
  @Prop({ type: String, default: '' }) currentRange;
  @Prop({ type: Array, default: () => [] }) customTime;

  @Watch('customTime')
  async onCustomTimeChange() {
    await this.initChange();
    await this.initMethod();
  }
  @Watch('currentRange')
  async onCurrentRangeChange() {
    await this.initChange();
    await this.initMethod();
  }

  mounted() {
    this.initMethod();
  }
  initChange() {
    this.pagination.current = 1;
    this.eventType = '';
    this.sensorType = '';
  }

  initMethod() {
    this.fetchEventData();
    this.fetchAnalyzeData();
  }
  upTabs = [
    {
      label: this.$t('generalView.tab.buildingEvent'),
    },
    {
      label: this.$t('generalView.tab.analysis'),
    },
  ];
  eventColumns = [
    {
      title: this.$t('generalView.table.buildings'),
      dataIndex: 'projectName',
      ellipsis: true,
      scopedSlots: { customRender: 'projectName' },
    },
    {
      title: this.$t('generalView.table.companies'),
      dataIndex: 'tenantName',
      ellipsis: true,
    },
    {
      title: this.$t('generalView.table.count'),
      dataIndex: 'count',
      scopedSlots: { customRender: 'countColumn' },
    },
  ];
  eventList = [];
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: total => `${this.$t('todo.flow.total', { total })}`,
  };
  handlePagination(data) {
    this.pagination.current = data.current;
    this.fetchEventData();
  }

  get paramsByCommon() {
    return {
      dateRangeType: rangeFormat(this),
      startTime: timeFormat(this, 0),
      endTime: timeFormat(this, 1),
    };
  }

  async fetchEventData() {
    const { records, current, size, total } = await getBuildingEvent({
      pageNum: this.pagination.current,
      pageSize: this.pagination.pageSize,
      eventType: this.eventType,
      sensorType: this.sensorType,
      ...this.paramsByCommon,
    });
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
    this.eventList = records.map(record => {
      return {
        ...record,
        projectName: record.buildingName
          ? `${record.projectName}(${record.buildingName})`
          : `${record.projectName}`,
      };
    });
  }

  analyzeData = {
    xAxis: [],
    series: [],
    legend: [],
  };
  async fetchAnalyzeData() {
    const list = await getBuildingAnalyze({
      ...this.paramsByCommon,
    });
    const xAxis = list.map((value, index) => `Top ${index + 1}`);

    const series = [
      { name: '分控箱超载', data: [], tenantName: '', projectName: '' },
      { name: '超载预警', data: [], tenantName: '', projectName: '' },
      { name: '分控箱倾斜', data: [], tenantName: '', projectName: '' },
      { name: '倾斜预警', data: [], tenantName: '', projectName: '' },
      { name: '分控箱失载', data: [], tenantName: '', projectName: '' },
      { name: '失载预警', data: [], tenantName: '', projectName: '' },
      { name: '分控箱掉线', data: [], tenantName: '', projectName: '' },
      { name: '总荷载预警', data: [], tenantName: '', projectName: '' },
      { name: '分控箱急停', data: [], tenantName: '', projectName: '' },
      { name: '总电流预警', data: [], tenantName: '', projectName: '' },
      { name: '主控箱急停', data: [], tenantName: '', projectName: '' },
      { name: '天气预警', data: [], tenantName: '', projectName: '' },
      { name: '分控箱连接', data: [], tenantName: '', projectName: '' },
    ];
    list.forEach(item => {
      const obj = {
        tenantName: item.tenantName,
        projectName: item.projectName,
        buildingName: item.buildingName,
        count: item.count,
      };
      series[0].data.push({
        value: toFixed(item.gravityError / item.count),
        ...obj,
      });
      series[1].data.push({
        value: toFixed(item.gravityWarn / item.count),
        ...obj,
      });
      series[2].data.push({
        value: toFixed(item.stanceError / item.count),
        ...obj,
      });
      series[3].data.push({
        value: toFixed(item.stanceWarn / item.count),
        ...obj,
      });
      series[4].data.push({
        value: toFixed(item.lostLoadError / item.count),
        ...obj,
      });
      series[5].data.push({
        value: toFixed(item.lostLoadWarn / item.count),
        ...obj,
      });
      series[6].data.push({
        value: toFixed(item.boxDroppedError / item.count),
        ...obj,
      });
      series[7].data.push({
        value: toFixed(item.totalLoadWarn / item.count),
        ...obj,
      });
      series[8].data.push({
        value: toFixed(item.boxStopError / item.count),
        ...obj,
      });
      series[9].data.push({
        value: toFixed(item.totalCurrentWarn / item.count),
        ...obj,
      });
      series[10].data.push({
        value: toFixed(item.mainBoxStopError / item.count),
        ...obj,
      });
      series[11].data.push({
        value: toFixed(item.weatherWarn / item.count),
        ...obj,
      });
      series[12].data.push({
        value: toFixed(item.subConnectionWarn / item.count),
        ...obj,
      });
    });

    const option = {
      type: 'bar',
      stack: '总量',
      barWidth: 20,
      label: {
        show: false,
      },
      lineStyle: {
        color: '#00A4A4',
      },
    };
    const resultSeries = series.map(value => {
      return {
        ...value,
        ...option,
      };
    });
    this.analyzeData = {
      xAxis,
      series: resultSeries,
      legend: series.map(v => v.name),
    };
  }
  handleEventDetail(item) {
    const params = {
      eventType: this.eventType,
      sensorType: this.sensorType,
      ...this.paramsByCommon,
    };
    createModal(
      () => <EventDetail data={item} type="building" params={params} />,
      {
        title: this.$t('generalView.details'),
        width: 700,
      },
    );
  }

  /**
   * ● 载荷事件 改为 故障（主控箱急停、分控箱急停、分控箱掉线、分控箱超载（原“载荷故障”）、分控箱失载（原“倾斜故障”）、分控箱倾斜）
   * ● 倾斜事件 改与 预警 （超载预警、失载预警、倾斜预警、总荷载预警、总电流预警、天气预警）
   */
  eventType = '';
  sensorType = '';
  typeList = [];
  eventTypeChange(item) {
    this.eventType = item;
    this.sensorType = '';
    if (item === 'WARN') {
      this.typeList = warnList;
    } else if (item === 'ERROR') {
      this.typeList = errorList;
    } else {
      this.typeList = [];
    }
    this.pagination.current = 1;
    this.fetchEventData();
  }
  sensorTypeChange(item) {
    this.sensorType = item;
    this.pagination.current = 1;
    this.fetchEventData();
  }

  @Debounce(500)
  async handleLinkProject(projectId, buildId) {
    await handleLink(projectId, buildId);
  }
}
</script>
<style lang="less" module>
.wrap {
  :global {
    .ant-select {
      width: 120px;
    }
    .ant-select + .ant-select {
      margin-left: 10px;
    }
    .ant-carousel .slick-list {
      overflow: inherit;
    }
  }
}
</style>
