import { safeParseJSON } from '@triascloud/utils';
import { setHeaders } from '@/services/request';
import { setCustomModuleUrl } from '.';
import {
  createDefaultModuleUrl,
  getSettingByDomain,
  getCurrentInfoByToken as _getCurrentInfoByToken,
} from '@/services/template-manager/website';
import { getOssPathWithoutAuth } from '@/services/oss/backend';

export const getCurrentInfoByToken = _getCurrentInfoByToken;

export async function checkHost(app = 'app') {
  if (
    location.origin === createDefaultModuleUrl(app) ||
    /localhost|\d+.\d+.\d+/.test(location.origin)
  )
    return false;
  const setting = await getSettingByDomain(location.host);
  if (setting) {
    setHeaders({
      'X-Referer-Token': setting.refererToken || '',
    });
    const liteModeHost = {
      app: setting.pc?.host,
      m: setting.mobile?.host,
      account: `${setting.pc?.host || location.host}/login.html`,
      iot: setting.iot?.host,
      ds: setting.ds?.host,
    };
    setCustomModuleUrl((module, protocol) =>
      liteModeHost[module]
        ? `${protocol}//${liteModeHost[module]}`
        : createDefaultModuleUrl(module, protocol),
    );
    const img = safeParseJSON(setting.domainImg, () => null);
    if (img?.[0]) {
      getOssPathWithoutAuth(img[0].ossPath).then(res => {
        const $favicon = document.querySelector('link[rel="icon"]');
        $favicon.href = res;
      });
    }
  }
  return setting;
}
