<template>
  <form-tab :tabs="upTabs" @change="changeTab">
    <section slot="form">
      <a-select
        :value="form.projectId"
        option-filter-prop="children"
        show-search
        :placeholder="$t('generalView.projects')"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
        @change="handleProjectChange"
        @search="input => filterProjectOption(input)"
      >
        <a-select-option
          v-for="item in projectList"
          :key="item.projectId"
          :value="item.projectId"
          >{{ item.projectName }}</a-select-option
        >
        <template slot="notFoundContent">
          <a-empty :image="simpleImage" />
        </template>
      </a-select>
      <a-select
        :value="form.buildingId"
        allowClear
        :placeholder="$t('generalView.buildings')"
        :getPopupContainer="triggerNode => triggerNode.parentNode"
        @change="handleBuildingChange"
      >
        <a-select-option
          v-for="item in buildingList"
          :key="item.pkBuildingId"
          :value="item.pkBuildingId"
          >{{ item.buildingName }}</a-select-option
        >
        <template slot="notFoundContent">
          <a-empty :image="simpleImage" />
        </template>
      </a-select>
    </section>
    <div v-if="activiteKey === 0" slot="value0" :class="$style.tableWrap">
      <a-table
        :columns="reportColumns"
        :rowKey="row => row.pkId"
        :data-source="reportList"
        :pagination="reportPagination"
        @change="handleReportPagination"
        :scroll="{ y: 410 }"
      >
        <template slot="scoreTitle">
          <span>{{ $t('generalView.table.reportRemarks') }}</span>
          <a-icon
            @click="openScoreTips"
            :class="$style.scoreIcon"
            type="question-circle"
          />
        </template>
        <span
          slot="scoreColumn"
          slot-scope="item, record"
          @click.stop="handleQrReport(record)"
          style="cursor:pointer"
        >
          {{ record.score }}
          <x-icon type="tc-icon-order"></x-icon>
        </span>
        <template slot="idxProjectName" slot-scope="text, record">
          <span
            :title="text"
            style="cursor: pointer"
            @click.stop="handleLinkProject(record.idxProjectId)"
          >
            {{ text }}
          </span>
        </template>
      </a-table>
    </div>
    <div v-else slot="value1" :class="$style.tableWrap">
      <a-table
        :columns="layerColumns"
        :rowKey="row => `${row.projectId}${row.buildId}index`"
        :data-source="layerList"
        :pagination="layerPagination"
        @change="handleLayerPagination"
        :scroll="{ y: 410 }"
      >
        <template slot="projectName" slot-scope="text, record">
          <span
            :title="text"
            style="cursor: pointer"
            @click.stop="handleLinkProject(record.projectId)"
          >
            {{ text }}
          </span>
        </template>
        <template slot="action" slot-scope="record">
          <span
            @click.stop="openBuildDetail(record)"
            style="cursor: pointer;color: #faad14"
          >
            详情
          </span>
        </template>
      </a-table>
    </div>
  </form-tab>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import moment from 'moment';
import { createModal } from '@triascloud/x-components';
import Qrcode from './qrcode.vue';
import PromoteDetail from './promote-detail';
import {
  getClimbRiseCount,
  getReportRelationCount,
} from '@/services/iot-electric-box/client';
import {
  getRelatedProject,
  getProjectAndBuildingInAuthority,
} from '@/services/things/project';
import { timeFormat, rangeFormat } from './format';
import { handleLink } from './link';
import { reportGenerate } from '@/services/things/building.js';
import { createModuleUrl } from '@/utils';
import { Empty } from 'ant-design-vue';
import ScoreTips from './score-tips';

@Component({})
export default class PromoteReport extends Vue {
  @Prop({ type: String, default: '' }) currentRange;
  @Prop({ type: Array, default: () => [] }) customTime;

  @Watch('customTime')
  async onCustomTimeChange() {
    await this.initChange();
    await this.initMethod();
  }
  @Watch('currentRange')
  async onCurrentRangeChange() {
    await this.initChange();
    await this.initMethod();
  }
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }
  mounted() {
    this.initMethod();

    this.$on('update-building-data', async () => {
      await this.fetchBuildingData();

      await this.fetchReportList();

      await this.fetchLayerList();
    });
  }

  initChange() {
    this.form = {
      projectId: undefined,
      buildingId: undefined,
    };
    this.reportPagination.current = 1;
    this.layerPagination.current = 1;
  }

  initMethod() {
    this.fetchReportList();
    this.fetchLayerList();
    this.fetchProjectData();
  }

  upTabs = [
    {
      label: this.$t('generalView.tab.liftedRecords'),
    },
    {
      label: this.$t('generalView.tab.liftedFloors'),
    },
  ];
  activiteKey = 0;
  async changeTab(index) {
    this.activiteKey = index;
    await this.initChange();
    await this.initMethod();
  }
  reportColumns = [
    {
      title: this.$t('generalView.table.buildings'),
      dataIndex: 'idxProjectName',
      ellipsis: true,
      scopedSlots: { customRender: 'idxProjectName' },
    },
    {
      title: this.$t('generalView.table.companies'),
      dataIndex: 'idxTenantName',
      ellipsis: true,
    },
    {
      dataIndex: 'score',
      slots: { title: 'scoreTitle' },
      scopedSlots: { customRender: 'scoreColumn' },
    },
    {
      title: this.$t('generalView.table.time'),
      dataIndex: 'reportDate',
      customRender: text => moment(+text).format('YYYY-MM-DD HH:mm'),
    },
  ];
  @Debounce(500)
  async handleLinkProject(record) {
    await handleLink(record);
  }
  reportPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: total => this.$t('todo.flow.improvementTotal', { total }),
  };
  layerPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    totalFloors: 0,
    showTotal: () =>
      this.$t('todo.flow.raiseTotal', {
        total: this.layerPagination.totalFloors,
      }),
  };
  handleReportPagination(data) {
    this.reportPagination = data;
    this.fetchReportList();
  }
  handleLayerPagination(data) {
    this.layerPagination = data;
    this.fetchLayerList();
  }
  reportList = [];
  async fetchReportList() {
    const { records, current, size, total } = await getReportRelationCount({
      projectId: this.form.projectId,
      buildId: this.form.buildingId,
      dateRangeType: rangeFormat(this),
      startTime: timeFormat(this, 0),
      endTime: timeFormat(this, 1),
      pageNum: this.reportPagination.current,
      pageSize: this.reportPagination.pageSize,
    });
    this.reportList = records.map(item => {
      return {
        idxProjectName: `${item.idxProjectName}（${item.idxBuildingName}-${item.currentLayer}）`,
        idxTenantName: item.idxTenantName,
        score: item.score,
        reportDate: item.reportDate,
        pkId: item.pkId,
        idxProjectId: item.idxProjectId,
      };
    });
    this.reportPagination.current = current;
    this.reportPagination.pageSize = size;
    this.reportPagination.total = total;
  }
  async handleQrReport(item) {
    const linkParams = {
      finallyEffectiveDate: 'ONE',
      password: '',
      idxReportRelation: item.pkId,
    };
    const { idxReportRelation } = await reportGenerate(linkParams);
    const src = `${createModuleUrl(
      'm',
    )}/#/pages/iot/shareInputPassword/index?id=${idxReportRelation}&type=REPORT`;
    createModal(
      () => (
        <Qrcode src={src}>
          <div slot="desc">
            此码仅供临时预览，1天后将自动失效！
            <br />
            为保证浏览体验，建议使用手机扫码查看！
          </div>
        </Qrcode>
      ),
      {
        title: '查看楼栋提升报告',
      },
    );
  }

  form = {
    projectId: undefined,
    buildingId: undefined,
  };
  projectList = [];
  projectSearchInput = '';
  async fetchProjectData() {
    const params = {};
    if (this.projectSearchInput) {
      params.keyword = this.projectSearchInput;
    } else {
      params['query.size'] = 30;
    }
    this.projectList = await getRelatedProject(params);
  }
  handleProjectChange(value) {
    this.form.projectId = value;
    this.form.buildingId = undefined;
    if (!value) {
      this.projectSearchInput = '';
      this.fetchProjectData();
    }
    // 楼栋列表数据
    this.$emit('update-building-data');
  }
  @Debounce(300)
  filterProjectOption(input) {
    this.projectSearchInput = input;
    this.fetchProjectData();
    // 楼栋列表数据
    this.$emit('update-building-data');
  }
  buildingList = [];
  async fetchBuildingData() {
    if (this.form.projectId) {
      const { buildings } = await getProjectAndBuildingInAuthority(
        this.form.projectId,
      );
      this.buildingList = buildings;
    } else {
      this.buildingList = [];
    }
  }
  handleBuildingChange(value) {
    this.form.buildingId = value;

    // 获取提升记录
    this.fetchReportList();
    // 获取提升层数
    this.fetchLayerList();
  }

  layerColumns = [
    {
      title: this.$t('generalView.table.buildings'),
      dataIndex: 'projectName',
      ellipsis: true,
      scopedSlots: { customRender: 'projectName' },
    },
    {
      title: this.$t('generalView.table.companies'),
      dataIndex: 'tenantName',
      ellipsis: true,
    },
    {
      title: this.$t('generalView.table.layer'),
      dataIndex: 'currentLayer',
    },
    {
      title: this.$t('iotScreenManage.operation'),
      key: 'operation',
      scopedSlots: { customRender: 'action' },
    },
  ];
  openScoreTips() {
    const model = createModal(
      () => <ScoreTips onclose={() => model.handleClose()} />,
      {
        title: this.$t('generalView.rule.title'),
        width: 500,
      },
    );
  }
  openBuildDetail(row) {
    createModal(() => <PromoteDetail layerDetailData={row} />, {
      title: this.$t('generalView.rule.rise'),
      width: 700,
    });
  }
  layerList = [];
  async fetchLayerList() {
    const {
      climbs,
      totalFloors,
      totals,
      pageNum,
      pageSize,
    } = await getClimbRiseCount({
      projectId: this.form.projectId,
      buildId: this.form.buildingId,
      dateRangeType: rangeFormat(this),
      startTime: timeFormat(this, 0),
      endTime: timeFormat(this, 1),
      pageNum: this.layerPagination.current,
      pageSize: this.layerPagination.pageSize,
    });
    this.layerList = climbs.map(item => {
      return {
        projectName: `${item.projectName}(${item.buildName}-${item.currentLayer}层)`,
        projectId: item.projectId,
        buildId: item.buildId,
        currentLayer: item.currentLayer,
        tenantName: item.tenantName,
      };
    });
    this.layerPagination.current = pageNum;
    this.layerPagination.pageSize = pageSize;
    this.layerPagination.total = totals;
    this.layerPagination.totalFloors = totalFloors;
  }
}
</script>
<style lang="less" module>
.tableWrap {
  .linkButton {
    cursor: pointer;
  }
  .scoreIcon {
    cursor: pointer;
    margin-left: 5px;
    color: var(--primary);
  }
}
:global {
  .ant-select {
    width: 120px;
  }
  .ant-select + .ant-select {
    margin-left: 10px;
  }
}
</style>
