import emojiRegex from 'emoji-regex';
import moment from 'moment';

/**
 * @name 替换输入框的空格和表情
 * @param { String } value 输入框内容
 * @returns
 */
export function replaceContent(value) {
  const regex = emojiRegex();
  const content = value.replace(/\s+/g, '').replace(regex, '');
  return content;
}

/**
 * @name 去除对象中的空值
 * @param { Object } object
 * @returns
 */
export function emptyValueFilter(object) {
  let _obj = {};
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      if (![undefined, null, ''].includes(object[key])) {
        _obj[key] = object[key];
      }
    }
  }
  return _obj;
}

/**
 * @name 文本省略
 * @param { String } text 需要处理的文本
 * @param { Number } length 文本最长的长度
 * @returns
 */
export function omittedText(text = '', length = 4) {
  return text.length > length ? `${text.substr(0, length) + '...'}` : `${text}`;
}

/**
 * @name 高德地图获取众多点位的中心点
 * @param { Array } points 坐标点数组 [{'lng': 经度, 'lat': 纬度}]
 * @returns
 */
export function getPointsCenter(points) {
  let point_num = points.length; // 坐标点个数
  let X = 0,
    Y = 0,
    Z = 0;
  for (let i = 0; i < points.length; i++) {
    let lat, lng, x, y, z;
    lat = (parseFloat(points[i].lat) * Math.PI) / 180;
    lng = (parseFloat(points[i].lng) * Math.PI) / 180;
    x = Math.cos(lat) * Math.cos(lng);
    y = Math.cos(lat) * Math.sin(lng);
    z = Math.sin(lat);
    X += x;
    Y += y;
    Z += z;
  }
  X = X / point_num;
  Y = Y / point_num;
  Z = Z / point_num;

  let tmp_lng = Math.atan2(Y, X);
  let tmp_lat = Math.atan2(Z, Math.sqrt(X * X + Y * Y));

  return [(tmp_lat * 180) / Math.PI, (tmp_lng * 180) / Math.PI];
}

/**
 * @name 截图
 * @param { HTMLElement } dom dom元素
 * @param { Number } width 图宽
 * @param { Number } height 图高
 * @returns
 */
export function capturePicture(dom, width = 900, height = 600) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(dom, 0, 0, canvas.width, canvas.height);
  const name = moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
  const url = canvas.toDataURL('image/jpeg');
  const a = document.createElement('a'); // 生成一个a元素
  a.download = name; // 设置图片名称
  a.href = url; // 将生成的URL设置为a.href属性
  const event = new MouseEvent('click'); // 创建一个单击事件
  a.dispatchEvent(event); // 触发a的单击事件
}

/**
 * 格式化时间-语音通话时长
 * @param {Number} value
 * @returns {String} 格式-00:00
 */
export function timeConversion(value) {
  if (value < 60) {
    return value >= 10 ? `00:${value}` : `00:0${value}`;
  }
  let minutes = parseInt(value / 60);
  let second = value % 60;
  minutes = minutes >= 10 ? minutes : `0${minutes}`;
  second = second >= 10 ? second : `0${second}`;
  return `${minutes}:${second}`;
}
