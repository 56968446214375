<template>
  <a-table
    :columns="columns"
    :rowKey="row => row.pkId"
    :data-source="list"
    :locale="emptyLocale"
    :pagination="pagination"
    @change="handlePagination"
  >
    <template slot="autoReport" slot-scope="record">
      <span>{{ formatStr(record) }}</span>
    </template>
    <template slot="action" slot-scope="record">
      <span
        @click.stop="shareReport(record)"
        style="cursor: pointer;color: #faad14"
      >
        {{ $t('camera.look') }}
      </span>
    </template>
  </a-table>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import Empty from '@/components/empty-content';
import { getReportList } from '@/services/things/building.js';
import ShareBox from '@/views/iot/iot-screen-manage/share-box';
import ShareForm from '@/views/iot/electric-box/device-manage/components/share-form';
import { createFormModal, createModal } from '@triascloud/x-components';

@Component({
  components: {
    Empty,
  },
})
export default class ReportList extends Vue {
  @Prop({ type: Object, default: () => {} }) reportInfo;

  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  list = [];

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };
  columns = [
    {
      title: this.$t('generalView.table.reportName'),
      dataIndex: 'autoReport',
      scopedSlots: { customRender: 'autoReport' },
    },
    {
      align: 'left',
      title: this.$t('generalView.table.rating'),
      dataIndex: 'score',
    },
    {
      width: 80,
      title: this.$t('iotScreenManage.operation'),
      key: 'operation',
      scopedSlots: { customRender: 'action' },
    },
  ];
  formatStr(row) {
    const flag = '操作报告（' + (row.autoReport ? '自动' : '手动') + '）-';
    const time = this.$moment(row.reportDate).format('YYYYMMDD');
    return flag + time;
  }
  mounted() {
    this.getList();
  }
  async getList() {
    const params = {
      pageNum: this.pagination.current,
      pageSize: this.pagination.pageSize,
      ...this.reportInfo,
    };
    try {
      const { records, current, total } = await getReportList(params);
      this.list = records;
      this.pagination.current = current;
      this.pagination.total = total;
    } catch {
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }
  async shareReport(row) {
    let title =
      '操作报告（' +
      (row.autoReport ? '自动' : '手动') +
      '）-' +
      this.$moment(row.reportDate).format('YYYYMMDD');

    const data = {
      reportId: row.pkId,
      projectName: title,
    };
    let shareInfo = await createFormModal(() => <ShareForm data={data} />, {
      title: this.$t('generalView.buildingImReport'),
      okText: this.$t('generalView.key.generateLink'),
      width: `500px`,
    });
    shareInfo.isReport = true;
    const modal = createModal(
      () => (
        <ShareBox
          shareInfo={shareInfo}
          showToView={false}
          onClose={() => modal.handleClose()}
        />
      ),
      {
        title: this.$t('generalView.buildingImReport'),
        width: `500px`,
      },
    );
  }
}
</script>

<style scoped></style>
