<template>
  <div>
    <action-bar
      :showText="false"
      ref="action"
      :sliceIndex="sliceIndex"
      :featureMap="featureMap"
      :class="$style.action"
      v-if="actionData.length"
    >
      <template v-slot="{ close }">
        <template v-for="action in actionList">
          <NoticeWrap
            v-if="action.moduleType === 'message'"
            :key="action.moduleType"
            :title="$t(action.actionName)"
            ref="noticeWrap"
          />
          <ThemeSelectWrap
            v-else-if="action.moduleType === 'theme'"
            :key="action.moduleType"
            :title="$t(action.actionName)"
          />
          <DownloadWrap
            v-else-if="action.moduleType === 'download'"
            :key="action.moduleType"
            :title="$t(action.actionName)"
          />
          <a-button
            v-else
            :key="action.moduleType"
            :title="$t(action.actionName)"
            @click="handleAction(action, close)"
            type="link"
            :class="[$style.button, 'headerButton']"
          >
            <router-link :to="action.url" v-if="action.moduleType === 'home'">
              <x-icon :type="action.icon" hover />
              <span class="text">{{ $t(action.actionName) }}</span>
            </router-link>
            <router-link
              v-if="
                ['todo', 'task', 'application', 'data', 'expand'].includes(
                  action.moduleType,
                )
              "
              v-slot="{ navigate }"
              :to="`/${action.moduleType}`"
              custom
            >
              <a @click="checkTenant(navigate, $event)">
                <a-badge
                  v-if="action.moduleType === 'todo'"
                  :count="todoCount"
                  :class="$style.todo"
                >
                  <x-icon type="tc-icon-todo" hover />
                </a-badge>
                <x-icon
                  v-else
                  :type="
                    `tc-icon-${
                      action.moduleType === 'expand'
                        ? 'store'
                        : action.moduleType
                    }`
                  "
                  hover
                />
                <span class="text">{{ $t(action.actionName) }}</span>
              </a>
            </router-link>
          </a-button>
        </template>
        <a-button
          key="Setting"
          :title="$t('common.setting')"
          @click="ActionSortSetting(close)"
          type="link"
          :class="[$style.button, $style.buttonSet]"
        >
          <a-tooltip
            :mouseLeaveDelay="0"
            :title="$t('common.setting')"
            v-if="featureMap.theme"
          >
            <x-icon type="tc-icon-setting" />
          </a-tooltip>
        </a-button>
      </template>
    </action-bar>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { crossStorageModule, globalModule } from '@/enum/store';
import { globalSocket } from '@triascloud/message-hub';
import { FlowSocket } from '@/enum/socket';
import NoticeWrap from './notice-wrap';
import ThemeSelectWrap from './theme-select-wrap.vue';
import DownloadWrap from './download-wrap.vue';
import ActionBar from './action-bar.vue';
import ActionSort from './action-sort';
import { HabitService } from '@triascloud/services';
import { deepClone, generateMap } from '@triascloud/utils';
import { createModuleUrl } from '@/utils';
import { todoTotal } from '@/services/flow/flow';

import {
  Notice,
  ThemeSelect,
  Download,
  LanguageSelect,
  UserMenu,
} from '@triascloud/x-blocks';

const Feature = {
  /** @name 主页 */
  Home: 'home',
  /** @name 应用管理 */
  Application: 'application',
  /**
   * @name 数据
   * @todo Release Adjustment
   */
  Data: 'data',
  /** @name 任务 */
  Task: 'task',
  /** @name 待办 */
  Todo: 'todo',
  /** @name 消息 */
  Message: 'message',
  /** @name 皮肤 */
  Theme: 'theme',
  /** @name 下载器 */
  Download: 'download',
  /** @name 语言选择 */
  Language: 'language',
  /** @name 用户 */
  Avatar: 'avatar',
  /** @name 拓展 */
  Expand: 'expand',
};

@Component({
  components: {
    ActionBar,
    ActionSort,
    Notice,
    ThemeSelect,
    Download,
    LanguageSelect,
    UserMenu,
    NoticeWrap,
    ThemeSelectWrap,
    DownloadWrap,
  },
})
export default class HeaderActionList extends Vue {
  @crossStorageModule.State user;
  @crossStorageModule.State skin;
  @globalModule.State refreshMessageFlag;
  @crossStorageModule.State tenant;
  @Prop({ type: Object }) featureMap;

  static Feature = Feature;
  get sliceIndex() {
    return this.actionData.filter(item => item.show).length ===
      this.actionData.length
      ? this.actionData.filter(item => item.show).length + 1
      : this.actionData.filter(item => item.show).length;
  }

  todoCount = 0;
  showSetModal = true;
  handleClose(close) {
    close();
  }
  appOrigin = createModuleUrl('app');
  navigateTo(path) {
    location.href = `${this.appOrigin}${path}`;
  }
  handleAction(action, close) {
    close();
    this.navigateTo(action.url);
    // this.$router.push({ path: action.url });
  }

  handleSkinChange(value) {
    this.setItem({
      key: 'skin',
      value: JSON.stringify(value),
    });
  }

  checkTenant(navigate, $event) {
    this.$emit('checkTenant', navigate, $event);
  }

  actionData = [];
  created() {
    globalSocket.on(FlowSocket.TodoTotal, this.handleResult);
    this.getTodoTotal();
  }

  async mounted() {
    await this.getActionData();
  }

  @Watch('refreshMessageFlag')
  handleRefreshMessage() {
    this.$refs.noticeWrap[0].$refs.notice.getMessageCount();
  }

  handleResult(data) {
    if (!data.event || !data || !data.data) return;
    if (data.data.event !== 'TODO') return;
    this.todoCount = data.data.count;
  }

  /** @name 基础头部功能 */
  get defaultActionData() {
    return [
      {
        url: '/',
        icon: 'tc-icon-home',
        show: true,
        actionName: 'common.home',
        moduleType: Feature.Home,
      },
      {
        url: '/application',
        icon: 'tc-icon-application',
        show: true,
        actionName: 'common.app',
        moduleType: Feature.Application,
      },
      /** @todo Release Adjustment */
      {
        url: '/data',
        icon: 'tc-icon-data',
        show: true,
        actionName: 'common.data',
        moduleType: Feature.Data,
      },
      {
        url: '/expand',
        icon: 'tc-icon-plugin',
        show: true,
        actionName: 'home.expand',
        moduleType: Feature.Expand,
      },
      {
        url: '/task',
        icon: 'tc-icon-task',
        show: true,
        actionName: 'common.task',
        moduleType: Feature.Task,
      },
      {
        url: '/todo',
        icon: 'tc-icon-todo',
        show: true,
        actionName: 'common.myProcess',
        moduleType: Feature.Todo,
      },
      {
        url: '/message',
        icon: 'tc-icon-bell',
        show: true,
        actionName: 'common.information',
        moduleType: Feature.Message,
      },
      {
        url: null,
        icon: 'tc-icon-skin',
        show: true,
        actionName: 'common.skin',
        moduleType: Feature.Theme,
      },
      {
        url: null,
        icon: 'tc-icon-download-square',
        show: true,
        actionName: 'common.downloadList',
        moduleType: Feature.Download,
      },
    ];
  }

  get actionList() {
    // 隐藏的还需要排序
    return this.actionData && this.actionData.sort((a, b) => b.show - a.show);
  }

  get userId() {
    return this.user && this.user.pkId;
  }

  async getActionData() {
    const list = await this.habitService.getItem(
      `global.header.action.${this.userId}`,
    );

    if (list) {
      const listData = JSON.parse(list).map((item, index) => ({
        ...item,
        sort: index,
      }));
      const listMap = generateMap(listData, 'moduleType', item => item);
      this.actionData = this.defaultActionData
        .map(item => ({
          ...item,
          ...listMap[item.moduleType],
        }))
        .sort((a, b) => a.sort - b.sort);
    } else {
      this.actionData = deepClone(this.defaultActionData);
    }
    // if (this.tenant) {
    //   const actionListAuth = [Feature.Application, Feature.Task, Feature.Todo];

    //   console.log(this.actionData);
    // }
  }

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }
  async getTodoTotal() {
    this.todoCount = (await todoTotal()).find(
      item => item.event === 'TODO',
    ).count;
  }

  async ActionSortSetting() {
    if (!this.showSetModal) {
      return false;
    }
    this.showSetModal = false;
    const list = await ActionSort.createModal(
      {
        actionData: deepClone(this.actionData),
        featureMap: this.featureMap,
      },
      {
        closed: () => {
          this.showSetModal = true;
        },
      },
    );
    this.showSetModal = true;
    this.habitService.setItem(
      `global.header.action.${this.userId}`,
      JSON.stringify(
        list
          ? list.map(item => ({
              show: item.show,
              moduleType: item.moduleType,
            }))
          : this.defaultActionData.map(item => ({
              show: item.show,
              moduleType: item.moduleType,
            })),
      ),
    );
    this.actionData = deepClone(list);
  }
  destroy() {
    globalSocket.off(FlowSocket.TodoTotal, this.handleResult);
  }
}
</script>
<style lang="less" module>
.button {
  color: inherit;
  min-width: min-content;
  padding: 0 10px;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  a {
    color: var(--font);
    margin: 0;
  }
  &:hover {
    color: var(--primary);
  }
  :global(.anticon) {
    font-size: 20px !important;
    margin-right: 0;
  }

  :global(.text) {
    margin-left: 8px;
  }
  :global(.ant-badge) {
    margin: 0;
  }

  &:global(.ant-btn-loading) :global {
    padding-left: 33px !important;
    .anticon:not(.anticon-loading) {
      display: none;
    }
  }
  &::before {
    background-color: transparent;
  }
}
.buttonSet {
  justify-content: center;
  color: inherit;
}
</style>
