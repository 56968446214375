<template>
  <div :class="$style.txcenter">
    <!-- <i
      v-if="isIcon"
      class="TC tc-icon-kong"
      :class="$style.tcIcon"
      :style="{ 'font-size': font }"
    ></i> -->
    <img
      v-if="isIcon"
      :class="$style.icon"
      :style="{ width: font }"
      :src="skin.mode === 'dark' ? DarkIcon : TintIcon"
    />
    <img
      v-else
      src="@/assets/images/no-data.png"
      :class="[$style.icon, $style.otherIcon]"
      :style="{ width: font, ...imgStyle }"
    />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { crossStorageModule } from '@/enum/store';
import DarkIcon from '@/assets/images/no-data-dark.png';
import TintIcon from '@/assets/images/no-data-tint.png';
@Component
export default class NoDataChart extends Vue {
  @Prop({ type: String, default: '' }) font;
  /** @name 是否使用图标-图标使用在不是大屏页面的空状态 */
  @Prop({ type: Boolean, default: false }) isIcon;
  @Prop({ type: Object, default: () => {} }) imgStyle;
  @crossStorageModule.State skin;

  TintIcon = TintIcon;
  DarkIcon = DarkIcon;
}
</script>
<style lang="less" module>
.txcenter {
  text-align: center;
  color: var(--ePColor);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  .otherIcon {
    width: 2rem;
  }
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    color: #07d3fd;
  }
}
</style>
