<template>
  <div>
    <a-form-model
      :model="form"
      ref="form"
      hideRequiredMark
      validateOnRuleChange
      :rules="rules"
    >
      <a-form-model-item prop="picture" :class="$style.modelItem">
        <div slot="label" :class="$style.labelWrap">
          <label>照片质量</label>
          <span
            v-if="pictureSet === true"
            :class="$style.btn"
            @click="pictureSet = false"
            >设置</span
          >
          <span v-else>
            <span :class="$style.btnCancel" @click="pictureSet = true">{{
              $t('msg.cancel')
            }}</span>
            <span :class="$style.btnSuccess" @click="handlePictureSave">{{
              $t('msg.save')
            }}</span>
          </span>
        </div>
        <a-select
          :disabled="pictureSet"
          v-model="form.picture"
          :class="$style.selectWrap"
        >
          <a-select-option
            v-for="item in pictures"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <p :class="$style.ml20">
          {{
            $t('hat.managementModal.setting.camera.pictureSize', {
              size: currentPicSize,
            })
          }}
        </p>
      </a-form-model-item>
      <a-form-model-item prop="video" :class="$style.modelItem">
        <div slot="label" :class="$style.labelWrap">
          <label>录像质量</label>
          <span
            v-if="videoSet === true"
            :class="$style.btn"
            @click="videoSet = false"
            >设置</span
          >
          <span v-else>
            <span :class="$style.btnCancel" @click="videoSet = true">取消</span>
            <span :class="$style.btnSuccess" @click="handleVideoSave"
              >保存</span
            >
          </span>
        </div>
        <a-select
          :disabled="videoSet"
          v-model="form.video"
          :class="$style.selectWrap"
        >
          <a-select-option
            v-for="item in videos"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <p :class="$style.ml20" v-if="form.video === 'RESOLUTION_1080'">
          {{
            $t('hat.managementModal.setting.camera.videoMoreSize', {
              size: currentVideoSize,
            })
          }}
        </p>
        <p :class="$style.ml20" v-else>
          {{
            $t('hat.managementModal.setting.camera.videoSize', {
              size: currentVideoSize,
            })
          }}
        </p>
      </a-form-model-item>
      <a-form-model-item prop="time">
        <div slot="label" :class="$style.labelWrap">
          <label>短视频时长</label>
          <span
            v-if="timeSet === true"
            :class="$style.btn"
            @click="timeSet = false"
            >设置</span
          >
          <span v-else>
            <span :class="$style.btnCancel" @click="timeSet = true">取消</span>
            <span :class="$style.btnSuccess" @click="handleTimeSave">保存</span>
          </span>
        </div>
        <a-select
          :disabled="timeSet"
          v-model="form.time"
          :class="$style.selectWrap"
        >
          <a-select-option
            v-for="item in times"
            :key="item.value"
            :value="item.value"
            >{{ item.label + $t('unit.m') }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" :class="$style.labelWrap">
          <label>拍照提示声</label>
          <span v-if="true" :class="$style.btn" @click="handleNotOpen"
            >设置</span
          >
        </div>
        <a-select
          :disabled="true"
          :class="$style.selectWrap"
          placeholder="开启"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" :class="$style.labelWrap">
          <label>录像提示声</label>
          <span v-if="true" :class="$style.btn" @click="handleNotOpen"
            >设置</span
          >
        </div>
        <a-select
          :disabled="true"
          :class="$style.selectWrap"
          placeholder="开启"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" :class="$style.labelWrap">
          <label>视频轨迹</label>
          <span v-if="true" :class="$style.btn" @click="handleNotOpen"
            >设置</span
          >
        </div>
        <a-select
          :disabled="true"
          :class="$style.selectWrap"
          placeholder="关闭"
        >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { updateDeviceData } from '@/services/smart-hat/device';
import { Component, Vue, Inject, InjectReactive } from 'vue-property-decorator';
@Component()
export default class Camera extends Vue {
  @InjectReactive('DEVICE_RECORD') device;
  @InjectReactive('deviceDetail') deviceDetail;
  @Inject('UPDATE_DEVICE_DETAIL') updateDeviceDetail;

  form = {
    picture: 1080,
    video: 720,
    time: 1,
  };
  rules = {};

  mounted() {
    this.getDeviceInfo();
  }

  async getDeviceInfo() {
    this.form.picture = this.deviceDetail.photoSetting.photoResolutionSetting;
    this.form.video = this.deviceDetail.photoSetting.videoResolutionSetting;
    this.form.time = this.deviceDetail.photoSetting.shootingDurationSetting;
  }

  pictureSet = true;
  async handlePictureSave() {
    try {
      await updateDeviceData({
        deviceId: this.device.deviceId,
        exData: {
          resolution: this.form.picture,
        },
        type: 'PHOTO_RESOLUTION_SETTINGS',
      });
      this.$message.success('更新成功');
      this.pictureSet = true;
      this.updateDeviceDetail();
    } catch (error) {
      return false;
    }
  }

  videoSet = true;
  async handleVideoSave() {
    try {
      await updateDeviceData({
        deviceId: this.device.deviceId,
        exData: {
          resolution: this.form.video,
        },
        type: 'VIDEO_RESOLUTION_SETTING',
      });
      this.$message.success('更新成功');
      this.videoSet = true;
      this.updateDeviceDetail();
    } catch (error) {
      return false;
    }
  }

  timeSet = true;
  async handleTimeSave() {
    try {
      await updateDeviceData({
        deviceId: this.device.deviceId,
        exData: {
          shortVideoLength: this.form.time,
        },
        type: 'SHOOTING_DURATION_SETTING',
      });
      this.$message.success('更新成功');
      this.timeSet = true;
      this.updateDeviceDetail();
    } catch (error) {
      return false;
    }
  }

  get currentPicSize() {
    const find = this.pictures.find(v => v.value === this.form.picture);
    if (find) {
      return find.size;
    }
    return '';
  }
  pictures = [
    {
      label: '480P（640*480）',
      value: 480,
      size: '100kb',
    },
    {
      label: '720P（1280*720）',
      value: 720,
      size: '300kb',
    },
    {
      label: '1080P（1920*1080）',
      value: 1080,
      size: '500kb',
    },
    {
      label: '2688P（2688*1512）',
      value: 2688,
      size: '1M',
    },
    {
      label: '4096P（4096*2160）',
      value: 4096,
      size: '3M',
    },
  ];

  get currentVideoSize() {
    const find = this.videos.find(v => v.value === this.form.video);
    if (find) {
      return find.size;
    }
    return '';
  }
  videos = [
    {
      label: '480P（640*480）',
      value: 480,
      size: '5M-10M',
    },
    {
      label: '720P（1280*720）',
      value: 720,
      size: '15M-30M',
    },
    {
      label: '1080P（1920*1080）',
      value: 1080,
      size: '30M',
    },
  ];

  times = [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
    {
      label: '6',
      value: 6,
    },
    {
      label: '7',
      value: 7,
    },
    {
      label: '8',
      value: 8,
    },
    {
      label: '9',
      value: 9,
    },
    {
      label: '10',
      value: 10,
    },
  ];

  handleNotOpen() {
    this.$message.warning('即将开放，尽请期待！');
  }
}
</script>
<style lang="less" module>
.selectWrap {
  width: 200px !important;
}

.labelWrap {
  .btn {
    color: var(--font-active);
    margin-left: 20px;
    cursor: pointer;
  }
  .btnCancel {
    color: var(--font);
    margin-left: 20px;
    cursor: pointer;
  }
  .btnSuccess {
    color: var(--success);
    margin-left: 10px;
    cursor: pointer;
  }
}
.ml20 {
  margin-left: 20px;
}
.modelItem {
  :global {
    .ant-form-item-children {
      display: flex;
      align-items: center;
    }
  }
}
</style>
