var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.actionData.length)?_c('action-bar',{ref:"action",class:_vm.$style.action,attrs:{"showText":false,"sliceIndex":_vm.sliceIndex,"featureMap":_vm.featureMap},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_vm._l((_vm.actionList),function(action){return [(action.moduleType === 'message')?_c('NoticeWrap',{key:action.moduleType,ref:"noticeWrap",refInFor:true,attrs:{"title":_vm.$t(action.actionName)}}):(action.moduleType === 'theme')?_c('ThemeSelectWrap',{key:action.moduleType,attrs:{"title":_vm.$t(action.actionName)}}):(action.moduleType === 'download')?_c('DownloadWrap',{key:action.moduleType,attrs:{"title":_vm.$t(action.actionName)}}):_c('a-button',{key:action.moduleType,class:[_vm.$style.button, 'headerButton'],attrs:{"title":_vm.$t(action.actionName),"type":"link"},on:{"click":function($event){return _vm.handleAction(action, close)}}},[(action.moduleType === 'home')?_c('router-link',{attrs:{"to":action.url}},[_c('x-icon',{attrs:{"type":action.icon,"hover":""}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t(action.actionName)))])],1):_vm._e(),(
              ['todo', 'task', 'application', 'data', 'expand'].includes(
                action.moduleType
              )
            )?_c('router-link',{attrs:{"to":("/" + (action.moduleType)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('a',{on:{"click":function($event){return _vm.checkTenant(navigate, $event)}}},[(action.moduleType === 'todo')?_c('a-badge',{class:_vm.$style.todo,attrs:{"count":_vm.todoCount}},[_c('x-icon',{attrs:{"type":"tc-icon-todo","hover":""}})],1):_c('x-icon',{attrs:{"type":("tc-icon-" + (action.moduleType === 'expand'
                      ? 'store'
                      : action.moduleType)),"hover":""}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t(action.actionName)))])],1)]}}],null,true)}):_vm._e()],1)]}),_c('a-button',{key:"Setting",class:[_vm.$style.button, _vm.$style.buttonSet],attrs:{"title":_vm.$t('common.setting'),"type":"link"},on:{"click":function($event){return _vm.ActionSortSetting(close)}}},[(_vm.featureMap.theme)?_c('a-tooltip',{attrs:{"mouseLeaveDelay":0,"title":_vm.$t('common.setting')}},[_c('x-icon',{attrs:{"type":"tc-icon-setting"}})],1):_vm._e()],1)]}}],null,false,4047650706)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }