<template>
  <layout>
    <layout-menu :menus="menus" :class="$style.menus" :value="$route.path">
      <template v-slot:header-left>
        <x-icon type="tc-icon-overview" :class="$style.icon" />
        <span :class="$style.title">{{ $t('menus.hat') }}</span>
      </template>
    </layout-menu>
    <router-view />
  </layout>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Layout, LayoutMenu } from '@triascloud/x-blocks';
import { findTree } from '@triascloud/utils';
import { crossStorageModule, globalModule } from '@/enum/store.js';
import { XOperationSelect } from '@/components/x-operation-select';
import { createModuleUrl } from '@/utils';
import { globalSocket } from '@triascloud/message-hub';
import { HatSocket } from '@/enum/socket';
import { useNotification } from './notification';
import { createModal } from '@triascloud/x-components';
import ManageModel from '@/views/hat/device/manage-model/index.vue';

const { sosNotification } = useNotification();
@Component({
  components: {
    Layout,
    LayoutMenu,
  },
})
export default class HatIndex extends Vue {
  @crossStorageModule.State('tenant') tenant;
  @globalModule.Action refreshCompanyList;
  get menus() {
    let result = findTree(
      this.$router.options.routes,
      item => item.name === 'hat',
    ).children.map(item => ({
      path: item.path,
      title: this.$path(item.path),
    }));
    result = result.filter(item => item.path !== '/hat/file/marking/:id');
    result.push({
      path: '/hat/screen',
      title: this.$path('/hat/screen'),
      target: '_blank',
    });
    // return result.filter(item => this.$p(item.path));
    return result;
  }

  firstLoading = true;
  @Watch('menus')
  menusChange(menus) {
    if (this.firstLoading) {
      this.firstLoading = false;
    } else {
      if (Array.isArray(menus) && menus.length === 0) {
        window.location.replace(createModuleUrl('app'));
      }
    }
  }

  created() {
    this.checkTenant();
    this.initSocket();
  }
  checkTenant() {
    if (this.tenant || !this.user) return;
    if (!this.menus.length) {
      this.$router.push('/403');
    } else {
      XOperationSelect.create()
        .then(this.refreshCompanyList)
        .catch(() => this.$router.push('/'));
    }
  }

  handleSOS(data) {
    const { deviceId, deviceName } = data.data;
    sosNotification({
      title: '安全帽SOS警报',
      description: `${deviceName}触发SOS，请及时处理`,
      onClick: () => {
        this.$router.push('/hat/device');
        const record = {
          deviceId,
        };
        setTimeout(() => {
          createModal(
            () => <ManageModel record={record} defaultActiveKey="event" />,
            {
              width: 1280,
              title: this.$t('hat.deviceManagement.manage'),
              maskClosable: false,
              className: this.$style.ManageModel,
            },
          );
        }, 300);
      },
    });
  }

  initSocket() {
    globalSocket.on(HatSocket.SOS, this.handleSOS);
  }

  beforeDestroy() {
    globalSocket.off(HatSocket.SOS, this.handleSOS);
  }
}
</script>
<style lang="less" module>
.menus {
  width: 200px;

  .icon {
    font-size: 18px;
    margin-right: 10px;
  }

  .title {
    font-size: 16px;
  }
}
:global(.ant-modal-content) {
  box-shadow: none;
}
.ManageModel {
  :global {
    .ant-model-body {
      padding: 0;
      margin-bottom: 0;
    }
  }
}
</style>
