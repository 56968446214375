<template>
  <div :class="$style.deviceDebug">
    <!-- 考勤更新频率 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.notice.Attendance') }}
      </a-col>
      <a-col style="margin-left: 40px;">
        <span
          v-if="!postObj['CHECK_IN'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['CHECK_IN'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['CHECK_IN'].edit"
          :class="$style.textBtn"
          @click="postObj['CHECK_IN'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['CHECK_IN'].edit"
          :class="$style.textBtn"
          @click="handleSave('CHECK_IN')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{
          $t(
            'hat.managementModal.setting.hint.notice.attendanceBeforeAndAfter ',
          )
        }}</span>
        <a-select
          v-model="postObj['CHECK_IN'].size"
          size="small"
          :disabled="!postObj['CHECK_IN'].edit"
          style="width: 90px;margin: 0 6px 0;"
        >
          <a-select-option :value="5">5</a-select-option>
          <a-select-option :value="10">10</a-select-option>
          <a-select-option :value="15">15</a-select-option>
          <a-select-option :value="20">20</a-select-option>
        </a-select>
        <span>{{
          $t('hat.managementModal.setting.hint.notice.tipsThree')
        }}</span>
        <span :class="$style.dsc"
          >（{{ $t('hat.managementModal.setting.hint.notice.tipsTwo') }}）</span
        >
      </a-col>
    </a-row>
    <!-- 待机更新频率 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.standby') }}
      </a-col>
    </a-row>
    <!-- 待机更新频率-传感器状态 -->
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{ $t('hat.managementModal.setting.hint.debug.sensor') }}</span>
        <a-select
          v-model="postObj['STANDBY_STATUS'].size"
          size="small"
          :disabled="!postObj['STANDBY_STATUS'].edit"
          :options="timeList"
          style="width: 90px;margin: 0 6px 0;"
        />
        <span>{{ $t('hat.managementModal.setting.hint.debug.perTime') }}</span>
      </a-col>
      <a-col style="margin-left: 10px;">
        <span
          v-if="!postObj['STANDBY_STATUS'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['STANDBY_STATUS'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['STANDBY_STATUS'].edit"
          :class="$style.textBtn"
          @click="postObj['STANDBY_STATUS'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['STANDBY_STATUS'].edit"
          :class="$style.textBtn"
          @click="handleSave('STANDBY_STATUS')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <!-- 待机更新频率-设备定位 -->
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{
          $t('hat.managementModal.setting.hint.debug.positioning')
        }}</span>
        <a-select
          v-model="postObj['STANDBY_POSITION'].size"
          size="small"
          :disabled="!postObj['STANDBY_POSITION'].edit"
          :options="timeList"
          style="width: 90px;margin: 0 6px 0;"
        />
        <span>{{ $t('hat.managementModal.setting.hint.debug.perTime') }}</span>
      </a-col>
      <a-col style="margin-left: 10px;">
        <span
          v-if="!postObj['STANDBY_POSITION'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['STANDBY_POSITION'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['STANDBY_POSITION'].edit"
          :class="$style.textBtn"
          @click="postObj['STANDBY_POSITION'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['STANDBY_POSITION'].edit"
          :class="$style.textBtn"
          @click="handleSave('STANDBY_POSITION')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <!-- 开机更新频率 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.power') }}
      </a-col>
    </a-row>
    <!-- 开机更新频率-传感器状态 -->
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{ $t('hat.managementModal.setting.hint.debug.sensor') }}</span>
        <a-select
          v-model="postObj['BOOT_STATUS'].size"
          size="small"
          :disabled="!postObj['BOOT_STATUS'].edit"
          :options="timeList"
          style="width: 90px;margin: 0 6px 0;"
        />
        <span>{{ $t('hat.managementModal.setting.hint.debug.perTime') }}</span>
      </a-col>
      <a-col style="margin-left: 10px;">
        <span
          v-if="!postObj['BOOT_STATUS'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['BOOT_STATUS'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['BOOT_STATUS'].edit"
          :class="$style.textBtn"
          @click="postObj['BOOT_STATUS'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['BOOT_STATUS'].edit"
          :class="$style.textBtn"
          @click="handleSave('BOOT_STATUS')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <!-- 开机更新频率-设备定位 -->
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{
          $t('hat.managementModal.setting.hint.debug.positioning')
        }}</span>
        <a-select
          v-model="postObj['BOOT_POSITION'].size"
          size="small"
          :disabled="!postObj['BOOT_POSITION'].edit"
          style="width: 90px;margin: 0 6px 0;"
        >
          <a-select-option :value="1">1{{ $t('unit.m') }}</a-select-option>
          <a-select-option
            v-for="item in timeList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <span>{{ $t('hat.managementModal.setting.hint.debug.perTime') }}</span>
      </a-col>
      <a-col style="margin-left: 10px;">
        <span
          v-if="!postObj['BOOT_POSITION'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['BOOT_POSITION'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['BOOT_POSITION'].edit"
          :class="$style.textBtn"
          @click="postObj['BOOT_POSITION'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['BOOT_POSITION'].edit"
          :class="$style.textBtn"
          @click="handleSave('BOOT_POSITION')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <!-- 脱帽消抖时间 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.Uncapping') }}
      </a-col>
      <a-col style="margin-left: 40px;">
        <span
          v-if="!postObj['UN_CAP'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['UN_CAP'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['UN_CAP'].edit"
          :class="$style.textBtn"
          @click="postObj['UN_CAP'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['UN_CAP'].edit"
          :class="$style.textBtn"
          @click="handleSave('UN_CAP')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{ $t('hat.managementModal.setting.hint.notice.five') }}</span>
        <a-select
          v-model="postObj['UN_CAP'].size"
          size="small"
          :disabled="!postObj['UN_CAP'].edit"
          style="width: 90px;margin: 0 6px 0;"
        >
          <a-select-option :value="1">1{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="5">5{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="10">10{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="15">15{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="20">20{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="30">30{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="45">45{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="60">60{{ $t('unit.s') }}</a-select-option>
        </a-select>
        <span>{{
          $t('hat.managementModal.setting.hint.notice.tipsfour')
        }}</span>
      </a-col>
    </a-row>
    <!-- 静电传感器灵敏度 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.voltage') }}
      </a-col>
      <a-col style="margin-left: 40px;">
        <span
          v-if="!postObj['ELECTROINDUCTION'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['ELECTROINDUCTION'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['ELECTROINDUCTION'].edit"
          :class="$style.textBtn"
          @click="postObj['ELECTROINDUCTION'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['ELECTROINDUCTION'].edit"
          :class="$style.textBtn"
          @click="handleSave('ELECTROINDUCTION')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{
          $t('hat.managementModal.setting.hint.debug.sensitivity')
        }}</span>
        <a-select
          v-model="postObj['ELECTROINDUCTION'].size"
          size="small"
          :disabled="!postObj['ELECTROINDUCTION'].edit"
          style="width: 100px;margin: 0 6px 0;"
        >
          <a-select-option :value="1">220V</a-select-option>
          <a-select-option :value="2">10KV</a-select-option>
          <a-select-option :value="3">35KV</a-select-option>
          <a-select-option :value="4">110KV</a-select-option>
          <a-select-option :value="5">220KV</a-select-option>
        </a-select>
        <span></span>
      </a-col>
    </a-row>
    <!-- 音量设置 -->
    <a-row :class="$style.row">
      <a-col>
        音量设置
      </a-col>
      <a-col style="margin-left: 10px;">
        <a-select
          v-model="postObj['VOLUME'].size"
          size="small"
          :disabled="!postObj['VOLUME'].edit"
          style="width: 100px;margin: 0 6px 0;"
        >
          <a-select-option
            v-for="(item, index) in 5"
            :key="index"
            :value="index + 1"
          >
            {{ index + 1 }}
          </a-select-option>
        </a-select>
        <span
          v-if="!postObj['VOLUME'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['VOLUME'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['VOLUME'].edit"
          :class="$style.textBtn"
          @click="postObj['VOLUME'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['VOLUME'].edit"
          :class="$style.textBtn"
          @click="handleSaveVolume('VOLUME')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1" :class="$style.dsc">
        音量由1到5逐步增大，广播和音视频通话音量统一由此控制
      </a-col>
    </a-row>
    <!-- 重置设备 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.reset') }}
      </a-col>
      <a-col style="margin-left: 20px;">
        <span
          v-if="$p.action('UPDATE')"
          :class="$style.textBtn"
          @click="handleReset"
        >
          {{ $t('common.setting') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.block]">
      <a-col :offset="1" :class="$style.dsc">{{
        $t('hat.managementModal.setting.hint.debug.deleteTips')
      }}</a-col>
    </a-row>
    <a-row>
      <a-col :class="[$style.row]">{{
        $t('hat.managementModal.setting.hint.debug.info')
      }}</a-col>
      <a-col :offset="1" :class="$style.deviceInfo">
        <p>
          {{ $t('hat.managementModal.setting.hint.debug.firmware') }}：{{
            deviceInfo.firmwareVersion
          }}
        </p>
        <p>
          {{ $t('hat.managementModal.setting.hint.debug.category') }}：{{
            deviceInfo.type === 'BASE'
              ? $t('hat.managementModal.setting.hint.debug.basic')
              : $t('hat.managementModal.setting.hint.notice.plus')
          }}<a
            :href="deviceInfo.detailUrl"
            target="_blank"
            v-if="deviceInfo.detailUrl"
            ><x-icon type="tc-icon-export" style="margin: 4px 0 0 2px;"
          /></a>
        </p>
        <p>
          ICCID：{{ deviceInfo.iotIccId
          }}<a-icon
            :type="
              deviceInfo.iccId === deviceInfo.iotIccId
                ? 'check-circle'
                : 'question-circle'
            "
            style="color: var(--primary); margin-left: 4px;"
            v-if="deviceInfo.iccId"
          />
        </p>
        <p>
          {{ $t('hat.managementModal.setting.hint.debug.production') }}：{{
            $moment(deviceInfo.factoryTime).format('YYYY-MM-DD')
          }}
        </p>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Component, Vue, Inject, InjectReactive } from 'vue-property-decorator';
import {
  resetDevice,
  putFrequency,
  updateDeviceData,
} from '@/services/smart-hat/device-management';
import { createFormModal } from '@triascloud/x-components';
import Tip from '@/components/tip';

@Component
export default class DeviceDebug extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @InjectReactive('deviceDetail') detail;
  @Inject('UPDATE_DEVICE_DETAIL') updateDeviceDetail;

  mounted() {
    this.getDeviceInfo();
  }
  timeList = [
    {
      label: '5' + this.$t('unit.m'),
      value: 5,
    },
    {
      label: '10' + this.$t('unit.m'),
      value: 10,
    },
    {
      label: '15' + this.$t('unit.m'),
      value: 15,
    },
    {
      label: '30' + this.$t('unit.m'),
      value: 30,
    },
    {
      label: '60' + this.$t('unit.m'),
      value: 60,
    },
    {
      label: '180' + this.$t('unit.m'),
      value: 180,
    },
    {
      label: '360' + this.$t('unit.m'),
      value: 360,
    },
    {
      label: '720' + this.$t('unit.m'),
      value: 720,
    },
    {
      label: '1440' + this.$t('unit.m'),
      value: 1440,
    },
  ];
  postObj = {
    CHECK_IN: {
      size: 5,
      edit: false,
    },
    STANDBY_STATUS: {
      size: 30,
      edit: false,
    },
    STANDBY_POSITION: {
      size: 30,
      edit: false,
    },
    BOOT_STATUS: {
      size: 30,
      edit: false,
    },
    BOOT_POSITION: {
      size: 30,
      edit: false,
    },
    UN_CAP: {
      size: 5,
      edit: false,
    },
    ELECTROINDUCTION: {
      size: 1,
      edit: false,
    },
    VOLUME: {
      size: 1,
      edit: false,
    },
  };
  async handleSave(type) {
    try {
      const data = {
        deviceId: this.deviceDetail.deviceId,
        frequency: type,
        size: this.postObj[type].size,
      };
      await putFrequency(data);
      this.$message.success(this.$t('common.SuccessfullySaved'));
      // Object.keys(this.postObj).forEach(key => {
      //   this.postObj[key].edit = false;
      // });
      this.postObj[type].edit = false;
      await this.updateDeviceDetail();
      this.getDeviceInfo();
    } catch {
      return false;
    }
  }
  async handleSaveVolume(type) {
    try {
      const data = {
        deviceId: this.detail.deviceId,
        exData: {
          value: this.postObj[type].size,
        },
        type: 'DEVICE_VOLUME',
      };
      await updateDeviceData(data);
      this.$message.success(this.$t('common.SuccessfullySaved'));
      this.postObj[type].edit = false;
      await this.updateDeviceDetail();
      this.getDeviceInfo();
    } catch {
      return false;
    }
  }
  async handleReset() {
    const text = this.$t('hat.managementModal.setting.hint.debug.tipsSeven');
    const tips = this.$t('hat.managementModal.setting.hint.debug.tipsSex');
    await createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>{text}</span>
          </template>
          <span slot="subTxt">{tips}</span>
        </Tip>
      ),
      {
        width: '442px',
        title: this.$t('hat.deviceManagement.operateConfirm'),
        onOk: async () => {
          await resetDevice(this.deviceDetail.deviceId);
          this.$message.success(this.$t('common.resetSuccessful') + '!');
          await this.updateDeviceDetail();
          this.getDeviceInfo();
        },
      },
    );
  }
  deviceInfo = {};
  async getDeviceInfo() {
    this.deviceInfo = this.detail;
    this.postObj['CHECK_IN'].size = this.deviceInfo.checkInFrequency;
    this.postObj['UN_CAP'].size = this.deviceInfo.unCapFrequency;
    this.postObj['STANDBY_STATUS'].size = this.deviceInfo.standbyFrequency;
    this.postObj[
      'STANDBY_POSITION'
    ].size = this.deviceInfo.standbyPositionFrequency;
    this.postObj['BOOT_STATUS'].size = this.deviceInfo.bootFrequency;
    this.postObj['BOOT_POSITION'].size = this.deviceInfo.bootPositionFrequency;
    this.postObj[
      'ELECTROINDUCTION'
    ].size = this.deviceInfo.electricityFrequency;
    this.postObj['VOLUME'].size = this.deviceInfo.photoSetting.volume;
  }
}
</script>

<style lang="less" module>
.deviceDebug {
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .textBtn {
      color: var(--primary);
      cursor: pointer;
      margin-right: 18px;
    }
  }
  .block {
    margin-bottom: 20px;
  }
  .dsc {
    color: var(--font-info);
  }
  .deviceInfo {
    & > p {
      margin-bottom: 10px;
    }
  }
}
</style>
