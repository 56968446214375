<template>
  <div
    v-if="imageSrc"
    id="editorCanvas"
    :width="imageWidth"
    :height="imageHeight"
    :style="{
      position: 'absolute',
      width: `${imageWidth}px`,
      height: `${imageHeight}px`,
      zIndex: 999,
      transform: `translate(${this.offset.x}px, ${this.offset.y}px) rotate(${this.rotateDeg}deg) scale(${this.scalePer})`,
    }"
    @mousedown="mousedown"
    @mouseup="mouseup"
  >
    <img
      :src="imageSrc"
      id="editingTarget"
      :width="imageWidth"
      :height="imageHeight"
      :style="{
        width: `${imageWidth}px`,
        height: `${imageHeight}px`,
      }"
      @load="handleLoaded"
    />
    <div
      :style="{
        touchAction: 'pinch-zoom',
        position: 'absolute',
        top: 0,
        width: `${imageWidth}px`,
        height: `${imageHeight}px`,
        transformOrigin: 'top left',
      }"
    >
      <div v-for="(tag, idx) in tagList" :key="'tag' + idx">
        <a-tooltip
          v-model="tag.marker.remarkFlag"
          placement="bottom"
          :trigger="tag.marker.opacity === 1 ? 'hover' : 'click'"
        >
          <template slot="title">
            <div>
              <div>可信度：{{ toFixedPercentage(tag.marker.probability) }}</div>
              <div>备注：{{ tag.marker.remark }}</div>
            </div>
          </template>
          <span
            :class="$style.tagWrapper"
            :style="{
              position: 'absolute',
              left: `${tag.x}px`,
              top: `${tag.y}px`,
              opacity: tag.opacity,
            }"
            ><a-icon
              v-if="tag.marker.opacity === 1"
              type="eye"
              :class="$style.icon"
              @click="handleEye(tag, 0)"
            /><a-icon
              v-else
              type="eye-invisible"
              :class="$style.icon"
              @click="handleEye(tag, 1)"
            />{{ tag.marker.tagName }}</span
          >
        </a-tooltip>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="imageWidth"
        :height="imageHeight"
        :viewBox="viewBox"
        ref="svgEle"
        id="markerImage"
      ></svg>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Settings } from './marking/marker/core/Settings';
import { SvgHelper } from './marking/marker/core/SvgHelper';
import {
  CalloutMarker,
  EllipseMarker,
  PolygonMarker,
  PolylineMarker,
} from './marking/marker';
import { toFixed } from './marking/marker/core/Utils';
import { getFileMark } from '@/services/smart-hat/file-mark';

const ALL_MARKER_TYPES = [
  CalloutMarker,
  EllipseMarker,
  PolylineMarker,
  PolygonMarker,
];

@Component()
export default class MarkerDisplay extends Vue {
  @Prop() target;
  @Prop() scalePer;
  @Prop() offset;
  @Prop() rotateDeg;
  @Prop() mousedown;
  @Prop() mouseup;
  imageWidth = 0;
  imageHeight = 0;

  handleEye(item, value) {
    item.marker.setOpacity(value);
    if (value === 0) {
      item.opacity = 0.1;
    } else {
      item.opacity = 1;
    }
  }

  toFixedPercentage(num = 0) {
    try {
      return (num * 100).toFixed(0) + '%';
    } catch (error) {
      return '0%';
    }
  }

  get imageSrc() {
    return this.target && this.target.$el && this.target.$el.src;
  }

  get imageTarget() {
    return this.target && this.target.$el;
  }

  @Prop({ type: Object }) opt;
  defaultState = {};
  async getMarkers() {
    const data = await getFileMark(this.opt.id);
    if (data.length > 0) {
      const item = data[0];
      const state = {
        width: item.imgWidth,
        height: item.imgHeight,
        markers: data,
      };
      this.defaultState = state;
    }
  }

  editorCanvas;
  editingTarget;
  mounted() {
    if (this.imageTarget) {
      this.imageWidth = this.imageTarget.clientWidth;
      this.imageHeight = this.imageTarget.clientHeight;
    }
  }

  onPointerOver(ev) {
    const hitMarker = this.markers.find(m => m.ownsTarget(ev.target));
    if (hitMarker !== undefined) {
      if (hitMarker.opacity === 1) {
        hitMarker.createRemark();
        // hitMarker.createHoverRect();
      }
    } else {
      this.markers.forEach(m => {
        m.removeRemark();
        // m.removeHoverRect();
      });
    }
  }

  attachEvents() {
    this.markerImage.addEventListener('pointerover', this.onPointerOver);
  }
  detachEvents() {
    this.markerImage.removeEventListener('pointerover', this.onPointerOver);
  }

  async handleLoaded() {
    await this.getMarkers();
    this.editorCanvas = document.getElementById('editorCanvas');
    this.editingTarget = document.getElementById('editingTarget');
    this.markerImage = document.getElementById('markerImage');
    // this.setupResizeObserver();
    this.restoreState(this.defaultState);
    // this.onTargetResize();

    setTimeout(() => {
      this.calcGroupPoints();
      this.attachEvents();
    }, 500);
  }

  beforeDestroy() {
    this.detachEvents();
  }

  /**
   *
   * @param {import('./marking/marker/core/MarkerBase').IPoint[]} points
   */
  makeBoundingBoxFromPoints(points) {
    const xPoints = points.map(point => point.x);
    const yPoints = points.map(point => point.y);
    const minX = Math.min(...xPoints);
    const maxX = Math.max(...xPoints);
    const minY = Math.min(...yPoints);
    const maxY = Math.max(...yPoints);
    const width = Math.abs(maxX - minX);
    const height = Math.abs(maxY - minY);
    return {
      left: minX,
      top: minY,
      width: width,
      height: height,
    };
  }

  get viewBox() {
    return `0 0 ${this.imageWidth} ${this.imageHeight}`;
  }

  markers = [];
  restoreState(state) {
    state.markers.forEach(markerState => {
      const markerType = ALL_MARKER_TYPES.find(
        mType => mType.typeName === markerState.typeName,
      );
      if (markerType !== undefined) {
        const marker = this.addNewMarker(markerType);
        marker.restoreState(markerState);
        this.markers.push(marker);
      }
    });

    if (
      state.width &&
      state.height &&
      (state.width !== this.imageWidth || state.height !== this.imageHeight)
    ) {
      this.scaleMarkers(
        this.imageWidth / state.width,
        this.imageHeight / state.height,
      );
    }
  }

  scaleMarkers(scaleX, scaleY) {
    this.markers.forEach(marker => {
      marker.scale(scaleX, scaleY);
    });
  }

  targetObserver;
  setupResizeObserver() {
    if (window.ResizeObserver) {
      this.targetObserver = new ResizeObserver(() => {
        this.onTargetResize();
      });
      this.targetObserver.observe(this.editorCanvas);
    }
  }
  onTargetResize() {
    const ratio = (1.0 * this.target.clientWidth) / this.target.clientHeight;
    const newWidth =
      this.editorCanvas.clientWidth / ratio > this.editorCanvas.clientHeight
        ? this.editorCanvas.clientHeight * ratio
        : this.editorCanvas.clientWidth;
    const newHeight =
      newWidth < this.editorCanvas.clientWidth
        ? this.editorCanvas.clientHeight
        : this.editorCanvas.clientWidth / ratio;
    this.resize(newWidth, newHeight);
  }

  resize(newWidth, newHeight) {
    newWidth = toFixed(newWidth);
    newHeight = toFixed(newHeight);
    const scaleX = toFixed(newWidth / this.imageWidth);
    const scaleY = toFixed(newHeight / this.imageHeight);

    this.imageWidth = Math.round(newWidth);
    this.imageHeight = Math.round(newHeight);
    if (
      this.target instanceof HTMLImageElement &&
      this.editingTarget instanceof HTMLImageElement
    ) {
      this.editingTarget.src = this.target.src;
    }

    this.scaleMarkers(scaleX, scaleY);
  }

  settings = new Settings();
  groups = [];
  addNewMarker(markerType, opts = {}) {
    const g = SvgHelper.createGroup();
    this.markerImage.appendChild(g);
    this.groups.push(g);
    return new markerType(g, {}, this.settings, opts);
  }

  tagList = [];
  calcGroupPoints() {
    this.tagList = [];
    const tagList = [];
    this.groups.forEach((g, idx) => {
      let bbox = g.getBBox();
      const points = [
        { x: bbox.x, y: bbox.y },
        { x: bbox.x + bbox.width, y: bbox.y },
        { x: bbox.x, y: bbox.y + bbox.height },
        { x: bbox.x + bbox.width, y: bbox.y + bbox.height },
      ];
      const rect = this.makeBoundingBoxFromPoints(points);
      const x = rect.left + rect.width;
      const y = rect.top;
      const marker = this.markers[idx];
      // marker.hoverLeft = x - rect.width;
      // marker.hoverTop = y;
      // marker.hoverWidth = rect.width;
      // marker.hoverHeight = rect.height;
      tagList.push({
        x,
        y,
        marker,
        opacity: 1,
      });
    });
    this.tagList = tagList;
  }
}
</script>
<style lang="less" module>
.wrapper {
  touch-action: pinch-zoom;
  position: absolute;
}
.tagWrapper {
  height: 32px;
  line-height: 32px;
  border-radius: 6px;
  color: var(--font);
  background-color: var(--main-bg);
  font-size: 16px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .icon {
    margin-right: 10px;
  }
}
</style>
