<template>
  <div :class="$style.index">
    <a-dropdown
      :trigger="['click']"
      :visible="visible"
      :overlayClassName="$style.dropdownSearch"
      @visibleChange="handleVisibleChange"
    >
      <x-icon
        type="tc-icon-search"
        :class="[
          $style['search-icon'],
          visible ? $style['search-icon-active'] : '',
        ]"
      />
      <a-menu slot="overlay">
        <a-menu-item>
          <a-select
            ref="select"
            show-search
            :show-arrow="false"
            :not-found-content="null"
            :auto-focus="true"
            :dropdown-class-name="$style.application__search"
            :class="$style.select"
            @search="handleSearch"
            :filter-option="false"
            @focus="searchVisible = true"
            @blur="searchVisible = false"
          >
            <div
              slot="dropdownRender"
              v-if="searchResult"
              :class="$style.result"
            >
              <a-spin v-if="fetching" slot="notFoundContent" size="small" />
              <template v-if="!isSearchEmpty">
                <template v-for="group in typeList">
                  <div
                    :key="group.value"
                    :class="$style.group"
                    v-if="
                      searchResult[group.value] &&
                        searchResult[group.value].length
                    "
                  >
                    <div :class="$style.title">{{ group.text }}</div>
                    <div
                      :class="$style['select-item']"
                      v-for="item in searchResult[group.value]"
                      :key="item.pkId"
                      @click.stop="jumpTo(item, group.type)"
                    >
                      <span
                        :class="$style['icon-block']"
                        :style="{ background: item.color }"
                      >
                        <x-custom-icon :type="item.icon" />
                      </span>
                      <x-ellipsis v-if="item.name.indexOf(keyword) > -1">
                        {{ item.name.substr(0, item.name.indexOf(keyword))
                        }}<span :class="$style.highlight">{{ keyword }}</span
                        >{{
                          item.name.substr(
                            item.name.indexOf(keyword) + keyword.length,
                          )
                        }}
                      </x-ellipsis>
                      <x-ellipsis v-else>{{ item.name }}</x-ellipsis>
                    </div>
                  </div>
                </template>
              </template>
              <empty-content slot="dropdownRender" v-else />
            </div>
          </a-select>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash-decorators';
import { getSearchRes } from '@/services/resource/screening';

import EmptyContent from '@/components/empty-content';

@Component({ components: { EmptyContent } })
export default class SearchSelect extends Vue {
  @Prop({ type: Array }) typeList;
  /** @name 搜索结果 */
  searchResult = null;
  /** @name 搜索loading */
  fetching = false;
  searchVisible = false;
  visible = false;

  get isSearchEmpty() {
    return (
      this.keyword &&
      !this.searchResult.applicationList.length &&
      !this.searchResult.groupList.length &&
      !this.searchResult.formList.length &&
      !this.searchResult.repositories.length &&
      !this.searchResult.repositoryDetails.length &&
      !this.searchResult.taskList.length
    );
  }

  handleSearch(value) {
    this.keyword = value;
    this.getSearchResult(value);
  }
  @debounce(300)
  async getSearchResult(value) {
    if (!value) return (this.searchResult = null);
    this.fetching = true;
    this.searchResult = await getSearchRes(value, 1, 10000);
    this.fetching = false;
  }

  async handleVisibleChange(visible) {
    this.visible = visible;
    if (visible) {
      await this.$nextTick();
      this.$refs.select.focus();
      this.$refs.select.$el.querySelector('input').focus();
    }
  }

  jumpTo(item, type) {
    this.visible = false;
    this.$emit('jumpTo', item, type);
  }
}
</script>
<style lang="less" module>
.index {
  position: relative;
  :global {
    .ant-select {
      width: 446px;
      height: 42px;
    }
    .ant-select-selection__rendered {
      margin-left: 11px;
    }
  }
}
.dropdownSearch {
  padding-top: 20px;
  background: transparent;
  box-shadow: inherit;
  .select {
    width: 446px;
  }
  :global {
    .ant-dropdown-menu {
      padding: 0;
      box-shadow: 0 0 0 2px var(--primary-fade-20);
    }
    .ant-select-search--inline {
      display: block !important;
    }
    .ant-select-selection--single {
      height: 42px;
    }
    .ant-select-selection__rendered {
      height: 40px;
      line-height: 40px;
    }
    .ant-dropdown-menu-item {
      padding: 0;
      max-width: 446px;
    }
  }
}
.application__search {
  .result {
    padding: 15px 12px;
  }
  .group {
    margin-bottom: 10px;
  }
  .title {
    color: var(--font-info);
    margin-bottom: 10px;
  }
  .select-item {
    display: flex;
    margin-bottom: 12px;
    cursor: pointer;
    color: var(--font);
    align-items: center;
  }
  .icon-block {
    display: inline-flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 8px;
  }
  .highlight {
    color: var(--delete);
  }
}

.search-icon {
  margin-right: 20px;
  margin-top: 4px;
  color: inherit;
  cursor: pointer;
}

.search-icon-active {
  color: var(--primary);
}
</style>
