import { getUnReadMessageList } from '@/services/things/alarm.js';
import { updateAlarmMessageStatus } from '@/services/iot-electric-box/message.js';
import { updateAlarmByIds } from '@/services/iot-electric-box/message.js';
import dayjs from 'dayjs';
export const EVENT_FAULT = 'ERROR'; // 故障
export const EVENT_WARN = 'WARN'; // 预警
export const EVENT_INFOS = 'INFOS';
export const TYPE_UN_READ = 'UN_READ'; // 未读
export const TYPE_READ = 'HAVE_READ'; // 已读
const BACK_ROUTER_LINK_KEY = '__BACK_ROUTE_LINK_KEY__';
import {
  Color_Rising,
  Color_Waiting,
  Color_Building,
  Color_Outing,
  Color_Electricbox,
  LEVEL_WAITING,
  LEVEL_BUILDING,
  LEVEL_OUTING,
  LEVEL_RISING,
  LEVEL_ELECTRIC_BOX,
} from '@/components/main-screen/final.js';

/** @name 获取概览页面返回链接 */
const fetchBackRouterLink = () => {
  const link = localStorage.getItem(BACK_ROUTER_LINK_KEY);
  return link ? link : '/iot/screen';
};
/** @name 设置概览页面返回链接 */
const setBackRouterLink = link => {
  localStorage.setItem(BACK_ROUTER_LINK_KEY, link);
};

const state = {
  faultArray: [], // 故障消息列表
  warnArray: [], // 预警消息列表
  infosArray: [], //信息消息列表
  messageType: '', // 消息类型
  backRouterLink: fetchBackRouterLink(), // 概览页面返回链接
  currentMatchSite: '', // 当前匹配的地图位置
  hasChangeFullScrren: false, // 大屏的全屏展示值，是否改变
  mapUrlQueue: [], // 跳转地址队列
  buttonGroups: [
    {
      label: 'screen.btn.notStarted',
      color: Color_Waiting,
      key: LEVEL_WAITING,
      selected: true,
    },
    {
      label: 'screen.btn.started',
      color: Color_Building,
      key: LEVEL_BUILDING,
      selected: true,
    },
    {
      label: 'screen.btn.terminalsInstalled',
      color: Color_Electricbox,
      key: LEVEL_ELECTRIC_BOX,
      selected: true,
    },
    {
      label: 'screen.btn.operating',
      color: Color_Rising,
      key: LEVEL_RISING,
      selected: true,
    },
    {
      label: 'screen.btn.finished',
      color: Color_Outing,
      key: LEVEL_OUTING,
      selected: true,
    },
  ],
  addMapUrlBoolean: false,
  snapMap: {},
};

const mutations = {
  SET_FAULTARRAY(state, list) {
    state.faultArray = list;
  },
  SET_WARNARRAY(state, list) {
    state.warnArray = list;
  },
  SET_INFOSARRAY(state, list) {
    state.infosArray = list;
  },
  SET_BACK_ROUTER_LINK(state, link) {
    state.backRouterLink = link;
  },
  SET_CURRENT_MATCH_SITE(state, site) {
    state.currentMatchSite = site;
  },
  SET_FULL_SCRREN_CHANGE(state, status) {
    state.hasChangeFullScrren = status;
  },
  SET_MAP_URL_QUEUE(state, payload) {
    state.mapUrlQueue = payload;
  },
  SET_BUTTON_GROUPS(state, payload) {
    state.buttonGroups = payload;
  },
  SET_ADD_MAP_URL_BOOLEAN(state, payload) {
    state.addMapUrlBoolean = payload;
  },
  SET_SNAP_MAP(state, payload) {
    state.snapMap = payload;
  },
};

const getters = {
  faultCount: state =>
    state.faultArray.filter(
      e =>
        dayjs(e.time).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') &&
        e.readStatus === TYPE_UN_READ,
    ).length,
  warnCount: state =>
    state.warnArray.filter(
      e =>
        dayjs(e.time).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') &&
        e.readStatus === TYPE_UN_READ,
    ).length,
  infosCount: state =>
    state.infosArray.filter(
      e =>
        dayjs(e.time).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') &&
        e.readStatus === TYPE_UN_READ,
    ).length,
  lastMapUrlQueueItem: state => state.mapUrlQueue[state.mapUrlQueue.length - 1],
  waitingButton: state => state.buttonGroups[0].selected,
  buildingButton: state => state.buttonGroups[1].selected,
  electricBoxButton: state => state.buttonGroups[2].selected,
  risingButton: state => state.buttonGroups[3].selected,
  outtingButton: state => state.buttonGroups[4].selected,
};

const actions = {
  /**
   * @name 获取消息列表
   */
  async getAlarmMessageList({ commit }, projectId) {
    const options = {};
    if (projectId) options.projectId = projectId;
    const data = await getUnReadMessageList(options);
    let faultArray = [];
    if (data.errors) {
      faultArray = data.errors.map(v => ({
        ...v,
        eventType: EVENT_FAULT,
      }));
    }
    let warnArray = [];
    if (data.warns) {
      warnArray = data.warns.map(v => ({
        ...v,
        eventType: EVENT_WARN,
      }));
    }
    let infosArray = [];
    if (data.infos) {
      infosArray = data.infos.map(v => ({
        ...v,
        eventType: EVENT_INFOS,
      }));
    }
    commit('SET_FAULTARRAY', faultArray);
    commit('SET_WARNARRAY', warnArray);
    commit('SET_INFOSARRAY', infosArray);
  },
  /** @name 获取消息列表通过webscoket  */
  getAlarmMessageListByWebsocket({ commit }, data) {
    let faultArray = [];
    if (data.errors) {
      faultArray = data.errors.map(v => ({
        ...v,
        eventType: EVENT_FAULT,
      }));
    }
    let warnArray = [];
    if (data.warns) {
      warnArray = data.warns.map(v => ({
        ...v,
        eventType: EVENT_WARN,
      }));
    }
    let infosArray = [];
    if (data.infos) {
      infosArray = data.infos.map(v => ({
        ...v,
        eventType: EVENT_INFOS,
      }));
    }
    commit('SET_FAULTARRAY', faultArray);
    commit('SET_WARNARRAY', warnArray);
    commit('SET_INFOSARRAY', infosArray);
  },
  /** @name 设置概览页面返回链接  */
  setBackRouterLinkAction({ commit }, link) {
    commit('SET_BACK_ROUTER_LINK', link);
    setBackRouterLink(link);
  },
  /**@name 已读 */
  async haveRead({ commit, state }, data) {
    const { id, eventType } = data;
    await updateAlarmMessageStatus(id);
    switch (eventType) {
      case EVENT_FAULT:
        {
          const faultArray = state.faultArray.map(e => ({ ...e }));
          faultArray.find(e => e.id === id).readStatus = TYPE_READ;
          commit('SET_FAULTARRAY', faultArray);
        }
        break;
      case EVENT_WARN:
        {
          const warnArray = state.warnArray.map(e => ({ ...e }));
          warnArray.find(e => e.id === id).readStatus = TYPE_READ;
          commit('SET_WARNARRAY', warnArray);
        }
        break;
      case EVENT_INFOS:
        {
          const infosArray = state.infosArray.map(e => ({ ...e }));
          infosArray.find(e => e.id === id).readStatus = TYPE_READ;
          commit('SET_INFOSARRAY', infosArray);
        }
        break;
    }
  },
  /**@name 一键已读 */
  async haveReadAll({ commit, state }) {
    let { faultArray, warnArray, infosArray } = state;
    const ids = [
      ...faultArray.filter(e => e.readStatus === TYPE_UN_READ).map(e => e.id),
      ...warnArray.filter(e => e.readStatus === TYPE_UN_READ).map(e => e.id),
      ...infosArray.filter(e => e.readStatus === TYPE_UN_READ).map(e => e.id),
    ];
    if (ids.length) {
      await updateAlarmByIds(ids);
      faultArray = faultArray.map(e => ({ ...e, readStatus: TYPE_READ }));
      warnArray = warnArray.map(e => ({ ...e, readStatus: TYPE_READ }));
      infosArray = infosArray.map(e => ({ ...e, readStatus: TYPE_READ }));
      commit('SET_FAULTARRAY', faultArray);
      commit('SET_WARNARRAY', warnArray);
      commit('SET_INFOSARRAY', infosArray);
    }
  },
  addMapUrlQueue({ commit, state }, payload) {
    commit('SET_MAP_URL_QUEUE', [...state.mapUrlQueue, payload]);
  },
  deleteMapUrlQueueOfPop({ commit, state }) {
    const arr = state.mapUrlQueue.slice();
    arr.pop();
    commit('SET_MAP_URL_QUEUE', [...arr]);
  },
  updateButtonGroups({ commit, state }, payload) {
    const arr = state.buttonGroups.slice();
    arr[payload.index].selected = payload.selected;
    commit('SET_BUTTON_GROUPS', arr);
  },
  setAddMapUrlBoolean({ commit }, payload) {
    commit('SET_SNAP_MAP', payload);
    commit('SET_ADD_MAP_URL_BOOLEAN', true);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
