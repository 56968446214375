<template>
  <div :class="$style.main">
    <a-row :gutter="10">
      <a-col :span="12">
        <!-- 位置分布-->
        <LocationDistribution />
      </a-col>
      <a-col :span="12">
        <!-- 分控箱占比-->
        <BoxProportion />
      </a-col>
    </a-row>
    <a-row :gutter="10" :class="$style.row">
      <a-col :span="12">
        <!-- 楼栋部署-->
        <BuildingDeploy />
      </a-col>
      <a-col :span="12">
        <!-- 部署效率-->
        <DeployEfficiency />
      </a-col>
    </a-row>
    <a-row :gutter="10" :class="$style.row">
      <!-- 事件分布-->
      <a-col :span="12">
        <EventDistribution />
      </a-col>
      <!-- 项目楼栋进度-->
      <a-col :span="12">
        <BuildingSchedule />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import BoxProportion from './box-proportion';
import DeployEfficiency from './deploy-efficiency';
import BuildingDeploy from './building-deploy';
import EventDistribution from './event-distribution';
import BuildingSchedule from './building-schedule';
import LocationDistribution from './location-distribution';

@Component({
  components: {
    BoxProportion,
    DeployEfficiency,
    BuildingDeploy,
    EventDistribution,
    BuildingSchedule,
    LocationDistribution,
  },
})
export default class DeviceStatus extends Vue {
  @Prop({ type: String, default: '' }) tenantId;
}
</script>

<style lang="less" module>
.main {
  margin: 5px;
  .row {
    margin: 10px 0;
  }
}
</style>
