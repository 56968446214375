import request from '../request';

const serviceName = '/iot-electric-box/message';

/**
 * @name 修改消息状态
 * @param { Number } id
 */
export function updateAlarmMessageStatus(id) {
  return request(`${serviceName}/status/haveRead/${id}`, {
    method: 'PUT',
  });
}

/**
 * @name 批量已读
 * @param {Number[]} ids
 */
export function updateAlarmByIds(ids) {
  return request(`${serviceName}/status/haveRead`, {
    method: 'PUT',
    body: ids,
  });
}

/**
 * @name 项目事件
 */
export function getProjectEvent(data) {
  return request(`${serviceName}/projectEvent`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 项目分析
 */
export function getProjectAnalyze(data) {
  return request(`${serviceName}/projectAnalyze`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/**
 * @name 项目事件详情
 */
export function getProjectEventDetails(data) {
  return request(`${serviceName}/projectEventDetails`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/**
 * @name 楼栋事件
 */
export function getBuildingEvent(data) {
  return request(`${serviceName}/buildingEvent`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/**
 * @name 楼栋分析
 */
export function getBuildingAnalyze(data) {
  return request(`${serviceName}/buildingAnalyze`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/**
 * @name 楼栋事件详情
 */
export function getBuildingEventDetails(data) {
  return request(`${serviceName}/buildingEventDetails`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
