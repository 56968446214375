<template>
  <div :class="$style.divCard">
    <div :class="$style.title">
      <span>{{ $t('generalView.count.periods ') }}</span>
      <a-tooltip placement="right">
        <template slot="title">
          <span>{{ $t('generalView.count.periodDesc') }}</span>
        </template>
        <a-icon :class="$style.icon" type="info-circle" />
      </a-tooltip>
    </div>
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <no-data-chart
        font="250px"
        isIcon
        :style="{ height: '100%', width: '100%' }"
      />
    </div>
    <div v-else ref="deployCharts" style="height: 400px; width: 780px" />
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { deployEfficiently } from '@/services/iot-electric-box/device-status';
import { crossStorageModule } from '@/enum/store.js';

@Component({})
export default class DeployEfficiency extends Vue {
  mounted() {
    this.getList();
  }
  isNoData = true;
  colorArr = ['#947dfa', '#00b893', '#fe5959', '#ffa348', '#516cf0', '#ffbf07'];
  legendData = ['1-30天', '31-60天', '61-90天', '91-150天', '151天及以上'];
  seriesData = [];
  async getList() {
    try {
      const data = await deployEfficiently();
      const count =
        data.between1And30 +
        data.between31And60 +
        data.between61And90 +
        data.between90And150 +
        data.greaterThan150;
      if (count > 0) {
        this.isNoData = false;
        this.seriesData = [
          { value: data.between1And30, name: '1-30天' },
          { value: data.between31And60, name: '31-60天' },
          { value: data.between61And90, name: '61-90天' },
          { value: data.between90And150, name: '91-150天' },
          { value: data.greaterThan150, name: '151天及以上' },
        ];
        this.$nextTick(() => {
          this.initChart();
        });
      }
    } catch {
      return false;
    }
  }
  @crossStorageModule.State skin;
  textColor = '';
  @Watch('skin', { immediate: true, deep: true })
  changeSkin(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.textColor = newVal.mode === 'dark' ? '#999999' : '#333333';
      this.initChart();
    }
  }
  initChart() {
    const chart = this.$refs.deployCharts;
    const myChart = echarts.init(chart);
    const textColor = this.textColor;
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}套({d}%)',
      },
      color: this.colorArr,
      legend: {
        data: this.legendData,
        orient: 'vertical',
        top: '30%',
        right: 58,
        textStyle: {
          color: textColor,
        },
        formatter: name => {
          if (this.seriesData.length) {
            const item = this.seriesData.filter(item => item.name === name)[0];
            return `${name}：${item.value}套`;
          }
        },
      },
      series: [
        {
          name: '部署效率',
          type: 'pie',
          center: ['25%', '50%'],
          radius: '70%',
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          labelLine: {
            show: false,
          },
          data: this.seriesData,
        },
      ],
    };
    if (this.skin.mode === 'dark') {
      option.legend.textStyle = {
        color: '#999999',
      };
    }
    myChart.setOption(option);
  }
}
</script>

<style lang="less" module>
.divCard {
  height: 360px;
  box-shadow: 0 0 8px 0 #eeeeee;
  border-radius: 5px;
  position: relative;
  .title {
    z-index: 999;
    font-size: 14px;
    margin: 10px;
    position: absolute;
    font-weight: 500;
    .icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>
